import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver, StorageAdapter as GraphCacheStorageAdapter } from '@urql/exchange-graphcache';
import { IntrospectionData } from '@urql/exchange-graphcache/dist/types/ast';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CareerRecommender_query_get_career_recommendations__embedded_careers_items_interest_profile_code: any;
  CourseRecommender_queryInput_getCourseRecommendations_lang: any;
  CourseRecommender_query_get_course_recommendations__embedded_courses_items_cost_currency: any;
  Date: any;
  DateTime: any;
  EmailAddress: any;
  JSON: any;
  JobRecommender_queryInput_get_job_recommendations_input_filters_array_items_first_detected_lang: any;
  JobRecommender_queryInput_get_job_recommendations_lang: any;
  NonNegativeInt: any;
  ObjMap: any;
  PositiveInt: any;
  ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_career_interest_items: any;
  ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_community_items: any;
  ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_cost_currency: any;
  ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_country: any;
  ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_support_needs_items: any;
  URL: any;
  UUID: any;
  Upload: any;
  Void: any;
};

export type AccountPreferences = {
  __typename?: 'AccountPreferences';
  marketing_consent_history?: Maybe<Array<MarketingConsentHistory>>;
  notifications?: Maybe<Array<Notification>>;
};

export type AccountPreferencesProfile = {
  __typename?: 'AccountPreferencesProfile';
  account_preferences?: Maybe<AccountPreferences>;
  user_uuid: Scalars['String'];
};

export type AddContactInput = {
  contacted_by_uuid: Scalars['String'];
  employer_uuid?: InputMaybe<Scalars['String']>;
  job_id: Scalars['String'];
  /** The id of the user whose profile the contact is added to. */
  user_uuid?: InputMaybe<Scalars['String']>;
};

export type AddTenantAssetInput = {
  asset_type: SupportedAssetType;
  configuration_id: Scalars['String'];
  language: SupportedLanguages;
  /** Ideally, it should be fetched from token, only use when manually creating asset using machine token */
  tenant_id?: InputMaybe<Scalars['String']>;
  /** S3 URL of the asset */
  url: Scalars['String'];
};

export type AddUserSkillsInput = {
  userSkills: Array<SkillInput>;
  userUUID: Scalars['String'];
};

export type AddressEmploymentOntario = {
  __typename?: 'AddressEmploymentOntario';
  address_type: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  postal_code: Scalars['String'];
  province: Scalars['String'];
  state: Scalars['String'];
  street_1: Scalars['String'];
  street_2: Scalars['String'];
  suite: Scalars['String'];
  zip: Scalars['String'];
};

export type AgeRange = {
  __typename?: 'AgeRange';
  max_age?: Maybe<Scalars['Float']>;
  min_age?: Maybe<Scalars['Float']>;
};

export type Assessment = {
  __typename?: 'Assessment';
  assessment_uuid: Scalars['String'];
  created_at: Scalars['DateTime'];
  created_by: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
  updated_by: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type AssetMetadata = {
  __typename?: 'AssetMetadata';
  /** stores current version (i.e. uuid of the asset) for each supported language */
  cookie_policy: TranslationField;
  /** stores current version (i.e. uuid of the asset) for each supported language */
  privacy_policy: TranslationField;
  /** stores current version (i.e. uuid of the asset) for each supported language */
  terms_of_service: TranslationField;
};

export type AssetsDto = {
  __typename?: 'AssetsDto';
  /** UUID of the asset */
  asset_id: Scalars['String'];
  asset_type: SupportedAssetType;
  language: SupportedLanguages;
  tenant_id: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  /** User ID */
  uploaded_by?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AssignCoachInput = {
  coach_uuid: Scalars['String'];
  user_uuids: Array<Scalars['String']>;
};

export type AssignGroupCoachesInput_Input = {
  /** Array of groups to update associations for */
  groups: Array<InputMaybe<MutationInput_AssignGroupCoaches_Input_Groups_Items_Input>>;
};

export type AsyncUpdateStatus = {
  __typename?: 'AsyncUpdateStatus';
  async_update_status_uuid: Scalars['String'];
  callback_url?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  /**
   * The ID of the record to update. This should be the ID that is generated client-side (i.e. not the ID generated by the EO API).
   * For example, for EAPs this would be the case_management_eap_id, not gov_eap_id.
   */
  record_id: Scalars['String'];
  record_type: EoAsyncUpdateStatusType;
  /** The response from the EO api. */
  response?: Maybe<Scalars['JSON']>;
  status: EoAsyncUpdateStatus;
  updated_at: Scalars['DateTime'];
};

export type BasicProfileData = {
  __typename?: 'BasicProfileData';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  user_uuid: Scalars['String'];
};

export type BatchUpdateResults = {
  __typename?: 'BatchUpdateResults';
  description?: Maybe<Scalars['String']>;
  externalRecordID?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type BuildUserResumeResponse = {
  __typename?: 'BuildUserResumeResponse';
  base64File?: Maybe<Scalars['String']>;
};

export type BulkDeleteUserData_200_Response = {
  __typename?: 'BulkDeleteUserData_200_response';
  deletedCount?: Maybe<Scalars['Float']>;
  msg?: Maybe<Mutation_BulkDeleteUserData_OneOf_0_Msg>;
  nextStepDocs?: Maybe<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs>;
  statusCode?: Maybe<Scalars['Float']>;
  userResumes?: Maybe<Mutation_BulkDeleteUserData_OneOf_0_UserResumes>;
};

export type BulkDeleteUserData_Request_Input = {
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BulkDeleteUserData_Response = BulkDeleteUserData_200_Response | ServerError | ServerError_Response;

export type BulkProfileUpdateInput = {
  profiles: Array<BulkProfileUpdateItem>;
  updateCognitoEmployerIds?: InputMaybe<Scalars['Boolean']>;
};

export type BulkProfileUpdateItem = {
  updates: BulkProfileUpdateValues;
  user_uuid: Scalars['String'];
};

export type BulkProfileUpdateResult = {
  __typename?: 'BulkProfileUpdateResult';
  failed: Array<BulkUpdateFailure>;
  successful: Array<Scalars['String']>;
};

export type BulkProfileUpdateValues = {
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_consent?: InputMaybe<Scalars['Boolean']>;
  employer_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Scalars['String']>;
  is_onboarded?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  pref_lang?: InputMaybe<Scalars['String']>;
  profileStep?: InputMaybe<Scalars['Int']>;
  recruiter_outreach?: InputMaybe<Scalars['Boolean']>;
  year_of_birth?: InputMaybe<Scalars['Int']>;
};

export type BulkUpdateFailure = {
  __typename?: 'BulkUpdateFailure';
  error: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type CandidateRecommender_Candidate = {
  __typename?: 'CandidateRecommender_Candidate';
  education_match?: Maybe<Scalars['Float']>;
  experience_match?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  match?: Maybe<Scalars['Float']>;
  overqualification?: Maybe<Scalars['Boolean']>;
  skill_match?: Maybe<Scalars['Float']>;
  trainable?: Maybe<Scalars['Boolean']>;
};

/**
 * When present, the recommender only considers candidates adhering to the
 * filter properties.
 */
export type CandidateRecommender_CandidateRecommenderFilters_Input = {
  /** Only return candidates from this list of UUIDs */
  candidates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  employer_id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  required_careers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required_skills?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  required_tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  trainable?: InputMaybe<Scalars['Boolean']>;
};

export type CandidateRecommender_CandidateRecommenderInput_Input = {
  /**
   * The UUID of the job for which we check if the candidate has
   * been contacted i.e. the `previously_contacted` field.
   * Note: this is not the classified job role id.
   */
  contact_job_id?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CandidateRecommender_CandidateRecommenderFilters_Input>;
  parameters?: InputMaybe<CandidateRecommender_CandidateRecommenderParameters_Input>;
};

export type CandidateRecommender_CandidateRecommenderOutput = {
  __typename?: 'CandidateRecommender_CandidateRecommenderOutput';
  _embedded?: Maybe<CandidateRecommender_EmbeddedCandidates>;
  _links?: Maybe<CandidateRecommender_CollectionLinks>;
  count?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type CandidateRecommender_CandidateRecommenderParameters_Input = {
  job_career_id?: InputMaybe<Scalars['String']>;
  job_edu_level?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  job_exp_level?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  job_role_id?: InputMaybe<Scalars['String']>;
  job_skill_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CandidateRecommender_CollectionLinks = {
  __typename?: 'CandidateRecommender_CollectionLinks';
  first?: Maybe<CandidateRecommender_Link>;
  last?: Maybe<CandidateRecommender_Link>;
  next?: Maybe<CandidateRecommender_Link>;
  prev?: Maybe<CandidateRecommender_Link>;
  self?: Maybe<CandidateRecommender_Link>;
};

export type CandidateRecommender_EmbeddedCandidates = {
  __typename?: 'CandidateRecommender_EmbeddedCandidates';
  candidates?: Maybe<Array<Maybe<CandidateRecommender_Candidate>>>;
};

export enum CandidateRecommender_HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export type CandidateRecommender_HealthResponse = {
  __typename?: 'CandidateRecommender_HealthResponse';
  status?: Maybe<CandidateRecommender_Query_Healthcheck_Status>;
};

export type CandidateRecommender_Link = {
  __typename?: 'CandidateRecommender_Link';
  href?: Maybe<Scalars['URL']>;
};

export enum CandidateRecommender_Query_Healthcheck_Status {
  FAIL = 'fail',
  PASS = 'pass',
  WARN = 'warn'
}

export type Career = {
  __typename?: 'Career';
  baseline_skills?: Maybe<Array<Skill>>;
  career_area?: Maybe<CareerArea>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interest_profile_code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  salary?: Maybe<MonetaryValue>;
  specialized_skills?: Maybe<Array<Skill>>;
};

export type CareerArea = {
  __typename?: 'CareerArea';
  career_area_id?: Maybe<Scalars['String']>;
  career_area_name?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  machine_key?: Maybe<Scalars['String']>;
  pref_label: Scalars['String'];
};

export type CareerGraph = {
  __typename?: 'CareerGraph';
  _id: Scalars['String'];
  baselineSkills: Array<CareerSkillGraph>;
  careerArea: CareerArea;
  description: Scalars['String'];
  interestProfileCode: Scalars['String'];
  label: Scalars['String'];
  salary: MonetaryValueGraph;
  specializedSkills: Array<CareerSkillGraph>;
};

export type CareerRecommender_Career = {
  __typename?: 'CareerRecommender_Career';
  baseline_skills?: Maybe<Array<Maybe<CareerRecommender_Skill>>>;
  career_area?: Maybe<CareerRecommender_CareerArea>;
  demand?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interest_profile_code?: Maybe<Scalars['CareerRecommender_query_get_career_recommendations__embedded_careers_items_interest_profile_code']>;
  label?: Maybe<Scalars['String']>;
  match?: Maybe<Scalars['Float']>;
  salary?: Maybe<CareerRecommender_MonetaryValue>;
  specialized_skills?: Maybe<Array<Maybe<CareerRecommender_Skill>>>;
};

export type CareerRecommender_CareerArea = {
  __typename?: 'CareerRecommender_CareerArea';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type CareerRecommender_CareerRecommenderOutput = {
  __typename?: 'CareerRecommender_CareerRecommenderOutput';
  _embedded?: Maybe<CareerRecommender_EmbeddedCareers>;
  _links?: Maybe<CareerRecommender_CollectionLinks>;
  count?: Maybe<Scalars['NonNegativeInt']>;
  total?: Maybe<Scalars['NonNegativeInt']>;
};

export type CareerRecommender_CareerRecommenderParameters_Input = {
  /** List of UUIDs of interested career areas */
  career_area_interest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  career_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  current_skill_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  role_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CareerRecommender_CollectionLinks = {
  __typename?: 'CareerRecommender_CollectionLinks';
  first?: Maybe<CareerRecommender_Link>;
  last?: Maybe<CareerRecommender_Link>;
  next?: Maybe<CareerRecommender_Link>;
  prev?: Maybe<CareerRecommender_Link>;
  self?: Maybe<CareerRecommender_Link>;
};

export type CareerRecommender_EmbeddedCareers = {
  __typename?: 'CareerRecommender_EmbeddedCareers';
  careers?: Maybe<Array<Maybe<CareerRecommender_Career>>>;
};

export enum CareerRecommender_HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export type CareerRecommender_HealthResponse = {
  __typename?: 'CareerRecommender_HealthResponse';
  status?: Maybe<CareerRecommender_Query_Healthcheck_Status>;
};

export type CareerRecommender_Link = {
  __typename?: 'CareerRecommender_Link';
  href?: Maybe<Scalars['String']>;
};

export type CareerRecommender_MonetaryValue = {
  __typename?: 'CareerRecommender_MonetaryValue';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CareerRecommender_RecommendedCareersRequest_Input = {
  /**
   * The configuration ID to retrieve and set the recommender configuration
   * from. Normally, we would get this through the JWT token and the tenant
   * service, but for testing (locally and in case of issues), we can use
   * this field to override it. This shouldn't be used in normal situations.
   */
  configuration_id?: InputMaybe<Scalars['UUID']>;
  parameters?: InputMaybe<CareerRecommender_CareerRecommenderParameters_Input>;
};

export type CareerRecommender_Skill = {
  __typename?: 'CareerRecommender_Skill';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  skilltype?: Maybe<CareerRecommender_Query_Get_Career_Recommendations__Embedded_Careers_Items_Baseline_Skills_Items_Skilltype>;
};

/** Sorting order of output recommendation. */
export enum CareerRecommender_SortBy {
  DEMAND = 'demand',
  SALARY = 'salary',
  SIMILARITY = 'similarity'
}

export enum CareerRecommender_Query_Get_Career_Recommendations__Embedded_Careers_Items_Baseline_Skills_Items_Skilltype {
  BASELINE = 'baseline',
  SPECIALIZED = 'specialized'
}

export enum CareerRecommender_Query_Healthcheck_Status {
  FAIL = 'fail',
  PASS = 'pass',
  WARN = 'warn'
}

export type CareerSkillGraph = {
  __typename?: 'CareerSkillGraph';
  id: Scalars['String'];
  label: Scalars['String'];
  proficiency: Scalars['Float'];
  skilltype: Scalars['String'];
};

export type CareerSortObject = {
  /** Field to sort */
  field: Scalars['String'];
  /** Sort field by */
  value: Scalars['String'];
};

export type Careers = {
  __typename?: 'Careers';
  career_area?: Maybe<CareerArea>;
  career_id: Scalars['String'];
  career_name: Scalars['String'];
};

export type CareersFiltersInput = {
  career_areas?: InputMaybe<Array<Scalars['String']>>;
  /** Config Status Filter type */
  status?: InputMaybe<ConfigStatusTypes>;
};

export type CaseNote = {
  __typename?: 'CaseNote';
  created_at: Scalars['DateTime'];
  created_by: CaseNoteEditor;
  id: Scalars['String'];
  modified_history: Array<CaseNoteModify>;
  note: Scalars['String'];
};

export type CaseNoteEditor = {
  __typename?: 'CaseNoteEditor';
  user_name: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type CaseNoteEditorInput = {
  user_name: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type CaseNoteInput = {
  created_at: Scalars['DateTime'];
  created_by: CaseNoteEditorInput;
  id: Scalars['String'];
  modified_history: Array<CaseNoteModifyInput>;
  note: Scalars['String'];
};

export type CaseNoteModify = {
  __typename?: 'CaseNoteModify';
  modified_at: Scalars['DateTime'];
  modified_by: CaseNoteEditor;
};

export type CaseNoteModifyInput = {
  modified_at: Scalars['DateTime'];
  modified_by: CaseNoteEditorInput;
};

export type Certification = {
  __typename?: 'Certification';
  certification_uuid: Scalars['String'];
  issue_date?: Maybe<Scalars['DateTime']>;
  /** Certification skills generated by LLM resume parser */
  llm_generated_skills?: Maybe<Array<LlmGeneratedSkill>>;
  name: Scalars['String'];
  provider: Scalars['String'];
};

export type CertificationForBuildResume = {
  __typename?: 'CertificationForBuildResume';
  issue_year: Scalars['String'];
  name: Scalars['String'];
  provider: Scalars['String'];
};

export type CertificationInput = {
  certification_uuid?: InputMaybe<Scalars['String']>;
  issue_date?: InputMaybe<Scalars['DateTime']>;
  /** Certification skills generated by LLM resume parser */
  llm_generated_skills?: InputMaybe<Array<LlmGeneratedSkillInput>>;
  name: Scalars['String'];
  provider: Scalars['String'];
};

export type CheckProfileExistenceInput = {
  email: Scalars['String'];
};

export type ClientIdentificationEmploymentOntario = {
  __typename?: 'ClientIdentificationEmploymentOntario';
  deaf: Scalars['Boolean'];
  deaf_blind: Scalars['Boolean'];
  first_nations: Scalars['Boolean'];
  francophone: Scalars['Boolean'];
  inuit: Scalars['Boolean'];
  metis: Scalars['Boolean'];
  new_comer: Scalars['Boolean'];
  odsp_ind: Scalars['Boolean'];
  person_with_disability: Scalars['Boolean'];
  racialized_person: Scalars['Boolean'];
  youth_ind: Scalars['Boolean'];
};

export type ClientTenant = {
  __typename?: 'ClientTenant';
  cognito_client_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Coach = {
  __typename?: 'Coach';
  coach_uuid: Scalars['String'];
  group_uuid: Scalars['String'];
  user?: Maybe<UserReference>;
};

export type CoachAssigned = {
  __typename?: 'CoachAssigned';
  coach_data?: Maybe<Array<CoachData>>;
  coach_uuid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type CoachData = {
  __typename?: 'CoachData';
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type CoachInput_Input = {
  email: Scalars['EmailAddress'];
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export type CognitoEmployerIdUpdateInput = {
  employerId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type CognitoUpdateError = {
  __typename?: 'CognitoUpdateError';
  error: Scalars['String'];
  userId: Scalars['String'];
};

export type CognitoUpdateResult = {
  __typename?: 'CognitoUpdateResult';
  failed: Array<CognitoUpdateError>;
  successful: Array<Scalars['String']>;
};

export type CombinedExportReportInput = {
  is_ascending?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<Scalars['String']>;
  /** the related tenant/instance for which to query reports */
  tenant_uuid?: InputMaybe<Scalars['String']>;
};

export type CommonBatchUpdateResponse = {
  __typename?: 'CommonBatchUpdateResponse';
  callBackURL?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  numberOfRecordReceived?: Maybe<Scalars['Float']>;
  receivedDateTime?: Maybe<Scalars['String']>;
  requestID?: Maybe<Scalars['String']>;
  requestStatus?: Maybe<Scalars['String']>;
  requestType?: Maybe<Scalars['String']>;
  resultInd?: Maybe<Scalars['Boolean']>;
};

export type ComprehensiveDeleteTenantInput_Input = {
  options?: InputMaybe<Scalars['String']>;
  tenantId: Scalars['String'];
};

export enum ConfigStatusTypes {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  DISABLED = 'DISABLED'
}

export type Configuration = {
  __typename?: 'Configuration';
  assets?: Maybe<AssetMetadata>;
  banner?: Maybe<TranslationField>;
  /** career ids to include or exclude */
  careers?: Maybe<FilterOptions>;
  configuration_id: Scalars['String'];
  /** course provider ids to include or exclude */
  course_providers?: Maybe<FilterOptions>;
  date_posted_range: Scalars['Float'];
  deep_case_management?: Maybe<DeepCaseManagement>;
  /** Determines the type of users that coaches who are not associated with any group have visibility to. */
  default_coach_visibility?: Maybe<Scalars['String']>;
  /** default language for the policy */
  default_policy_language?: Maybe<SupportedLanguages>;
  featured_job_providers?: Maybe<Array<JobSource>>;
  insight_configuration?: Maybe<Array<InsightConfiguration>>;
  /** Restrict Job Search based on user's employer id. */
  job_employer_restriction: Scalars['Boolean'];
  /** Restrict Job Search based on user's language preference when set to true. */
  job_language_restriction: Scalars['Boolean'];
  /** job source slugs to include or exclude - these come from jobs service at the moment */
  job_sources?: Maybe<JobSourceConfiguration>;
  /** Label: <INSTANCE_NAME> - UUID <CONFIG_ID> */
  label: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  location_restrictions?: Maybe<Array<CountryDivisions>>;
  marketingConsent?: Maybe<TranslationField>;
  overlayDescription?: Maybe<TranslationField>;
  overlayTitle?: Maybe<TranslationField>;
  /** resource ids to include or exclude */
  resources?: Maybe<FilterOptions>;
  /** @deprecated Tenants can have multiple rest clients, so use rest_client_ids instead. */
  rest_client_id?: Maybe<Scalars['String']>;
  rest_client_ids?: Maybe<Array<Scalars['String']>>;
  /** Allows recruiter to view all candidates if set to true. */
  show_all_candidates: Scalars['Boolean'];
  /** recruiter visibility restrict by tags. */
  show_candidate_tag_rule?: Maybe<TagRule>;
  /** Determines whether to display suggested learning opportunities for each job posting */
  show_course_recs_for_jobs: Scalars['Boolean'];
  /** List of supported career area ids */
  supported_career_area_ids?: Maybe<Array<Scalars['String']>>;
  tenant_id?: Maybe<Scalars['String']>;
};

export enum ConfigurationCategory {
  CAREERS = 'CAREERS',
  /** @deprecated Separate job sources from other configurations */
  JOB_SOURCES = 'JOB_SOURCES',
  LEARNING_PROVIDERS = 'LEARNING_PROVIDERS',
  RESOURCES = 'RESOURCES'
}

export type ConfigureTenantInput = {
  adminUsers: Array<UserInfoInput>;
  superAdminUsers?: InputMaybe<Array<InputMaybe<UserInfoInput>>>;
  tenantId: Scalars['String'];
};

export type ConfirmNextStepDocInput = {
  docUrls?: InputMaybe<Array<Scalars['String']>>;
  fromEndUser?: InputMaybe<Scalars['Boolean']>;
  step_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type ConfirmNextStepDocResponse = {
  __typename?: 'ConfirmNextStepDocResponse';
  finalDocs?: Maybe<Array<NextStepDoc>>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type ConfirmRegistrationRequestBody_Input = {
  email: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type ConfirmRegistrationResponse = {
  __typename?: 'ConfirmRegistrationResponse';
  verified?: Maybe<Scalars['Boolean']>;
};

export type Contact = {
  __typename?: 'Contact';
  contact_uuid: Scalars['String'];
  contacted_by_name: Scalars['String'];
  contacted_by_uuid: Scalars['String'];
  created_at: Scalars['DateTime'];
  employer_name?: Maybe<Scalars['String']>;
  employer_uuid?: Maybe<Scalars['String']>;
  job_id: Scalars['String'];
  job_title: Scalars['String'];
};

export type ContactInfoEmploymentOntario = {
  __typename?: 'ContactInfoEmploymentOntario';
  addresses: Array<AddressEmploymentOntario>;
  emails: Array<EmailEmploymentOntario>;
  phones: Array<PhoneEmploymentOntario>;
};

export type Cost = {
  __typename?: 'Cost';
  currency: Scalars['String'];
  value: Scalars['Float'];
};

export type CountryDivisions = {
  __typename?: 'CountryDivisions';
  country_code: Scalars['String'];
  country_label: Scalars['String'];
  /** Country division/sub-division i.e. province for Canada or state for U.S. */
  division_code: Scalars['String'];
  division_label: Scalars['String'];
  id: Scalars['String'];
};


export type CountryDivisionsCountry_LabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


export type CountryDivisionsDivision_LabelArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type Course = {
  __typename?: 'Course';
  careers?: Maybe<Array<CareerGraph>>;
  cost?: Maybe<CourseCost>;
  country?: Maybe<Scalars['String']>;
  course_results?: Maybe<Array<CourseResult>>;
  course_type?: Maybe<Array<Scalars['String']>>;
  credits_accepted?: Maybe<Scalars['Boolean']>;
  delivery?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  diplomas?: Maybe<Array<Scalars['String']>>;
  duration_hours?: Maybe<Scalars['Float']>;
  duration_unit?: Maybe<Scalars['String']>;
  duration_value?: Maybe<Scalars['Float']>;
  lang?: Maybe<Scalars['String']>;
  lang_label?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  pref_label: Scalars['String'];
  provider?: Maybe<CourseProvider>;
  skills?: Maybe<Array<CourseSkillGraph>>;
  source_id?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<CourseSubject>>;
  subscription_required?: Maybe<Scalars['Boolean']>;
  tenant_id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type CourseCost = {
  __typename?: 'CourseCost';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type CourseProvider = {
  __typename?: 'CourseProvider';
  _id: Scalars['String'];
  pref_label: Scalars['String'];
};

export type CourseRecommender_Career = {
  __typename?: 'CourseRecommender_Career';
  id?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
};

export type CourseRecommender_CollectionLinks = {
  __typename?: 'CourseRecommender_CollectionLinks';
  first?: Maybe<CourseRecommender_Link>;
  last?: Maybe<CourseRecommender_Link>;
  next?: Maybe<CourseRecommender_Link>;
  prev?: Maybe<CourseRecommender_Link>;
  self?: Maybe<CourseRecommender_Link>;
};

export type CourseRecommender_Course = {
  __typename?: 'CourseRecommender_Course';
  careers?: Maybe<Array<Maybe<CourseRecommender_Career>>>;
  cost?: Maybe<CourseRecommender_Course_Cost>;
  credits_accepted?: Maybe<Scalars['Boolean']>;
  diplomas?: Maybe<Array<Maybe<Scalars['String']>>>;
  duration_hours?: Maybe<Scalars['Float']>;
  duration_unit?: Maybe<Scalars['String']>;
  duration_value?: Maybe<Scalars['Float']>;
  id: Scalars['UUID'];
  label?: Maybe<Scalars['String']>;
  owner?: Maybe<CourseRecommender_Provider>;
  provider?: Maybe<CourseRecommender_Provider>;
  skills?: Maybe<Array<Maybe<CourseRecommender_Skill>>>;
  subscription_required?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['URL']>;
};

export type CourseRecommender_CourseRecommenderOutput = {
  __typename?: 'CourseRecommender_CourseRecommenderOutput';
  _embedded?: Maybe<CourseRecommender_EmbeddedCourses>;
  _links?: Maybe<CourseRecommender_CollectionLinks>;
  count?: Maybe<Scalars['NonNegativeInt']>;
  total?: Maybe<Scalars['NonNegativeInt']>;
};

/** Input parameters for the recommender */
export type CourseRecommender_CourseRecommenderParameters_Input = {
  /** The User's most recent career in their work history */
  most_recent_career_id?: InputMaybe<Scalars['String']>;
  /** Career the user is targeting */
  target_career_id?: InputMaybe<Scalars['String']>;
  /** List of skills the user would like to acquire */
  target_skill_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** List of skills from the user skills profile */
  user_skill_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CourseRecommender_Course_Cost = {
  __typename?: 'CourseRecommender_Course_cost';
  currency?: Maybe<Scalars['CourseRecommender_query_get_course_recommendations__embedded_courses_items_cost_currency']>;
  value?: Maybe<Scalars['Float']>;
};

export type CourseRecommender_EmbeddedCourses = {
  __typename?: 'CourseRecommender_EmbeddedCourses';
  courses?: Maybe<Array<Maybe<CourseRecommender_Course>>>;
};

export enum CourseRecommender_HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export type CourseRecommender_HealthResponse = {
  __typename?: 'CourseRecommender_HealthResponse';
  status?: Maybe<CourseRecommender_Query_Healthcheck_Status>;
};

export type CourseRecommender_Link = {
  __typename?: 'CourseRecommender_Link';
  href?: Maybe<Scalars['URL']>;
};

export type CourseRecommender_Provider = {
  __typename?: 'CourseRecommender_Provider';
  id?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['URL']>;
};

/** Request body to retrieve recommended courses */
export type CourseRecommender_RecommendedCoursesRequest_Input = {
  /**
   * The configuration ID to retrieve and set the recommender configuration
   * from. Normally, we would get this through the JWT token and the tenant
   * service, but for testing (locally and in case of issues), we can use
   * this field to override it. This shouldn't be used in normal situations.
   */
  configuration_id?: InputMaybe<Scalars['UUID']>;
  parameters?: InputMaybe<CourseRecommender_CourseRecommenderParameters_Input>;
};

export type CourseRecommender_Skill = {
  __typename?: 'CourseRecommender_Skill';
  id?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
  skilltype?: Maybe<CourseRecommender_Query_Get_Course_Recommendations__Embedded_Courses_Items_Skills_Items_Skilltype>;
};

export enum CourseRecommender_Query_Get_Course_Recommendations__Embedded_Courses_Items_Skills_Items_Skilltype {
  BASELINE = 'baseline',
  SPECIALIZED = 'specialized'
}

export enum CourseRecommender_Query_Healthcheck_Status {
  FAIL = 'fail',
  PASS = 'pass',
  WARN = 'warn'
}

export type CourseResult = {
  __typename?: 'CourseResult';
  result: Scalars['String'];
  result_type: Scalars['String'];
};

export type CourseSkillGraph = {
  __typename?: 'CourseSkillGraph';
  pref_label: Scalars['String'];
  skill_uuid: Scalars['String'];
  skilltype?: Maybe<Scalars['String']>;
};

export type CourseSubject = {
  __typename?: 'CourseSubject';
  _id: Scalars['String'];
  pref_label: Scalars['String'];
};

export type CoursesResponse = {
  __typename?: 'CoursesResponse';
  count: Scalars['Int'];
  courses: Array<Course>;
};

export type CreateAsyncUpdateStatusInput = {
  callback_url: Scalars['String'];
  /**
   * The ID of the record to update. This should be the ID that is generated client-side (i.e. not the ID generated by the EO API).
   * For example, for EAPs this would be the case_management_eap_id, not gov_eap_id.
   */
  record_id: Scalars['String'];
  /** The type of record the ID corresponds to. */
  record_type: EoAsyncUpdateStatusType;
  /** The response from the callback URL. */
  response?: InputMaybe<Scalars['JSON']>;
  status: EoAsyncUpdateStatus;
};

export type CreateConfigurationInputV2 = {
  /** overwrite the entire careers object */
  careers?: InputMaybe<FilterOptionsInput>;
  /** overwrite the entire course_providers object */
  course_providers?: InputMaybe<FilterOptionsInput>;
  /** Date posted range for job search in days (-1 for Anytime) */
  date_posted_range?: InputMaybe<Scalars['Float']>;
  deep_case_management?: InputMaybe<DeepCaseManagementMutationInput>;
  default_coach_visibility?: InputMaybe<DefaultCoachVisibilityEnum>;
  default_policy_language?: InputMaybe<SupportedLanguages>;
  featured_job_providers?: InputMaybe<Array<JobSource>>;
  insight_configuration?: InputMaybe<Array<InsightConfigurationInput>>;
  job_employer_restriction?: InputMaybe<Scalars['Boolean']>;
  job_language_restriction?: InputMaybe<Scalars['Boolean']>;
  job_sources?: InputMaybe<JobSourceOptionsInput>;
  /** Country Division ID string list */
  location_restrictions?: InputMaybe<Array<Scalars['String']>>;
  /** overwrite the entire resources object */
  resources?: InputMaybe<FilterOptionsInput>;
  rest_client_id?: InputMaybe<Scalars['String']>;
  rest_client_ids?: InputMaybe<Array<Scalars['String']>>;
  show_all_candidates?: InputMaybe<Scalars['Boolean']>;
  /** recruiter visibility restrict by tags. */
  show_candidate_tag_rule?: InputMaybe<TagRuleInput>;
  show_course_recs_for_jobs?: InputMaybe<Scalars['Boolean']>;
  supported_career_area_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Tenant ID */
  tenantId: Scalars['String'];
  /** Name of a tenant, will be used for label along with the UUID version of the Binary ID */
  tenantName: Scalars['String'];
};

export type CreateEmployerCaseNoteRequestBody_Input = {
  /** an admin user uuid */
  created_by: Scalars['String'];
  description: Scalars['String'];
  employer_uuid: Scalars['String'];
};

export type CreateEmployerInput_Input = {
  /** The language displayed in the invitation email */
  invitation_email_locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recruiters?: InputMaybe<Array<InputMaybe<RecruiterInput_Input>>>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
};

export type CreateFormInput = {
  components?: InputMaybe<Array<Input_InputField>>;
  description?: InputMaybe<Scalars['String']>;
  /**
   * Input to specify which registration form fields are active.
   *       Only needed when forms-v3-default-questions flag is disabled.
   *       Note: This will be deprecated once the flag is removed.
   */
  registrationFormInput?: InputMaybe<RegistrationFormInput>;
  status?: InputMaybe<FormStatus>;
  title?: InputMaybe<Scalars['String']>;
  type: FormTypes;
};

export type CreateFormResponse = {
  __typename?: 'CreateFormResponse';
  form_uuid?: Maybe<Scalars['String']>;
  form_version_uuid?: Maybe<Scalars['String']>;
};

export type CreateFormVersionInput = {
  components: Array<Input_InputField>;
  deletedQuestionUuids: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  formUuid: Scalars['String'];
  /**
   * Input to specify which registration form fields are active.
   *       Only needed when forms-v3-default-questions flag is disabled.
   *       Note: This will be deprecated once the flag is removed.
   */
  registrationFormInput?: InputMaybe<RegistrationFormInput>;
  status: FormStatus;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateGroupInput_Input = {
  coaches?: InputMaybe<Array<InputMaybe<CoachInput_Input>>>;
  /** The language displayed in the invitation email */
  invitation_email_locale?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateIntegrationInput = {
  api_key: Scalars['String'];
  base_api_url: Scalars['String'];
  integration_type: IntegrationType;
  tenant_id?: InputMaybe<Scalars['ID']>;
};

export type CreateNewDefaultQuestionsBodyRequest_Input = {
  questions?: InputMaybe<Array<InputMaybe<DefaultQuestionInputField_Input>>>;
  tenantUuid?: InputMaybe<Scalars['String']>;
};

export type CreateNextStepsMetadataInput = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  is_completable: Scalars['Boolean'];
  link?: InputMaybe<LinkInput>;
  locale: Scalars['String'];
  next_step_type?: InputMaybe<Scalars['String']>;
  requireUpload?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type CreateProfileInput = {
  communitiesObject?: InputMaybe<Array<DataSourceTypeInput>>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<DataSourceTypeInput>;
  email?: InputMaybe<Scalars['String']>;
  email_consent?: InputMaybe<Scalars['Boolean']>;
  employer_id?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Array<DataSourceTypeInput>>;
  ethnicities?: InputMaybe<Array<DataSourceTypeInput>>;
  external_identities?: InputMaybe<ExternalIdentitiesInput>;
  first_name?: InputMaybe<Scalars['String']>;
  genderObject?: InputMaybe<DataSourceTypeInput>;
  help_response?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  link_response?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  marketing_consent?: InputMaybe<Scalars['Boolean']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  pref_lang?: InputMaybe<Scalars['String']>;
  recruiter_outreach?: InputMaybe<Scalars['Boolean']>;
  signup_type?: InputMaybe<ExternalIdentityTypes>;
  tags?: InputMaybe<Array<UserTagInput>>;
  tenantRoles?: InputMaybe<Array<TenantRoleInput>>;
  tenants?: InputMaybe<Array<Scalars['String']>>;
  user_uuid: Scalars['String'];
};

export type CreateReportExportInput = {
  report_name: Scalars['String'];
  tenant_uuids?: InputMaybe<Array<Scalars['String']>>;
  timezone?: InputMaybe<Scalars['String']>;
  user_uuids: Array<Scalars['String']>;
};

export type CreateRoleInput = {
  email: Scalars['String'];
  label: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type CreateSavedTalentsRequestBody_Input = {
  job_uuid?: InputMaybe<Scalars['String']>;
  talent_user_uuids: Array<InputMaybe<Scalars['String']>>;
};

export type CreateUserCaseNoteInput = {
  note: Scalars['String'];
  user_uuid: Scalars['ID'];
};

export type CreateUserTagsInput = {
  tags: Array<TaggingUserTagInput>;
  user_uuids: Array<Scalars['String']>;
};

export type CreatedAtRangeFilterInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CurrencyInputField = {
  __typename?: 'CurrencyInputField';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  hasRange: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholder?: Maybe<Scalars['String']>;
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<InputFieldUserCurrencyResponse>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type CurrencyInputFieldUserResponse = {
  __typename?: 'CurrencyInputFieldUserResponse';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type CurrencyInputFieldUserResponse_Input = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type CurrencyInputField_Input = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription: Scalars['Boolean'];
  hasRange: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order?: InputMaybe<Scalars['Int']>;
  placeholder?: InputMaybe<Scalars['String']>;
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<CurrencyInputFieldUserResponse_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type CurrentFormVersionResponse = {
  __typename?: 'CurrentFormVersionResponse';
  components?: Maybe<Array<Maybe<InputField>>>;
  data: Scalars['JSON'];
  description: Scalars['String'];
  description_i18n?: Maybe<TranslatedField>;
  formUuid: Scalars['String'];
  formVersionUuid: Scalars['String'];
  source_language?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title_i18n?: Maybe<TranslatedField>;
  version?: Maybe<Scalars['Int']>;
};

export type CurrentUserTenantResponse = {
  __typename?: 'CurrentUserTenantResponse';
  tenant: Tenant;
};

export type DataSourceType = {
  __typename?: 'DataSourceType';
  additionalText?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type DataSourceTypeInput = {
  additionalText?: InputMaybe<Scalars['String']>;
  dataSource?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

export type DateInputField = {
  __typename?: 'DateInputField';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  hasRange: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholder?: Maybe<Scalars['String']>;
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<InputFieldUserDateResponse>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DateInputFieldUserResponse = {
  __typename?: 'DateInputFieldUserResponse';
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type DateInputFieldUserResponse_Input = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type DateInputField_Input = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription: Scalars['Boolean'];
  hasRange: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order?: InputMaybe<Scalars['Int']>;
  placeholder?: InputMaybe<Scalars['String']>;
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<DateInputFieldUserResponse_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DeepCaseManagement = {
  __typename?: 'DeepCaseManagement';
  gov_client_configuration?: Maybe<GovClientConfiguration>;
};

export type DeepCaseManagementMutationInput = {
  configurationId?: InputMaybe<Scalars['String']>;
  gov_client_configuration?: InputMaybe<GovClientConfigurationInput>;
};

export enum DefaultCoachVisibilityEnum {
  ALLUSERS = 'AllUsers',
  ASSIGNEDUSERS = 'AssignedUsers',
  NOUSERS = 'NoUsers'
}

export type DefaultQuestionField = {
  __typename?: 'DefaultQuestionField';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<DefaultQuestionOptionWithTags>>>;
  questionText?: Maybe<Scalars['String']>;
  questionTextI18n?: Maybe<TranslatedField>;
};

export type DefaultQuestionGraphInputField = {
  __typename?: 'DefaultQuestionGraphInputField';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options?: Maybe<Array<Maybe<DefaultQuestionOptionWithTags>>>;
  order?: Maybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DefaultQuestionGraphInputField_Input = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options?: InputMaybe<Array<InputMaybe<DefaultQuestionOptionWithTags_Input>>>;
  order?: InputMaybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DefaultQuestionInputField_Input = {
  description?: InputMaybe<Scalars['String']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAssigned?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Array<InputMaybe<DefaultQuestionOptionWithTags_Input>>>;
  questionText?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type DefaultQuestionOptionWithTags = {
  __typename?: 'DefaultQuestionOptionWithTags';
  additionalText?: Maybe<Scalars['String']>;
  additionalTextI18n?: Maybe<TranslatedField>;
  dataSource: Scalars['String'];
  isChecked: Scalars['Boolean'];
  requiredAditionalTextBox: Scalars['Boolean'];
  tags?: Maybe<Array<FormBuilderTag>>;
  text: Scalars['String'];
  textI18n?: Maybe<TranslatedField>;
  value: Scalars['Int'];
};

export type DefaultQuestionOptionWithTags_Input = {
  additionalText?: InputMaybe<Scalars['String']>;
  additionalTextI18n?: InputMaybe<TranslatedField_Input>;
  dataSource?: InputMaybe<Scalars['String']>;
  isChecked?: InputMaybe<Scalars['Boolean']>;
  requiredAditionalTextBox?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<InputMaybe<FormBuilderTag_Input>>>;
  text?: InputMaybe<Scalars['String']>;
  textI18n?: InputMaybe<TranslatedField_Input>;
  value?: InputMaybe<Scalars['Float']>;
};

export type DefaultQuestionUpdateInputField_Input = {
  description?: InputMaybe<Scalars['String']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAssigned?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Array<InputMaybe<DefaultQuestionOptionWithTags_Input>>>;
  questionText?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags = {
  __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags';
  aditionalResponse?: Maybe<Scalars['String']>;
  textValue?: Maybe<Scalars['String']>;
  value?: Maybe<Array<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>>;
};

export type DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags_Input = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  textValue?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Array<InputMaybe<SelectedMultiChoiceCheckboxInputFieldOptionWithTags_Input>>>;
};

export type DefaultQuestionsResponse = {
  __typename?: 'DefaultQuestionsResponse';
  assigned_form_title?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_assigned?: Maybe<Scalars['Boolean']>;
  question?: Maybe<DefaultQuestionField>;
  tenant_uuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type DelNextStepDocInput = {
  s3Key?: InputMaybe<Scalars['String']>;
};

export type DelNextStepDocResponse = {
  __typename?: 'DelNextStepDocResponse';
  docDeleted?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type DeleteConfigurationOutput = {
  __typename?: 'DeleteConfigurationOutput';
  deletedStatus: Scalars['String'];
};

export type DeleteEmployerCaseNoteRequestBody_Input = {
  employer_uuid: Scalars['String'];
};

export type DeleteEmployersByTenantIdRequestBody_Input = {
  tenantUuid: Scalars['String'];
};

export type DeleteEmployersByTenantResponse = {
  __typename?: 'DeleteEmployersByTenantResponse';
  deletedCount?: Maybe<Scalars['Int']>;
};

export type DeleteEmployersRequestBody_Input = {
  employerUuids: Array<InputMaybe<Scalars['String']>>;
};

export type DeleteExportReportsOutput = {
  __typename?: 'DeleteExportReportsOutput';
  deleteStatus: Scalars['String'];
};

export type DeleteProfilesOutput = {
  __typename?: 'DeleteProfilesOutput';
  deletedCount?: Maybe<Scalars['Float']>;
};

export type DeleteRecruiterByUserUuidsRequestBody_Input = {
  user_uuids: Array<InputMaybe<Scalars['String']>>;
};

export type DeleteRecruitersRequestBody_Input = {
  ids: Array<InputMaybe<Scalars['String']>>;
};

export type DeleteTenantNextStepsOutput = {
  __typename?: 'DeleteTenantNextStepsOutput';
  deleteStatus: Scalars['String'];
};

export type DeleteTenantPolicyAssetInput = {
  asset_type?: InputMaybe<Array<SupportedAssetType>>;
  configuration_id: Scalars['String'];
  language: SupportedLanguages;
};

export type DeleteUserCaseNoteInput = {
  case_note_uuid: Scalars['ID'];
  user_uuid: Scalars['ID'];
};

export type DeleteUserNextStepsOutput = {
  __typename?: 'DeleteUserNextStepsOutput';
  deletedCount?: Maybe<Scalars['Float']>;
};

export type DeleteUserStepInput = {
  next_step_uuid: Scalars['String'];
  tenant_id: Scalars['String'];
  user_id: Scalars['String'];
};

export type DeleteUserTagsInput = {
  tag_uuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_uuids: Array<Scalars['String']>;
};

export type DeleteUsersInput = {
  tenant_uuid: Scalars['String'];
  user_data: Array<UserDataInput>;
};

export type DeleteUsersOutput = {
  __typename?: 'DeleteUsersOutput';
  deletedCount: Scalars['Int'];
};

export type DetectLanguageOption = {
  defaultLanguage?: InputMaybe<Scalars['String']>;
  isRestrictedToSupportedLanguage: Scalars['Boolean'];
};

export type DownloadNextStepDocInput = {
  fromEndUser?: InputMaybe<Scalars['Boolean']>;
  step_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type DownloadNextStepDocsResponse = {
  __typename?: 'DownloadNextStepDocsResponse';
  docUrls?: Maybe<Array<NextStepDocDownload>>;
  msg?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type DropdownInputField = {
  __typename?: 'DropdownInputField';
  defaultOption: DropdownInputFieldOption;
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<Maybe<DropdownInputFieldOption>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<InputFieldUserNumberResponse>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DropdownInputFieldInput_Input = {
  defaultOption: DropdownInputFieldOptionInput_Input;
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<InputMaybe<DropdownInputFieldOptionInput_Input>>;
  order?: InputMaybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<DropdownInputFieldUserResponseInput_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DropdownInputFieldOption = {
  __typename?: 'DropdownInputFieldOption';
  text: Scalars['String'];
  textI18n?: Maybe<TranslatedField>;
  value: Scalars['Int'];
};

export type DropdownInputFieldOptionInput_Input = {
  text: Scalars['String'];
  textI18n?: InputMaybe<TranslatedField_Input>;
  value: Scalars['Int'];
};

export type DropdownInputFieldOptionWithTags = {
  __typename?: 'DropdownInputFieldOptionWithTags';
  tags?: Maybe<Array<Maybe<FormBuilderTag>>>;
  text: Scalars['String'];
  textI18n?: Maybe<TranslatedField>;
  value: Scalars['Int'];
};

export type DropdownInputFieldOptionWithTagsInput_Input = {
  tags: Array<InputMaybe<FormBuilderTagInput_Input>>;
  text: Scalars['String'];
  textI18n?: InputMaybe<TranslatedField_Input>;
  value: Scalars['Int'];
};

export type DropdownInputFieldUserResponse = {
  __typename?: 'DropdownInputFieldUserResponse';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Scalars['Int'];
};

export type DropdownInputFieldUserResponseInput = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<FormBuilderTagInput>>;
  value: Scalars['Int'];
};

export type DropdownInputFieldUserResponseInput_Input = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<FormBuilderTag_Input>>>;
  value: Scalars['Int'];
};

export type DropdownInputFieldWithTags = {
  __typename?: 'DropdownInputFieldWithTags';
  defaultOption: DropdownInputFieldOptionWithTags;
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<Maybe<DropdownInputFieldOptionWithTags>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<InputFieldUserResponseWithTags>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type DropdownInputFieldWithTagsInput_Input = {
  defaultOption: DropdownInputFieldOptionWithTagsInput_Input;
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  options: Array<InputMaybe<DropdownInputFieldOptionWithTagsInput_Input>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<DropdownInputFieldUserResponseInput_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export enum Error_Code {
  FORBIDDEN = 'FORBIDDEN',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED'
}

export type EduExperienceForBuildResume = {
  __typename?: 'EduExperienceForBuildResume';
  date_range: Scalars['String'];
  degree_type: Scalars['String'];
  institution_name: Scalars['String'];
  major: Scalars['String'];
};

export type EducationExperience = {
  __typename?: 'EducationExperience';
  completion_year?: Maybe<Scalars['Float']>;
  degree_type?: Maybe<Scalars['String']>;
  education_exp_uuid: Scalars['String'];
  from_resume?: Maybe<Scalars['Boolean']>;
  institution_name?: Maybe<Scalars['String']>;
  is_current?: Maybe<Scalars['Boolean']>;
  /** Education experience skills generated by LLM resume parser */
  llm_generated_skills?: Maybe<Array<LlmGeneratedSkill>>;
  major: Scalars['String'];
  starting_year?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type EducationExperienceInput = {
  completion_year?: InputMaybe<Scalars['Float']>;
  degree_type?: InputMaybe<Scalars['String']>;
  education_exp_uuid?: InputMaybe<Scalars['String']>;
  from_resume?: InputMaybe<Scalars['Boolean']>;
  institution_name?: InputMaybe<Scalars['String']>;
  is_current?: InputMaybe<Scalars['Boolean']>;
  /** Education experience skills generated by LLM resume parser */
  llm_generated_skills?: InputMaybe<Array<LlmGeneratedSkillInput>>;
  major: Scalars['String'];
  starting_year?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
};

export type EducationOutcome = {
  __typename?: 'EducationOutcome';
  degree_type?: Maybe<Scalars['String']>;
  institution_name?: Maybe<Scalars['String']>;
  program_name?: Maybe<Scalars['String']>;
};

export type EducationOutcomeInput = {
  degree_type?: InputMaybe<Scalars['String']>;
  institution_name?: InputMaybe<Scalars['String']>;
  program_name?: InputMaybe<Scalars['String']>;
};

export type EmailEmploymentOntario = {
  __typename?: 'EmailEmploymentOntario';
  email_address: Scalars['String'];
  primary_ind: Scalars['Boolean'];
  type: Scalars['String'];
};

export type EmployedOutcome = {
  __typename?: 'EmployedOutcome';
  company?: Maybe<Scalars['String']>;
  employment_type?: Maybe<EmploymentTypeEnum>;
  job_title?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  /** An ISO-4217-compliant currency code i.e. USD, CAD, etc... */
  salary_currency_code?: Maybe<Scalars['String']>;
  salary_type?: Maybe<SalaryTypeEnum>;
};

export type EmployedOutcomeInput = {
  company?: InputMaybe<Scalars['String']>;
  employment_type?: InputMaybe<EmploymentTypeEnum>;
  job_title?: InputMaybe<Scalars['String']>;
  salary?: InputMaybe<Scalars['Float']>;
  /** An ISO-4217-compliant currency code i.e. USD, CAD, etc... */
  salary_currency_code?: InputMaybe<Scalars['String']>;
  salary_type?: InputMaybe<SalaryTypeEnum>;
};

export type Employer = {
  __typename?: 'Employer';
  CaseNote?: Maybe<Array<Maybe<EmployerCaseNote>>>;
  created_at?: Maybe<Scalars['DateTime']>;
  employer_uuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  recruiters?: Maybe<Array<Maybe<Recruiter>>>;
  type?: Maybe<Scalars['String']>;
};

export type EmployerCaseNote = {
  __typename?: 'EmployerCaseNote';
  created_at?: Maybe<Scalars['DateTime']>;
  /** an admin user uuid */
  created_by?: Maybe<Scalars['String']>;
  /** the caseNote description */
  description?: Maybe<Scalars['String']>;
  employer_uuid?: Maybe<Scalars['String']>;
  /** caseNote uuid */
  id?: Maybe<Scalars['String']>;
  /** an admin user uuid */
  modified_by?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type EmployerSearchRequestBody_Input = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  recruiters?: InputMaybe<Scalars['Boolean']>;
  searchString?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EmployerSortBy>;
  sortDirection?: InputMaybe<EmployerSortDirection>;
  tenant_uuid: Scalars['String'];
  type?: InputMaybe<PartnerType>;
  user_selection?: InputMaybe<QueryInput_GetEmployers_Input_User_Selection_Input>;
};

export type EmployerSearchResult = {
  __typename?: 'EmployerSearchResult';
  employers?: Maybe<Array<Maybe<Employer>>>;
  tenantCode?: Maybe<Scalars['String']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export enum EmployerSortBy {
  CREATED_AT = 'created_at',
  NAME = 'name'
}

export enum EmployerSortDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none'
}

export type EmploymentOntarioIdentity = {
  __typename?: 'EmploymentOntarioIdentity';
  employment_ontario_user_id?: Maybe<Scalars['String']>;
};

export type EmploymentOntarioIdentityInput = {
  employment_ontario_user_id?: InputMaybe<Scalars['String']>;
};

export enum EmploymentTypeEnum {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME'
}

export type EmpyraIdentity = {
  __typename?: 'EmpyraIdentity';
  empyra_user_id?: Maybe<Scalars['String']>;
};

export type EmpyraIdentityInput = {
  empyra_user_id?: InputMaybe<Scalars['String']>;
};

export enum EoAsyncUpdateStatus {
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  SUCCESS = 'SUCCESS'
}

export enum EoAsyncUpdateStatusType {
  CHECKPOINT = 'CHECKPOINT',
  EMPLOYMENT_ACTION_PLAN = 'EMPLOYMENT_ACTION_PLAN',
  QUESTIONNAIRE = 'QUESTIONNAIRE'
}

export type ExistenceCheckResponse = {
  __typename?: 'ExistenceCheckResponse';
  exists?: Maybe<Scalars['Boolean']>;
};

export type ExportProfileAuthorizeInput = {
  user_ids?: InputMaybe<Array<Scalars['String']>>;
};

export type ExportProfileAuthorizeResponseData = {
  __typename?: 'ExportProfileAuthorizeResponseData';
  body?: Maybe<Scalars['String']>;
  export_report_uuid?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Float']>;
};

export type ExportProfileData = {
  __typename?: 'ExportProfileData';
  account_preferences?: Maybe<AccountPreferences>;
  anonymized_user_uuid?: Maybe<Scalars['String']>;
  career_area_interests: Array<CareerArea>;
  caseNotes: Array<CaseNote>;
  certifications?: Maybe<Array<Certification>>;
  coach_assigned?: Maybe<CoachAssigned>;
  communities?: Maybe<Array<Scalars['String']>>;
  communitiesObject?: Maybe<Array<DataSourceType>>;
  contact_history?: Maybe<Array<Contact>>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  date_of_birth?: Maybe<Scalars['String']>;
  education?: Maybe<DataSourceType>;
  education_experiences?: Maybe<Array<EducationExperience>>;
  email?: Maybe<Scalars['String']>;
  email_consent: Scalars['Boolean'];
  employer_id?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Array<DataSourceType>>;
  ethnicities?: Maybe<Array<DataSourceType>>;
  external_identities?: Maybe<ExternalIdentities>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  genderObject?: Maybe<DataSourceType>;
  goal?: Maybe<Scalars['String']>;
  help_response?: Maybe<Scalars['String']>;
  industries_of_interest?: Maybe<Array<Scalars['String']>>;
  /** the name for the corresponding tenant record, pulled from the tenant service */
  instance_name?: Maybe<Scalars['String']>;
  interest_profile?: Maybe<InterestProfile>;
  is_onboarded?: Maybe<Scalars['Boolean']>;
  job_applications?: Maybe<Array<JobApplication>>;
  last_career?: Maybe<WorkExperience>;
  last_education?: Maybe<EducationExperience>;
  last_name?: Maybe<Scalars['String']>;
  link_response?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  no_of_step_completed?: Maybe<Scalars['Float']>;
  no_of_step_outstanding?: Maybe<Scalars['Float']>;
  outcomes?: Maybe<Outcomes>;
  phone_number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  pref_lang?: Maybe<Scalars['String']>;
  previously_contacted: Scalars['Boolean'];
  profileStep?: Maybe<Scalars['Int']>;
  recruiter_outreach?: Maybe<Scalars['Boolean']>;
  services?: Maybe<Array<Service>>;
  signup_type?: Maybe<ExternalIdentityTypes>;
  skills?: Maybe<Array<UserSkill>>;
  support_needs?: Maybe<Array<Scalars['String']>>;
  support_needs_custom?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<UserTag>>;
  target_career?: Maybe<Scalars['String']>;
  target_career_label?: Maybe<Scalars['String']>;
  target_skills?: Maybe<Array<Scalars['String']>>;
  tenantRoles?: Maybe<Array<TenantRole>>;
  /** Deprecated, use tenantRoles instead */
  tenants: Array<Scalars['String']>;
  user_tenant_role?: Maybe<Scalars['String']>;
  user_uuid: Scalars['String'];
  work_experiences?: Maybe<Array<WorkExperience>>;
  year_of_birth?: Maybe<Scalars['Int']>;
};


export type ExportProfileDataCareer_Area_InterestsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


export type ExportProfileDataEducation_ExperiencesArgs = {
  only_latest?: InputMaybe<Scalars['Boolean']>;
};


export type ExportProfileDataPreviously_ContactedArgs = {
  job_id?: InputMaybe<Scalars['String']>;
};


export type ExportProfileDataWork_ExperiencesArgs = {
  only_latest?: InputMaybe<Scalars['Boolean']>;
};

export type ExportProfileDownloadCsvFileInput = {
  export_report_uuid: Scalars['String'];
};

export type ExportProfileDownloadCsvFileResponseData = {
  __typename?: 'ExportProfileDownloadCsvFileResponseData';
  statusCode?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type ExportReport = {
  __typename?: 'ExportReport';
  created_at: Scalars['DateTime'];
  export_report_uuid: Scalars['String'];
  failed_message: Scalars['String'];
  failed_retry: Scalars['Float'];
  logGroupName: Scalars['String'];
  logStreamName: Scalars['String'];
  name: Scalars['String'];
  num_users?: Maybe<Scalars['Float']>;
  s3key: Scalars['String'];
  status: Scalars['String'];
  tenant_id: Scalars['String'];
  tenant_ids?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
  user_specified_name?: Maybe<Scalars['String']>;
  user_uuid: Scalars['String'];
};

export type ExternalAssessment = {
  __typename?: 'ExternalAssessment';
  externalId: Scalars['String'];
  integrationType: Scalars['String'];
  name: Scalars['String'];
};

export type ExternalIdentities = {
  __typename?: 'ExternalIdentities';
  employment_ontario?: Maybe<EmploymentOntarioIdentity>;
  empyra?: Maybe<EmpyraIdentity>;
  intercom?: Maybe<IntercomIdentity>;
  merge?: Maybe<Array<MergeIdentity>>;
};

export type ExternalIdentitiesInput = {
  employment_ontario?: InputMaybe<EmploymentOntarioIdentityInput>;
  empyra?: InputMaybe<EmpyraIdentityInput>;
  intercom?: InputMaybe<IntercomIdentityInput>;
  merge?: InputMaybe<Array<MergeIdentityInput>>;
};

export enum ExternalIdentityTypes {
  EMPLOYMENT_ONTARIO = 'employment_ontario',
  EMPYRA = 'empyra',
  INTERCOM = 'intercom',
  MERGE = 'merge'
}

export type ExtractorRequest_Input = {
  jobs: Array<InputMaybe<Job_Input>>;
  lang?: InputMaybe<Scalars['String']>;
};

export type Feature = {
  __typename?: 'Feature';
  /** Whether the feature is enabled by default for new tenants */
  default_enabled: Scalars['Boolean'];
  feature_uuid: Scalars['String'];
  label: Scalars['String'];
  slug: Scalars['String'];
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  exclude: Array<Scalars['String']>;
  include: Array<Scalars['String']>;
};

export type FilterOptionsInput = {
  exclude?: InputMaybe<Array<Scalars['String']>>;
  include?: InputMaybe<Array<Scalars['String']>>;
};

export type Filters = {
  __typename?: 'Filters';
  location: Array<Maybe<Scalars['String']>>;
  target_careers?: Maybe<Array<TargetCareer>>;
};

export type FiltersInput = {
  assigned_coach_uuids?: InputMaybe<Array<Scalars['String']>>;
  created_at_range?: InputMaybe<CreatedAtRangeFilterInput>;
  /** Optional filter by employer ID (currently used for internal only) */
  employer_id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  partner_ids?: InputMaybe<Array<Scalars['String']>>;
  recruiter_outreach?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  skill_uuids?: InputMaybe<Array<Scalars['String']>>;
  tag_filter_groups?: InputMaybe<TagFilter>;
  target_career?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  user_uuids?: InputMaybe<Array<Scalars['String']>>;
  work_experience_career_uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type FindAsyncUpdateStatusesInput = {
  async_update_status_uuid?: InputMaybe<Scalars['String']>;
  /**
   * The ID of the record to update. This should be the ID that is generated client-side (i.e. not the ID generated by the EO API).
   * For example, for EAPs this would be the case_management_eap_id, not gov_eap_id.
   */
  record_id?: InputMaybe<Scalars['String']>;
  /** The type of record the ID corresponds to. */
  record_type?: InputMaybe<EoAsyncUpdateStatusType>;
  status?: InputMaybe<EoAsyncUpdateStatus>;
};

export type FindIntegrationByTypeInput = {
  integration_type: IntegrationType;
  tenant_id?: InputMaybe<Scalars['ID']>;
};

export type FindIntegrationInput = {
  integration_id: Scalars['ID'];
  tenant_id?: InputMaybe<Scalars['ID']>;
};

export type FindProfilesSearchInput = {
  exclude_roles?: InputMaybe<Array<Scalars['String']>>;
  filters?: InputMaybe<FiltersInput>;
  is_ascending?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  retrieve_all_fields?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
  tenant_uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type FixedText = {
  __typename?: 'FixedText';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type FixedTextInput_Input = {
  description: Scalars['String'];
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription: Scalars['Boolean'];
  isDeprecated?: InputMaybe<Scalars['Boolean']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText?: InputMaybe<Scalars['String']>;
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  uuid?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

export type Form = {
  __typename?: 'Form';
  components: Array<Maybe<InputField>>;
  created_at: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  description_i18n?: Maybe<TranslatedField>;
  email_active?: Maybe<Scalars['Boolean']>;
  first_name_active?: Maybe<Scalars['Boolean']>;
  form_id: Scalars['String'];
  form_version_id?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name_active?: Maybe<Scalars['Boolean']>;
  location_active?: Maybe<Scalars['Boolean']>;
  phone_number_active?: Maybe<Scalars['Boolean']>;
  sourceLanguage?: Maybe<Scalars['String']>;
  status: FormStatus;
  tenant_uuid: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  title_i18n?: Maybe<TranslatedField>;
  type: FormTypes;
  updated_at: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  zip_code_active?: Maybe<Scalars['Boolean']>;
};

export type FormAndLastSubmissionResponse = {
  __typename?: 'FormAndLastSubmissionResponse';
  data: Array<Maybe<InputField>>;
  description: Scalars['String'];
  descriptionI18n?: Maybe<TranslatedField>;
  formUuid: Scalars['String'];
  formVersionUuid: Scalars['String'];
  sourceLanguage?: Maybe<Scalars['String']>;
  submissionUuid?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleI18n?: Maybe<TranslatedField>;
  userUuid?: Maybe<Scalars['String']>;
};

export type FormBuilderTag = {
  __typename?: 'FormBuilderTag';
  label?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type FormBuilderTagInput = {
  label?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type FormBuilderTagInput_Input = {
  label?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type FormBuilderTag_Input = {
  label?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type FormResponse = {
  __typename?: 'FormResponse';
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  form_id: Scalars['String'];
  form_version_id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
};

export enum FormStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

/** Indicates the type of form i.e. basic (roadmap), registration (custom sign up form) or onboarding. */
export enum FormTypes {
  BASIC_FORM = 'BASIC_FORM',
  ONBOARDING_FORM = 'ONBOARDING_FORM',
  REGISTRATION_FORM = 'REGISTRATION_FORM'
}

export type FormVersionToFormBuilderResponse = {
  __typename?: 'FormVersionToFormBuilderResponse';
  components: Array<Maybe<InputField>>;
  description: Scalars['String'];
  description_i18n?: Maybe<TranslatedField>;
  form_id: Scalars['String'];
  form_version_id: Scalars['String'];
  source_language?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title: Scalars['String'];
  title_i18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  version: Scalars['Int'];
};

export type FormsListItem = {
  __typename?: 'FormsListItem';
  created_at?: Maybe<Scalars['DateTime']>;
  form_uuid?: Maybe<Scalars['String']>;
  form_version_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tenant_uuid?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type GenerateUrl = {
  key?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type GenerateUrlNextStepDocInput = {
  docs?: InputMaybe<Array<NextStepDocInput>>;
};

export type GenerateUrlResponseNextStepDoc = {
  __typename?: 'GenerateURLResponseNextStepDoc';
  presignedPosts: Array<PresignedPost>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type GenereateUrlReponse = {
  __typename?: 'GenereateURLReponse';
  body?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type GetAllUserUuidsWrapper = {
  __typename?: 'GetAllUserUuidsWrapper';
  user_uuids: Array<Scalars['String']>;
};

export type GetCareerInput = {
  careerID: Scalars['String'];
  locale: Locale;
};

export type GetCareersInput = {
  filters?: InputMaybe<CareersFiltersInput>;
  /** Number of results */
  limit: Scalars['Int'];
  locale?: InputMaybe<Scalars['String']>;
  /** Page number */
  page: Scalars['Int'];
  /** Search career by name */
  search?: InputMaybe<Scalars['String']>;
  /** Sort by field */
  sort?: InputMaybe<Array<CareerSortObject>>;
};

export type GetCareersOutput = {
  __typename?: 'GetCareersOutput';
  allCareerIds?: Maybe<Array<Scalars['String']>>;
  careers: Array<Careers>;
  count: Scalars['Int'];
};

export type GetConfigurationInput = {
  /** CAREERS | LEARNING_PROVIDERS | RESOURCES */
  category: ConfigurationCategory;
  /** Configuration id */
  configuration_id: Scalars['String'];
};

export type GetCourseInput = {
  courseId: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
};

export type GetCoursesFilterInput = {
  /** Course Types */
  courseTypes?: InputMaybe<Array<Scalars['String']>>;
  /** Course Language */
  languages?: InputMaybe<Array<Scalars['String']>>;
  /** Learning Provider Ids */
  providerIds?: InputMaybe<Array<Scalars['String']>>;
  /** Source Filter type */
  sources?: InputMaybe<SourceTypes>;
};

export type GetCoursesInput = {
  filters?: InputMaybe<GetCoursesFilterInput>;
  /** Number of results */
  limit: Scalars['Int'];
  locale?: InputMaybe<Scalars['String']>;
  /** Page number */
  page: Scalars['Int'];
  /** Search provider by name */
  search?: InputMaybe<Scalars['String']>;
  /** Sort by field */
  sort?: InputMaybe<Array<GetCoursesSortObject>>;
};

export type GetCoursesSortObject = {
  /** Field to sort */
  field: Scalars['String'];
  /** Sort field by */
  value: Scalars['String'];
};

export type GetFormsInput = {
  statuses?: InputMaybe<Array<FormStatus>>;
  types?: InputMaybe<Array<FormTypes>>;
};

export type GetLearningProvidersInput = {
  filters?: InputMaybe<LearningProvidersFiltersInput>;
  /** Number of results */
  limit: Scalars['Int'];
  /** Page number */
  page: Scalars['Int'];
  /** Search provider by name */
  search?: InputMaybe<Scalars['String']>;
  /** Sort by field */
  sort?: InputMaybe<Array<LearningProviderSortObject>>;
};

export type GetLearningProvidersOutput = {
  __typename?: 'GetLearningProvidersOutput';
  allLearningProvidersIds?: Maybe<Array<Scalars['String']>>;
  count: Scalars['Int'];
  providers: Array<LearningProviders>;
};

export type GetLookerInsightInput = {
  dashboard_id?: InputMaybe<Scalars['String']>;
};

export type GetLookerInsightOutput = {
  __typename?: 'GetLookerInsightOutput';
  url: Scalars['String'];
};

export type GetPipelineMetadata = {
  /** Search file by unique name */
  file_name: Scalars['String'];
  /** Search file by tenant uuid */
  tenant_uuid?: InputMaybe<Scalars['String']>;
};

export type GetProfileInput = {
  locale?: InputMaybe<Scalars['String']>;
  user_uuid?: InputMaybe<Scalars['String']>;
};

export type GetResourceInput = {
  locale: Scalars['String'];
  resourceId: Scalars['String'];
};

export type GetResourcesInput = {
  filters?: InputMaybe<ResourcesFiltersInput>;
  /** Number of results */
  limit: Scalars['Int'];
  /** Page number */
  page: Scalars['Int'];
  /** Search resources by name */
  search?: InputMaybe<Scalars['String']>;
  /** Sort by field */
  sort?: InputMaybe<Array<ResourcesSortObject>>;
};

export type GetResourcesOutput = {
  __typename?: 'GetResourcesOutput';
  allResourcesIds?: Maybe<Array<Scalars['String']>>;
  count: Scalars['Int'];
  resources: Array<Resources>;
};

export type GetRoadmapInput = {
  locale?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
};

export type GetTagsInput = {
  is_ascending?: InputMaybe<Scalars['Boolean']>;
  /** How many records to skip (from first document) when querying. */
  skip?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<SortTag>;
  /** How many records of this resource type to fetch. */
  take?: InputMaybe<Scalars['Int']>;
};

export type GetTagsResponse = {
  __typename?: 'GetTagsResponse';
  count: Scalars['Float'];
  items: Array<Tag>;
  next?: Maybe<Scalars['Float']>;
};

export type GetTenantAssetsInput = {
  asset_types?: InputMaybe<Array<SupportedAssetType>>;
  configuration_id: Scalars['String'];
  language: SupportedLanguages;
};

export type GetTenantPolicyAssetsInput = {
  configuration_id: Scalars['String'];
  language: SupportedLanguages;
};

export type GetTranslationInput = {
  detectLanguageOption?: InputMaybe<DetectLanguageOption>;
  documentType?: InputMaybe<Scalars['String']>;
  languageMapping?: InputMaybe<LanguageMappingPair>;
  sourceLanguage?: InputMaybe<Scalars['String']>;
  targetLanguage: Scalars['String'];
  terminologyNames?: InputMaybe<Array<Scalars['String']>>;
  text: Scalars['String'];
};

export type GetTranslationsInput = {
  detectLanguageOption?: InputMaybe<DetectLanguageOption>;
  documentType?: InputMaybe<Scalars['String']>;
  languageMapping?: InputMaybe<LanguageMappingPair>;
  sourceLanguage?: InputMaybe<Scalars['String']>;
  targetLanguage: Scalars['String'];
  terminologyNames?: InputMaybe<Array<Scalars['String']>>;
  texts: Array<Scalars['String']>;
};

export type GetUserCaseNotesInput = {
  /** How many records to skip (from first document) when querying. */
  skip?: InputMaybe<Scalars['Int']>;
  /** How many records of this resource type to fetch. */
  take?: InputMaybe<Scalars['Int']>;
  user_uuid: Scalars['ID'];
};

export type GetUserCaseNotesResponse = {
  __typename?: 'GetUserCaseNotesResponse';
  /** The total number of items for this resource. */
  count: Scalars['Int'];
  items: Array<CaseNote>;
  /** The next index for which the record set should be queried, null if there is none. */
  next?: Maybe<Scalars['Int']>;
};

export type GetUserRoleInput = {
  tenant_id?: InputMaybe<Scalars['String']>;
  user_uuid?: InputMaybe<Scalars['String']>;
};

export type GovClientConfiguration = {
  __typename?: 'GovClientConfiguration';
  api_key: Scalars['String'];
  /** The base url for the gov api */
  base_url: Scalars['String'];
  /** The type of system connecting to (ie eo_api) */
  config_type: Scalars['String'];
  /** Label indicating the environment (ie qa, uat, etc) */
  env: Scalars['String'];
  /** The organization id */
  organization_id: Scalars['String'];
};

export type GovClientConfigurationInput = {
  api_key_env_var: Scalars['String'];
  base_url: Scalars['String'];
  config_type: Scalars['String'];
  env: Scalars['String'];
  organization_id: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  coaches?: Maybe<Array<Maybe<Coach>>>;
  created_at?: Maybe<Scalars['DateTime']>;
  employer_uuid: Scalars['String'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type GroupCoachFailure = {
  __typename?: 'GroupCoachFailure';
  /** UUID of the coach that failed to process */
  coach_uuid: Scalars['String'];
  /** Error message explaining the failure */
  error: Scalars['String'];
};

export type GroupCoachSuccess = {
  __typename?: 'GroupCoachSuccess';
  /** UUID of the successfully processed coach */
  coach_uuid: Scalars['String'];
};

export type GroupCoachesResult = {
  __typename?: 'GroupCoachesResult';
  /** Array of failed coach operations */
  failed: Array<Maybe<GroupCoachFailure>>;
  /** Array of successfully processed coaches */
  successful: Array<Maybe<GroupCoachSuccess>>;
};

export enum GroupFilterOperation {
  AND = 'AND',
  OR = 'OR'
}

export type GroupsResponse = {
  __typename?: 'GroupsResponse';
  groups?: Maybe<Array<Maybe<Group>>>;
  limit?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export type GroupsSearchRequestBody_Input = {
  /** Include coaches in response */
  coaches?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<QueryInput_GetGroups_Input_SortBy>;
  sortDirection?: InputMaybe<QueryInput_GetGroups_Input_SortDirection>;
};

export enum HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export type HealthResponse = {
  __typename?: 'HealthResponse';
  status?: Maybe<Scalars['String']>;
};

export enum IsoLanguageCode {
  EN_CA = 'en_CA',
  EN_US = 'en_US',
  ES_ES = 'es_ES',
  FR_CA = 'fr_CA',
  PT_PT = 'pt_PT'
}

export type InputField = {
  __typename?: 'InputField';
  currencyInputField?: Maybe<CurrencyInputField>;
  dateInputField?: Maybe<DateInputField>;
  defaultQuestionField?: Maybe<DefaultQuestionGraphInputField>;
  dropdownInputField?: Maybe<DropdownInputField>;
  dropdownInputFieldWithTags?: Maybe<DropdownInputFieldWithTags>;
  fixedText?: Maybe<FixedText>;
  multiChoiceCheckboxInputField?: Maybe<MultiChoiceCheckboxInputField>;
  multiChoiceCheckboxInputFieldWithTags?: Maybe<MultiChoiceCheckboxInputFieldWithTags>;
  textInputField?: Maybe<TextInputField>;
};

export type InputFieldInput_Input = {
  currencyInputField?: InputMaybe<CurrencyInputField_Input>;
  dateInputField?: InputMaybe<DateInputField_Input>;
  defaultQuestionField?: InputMaybe<DefaultQuestionGraphInputField_Input>;
  dropdownInputField?: InputMaybe<DropdownInputFieldInput_Input>;
  dropdownInputFieldWithTags?: InputMaybe<DropdownInputFieldWithTagsInput_Input>;
  fixedText?: InputMaybe<FixedTextInput_Input>;
  multiChoiceCheckboxInputField?: InputMaybe<MultiChoiceCheckboxInputFieldInput_Input>;
  multiChoiceCheckboxInputFieldWithTags?: InputMaybe<MultiChoiceCheckboxInputFieldWithTagsInput_Input>;
  textInputField?: InputMaybe<TextInputFieldInput_Input>;
};

export type InputFieldUserCurrencyResponse = {
  __typename?: 'InputFieldUserCurrencyResponse';
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type InputFieldUserDateResponse = {
  __typename?: 'InputFieldUserDateResponse';
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type InputFieldUserMultiChoiceResponse = {
  __typename?: 'InputFieldUserMultiChoiceResponse';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Array<SelectedMultiChoiceCheckboxInputFieldOption>;
};

export type InputFieldUserMultiChoiceResponseWithTags = {
  __typename?: 'InputFieldUserMultiChoiceResponseWithTags';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Array<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>;
};

export type InputFieldUserNumberResponse = {
  __typename?: 'InputFieldUserNumberResponse';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Scalars['Int'];
};

export type InputFieldUserResponseWithTags = {
  __typename?: 'InputFieldUserResponseWithTags';
  aditionalResponse?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<FormBuilderTag>>>;
  value: Scalars['Int'];
};

export type InputFieldUserStringResponse = {
  __typename?: 'InputFieldUserStringResponse';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type Input_BatchUpdateResults = {
  description?: InputMaybe<Scalars['String']>;
  externalRecordID?: InputMaybe<Scalars['String']>;
  referenceNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export type Input_CommonBatchUpdateResponse = {
  callBackURL?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  numberOfRecordReceived?: InputMaybe<Scalars['Float']>;
  receivedDateTime?: InputMaybe<Scalars['String']>;
  requestID?: InputMaybe<Scalars['String']>;
  requestStatus?: InputMaybe<Scalars['String']>;
  requestType?: InputMaybe<Scalars['String']>;
  resultInd?: InputMaybe<Scalars['Boolean']>;
};

export type Input_CurrencyInputField = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  hasRange: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholder?: InputMaybe<Scalars['String']>;
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_InputFieldUserCurrencyResponse>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_DateInputField = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  hasRange: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholder?: InputMaybe<Scalars['String']>;
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_InputFieldUserDateResponse>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_DefaultQuestionGraphInputField = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options?: InputMaybe<Array<InputMaybe<Input_DefaultQuestionOptionWithTags>>>;
  order?: InputMaybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_DefaultQuestionOptionWithTags = {
  additionalText?: InputMaybe<Scalars['String']>;
  additionalTextI18n?: InputMaybe<Input_TranslatedField>;
  dataSource: Scalars['String'];
  isChecked: Scalars['Boolean'];
  requiredAditionalTextBox: Scalars['Boolean'];
  tags?: InputMaybe<Array<Input_FormBuilderTag>>;
  text: Scalars['String'];
  textI18n?: InputMaybe<Input_TranslatedField>;
  value: Scalars['Int'];
};

export type Input_DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  textValue?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Array<Input_SelectedMultiChoiceCheckboxInputFieldOptionWithTags>>;
};

export type Input_DropdownInputField = {
  defaultOption: Input_DropdownInputFieldOption;
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<InputMaybe<Input_DropdownInputFieldOption>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_InputFieldUserNumberResponse>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_DropdownInputFieldOption = {
  text: Scalars['String'];
  textI18n?: InputMaybe<Input_TranslatedField>;
  value: Scalars['Int'];
};

export type Input_DropdownInputFieldOptionWithTags = {
  tags?: InputMaybe<Array<InputMaybe<Input_FormBuilderTag>>>;
  text: Scalars['String'];
  textI18n?: InputMaybe<Input_TranslatedField>;
  value: Scalars['Int'];
};

export type Input_DropdownInputFieldWithTags = {
  defaultOption: Input_DropdownInputFieldOptionWithTags;
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<InputMaybe<Input_DropdownInputFieldOptionWithTags>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_InputFieldUserResponseWithTags>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_FixedText = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_FormBuilderTag = {
  label?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type Input_InputField = {
  currencyInputField?: InputMaybe<Input_CurrencyInputField>;
  dateInputField?: InputMaybe<Input_DateInputField>;
  defaultQuestionField?: InputMaybe<Input_DefaultQuestionGraphInputField>;
  dropdownInputField?: InputMaybe<Input_DropdownInputField>;
  dropdownInputFieldWithTags?: InputMaybe<Input_DropdownInputFieldWithTags>;
  fixedText?: InputMaybe<Input_FixedText>;
  multiChoiceCheckboxInputField?: InputMaybe<Input_MultiChoiceCheckboxInputField>;
  multiChoiceCheckboxInputFieldWithTags?: InputMaybe<Input_MultiChoiceCheckboxInputFieldWithTags>;
  textInputField?: InputMaybe<Input_TextInputField>;
};

export type Input_InputFieldUserCurrencyResponse = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type Input_InputFieldUserDateResponse = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type Input_InputFieldUserMultiChoiceResponse = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Array<Input_SelectedMultiChoiceCheckboxInputFieldOption>;
};

export type Input_InputFieldUserMultiChoiceResponseWithTags = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Array<Input_SelectedMultiChoiceCheckboxInputFieldOptionWithTags>;
};

export type Input_InputFieldUserNumberResponse = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Scalars['Int'];
};

export type Input_InputFieldUserResponseWithTags = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Input_FormBuilderTag>>>;
  value: Scalars['Int'];
};

export type Input_InputFieldUserStringResponse = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type Input_MultiChoiceCheckboxInputField = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<InputMaybe<Input_MultiChoiceCheckboxInputFieldOption>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_InputFieldUserMultiChoiceResponse>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_MultiChoiceCheckboxInputFieldOption = {
  id: Scalars['Int'];
  isChecked: Scalars['Boolean'];
  label: Scalars['String'];
  labelI18n?: InputMaybe<Input_TranslatedField>;
  requiredAditionalTextBox: Scalars['Boolean'];
};

export type Input_MultiChoiceCheckboxInputFieldOptionWithTags = {
  id: Scalars['Int'];
  isChecked: Scalars['Boolean'];
  label: Scalars['String'];
  labelI18n?: InputMaybe<Input_TranslatedField>;
  requiredAditionalTextBox: Scalars['Boolean'];
  tags?: InputMaybe<Array<InputMaybe<Input_FormBuilderTag>>>;
};

export type Input_MultiChoiceCheckboxInputFieldWithTags = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<InputMaybe<Input_MultiChoiceCheckboxInputFieldOptionWithTags>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_InputFieldUserMultiChoiceResponseWithTags>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_SelectedMultiChoiceCheckboxInputFieldOption = {
  aditionalText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  textValue?: InputMaybe<Scalars['String']>;
};

export type Input_SelectedMultiChoiceCheckboxInputFieldOptionWithTags = {
  aditionalText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  tags?: InputMaybe<Array<InputMaybe<Input_FormBuilderTag>>>;
  textValue?: InputMaybe<Scalars['String']>;
};

export type Input_TextInputField = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<Input_TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<Input_TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<Input_TranslatedField>;
  type: Scalars['String'];
  userResponse?: InputMaybe<Input_InputFieldUserStringResponse>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Input_TranslatedField = {
  en_US: Scalars['String'];
  es_ES?: InputMaybe<Scalars['String']>;
  fr_CA: Scalars['String'];
  pt_PT?: InputMaybe<Scalars['String']>;
};

export type InsightConfiguration = {
  __typename?: 'InsightConfiguration';
  dashboard_id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type InsightConfigurationInput = {
  /** Looker Dashboard ID */
  dashboard_id: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
};

export type Integration = {
  __typename?: 'Integration';
  api_key: Scalars['String'];
  base_api_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  integration_id: Scalars['ID'];
  integration_type: IntegrationType;
  is_active?: Maybe<Scalars['Boolean']>;
  tenant_id: Scalars['ID'];
};

export enum IntegrationType {
  VERVOE = 'VERVOE'
}

export type IntercomIdentity = {
  __typename?: 'IntercomIdentity';
  intercom_user_id: Scalars['String'];
};

export type IntercomIdentityInput = {
  intercom_user_id?: InputMaybe<Scalars['String']>;
};

export type InterestProfile = {
  __typename?: 'InterestProfile';
  artistic: Scalars['Float'];
  conventional: Scalars['Float'];
  enterprising: Scalars['Float'];
  investigative: Scalars['Float'];
  realistic: Scalars['Float'];
  social: Scalars['Float'];
};

export type InterestProfileInput = {
  artistic: Scalars['Float'];
  conventional: Scalars['Float'];
  enterprising: Scalars['Float'];
  investigative: Scalars['Float'];
  realistic: Scalars['Float'];
  social: Scalars['Float'];
};

export type Invitation = {
  __typename?: 'Invitation';
  created_at?: Maybe<Scalars['DateTime']>;
  /** UUID of the user the invitation was created by */
  created_by_uuid?: Maybe<Scalars['String']>;
  /** Email for this invitation resource */
  email?: Maybe<Scalars['String']>;
  employer_uuid?: Maybe<Scalars['String']>;
  invitation_link: Scalars['String'];
  invitation_uuid: Scalars['String'];
  /** The UUID of the underlying invite link */
  invite_link_uuid?: Maybe<Scalars['UUID']>;
  /** Whether this invitation has already been confirmed or not */
  is_valid?: Maybe<Scalars['Boolean']>;
  /** The role type for this invitation (coach/recruiter/...) */
  role?: Maybe<Scalars['String']>;
  /** This invitation's tenant holder */
  tenant_uuid?: Maybe<Scalars['UUID']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_uuid?: Maybe<Scalars['String']>;
};

export enum InvitationRole {
  COACH = 'coach',
  RECRUITER = 'recruiter'
}

export type InviteLink = {
  __typename?: 'InviteLink';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  /** A list of email domains that are allowed to use this invite link */
  email_domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  employer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  tag_id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_count?: Maybe<Scalars['Int']>;
};

export type JobApplication = {
  __typename?: 'JobApplication';
  application_id: Scalars['String'];
  applied_at: Scalars['DateTime'];
  job_id: Scalars['String'];
};

export type JobApplicationInput = {
  application_id?: InputMaybe<Scalars['String']>;
  applied_at?: InputMaybe<Scalars['DateTime']>;
  job_id?: InputMaybe<Scalars['String']>;
};

export type JobAttributes = {
  __typename?: 'JobAttributes';
  attributes: Array<Maybe<Scalars['JSON']>>;
  job_id: Scalars['String'];
};

export type JobConfigurationOutput = {
  __typename?: 'JobConfigurationOutput';
  featured_job_providers?: Maybe<Array<JobSource>>;
  job_sources?: Maybe<JobSourceConfiguration>;
};

export type JobRecommender_EmbeddedJobs = {
  __typename?: 'JobRecommender_EmbeddedJobs';
  jobs?: Maybe<Array<Maybe<JobRecommender_Job>>>;
};

export enum JobRecommender_HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export type JobRecommender_HealthResponse = {
  __typename?: 'JobRecommender_HealthResponse';
  status?: Maybe<JobRecommender_Query_Healthcheck_Status>;
};

export type JobRecommender_Job = {
  __typename?: 'JobRecommender_Job';
  education_match?: Maybe<Scalars['Float']>;
  experience_match?: Maybe<Scalars['Float']>;
  job_id: Scalars['UUID'];
  match?: Maybe<Scalars['Float']>;
  skill_match?: Maybe<Scalars['Float']>;
};

export type JobRecommender_JobRecommenderOutput = {
  __typename?: 'JobRecommender_JobRecommenderOutput';
  _embedded?: Maybe<JobRecommender_EmbeddedJobs>;
  count?: Maybe<Scalars['NonNegativeInt']>;
  total?: Maybe<Scalars['NonNegativeInt']>;
};

export type JobRecommender_RecommendedJobsRequestByIds_Input = {
  /**
   * The configuration ID to retrieve and set the recommender configuration
   * from. Normally, we would get this through the JWT token and the tenant
   * service, but for testing (locally and in case of issues), we can use
   * this field to override it. This shouldn't be used in normal situations.
   */
  configuration_id?: InputMaybe<Scalars['UUID']>;
  job_ids: Array<InputMaybe<Scalars['String']>>;
  parameters?: InputMaybe<JobRecommender_WorkRecommenderParameters_Input>;
};

export type JobRecommender_RecommendedJobsRequest_Input = {
  company?: InputMaybe<Scalars['String']>;
  /**
   * The configuration ID to retrieve and set the recommender configuration
   * from. Normally, we would get this through the JWT token and the tenant
   * service, but for testing (locally and in case of issues), we can use
   * this field to override it. This shouldn't be used in normal situations.
   */
  configuration_id?: InputMaybe<Scalars['UUID']>;
  created_after?: InputMaybe<Scalars['Date']>;
  exact_match?: InputMaybe<Scalars['Boolean']>;
  exact_match_company?: InputMaybe<Scalars['Boolean']>;
  filters_array?: InputMaybe<Array<InputMaybe<JobRecommender_WorkRecommenderJobServiceFilters_Input>>>;
  parameters?: InputMaybe<JobRecommender_WorkRecommenderParameters_Input>;
  search?: InputMaybe<Scalars['String']>;
  search_type?: InputMaybe<Scalars['String']>;
  search_version?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<JobRecommender_QueryInput_Get_Job_Recommendations_Input_Sort_Input>;
};

/**
 * When present, the recommender only considers jobs adhering to the
 * filter properties.
 * If both role_id and career_id are given, we assume that the role is
 * a specialization of the career. In this case, we filter by the
 * career, but exact role matches will appear first in the output with
 * the field exact_role_match set to true.
 */
export type JobRecommender_WorkRecommenderJobServiceFilters_Input = {
  classified_job_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  classified_seniority_types?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  country?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['String']>;
  first_detected_lang?: InputMaybe<Scalars['JobRecommender_queryInput_get_job_recommendations_input_filters_array_items_first_detected_lang']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  job_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  location?: InputMaybe<Scalars['String']>;
  location_coordinates?: InputMaybe<JobRecommender_QueryInput_Get_Job_Recommendations_Input_Filters_Array_Items_Location_Coordinates_Input>;
  providers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  radius?: InputMaybe<Scalars['Float']>;
  remote?: InputMaybe<Scalars['Boolean']>;
  remote_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  state?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
};

export type JobRecommender_WorkRecommenderParameters_Input = {
  current_skill_ids: Array<InputMaybe<Scalars['String']>>;
  education_experiences: Array<InputMaybe<Scalars['String']>>;
  work_experiences: Array<InputMaybe<JobRecommender_QueryInput_Get_Job_Recommendations_Input_Parameters_Work_Experiences_Items_Input>>;
};

export type JobRecommender_QueryInput_Get_Job_Recommendations_Input_Filters_Array_Items_Location_Coordinates_Input = {
  lat?: InputMaybe<Scalars['Float']>;
  long?: InputMaybe<Scalars['Float']>;
};

export type JobRecommender_QueryInput_Get_Job_Recommendations_Input_Parameters_Work_Experiences_Items_Input = {
  career?: InputMaybe<Scalars['String']>;
  completion_date?: InputMaybe<Scalars['String']>;
  starting_date?: InputMaybe<Scalars['String']>;
};

export type JobRecommender_QueryInput_Get_Job_Recommendations_Input_Sort_Input = {
  is_ascending?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export enum JobRecommender_Query_Healthcheck_Status {
  FAIL = 'fail',
  PASS = 'pass',
  WARN = 'warn'
}

export type JobService_AdditionalSkill = {
  __typename?: 'JobService_AdditionalSkill';
  classified_skill_existed: Scalars['Boolean'];
  custom_label: Scalars['String'];
  skill_uuid: Scalars['String'];
  skilltype: Scalars['String'];
};

export type JobService_AdditionalSkillInput = {
  classified_skill_existed: Scalars['Boolean'];
  classified_skill_label: Scalars['String'];
  custom_label: Scalars['String'];
  skill_uuid: Scalars['String'];
  skilltype: Scalars['String'];
};

export type JobService_ArchiveAllManualJobsResponse = {
  __typename?: 'JobService_ArchiveAllManualJobsResponse';
  numArchived: Scalars['Int'];
};

export type JobService_ArchiveManualJobInput = {
  job_id: Scalars['String'];
};

export type JobService_Coordinate = {
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type JobService_CreateLink = {
  email_address: Scalars['String'];
  organization_id: Scalars['String'];
  organization_name: Scalars['String'];
};

export type JobService_CreateManualJobInput = {
  additional_skills?: InputMaybe<Array<JobService_AdditionalSkillInput>>;
  application_email?: InputMaybe<Scalars['String']>;
  application_url?: InputMaybe<Scalars['String']>;
  career_id?: InputMaybe<Scalars['String']>;
  /** user provide classified job type to aligned with non-manual job filters. */
  classified_job_type?: InputMaybe<Array<Scalars['String']>>;
  /** user provide classified seniority type to aligned with non-manual job filters. */
  classified_seniority_type?: InputMaybe<Scalars['Float']>;
  company: Scalars['String'];
  country: Scalars['String'];
  department?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  education_level?: InputMaybe<Scalars['Int']>;
  employer_id?: InputMaybe<Scalars['String']>;
  expiry_date?: InputMaybe<Scalars['DateTime']>;
  inferred_attributes?: InputMaybe<Array<JobService_InferredJobAttributeInput>>;
  job_title: Scalars['String'];
  job_type?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  /** user provide remote type to aligned with non-manual job filters. */
  remote_type?: InputMaybe<Array<Scalars['String']>>;
  salary_range?: InputMaybe<JobService_JobSalaryRangeInput>;
  seniority?: InputMaybe<Scalars['String']>;
  skills: Array<JobService_Skill>;
  tenant_id: Scalars['String'];
  work_experience_level?: InputMaybe<Scalars['Int']>;
};

export type JobService_EasyApplyToAtsJobInput = {
  /** Country */
  country: Scalars['String'];
  /** Job Seekers current company */
  currentCompany?: InputMaybe<Scalars['String']>;
  /** Job Seekers current jobTitle */
  currentJobTitle?: InputMaybe<Scalars['String']>;
  /** Email address */
  email: Scalars['String'];
  /** Employer Id */
  employerId: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  /** Job Id */
  jobId: Scalars['String'];
  /** Last name */
  lastName: Scalars['String'];
  /** Phone Number */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** Provider Id - This is the ID of the job on Merge */
  providerId?: InputMaybe<Scalars['String']>;
  /** file name for resume */
  resumeFileName: Scalars['String'];
  /** Resume url */
  resumeUrl: Scalars['String'];
};

export type JobService_EasyApplyToAtsJobResponse = {
  __typename?: 'JobService_EasyApplyToATSJobResponse';
  application_id: Scalars['String'];
  applied_at: Scalars['DateTime'];
  job_id: Scalars['String'];
};

export type JobService_ExchangeAccountToken = {
  email_address: Scalars['String'];
  employer_uuid: Scalars['String'];
  public_token: Scalars['String'];
};

export type JobService_GetJobInput = {
  job_id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
};

export type JobService_GetJobsInput = {
  job_ids: Array<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type JobService_GetJobsResponseWrapper = {
  __typename?: 'JobService_GetJobsResponseWrapper';
  jobs: Array<JobService_JobSearchJob>;
  total_results: Scalars['Float'];
};

export type JobService_GetJobsV2ResponseWrapper = {
  __typename?: 'JobService_GetJobsV2ResponseWrapper';
  jobs: Array<JobService_JobDto>;
  total_results: Scalars['Float'];
};

export type JobService_GetSkillsInput = {
  career_id: Scalars['String'];
  pref_lang?: InputMaybe<Scalars['String']>;
};

export type JobService_InferredJobAttributeInput = {
  label?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  phrase?: InputMaybe<Scalars['String']>;
  skilltype?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  uuid?: InputMaybe<Scalars['String']>;
};

export type JobService_Job = {
  __typename?: 'JobService_Job';
  ingested_at: Scalars['DateTime'];
  source: Scalars['String'];
  source_type: Scalars['String'];
  uuid: Scalars['String'];
};

export type JobService_JobDto = {
  __typename?: 'JobService_JobDto';
  active?: Maybe<Scalars['Boolean']>;
  additional_skills?: Maybe<Array<JobService_AdditionalSkill>>;
  allows_easy_apply?: Maybe<Scalars['Boolean']>;
  application_count?: Maybe<Scalars['String']>;
  application_email?: Maybe<Scalars['String']>;
  application_url?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  career?: Maybe<Scalars['String']>;
  career_object?: Maybe<JobService_JobSearchCareer>;
  classified_job_type?: Maybe<Array<Scalars['String']>>;
  classified_seniority_type?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  education_level?: Maybe<Scalars['Int']>;
  edus_needed?: Maybe<Array<JobService_JobEducationNeeded>>;
  employer_id?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  exps_needed?: Maybe<Array<JobService_JobExperienceNeeded>>;
  inferred_attributes?: Maybe<Array<JobService_JobSearchInferredJobAttribute>>;
  job_id?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  job_type?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  last_updated_at?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['String']>;
  location_lower?: Maybe<Scalars['String']>;
  posted_at?: Maybe<Scalars['String']>;
  posting_source?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  remote?: Maybe<Scalars['Boolean']>;
  remote_type?: Maybe<Array<Scalars['String']>>;
  salary_range?: Maybe<JobService_JobSalaryRange>;
  score?: Maybe<Scalars['Float']>;
  seniority?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['String']>>;
  skills_needed?: Maybe<Array<Scalars['String']>>;
  skills_needed_objects?: Maybe<Array<JobService_JobSearchSkill>>;
  skills_objects?: Maybe<Array<JobService_JobSearchSkill>>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  work_experience_level?: Maybe<Scalars['Int']>;
};

export type JobService_JobEducationNeeded = {
  __typename?: 'JobService_JobEducationNeeded';
  degree_type?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
};

export type JobService_JobExperienceNeeded = {
  __typename?: 'JobService_JobExperienceNeeded';
  level?: Maybe<Scalars['Int']>;
  max_year?: Maybe<Scalars['Int']>;
  min_year?: Maybe<Scalars['Int']>;
};

export type JobService_JobSalaryRange = {
  __typename?: 'JobService_JobSalaryRange';
  currency?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  pay_period?: Maybe<Scalars['String']>;
};

export type JobService_JobSalaryRangeInput = {
  currency?: InputMaybe<Scalars['String']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
  pay_period?: InputMaybe<Scalars['String']>;
};

export type JobService_JobSearchCareer = {
  __typename?: 'JobService_JobSearchCareer';
  baseline_skills?: Maybe<Array<JobService_JobSearchSkill>>;
  career_area?: Maybe<JobService_JobSearchCareerArea>;
  career_uuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  interest_profile_code?: Maybe<Scalars['String']>;
  pref_label?: Maybe<Scalars['String']>;
  salary_mean?: Maybe<JobService_JobSearchMonetaryValue>;
  specialized_skills?: Maybe<Array<JobService_JobSearchSkill>>;
};

export type JobService_JobSearchCareerArea = {
  __typename?: 'JobService_JobSearchCareerArea';
  career_area_uuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  machine_key?: Maybe<Scalars['String']>;
  pref_label?: Maybe<Scalars['String']>;
};

export type JobService_JobSearchFiltersInput = {
  classified_job_types?: InputMaybe<Array<Scalars['String']>>;
  classified_seniority_types?: InputMaybe<Array<Scalars['Float']>>;
  country?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['String']>;
  exclude_providers?: InputMaybe<Array<Scalars['String']>>;
  first_detected_lang?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  job_type?: InputMaybe<Array<Scalars['String']>>;
  location?: InputMaybe<Scalars['String']>;
  location_coordinates?: InputMaybe<JobService_Coordinate>;
  providers?: InputMaybe<Array<Scalars['String']>>;
  radius?: InputMaybe<Scalars['Float']>;
  remote?: InputMaybe<Scalars['Boolean']>;
  remote_types?: InputMaybe<Array<Scalars['String']>>;
  state?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
};

export type JobService_JobSearchInferredJobAttribute = {
  __typename?: 'JobService_JobSearchInferredJobAttribute';
  label?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  phrase?: Maybe<Scalars['String']>;
  skilltype?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type JobService_JobSearchInput = {
  company?: InputMaybe<Scalars['String']>;
  /** Date, in YYYY-MM-DD format */
  created_after?: InputMaybe<Scalars['DateTime']>;
  exact_match?: InputMaybe<Scalars['Boolean']>;
  exact_match_company?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<JobService_JobSearchFiltersInput>;
  filters_array?: InputMaybe<Array<JobService_JobSearchFiltersInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  search_type?: InputMaybe<Scalars['String']>;
  search_version?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<JobService_JobSearchSortInput>;
};

export type JobService_JobSearchJob = {
  __typename?: 'JobService_JobSearchJob';
  active?: Maybe<Scalars['Boolean']>;
  additional_skills?: Maybe<Array<JobService_AdditionalSkill>>;
  allows_easy_apply?: Maybe<Scalars['Boolean']>;
  application_count?: Maybe<Scalars['String']>;
  application_email?: Maybe<Scalars['String']>;
  application_url?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  career?: Maybe<JobService_JobSearchCareer>;
  classified_job_type?: Maybe<Array<Scalars['String']>>;
  classified_seniority_type?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  education_level?: Maybe<Scalars['Int']>;
  employer_id?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  inferred_attributes?: Maybe<Array<JobService_JobSearchInferredJobAttribute>>;
  job_id?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  job_type?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  last_updated_at?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['String']>;
  location_lower?: Maybe<Scalars['String']>;
  posted_at?: Maybe<Scalars['String']>;
  posted_by_uuid?: Maybe<Scalars['String']>;
  posting_source?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  remote?: Maybe<Scalars['Boolean']>;
  remote_type?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<JobService_JobSearchRole>;
  salary_range?: Maybe<JobService_JobSalaryRange>;
  score?: Maybe<Scalars['Float']>;
  seniority?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<JobService_JobSearchSkill>>;
  skills_needed?: Maybe<Array<JobService_JobSearchSkill>>;
  state?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  work_experience_level?: Maybe<Scalars['Int']>;
};

export type JobService_JobSearchMonetaryValue = {
  __typename?: 'JobService_JobSearchMonetaryValue';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type JobService_JobSearchResponseWrapper = {
  __typename?: 'JobService_JobSearchResponseWrapper';
  jobs: Array<JobService_JobSearchJob>;
  total_pages: Scalars['Float'];
  total_results: Scalars['Float'];
};

export type JobService_JobSearchRole = {
  __typename?: 'JobService_JobSearchRole';
  description?: Maybe<Scalars['String']>;
  pref_label?: Maybe<Scalars['String']>;
  role_uuid?: Maybe<Scalars['String']>;
};

export type JobService_JobSearchSkill = {
  __typename?: 'JobService_JobSearchSkill';
  description?: Maybe<Scalars['String']>;
  pref_label?: Maybe<Scalars['String']>;
  skill_uuid?: Maybe<Scalars['String']>;
  skilltype?: Maybe<Scalars['String']>;
};

export type JobService_JobSearchSortInput = {
  is_ascending?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export type JobService_JobSearchV2ResponseWrapper = {
  __typename?: 'JobService_JobSearchV2ResponseWrapper';
  jobs: Array<JobService_JobDto>;
  total_pages: Scalars['Float'];
  total_results: Scalars['Float'];
};

export type JobService_JobSearchV3ResponseWrapper = {
  __typename?: 'JobService_JobSearchV3ResponseWrapper';
  jobs: Array<JobService_JobDto>;
};

export type JobService_JobSemanticSearchInput = {
  alpha?: InputMaybe<Scalars['Float']>;
  company?: InputMaybe<Scalars['String']>;
  /** Date, in YYYY-MM-DD format */
  created_after?: InputMaybe<Scalars['DateTime']>;
  exact_match?: InputMaybe<Scalars['Boolean']>;
  exact_match_company?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<JobService_JobSearchFiltersInput>;
  filters_array?: InputMaybe<Array<JobService_JobSearchFiltersInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  num_candidates?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  query_embedding?: InputMaybe<Array<Scalars['Float']>>;
  search?: InputMaybe<Scalars['String']>;
  search_type?: InputMaybe<Scalars['String']>;
  search_version?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<JobService_JobSearchSortInput>;
};

export type JobService_ManualJobResponse = {
  __typename?: 'JobService_ManualJobResponse';
  job?: Maybe<JobService_JobSearchJob>;
  jobDto?: Maybe<JobService_JobDto>;
  message: Scalars['String'];
};

export type JobService_SaveJobInput = {
  company?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobService_SaveJobSourceInput>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type JobService_SaveJobSourceInput = {
  ingested_at?: InputMaybe<Scalars['DateTime']>;
  source: Scalars['String'];
  source_type: Scalars['String'];
};

export type JobService_SavedJob = {
  __typename?: 'JobService_SavedJob';
  company?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  job: JobService_Job;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  stage_history: Array<JobService_Stage>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type JobService_Skill = {
  id: Scalars['String'];
  label: Scalars['String'];
  skilltype: Scalars['String'];
};

export type JobService_SkillsResponse = {
  __typename?: 'JobService_SkillsResponse';
  message: Scalars['String'];
  skills?: Maybe<Array<JobService_JobSearchSkill>>;
};

export type JobService_Stage = {
  __typename?: 'JobService_Stage';
  created_at: Scalars['DateTime'];
  stage: Scalars['String'];
};

export type JobService_UpdateManualJobInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  additional_skills?: InputMaybe<Array<JobService_AdditionalSkillInput>>;
  application_email?: InputMaybe<Scalars['String']>;
  application_url?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  career_id?: InputMaybe<Scalars['String']>;
  /** user provide classified job type to aligned with non-manual job filters. */
  classified_job_type?: InputMaybe<Array<Scalars['String']>>;
  /** user provide classified seniority type to aligned with non-manual job filters. */
  classified_seniority_type?: InputMaybe<Scalars['Float']>;
  company?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  education_level?: InputMaybe<Scalars['Int']>;
  employer_id?: InputMaybe<Scalars['String']>;
  expiry_date?: InputMaybe<Scalars['DateTime']>;
  inferred_attributes?: InputMaybe<Array<JobService_InferredJobAttributeInput>>;
  job_id: Scalars['String'];
  job_title?: InputMaybe<Scalars['String']>;
  job_type?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  /** user provide remote type to aligned with non-manual job filters. */
  remote_type?: InputMaybe<Array<Scalars['String']>>;
  salary_range?: InputMaybe<JobService_JobSalaryRangeInput>;
  seniority?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Array<JobService_Skill>>;
  tenant_id?: InputMaybe<Scalars['String']>;
  work_experience_level?: InputMaybe<Scalars['Int']>;
};

export enum JobSource {
  API = 'Api',
  ATS = 'Ats',
  CONNECT2JOBS = 'Connect2Jobs',
  INDEED = 'Indeed',
  LINKEDIN = 'LinkedIn',
  LINKUP = 'Linkup',
  PORTAL = 'Portal',
  RANDSTAD = 'Randstad',
  TECNOEMPLEO = 'Tecnoempleo',
  TELEFONICA = 'Telefonica'
}

export type JobSourceConfiguration = {
  __typename?: 'JobSourceConfiguration';
  exclude: Array<JobSource>;
  include: Array<JobSource>;
};

export type JobSourceOptionsInput = {
  exclude: Array<JobSource>;
  include: Array<JobSource>;
};

export type Job_Input = {
  job_id: Scalars['String'];
  text: Scalars['String'];
};

export type JobsClassifierResponse = {
  __typename?: 'JobsClassifierResponse';
  confidence_score: Scalars['Float'];
  job_title_requested: Scalars['String'];
  role: Scalars['String'];
  role_uuid: Scalars['String'];
  top_candidate: Scalars['String'];
  uuid: Scalars['String'];
};

export type LlmBuildResume_Response = ResumeBuilderResponse;

export type LlmGenerateSkills_Response = UserSkillsGenerationResponse;

export type LlmParseResume_Response = ResumeParserResponse;

export type Label = {
  __typename?: 'Label';
  en_GB?: Maybe<Scalars['String']>;
  en_US?: Maybe<Scalars['String']>;
  es_ES?: Maybe<Scalars['String']>;
  fr_CA?: Maybe<Scalars['String']>;
  pt_PT?: Maybe<Scalars['String']>;
};

export type LeadSignUpInput = {
  career_area_interests?: InputMaybe<Array<Scalars['String']>>;
  communities?: InputMaybe<Array<Scalars['String']>>;
  communitiesObject?: InputMaybe<Array<DataSourceTypeInput>>;
  country?: InputMaybe<Scalars['String']>;
  custom_questions: Array<InputMaybe<RegistrationSubmissionUserResponseInput>>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<DataSourceTypeInput>;
  email: Scalars['String'];
  email_consent: Scalars['Boolean'];
  employer_id?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Array<DataSourceTypeInput>>;
  ethnicities?: InputMaybe<Array<DataSourceTypeInput>>;
  first_name?: InputMaybe<Scalars['String']>;
  form_version_id?: InputMaybe<Scalars['String']>;
  genderObject?: InputMaybe<DataSourceTypeInput>;
  industries_of_interest?: InputMaybe<Array<Scalars['String']>>;
  invite_code?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  location?: InputMaybe<Scalars['String']>;
  marketing_consent?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  target_career?: InputMaybe<Scalars['String']>;
  tenant_code: Scalars['String'];
  zip_code?: InputMaybe<Scalars['String']>;
};

export type LearningProviderSortObject = {
  /** Field to sort */
  field: Scalars['String'];
  /** Sort field by */
  value: Scalars['String'];
};

export type LearningProviders = {
  __typename?: 'LearningProviders';
  description?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  provider_id: Scalars['String'];
  provider_name: Scalars['String'];
  tenant_id?: Maybe<Scalars['String']>;
};

export type LearningProvidersFiltersInput = {
  /** Source Filter type */
  sources?: InputMaybe<SourceTypes>;
  /** Config Status Filter type */
  status?: InputMaybe<ConfigStatusTypes>;
};

export type LibraryInput = {
  locale?: InputMaybe<Scalars['String']>;
  next_steps?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
};

export type LibraryQl = {
  __typename?: 'LibraryQL';
  library_uuid: Scalars['String'];
  next_steps?: Maybe<Array<NextStepsMetadata>>;
  tenant_uuid?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updated_at: Scalars['String'];
  updated_by: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  target?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uri?: Maybe<Scalars['String']>;
};

export type LinkInput = {
  target?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};

export type LlmGeneratedSkill = {
  __typename?: 'LlmGeneratedSkill';
  classified_skill_uuid: Scalars['String'];
  llm_generated_label: Scalars['String'];
};

export type LlmGeneratedSkillInput = {
  classified_skill_uuid: Scalars['String'];
  llm_generated_label: Scalars['String'];
};

export type LlmGeneratedWorkExpSkills = {
  __typename?: 'LlmGeneratedWorkExpSkills';
  classified_skill_uuid: Scalars['String'];
  llm_generated_label: Scalars['String'];
};

export type LlmGeneratedWorkExpSkillsInput = {
  classified_skill_uuid: Scalars['String'];
  llm_generated_label: Scalars['String'];
};

export enum Locale {
  /** @deprecated App no longer supports en-GB */
  EN_GB = 'EN_GB',
  EN_US = 'EN_US',
  ES_ES = 'ES_ES',
  FR_CA = 'FR_CA',
  PT_PT = 'PT_PT'
}

export type ManageDeleteNextStepInput = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['ID'];
};

export type MarketingConsentHistory = {
  __typename?: 'MarketingConsentHistory';
  created_at: Scalars['DateTime'];
  is_consented: Scalars['Boolean'];
};

export type Member = {
  __typename?: 'Member';
  tenant_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type MergeIdentity = {
  __typename?: 'MergeIdentity';
  candidate_id: Scalars['String'];
  employer_uuid: Scalars['String'];
};

export type MergeIdentityInput = {
  candidate_id?: InputMaybe<Scalars['String']>;
  employer_uuid?: InputMaybe<Scalars['String']>;
};

export type MonetaryValue = {
  __typename?: 'MonetaryValue';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type MonetaryValueGraph = {
  __typename?: 'MonetaryValueGraph';
  currency: Scalars['String'];
  value: Scalars['Int'];
};

export type MoveUserInput = {
  admin_id: Scalars['String'];
  admin_name: Scalars['String'];
  destination_tenant_uuid: Scalars['String'];
  source_tenant_name: Scalars['String'];
  transfer_note: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type MoveUsersInput = {
  admin_id: Scalars['String'];
  destination_tenant_uuid: Scalars['String'];
  email: Scalars['String'];
  source_tenant_id: Scalars['String'];
  source_tenant_name: Scalars['String'];
  transfer_note: Scalars['String'];
};

export type MultiChoiceCheckboxInputField = {
  __typename?: 'MultiChoiceCheckboxInputField';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<Maybe<MultiChoiceCheckboxInputFieldOption>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<InputFieldUserMultiChoiceResponse>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type MultiChoiceCheckboxInputFieldInput_Input = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<InputMaybe<MultiChoiceCheckboxInputFieldOptionInput_Input>>;
  order?: InputMaybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<MultiChoiceCheckboxInputFieldUserResponseInput_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type MultiChoiceCheckboxInputFieldOption = {
  __typename?: 'MultiChoiceCheckboxInputFieldOption';
  id: Scalars['Int'];
  isChecked: Scalars['Boolean'];
  label: Scalars['String'];
  labelI18n?: Maybe<TranslatedField>;
  requiredAditionalTextBox: Scalars['Boolean'];
};

export type MultiChoiceCheckboxInputFieldOptionInput_Input = {
  id: Scalars['Int'];
  isChecked: Scalars['Boolean'];
  label: Scalars['String'];
  labelI18n?: InputMaybe<TranslatedField_Input>;
  requiredAditionalTextBox: Scalars['Boolean'];
};

export type MultiChoiceCheckboxInputFieldOptionWithTags = {
  __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags';
  id: Scalars['Int'];
  isChecked: Scalars['Boolean'];
  label: Scalars['String'];
  labelI18n?: Maybe<TranslatedField>;
  requiredAditionalTextBox: Scalars['Boolean'];
  tags?: Maybe<Array<Maybe<FormBuilderTag>>>;
};

export type MultiChoiceCheckboxInputFieldOptionWithTagsInput_Input = {
  id: Scalars['Int'];
  isChecked: Scalars['Boolean'];
  label: Scalars['String'];
  labelI18n?: InputMaybe<TranslatedField_Input>;
  requiredAditionalTextBox: Scalars['Boolean'];
  tags: Array<InputMaybe<FormBuilderTagInput_Input>>;
};

export type MultiChoiceCheckboxInputFieldUserResponse = {
  __typename?: 'MultiChoiceCheckboxInputFieldUserResponse';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Array<Maybe<SelectedMultiChoiceCheckboxInputFieldOption>>;
};

export type MultiChoiceCheckboxInputFieldUserResponseInput_Input = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Array<InputMaybe<SelectedMultiChoiceCheckboxInputFieldOptionInput_Input>>;
};

export type MultiChoiceCheckboxInputFieldUserResponseWithTags = {
  __typename?: 'MultiChoiceCheckboxInputFieldUserResponseWithTags';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Array<Maybe<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>>;
};

export type MultiChoiceCheckboxInputFieldUserResponseWithTagsInput = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  textValue?: InputMaybe<Scalars['String']>;
  value: Array<SelectedMultiChoiceCheckboxInputFieldOptionWithTagsInput>;
};

export type MultiChoiceCheckboxInputFieldUserResponseWithTagsInput_Input = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Array<InputMaybe<SelectedMultiChoiceCheckboxInputFieldOptionWithTagsInput_Input>>;
};

export type MultiChoiceCheckboxInputFieldWithTags = {
  __typename?: 'MultiChoiceCheckboxInputFieldWithTags';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<Maybe<MultiChoiceCheckboxInputFieldOptionWithTags>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<InputFieldUserMultiChoiceResponseWithTags>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type MultiChoiceCheckboxInputFieldWithTagsInput_Input = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  options: Array<InputMaybe<MultiChoiceCheckboxInputFieldOptionWithTagsInput_Input>>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<MultiChoiceCheckboxInputFieldUserResponseWithTagsInput_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Deleted user data in bulk and returns the number of affected rows. */
  BulkDeleteUserData?: Maybe<BulkDeleteUserData_200_Response>;
  /** Build Resume */
  LLMBuildResume?: Maybe<LlmBuildResume_Response>;
  /** Generate User Skills */
  LLMGenerateSkills?: Maybe<LlmGenerateSkills_Response>;
  /** Parse Resume */
  LLMParseResume?: Maybe<LlmParseResume_Response>;
  UserResumeController_processResume?: Maybe<Scalars['JSON']>;
  addContact: Contact;
  addMember?: Maybe<Member>;
  addNextStepToLibrary: LibraryQl;
  addTenantAsset: AssetsDto;
  addUserCaseNote: CaseNote;
  addUserSkills: SkillModification;
  /** Archives all jobs belonging to the tenant. Generally used for testing purposes to clear state */
  archiveAllJobs: JobService_ArchiveAllManualJobsResponse;
  archiveForm: Scalars['Boolean'];
  archiveManualJob: JobService_ManualJobResponse;
  assignCoach: Array<Profile>;
  /** Assign coaches to their group assignments. Requires admin or super-admin role. */
  assignGroupCoaches?: Maybe<GroupCoachesResult>;
  assignUserNextStep: UserNextStepsWrapper;
  buildUserResume: BuildUserResumeResponse;
  bulkUpdateProfiles: BulkProfileUpdateResult;
  clearCandidateCache?: Maybe<Scalars['Boolean']>;
  comprehensiveDeleteTenant?: Maybe<ComprehensiveDeleteTenant_200_Response>;
  configureTenant: Scalars['String'];
  confirmNextStepDoc: ConfirmNextStepDocResponse;
  /** Confirm a user's registration given their email and user_uuid */
  confirmRecruiterRegistration?: Maybe<ConfirmRegistrationResponse>;
  createAssessmentFromExternalData: Assessment;
  createAsyncUpdateStatus: AsyncUpdateStatus;
  createConfigurationV2: Configuration;
  createCustomerObjectUrl: PreSignedUrlForCustomerType;
  createDefaultQuestions?: Maybe<Scalars['String']>;
  /** Create employer */
  createEmployer?: Maybe<Employer>;
  /** Create a caseNote */
  createEmployerCaseNote?: Maybe<EmployerCaseNote>;
  /** Creates an empty form and form version for it that is set as draft. */
  createForm: Form;
  /** Creates a new form version and archives any previous versions of the same status. */
  createFormVersion: Form;
  /** Create group */
  createGroup?: Maybe<Group>;
  createIntegration?: Maybe<Integration>;
  createInvitation?: Maybe<Invitation>;
  createInviteLink?: Maybe<InviteLink>;
  createManualPostJob: JobService_ManualJobResponse;
  createMergeLinkToken: Scalars['String'];
  /**
   * Use the `createFormVersion` graphql mutation instead.
   * @deprecated deprecated
   */
  createNewDraft?: Maybe<CreateFormResponse>;
  /**
   * Use the `createForm` graphql mutation instead.
   * @deprecated deprecated
   */
  createNewForm?: Maybe<CreateFormResponse>;
  createNextStepsConfigurations: NextStepsConfigurations;
  createNextStepsMetadata: NextStepsMetadata;
  createPostObjectURL: PreSignedUrlType;
  /** Create recruiter */
  createRecruiter?: Maybe<Recruiter>;
  /**
   * Use the `createForm` graphql mutation instead.
   * @deprecated deprecated
   */
  createRegistrationForm?: Maybe<RegistrationForm>;
  createRegistrationSubmission?: Maybe<Scalars['Boolean']>;
  createReportExport: Scalars['Boolean'];
  /** Creates a single external REST API client */
  createRestApiClient?: Maybe<RestApiClient>;
  createSubTenant?: Maybe<Tenant>;
  createTenant?: Maybe<Tenant>;
  createUserProfile: Profile;
  createUserRole: Role;
  createUserTags: Array<TaggingUserTag>;
  createUserTagsForTenant: Array<TaggingUserTag>;
  delNextStepDoc: DelNextStepDocResponse;
  /** This endpoint will delete a user's cached resume data from the resume parser database. It does not delete the user's resume from the entire system and thus should not be called independently by the client. */
  deleteCachedResumeData?: Maybe<DeleteCachedResumeData_200_Response>;
  /** Delete contacts for a specific user. */
  deleteContactsFromUser: Array<Contact>;
  deleteDataByTenant: DeleteConfigurationOutput;
  /** Delete employers and its linked recruiter users */
  deleteEmployer?: Maybe<Scalars['Int']>;
  /** Delete a caseNote */
  deleteEmployerCaseNote?: Maybe<EmployerCaseNote>;
  /** Delete employers and associated recruiter users for a tenant ID */
  deleteEmployerDataByTenant?: Maybe<DeleteEmployersByTenantResponse>;
  deleteExportReportsByTenant: DeleteExportReportsOutput;
  deleteFeature: Scalars['String'];
  /**
   * Use the `archiveForm` graphql mutation instead.
   * @deprecated deprecated
   */
  deleteForm?: Maybe<Scalars['Boolean']>;
  deleteFormBuilderData?: Maybe<DeleteFormBuilderData_200_Response>;
  deleteInvitation?: Maybe<Scalars['Void']>;
  deletePolicyAssets: Configuration;
  deleteProfiles: DeleteProfilesOutput;
  /** Delete recruiter by id */
  deleteRecruiter?: Maybe<Recruiter>;
  /** Delete recruiters */
  deleteRecruiters?: Maybe<Array<Maybe<Mutation_DeleteRecruiters_Items>>>;
  /** Delete recruiters by user uuids */
  deleteRecruitersByUserUuids?: Maybe<Array<Maybe<Mutation_DeleteRecruiters_Items>>>;
  /** Delete user resume */
  deleteResume?: Maybe<DeleteResume_200_Response>;
  deleteSavedJob: JobService_SavedJob;
  deleteTag: Scalars['String'];
  deleteTagsByTenant: Scalars['String'];
  deleteTenant?: Maybe<Scalars['Void']>;
  deleteTenantFeatures: Scalars['String'];
  deleteTenantFromAccessControl?: Maybe<Scalars['String']>;
  deleteTenantNextSteps: DeleteTenantNextStepsOutput;
  deleteUserCaseNote: Scalars['String'];
  deleteUserNextStep: DeleteUserNextStepsOutput;
  deleteUserNextSteps: DeleteUserNextStepsOutput;
  deleteUserTags: Array<TaggingUserTag>;
  deleteUsers: Scalars['Boolean'];
  deleteUsersFromAccessControl: DeleteUsersOutput;
  deleteUsersFromAssessments: DeleteUsersOutput;
  disableTenantFeatures: Array<Scalars['String']>;
  disableUserFeatures: Scalars['String'];
  easyApplyToATSJob: JobService_EasyApplyToAtsJobResponse;
  enableTenantFeatures: Array<Feature>;
  enableTenantFeaturesBulk: TenantFeatureBulkResult;
  enableUserFeatures: Array<Feature>;
  exchangeMergeAccountToken: Scalars['String'];
  exportProfileAuthorize: ExportProfileAuthorizeResponseData;
  exportProfileDownloadCsvFile: ExportProfileDownloadCsvFileResponseData;
  getCandidateRecommendationsByID?: Maybe<CandidateRecommender_Candidate>;
  getSkillsFromCareer: JobService_SkillsResponse;
  inviteToVervoeAssessment: VervoeInviteToAssessmentResponse;
  leadSignUp: Scalars['Boolean'];
  manageDeleteNextStep: Scalars['Boolean'];
  moveUser: Scalars['String'];
  moveUsers: Scalars['String'];
  /** Process Search Query */
  processSearchQuery?: Maybe<ProcessSearchQuery_Response>;
  provisionTenant?: Maybe<Tenant>;
  /**
   * Use the `updateFormVersion` graphql mutation instead.
   * @deprecated deprecated
   */
  publishForm?: Maybe<Scalars['Boolean']>;
  publishRoadmap: Roadmap;
  removeAsyncUpdateStatus: Scalars['Boolean'];
  removeMember?: Maybe<Scalars['Void']>;
  removeUserSkills: SkillModification;
  removeUsersFromFormBuilder?: Maybe<RemoveUsersFromFormBuilderResponse>;
  /** Delete all data for a list of users */
  removeUsersFromSaveCareer?: Maybe<RemoveUsersOutput>;
  /** Delete all data for a list of users */
  removeUsersFromSaveLearning?: Maybe<RemoveUsersOutput>;
  /** Delete all data for a list of users */
  removeUsersFromSaveResource?: Maybe<RemoveUsersOutput>;
  requestSubmissionsExportation?: Maybe<Scalars['Boolean']>;
  resetProfile: Profile;
  resetUserNextSteps: UserNextStepsWrapper;
  revokeToken: Scalars['String'];
  /** Add new a saved career entry */
  saveCareer?: Maybe<SaveCareerOutput>;
  /**
   * Use the `updateFormVersion` graphql mutation instead.
   * @deprecated deprecated
   */
  saveFormVersionDraft?: Maybe<Scalars['Boolean']>;
  saveJob: JobService_SavedJob;
  /** Add new a saved learnings entry */
  saveLearning?: Maybe<SaveLearningOutput>;
  /** Add new a saved resource entry */
  saveResource?: Maybe<SaveResourceOutput>;
  saveSubmission?: Maybe<SubmissionResponse>;
  /** Save an array of talents' user_uuids for a recruiter */
  saveTalents?: Maybe<Array<Maybe<SavedTalent>>>;
  /** @deprecated Meant to run once and then delete in next release. */
  scriptUpdateLanguageRestriction: Scalars['String'];
  /** @deprecated Use "create/updateConfigurationV2" instead */
  setDeepCaseManagementConfiguration: DeepCaseManagement;
  /** Syncs an EAP stored in mongo with EO's API. */
  syncEap: Scalars['Boolean'];
  /** @deprecated Tagging users via the user-profile service is deprecated. Please use createUserTags mutation in the tagging-service or pass the tags in the updateUserProfile mutation. */
  tagUsers: Array<Profile>;
  toggleTagVisibility: Tag;
  unassignCoach: Array<Profile>;
  /** Remove coaches from their current group assignments. Requires admin or super-admin role. */
  unassignGroupCoaches?: Maybe<GroupCoachesResult>;
  /** Unsave a career (Remove a career entry) */
  unsaveCareer?: Maybe<UnsaveCareerOutput>;
  /** Unsave a learning item (Remove a learning entry) */
  unsaveLearning?: Maybe<UnsaveLearningOutput>;
  /** Unsave a resource (Remove a resource entry) */
  unsaveResource?: Maybe<UnsaveResourceOutput>;
  /** Delete an array of talents' user_uuids' relation with a recruiter */
  unsaveTalents?: Maybe<Array<Maybe<SavedTalent>>>;
  updateAccountPreferences: AccountPreferencesProfile;
  updateAsyncUpdateStatus: AsyncUpdateStatus;
  updateCognitoEmployerIds: CognitoUpdateResult;
  /** @deprecated Use updateConfigurationV2 instead */
  updateConfiguration: Scalars['String'];
  updateConfigurationV2: Configuration;
  updateDefaultQuestions?: Maybe<Scalars['String']>;
  updateDraftRoadmap: Roadmap;
  /** Update employer by id */
  updateEmployer?: Maybe<Employer>;
  /** Update a caseNote */
  updateEmployerCaseNote?: Maybe<EmployerCaseNote>;
  updateExportReport: ExportReport;
  /** Updates an existing form version in-place. */
  updateFormVersion: Form;
  /** Update group by id */
  updateGroup?: Maybe<Group>;
  updateIntegration?: Maybe<Integration>;
  updateInvitation?: Maybe<Invitation>;
  updateInviteLink?: Maybe<InviteLink>;
  updateJobConfiguration: JobConfigurationOutput;
  updateJobSearchHistory: Scalars['String'];
  updateLearningProviderLogo: LearningProviders;
  updateLibrary: LibraryQl;
  updateManualJob: JobService_ManualJobResponse;
  updateNextStepsConfigurations: NextStepsConfigurations;
  updateNextStepsMetadata: NextStepsMetadata;
  /**
   * Activating and deactivating onboarding forms is deprecated. Instead, set the onboarding form as draft or published.
   * @deprecated deprecated
   */
  updateOnboardingFormActivationStatus?: Maybe<Scalars['Boolean']>;
  /** @deprecated All functionalities of updateProfileWithTagChecking mutation can be done by updateUserProfile mutation */
  updateProfileWithTagChecking: Profile;
  /** Update recruiter by id */
  updateRecruiter?: Maybe<Recruiter>;
  updateRegistrationConfiguration: Configuration;
  /**
   * Use the `updateFormVersion` graphql mutation instead.
   * @deprecated deprecated
   */
  updateRegistrationForm?: Maybe<RegistrationForm>;
  updateSubmission?: Maybe<SubmissionResponse>;
  updateTenant?: Maybe<Tenant>;
  updateTenantWorkConfiguration: Scalars['String'];
  updateUserCaseNote: CaseNote;
  updateUserProfile: Profile;
  updateUserProfileRole: UserProfileRole;
  /** @deprecated Replaced by upsertUserRole */
  updateUserRole: Role;
  updateUserSkills: SkillModification;
  updateUserTenant?: Maybe<Array<Maybe<UpdateUserTenantOutput>>>;
  updateUserTenantForAccessControl: Scalars['String'];
  upsertAssessment: Assessment;
  upsertFeature: Feature;
  upsertIrrelevantWorkExperienceSkills: WorkExperience;
  upsertTag: Tag;
  upsertTags: Array<Tag>;
  upsertTenant: Tenant;
  upsertUserNextStep: UserNextStepsWrapper;
  upsertUserRole: Role;
  userResumeUpload: UserType;
};


export type MutationBulkDeleteUserDataArgs = {
  input?: InputMaybe<BulkDeleteUserData_Request_Input>;
};


export type MutationLlmBuildResumeArgs = {
  input?: InputMaybe<ResumeBuilderRequest_Input>;
};


export type MutationLlmGenerateSkillsArgs = {
  input?: InputMaybe<UserSkillsGenerationRequest_Input>;
};


export type MutationLlmParseResumeArgs = {
  input?: InputMaybe<ResumeParserRequest_Input>;
};


export type MutationUserResumeController_ProcessResumeArgs = {
  input?: InputMaybe<ProcessResumeDto_Input>;
};


export type MutationAddContactArgs = {
  addContactInput: AddContactInput;
};


export type MutationAddMemberArgs = {
  input?: InputMaybe<AddMember_Request_Input>;
  tenant_id: Scalars['String'];
};


export type MutationAddNextStepToLibraryArgs = {
  addNextStepToLibraryInput: LibraryInput;
};


export type MutationAddTenantAssetArgs = {
  addTenantAssetInput: AddTenantAssetInput;
};


export type MutationAddUserCaseNoteArgs = {
  createUserCaseNoteInput: CreateUserCaseNoteInput;
};


export type MutationAddUserSkillsArgs = {
  addUserSkillsInput: AddUserSkillsInput;
};


export type MutationArchiveFormArgs = {
  formUuid: Scalars['String'];
  formVersionUuid?: InputMaybe<Scalars['String']>;
};


export type MutationArchiveManualJobArgs = {
  archiveManualJobInput: JobService_ArchiveManualJobInput;
};


export type MutationAssignCoachArgs = {
  assignCoachInput: AssignCoachInput;
};


export type MutationAssignGroupCoachesArgs = {
  input?: InputMaybe<AssignGroupCoachesInput_Input>;
};


export type MutationAssignUserNextStepArgs = {
  upsertUserNextStepInput: UpsertUserNextStepInput;
};


export type MutationBulkUpdateProfilesArgs = {
  bulkUpdateInput: BulkProfileUpdateInput;
};


export type MutationComprehensiveDeleteTenantArgs = {
  input?: InputMaybe<ComprehensiveDeleteTenantInput_Input>;
};


export type MutationConfigureTenantArgs = {
  configureTenantInput: ConfigureTenantInput;
};


export type MutationConfirmNextStepDocArgs = {
  confirmNextStepDocInput: ConfirmNextStepDocInput;
};


export type MutationConfirmRecruiterRegistrationArgs = {
  input?: InputMaybe<ConfirmRegistrationRequestBody_Input>;
};


export type MutationCreateAssessmentFromExternalDataArgs = {
  createAssessmentFromExternalDataInput: UpsertAssessmentInput;
};


export type MutationCreateAsyncUpdateStatusArgs = {
  createAsyncUpdateStatusInput: CreateAsyncUpdateStatusInput;
};


export type MutationCreateConfigurationV2Args = {
  createConfigurationInput: CreateConfigurationInputV2;
};


export type MutationCreateCustomerObjectUrlArgs = {
  input: PreSignedUrlForCustomerInput;
};


export type MutationCreateDefaultQuestionsArgs = {
  input?: InputMaybe<CreateNewDefaultQuestionsBodyRequest_Input>;
  tenant_uuid: Scalars['String'];
};


export type MutationCreateEmployerArgs = {
  input?: InputMaybe<CreateEmployerInput_Input>;
};


export type MutationCreateEmployerCaseNoteArgs = {
  input?: InputMaybe<CreateEmployerCaseNoteRequestBody_Input>;
};


export type MutationCreateFormArgs = {
  input: CreateFormInput;
};


export type MutationCreateFormVersionArgs = {
  input: CreateFormVersionInput;
};


export type MutationCreateGroupArgs = {
  input?: InputMaybe<CreateGroupInput_Input>;
};


export type MutationCreateIntegrationArgs = {
  createIntegrationInput: CreateIntegrationInput;
};


export type MutationCreateInvitationArgs = {
  input?: InputMaybe<NewInvitation_Input>;
};


export type MutationCreateInviteLinkArgs = {
  input?: InputMaybe<NewInviteLink_Input>;
};


export type MutationCreateManualPostJobArgs = {
  createManualPostJobInput: JobService_CreateManualJobInput;
};


export type MutationCreateMergeLinkTokenArgs = {
  create_link: JobService_CreateLink;
};


export type MutationCreateNewDraftArgs = {
  formVersionUuid: Scalars['String'];
  input?: InputMaybe<CreateNewDraft_Request_Input>;
  tenantUuid: Scalars['String'];
};


export type MutationCreateNewFormArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationCreateNextStepsConfigurationsArgs = {
  createNextStepsConfigurationInput: NextStepsConfigurationInput;
};


export type MutationCreateNextStepsMetadataArgs = {
  createNextStepsMetadataInput: CreateNextStepsMetadataInput;
};


export type MutationCreatePostObjectUrlArgs = {
  input: PreSignedUrlInput;
};


export type MutationCreateRecruiterArgs = {
  input?: InputMaybe<RecruiterCreateRequestBody_Input>;
};


export type MutationCreateRegistrationFormArgs = {
  input?: InputMaybe<RegistrationFormInput_Input>;
  tenantUuid: Scalars['String'];
};


export type MutationCreateRegistrationSubmissionArgs = {
  input?: InputMaybe<CreateRegistrationSubmission_Request_Input>;
  userUuid: Scalars['String'];
};


export type MutationCreateReportExportArgs = {
  createReportExportInput: CreateReportExportInput;
};


export type MutationCreateRestApiClientArgs = {
  input?: InputMaybe<CreateRestApiClient_Request_Input>;
};


export type MutationCreateSubTenantArgs = {
  input?: InputMaybe<NewTenant_Input>;
  tenant_id: Scalars['String'];
};


export type MutationCreateTenantArgs = {
  input?: InputMaybe<NewTenant_Input>;
};


export type MutationCreateUserProfileArgs = {
  createProfileInput: CreateProfileInput;
};


export type MutationCreateUserRoleArgs = {
  createRoleInput: CreateRoleInput;
};


export type MutationCreateUserTagsArgs = {
  createUserTagsInput: CreateUserTagsInput;
};


export type MutationCreateUserTagsForTenantArgs = {
  tags?: InputMaybe<Array<InputMaybe<TaggingUserTagInput>>>;
};


export type MutationDelNextStepDocArgs = {
  delNextStepDocInput: DelNextStepDocInput;
};


export type MutationDeleteCachedResumeDataArgs = {
  input?: InputMaybe<DeleteCachedResumeData_Request_Input>;
};


export type MutationDeleteContactsFromUserArgs = {
  contact_uuids?: InputMaybe<Array<Scalars['String']>>;
  user_uuid: Scalars['String'];
};


export type MutationDeleteDataByTenantArgs = {
  configurationId: Scalars['String'];
  tenantId: Scalars['String'];
};


export type MutationDeleteEmployerArgs = {
  input?: InputMaybe<DeleteEmployersRequestBody_Input>;
};


export type MutationDeleteEmployerCaseNoteArgs = {
  id: Scalars['String'];
  input?: InputMaybe<DeleteEmployerCaseNoteRequestBody_Input>;
};


export type MutationDeleteEmployerDataByTenantArgs = {
  input?: InputMaybe<DeleteEmployersByTenantIdRequestBody_Input>;
};


export type MutationDeleteExportReportsByTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteFeatureArgs = {
  feature_uuid: Scalars['String'];
};


export type MutationDeleteFormArgs = {
  formId: Scalars['String'];
  input?: InputMaybe<DeleteForm_Request_Input>;
  tenantUuid: Scalars['String'];
};


export type MutationDeleteFormBuilderDataArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteInvitationArgs = {
  invitationId: Scalars['String'];
};


export type MutationDeletePolicyAssetsArgs = {
  deleteTenantPolicyAssetInput: DeleteTenantPolicyAssetInput;
};


export type MutationDeleteProfilesArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationDeleteRecruiterArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRecruitersArgs = {
  input?: InputMaybe<DeleteRecruitersRequestBody_Input>;
};


export type MutationDeleteRecruitersByUserUuidsArgs = {
  input?: InputMaybe<DeleteRecruiterByUserUuidsRequestBody_Input>;
};


export type MutationDeleteResumeArgs = {
  input?: InputMaybe<DeleteResume_Request_Input>;
};


export type MutationDeleteSavedJobArgs = {
  uuid: Scalars['String'];
};


export type MutationDeleteTagArgs = {
  tag_uuid: Scalars['String'];
};


export type MutationDeleteTagsByTenantArgs = {
  tenantId: Scalars['String'];
};


export type MutationDeleteTenantArgs = {
  tenant_id: Scalars['String'];
};


export type MutationDeleteTenantFeaturesArgs = {
  tenantId: Scalars['String'];
};


export type MutationDeleteTenantFromAccessControlArgs = {
  tenantId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTenantNextStepsArgs = {
  tenantId: Scalars['String'];
};


export type MutationDeleteUserCaseNoteArgs = {
  deleteUserCaseNoteInput: DeleteUserCaseNoteInput;
};


export type MutationDeleteUserNextStepArgs = {
  deleteUserStepInput: DeleteUserStepInput;
};


export type MutationDeleteUserNextStepsArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationDeleteUserTagsArgs = {
  deleteUserTagsInput: DeleteUserTagsInput;
};


export type MutationDeleteUsersArgs = {
  delete_users_input: DeleteUsersInput;
};


export type MutationDeleteUsersFromAccessControlArgs = {
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationDeleteUsersFromAssessmentsArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationDisableTenantFeaturesArgs = {
  tenant_features: TenantFeature;
};


export type MutationDisableUserFeaturesArgs = {
  user_features: UserFeature;
};


export type MutationEasyApplyToAtsJobArgs = {
  easyApplyToATSJobInput: JobService_EasyApplyToAtsJobInput;
};


export type MutationEnableTenantFeaturesArgs = {
  tenant_features: TenantFeature;
};


export type MutationEnableTenantFeaturesBulkArgs = {
  tenant_features: TenantFeatureBulkInput;
};


export type MutationEnableUserFeaturesArgs = {
  user_features: UserFeature;
};


export type MutationExchangeMergeAccountTokenArgs = {
  exchange: JobService_ExchangeAccountToken;
};


export type MutationExportProfileAuthorizeArgs = {
  exportProfileAuthorizeInput: ExportProfileAuthorizeInput;
};


export type MutationExportProfileDownloadCsvFileArgs = {
  createExportProfileInput: ExportProfileDownloadCsvFileInput;
};


export type MutationGetCandidateRecommendationsByIdArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<CandidateRecommender_CandidateRecommenderInput_Input>;
  uuid: Scalars['String'];
};


export type MutationGetSkillsFromCareerArgs = {
  getSkillsInput: JobService_GetSkillsInput;
};


export type MutationInviteToVervoeAssessmentArgs = {
  inviteToVervoeAssessmentInput: VervoeInviteToAssessmentRequest;
};


export type MutationLeadSignUpArgs = {
  profile_info: LeadSignUpInput;
};


export type MutationManageDeleteNextStepArgs = {
  manageDeleteNextStepInput: ManageDeleteNextStepInput;
};


export type MutationMoveUserArgs = {
  moveUserInput: MoveUserInput;
};


export type MutationMoveUsersArgs = {
  move_users_input: MoveUsersInput;
};


export type MutationProcessSearchQueryArgs = {
  input?: InputMaybe<SearchQueryProcessingRequest_Input>;
};


export type MutationProvisionTenantArgs = {
  input?: InputMaybe<ProvisionTenantInput_Input>;
};


export type MutationPublishFormArgs = {
  formVersionUuid: Scalars['String'];
};


export type MutationPublishRoadmapArgs = {
  publishRoadmapInput: PublishRoadmapInput;
};


export type MutationRemoveAsyncUpdateStatusArgs = {
  async_update_status_uuid: Scalars['String'];
};


export type MutationRemoveMemberArgs = {
  tenant_id: Scalars['String'];
  user_id: Scalars['String'];
};


export type MutationRemoveUserSkillsArgs = {
  removeUserSkillsInput: RemoveUserSkillsInput;
};


export type MutationRemoveUsersFromFormBuilderArgs = {
  input?: InputMaybe<RemoveUsersFromFormBuilderInput_Input>;
};


export type MutationRemoveUsersFromSaveCareerArgs = {
  input?: InputMaybe<RemoveUsersInput_Input>;
};


export type MutationRemoveUsersFromSaveLearningArgs = {
  input?: InputMaybe<RemoveUsersInput_Input>;
};


export type MutationRemoveUsersFromSaveResourceArgs = {
  input?: InputMaybe<RemoveUsersInput_Input>;
};


export type MutationRequestSubmissionsExportationArgs = {
  input?: InputMaybe<RequestSubmissionsExportation_Request_Input>;
  tenantUuid: Scalars['String'];
};


export type MutationResetProfileArgs = {
  user_uuid: Scalars['String'];
};


export type MutationResetUserNextStepsArgs = {
  user_uuid: Scalars['String'];
};


export type MutationRevokeTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationSaveCareerArgs = {
  input?: InputMaybe<SaveCareerInput_Input>;
};


export type MutationSaveFormVersionDraftArgs = {
  formVersionUuid: Scalars['String'];
  input?: InputMaybe<SaveFormVersionDraft_Request_Input>;
  tenantUuid: Scalars['String'];
};


export type MutationSaveJobArgs = {
  save: JobService_SaveJobInput;
};


export type MutationSaveLearningArgs = {
  input?: InputMaybe<SaveLearningInput_Input>;
};


export type MutationSaveResourceArgs = {
  input?: InputMaybe<SaveResourceInput_Input>;
};


export type MutationSaveSubmissionArgs = {
  input?: InputMaybe<SaveSubmission_Request_Input>;
  userUuid: Scalars['String'];
};


export type MutationSaveTalentsArgs = {
  input?: InputMaybe<CreateSavedTalentsRequestBody_Input>;
  user_uuid: Scalars['String'];
};


export type MutationSetDeepCaseManagementConfigurationArgs = {
  deepCaseManagement: DeepCaseManagementMutationInput;
};


export type MutationSyncEapArgs = {
  case_management_eap_id: Scalars['String'];
};


export type MutationTagUsersArgs = {
  tagUsersInput: TagUsersInput;
};


export type MutationToggleTagVisibilityArgs = {
  tag_uuid: Scalars['String'];
};


export type MutationUnassignCoachArgs = {
  unassignCoachInput: UnassignCoachInput;
};


export type MutationUnassignGroupCoachesArgs = {
  input?: InputMaybe<UnassignGroupCoachesInput_Input>;
};


export type MutationUnsaveCareerArgs = {
  input?: InputMaybe<UnsaveCareerInput_Input>;
};


export type MutationUnsaveLearningArgs = {
  input?: InputMaybe<UnsaveLearningInput_Input>;
};


export type MutationUnsaveResourceArgs = {
  input?: InputMaybe<UnsaveResourceInput_Input>;
};


export type MutationUnsaveTalentsArgs = {
  input?: InputMaybe<UnsaveTalentsRequestBody_Input>;
  user_uuid: Scalars['String'];
};


export type MutationUpdateAccountPreferencesArgs = {
  updateAccountPreferencesInput: UpdateAccountPreferencesInput;
};


export type MutationUpdateAsyncUpdateStatusArgs = {
  updateAsyncUpdateStatusInput: UpdateAsyncUpdateStatusInput;
};


export type MutationUpdateCognitoEmployerIdsArgs = {
  updates: Array<CognitoEmployerIdUpdateInput>;
};


export type MutationUpdateConfigurationArgs = {
  updateConfigurationInput: UpdateConfigurationInput;
};


export type MutationUpdateConfigurationV2Args = {
  updateConfigurationInput: UpdateConfigurationInputV2;
};


export type MutationUpdateDefaultQuestionsArgs = {
  input?: InputMaybe<UpdateDefaultQuestionsBodyRequest_Input>;
  tenant_uuid: Scalars['String'];
};


export type MutationUpdateDraftRoadmapArgs = {
  updateDraftRoadmapInput: UpdateDraftRoadmapInput;
};


export type MutationUpdateEmployerArgs = {
  id: Scalars['String'];
  input?: InputMaybe<UpdateEmployerInput_Input>;
};


export type MutationUpdateEmployerCaseNoteArgs = {
  id: Scalars['String'];
  input?: InputMaybe<UpdateEmployerCaseNoteRequestBody_Input>;
};


export type MutationUpdateExportReportArgs = {
  updateExportReportInput: UpdateExportReportInput;
};


export type MutationUpdateFormVersionArgs = {
  input: UpdateFormVersionInput;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['String'];
  input?: InputMaybe<UpdateGroupInput_Input>;
};


export type MutationUpdateIntegrationArgs = {
  updateIntegrationInput: UpdateIntegrationInput;
};


export type MutationUpdateInvitationArgs = {
  input?: InputMaybe<UpdateInvitation_Input>;
  invitationId: Scalars['String'];
};


export type MutationUpdateInviteLinkArgs = {
  input?: InputMaybe<UpdateInviteLink_Input>;
};


export type MutationUpdateJobConfigurationArgs = {
  updateJobConfigurationInput: UpdateJobConfigurationInput;
};


export type MutationUpdateJobSearchHistoryArgs = {
  searched_job: SearchedJob;
  user_uuid: Scalars['String'];
};


export type MutationUpdateLearningProviderLogoArgs = {
  updateLearningProviderLogoInput: UpdateLearningProviderLogoInput;
};


export type MutationUpdateLibraryArgs = {
  updateLibraryInput: LibraryInput;
};


export type MutationUpdateManualJobArgs = {
  updateManualJobInput: JobService_UpdateManualJobInput;
};


export type MutationUpdateNextStepsConfigurationsArgs = {
  updateNextStepsConfigurationInput: NextStepsConfigurationInput;
};


export type MutationUpdateNextStepsMetadataArgs = {
  updateNextStepsMetadataInput: UpdateNextStepsMetadataInput;
};


export type MutationUpdateOnboardingFormActivationStatusArgs = {
  input?: InputMaybe<UpdateOnboardingFormActivationStatus_Request_Input>;
  tenantUuid: Scalars['String'];
};


export type MutationUpdateProfileWithTagCheckingArgs = {
  updateProfileTagInput: UpdateProfileTagInput;
};


export type MutationUpdateRecruiterArgs = {
  id: Scalars['String'];
  input?: InputMaybe<UpdateRecruiterRequestBody_Input>;
};


export type MutationUpdateRegistrationConfigurationArgs = {
  updateRegistrationConfigurationInput: UpdateRegistrationConfigurationInput;
};


export type MutationUpdateRegistrationFormArgs = {
  input?: InputMaybe<RegistrationFormInput_Input>;
  tenantUuid: Scalars['String'];
};


export type MutationUpdateSubmissionArgs = {
  input?: InputMaybe<UpdateSubmission_Request_Input>;
  submission_id: Scalars['String'];
  userUuid: Scalars['String'];
};


export type MutationUpdateTenantArgs = {
  input?: InputMaybe<UpdateTenantInput_Input>;
  tenant_id: Scalars['String'];
};


export type MutationUpdateTenantWorkConfigurationArgs = {
  configuration_id: Scalars['String'];
  location_restrictions: Array<InputMaybe<Scalars['String']>>;
};


export type MutationUpdateUserCaseNoteArgs = {
  updateUserCaseNoteInput: UpdateUserCaseNoteInput;
};


export type MutationUpdateUserProfileArgs = {
  updateProfileInput: UpdateProfileInput;
};


export type MutationUpdateUserProfileRoleArgs = {
  userProfileRoleInput: UserProfileRoleInput;
};


export type MutationUpdateUserRoleArgs = {
  updateRoleInput: UpdateRoleInput;
};


export type MutationUpdateUserSkillsArgs = {
  updateUserSkillsInput: UpdateUserSkillsInput;
};


export type MutationUpdateUserTenantArgs = {
  input?: InputMaybe<UpdateUserTenantBody_Input>;
  tenant_id: Scalars['String'];
  user_id: Scalars['String'];
};


export type MutationUpdateUserTenantForAccessControlArgs = {
  updateUserTenantInput: UpdateUserTenantInput;
};


export type MutationUpsertAssessmentArgs = {
  upsertAssessmentInput: UpsertAssessmentInput;
};


export type MutationUpsertFeatureArgs = {
  feature: UpsertFeature;
};


export type MutationUpsertIrrelevantWorkExperienceSkillsArgs = {
  upsertIrrelevantWorkExpSkills: UpsertIrrelevantWorkExpSkillsInput;
};


export type MutationUpsertTagArgs = {
  upsertTagInput: UpsertTagInput;
};


export type MutationUpsertTagsArgs = {
  upsertTagsInput: UpsertTagsInput;
};


export type MutationUpsertTenantArgs = {
  upsertTenantInput: UpsertTenantInput;
};


export type MutationUpsertUserNextStepArgs = {
  upsertUserNextStepInput: UpsertUserNextStepInput;
};


export type MutationUpsertUserRoleArgs = {
  upsertRoleInput: UpsertRoleInput;
};


export type MutationUserResumeUploadArgs = {
  input: UserInput;
};

export type NestedTriggerReportingEtlInput = {
  tenantUuids?: InputMaybe<Array<Scalars['String']>>;
};

export type NewInvitation_Input = {
  /** Whether this invitation has already been confirmed or not */
  created_by_uuid?: InputMaybe<Scalars['String']>;
  /** Email for this invitation resource */
  email?: InputMaybe<Scalars['String']>;
  employer_id?: InputMaybe<Scalars['String']>;
  /** The language displayed in the invitation email */
  invitation_email_locale?: InputMaybe<Scalars['String']>;
  /** The role type for this invitation (coach/recruiter/...) */
  role?: InputMaybe<Scalars['String']>;
};

export type NewInviteLink_Input = {
  /** A list of email domains that are allowed to use this invite link */
  emailDomains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  employerId?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  linkType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  tenantID: Scalars['String'];
};

export type NewTenant_Input = {
  /** Appears after UUID in the entry in MongoDB for this tenant in the configuration collection */
  configuration_id?: InputMaybe<Scalars['String']>;
  custom_domain?: InputMaybe<Scalars['String']>;
  favicon?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  page_title?: InputMaybe<Scalars['String']>;
};

export type NextStepDoc = {
  __typename?: 'NextStepDoc';
  name?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
};

export type NextStepDocDownload = {
  __typename?: 'NextStepDocDownload';
  fileName?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type NextStepDocInput = {
  docRefId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  orig_type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type NextStepsConfigurationInput = {
  name: Scalars['String'];
  next_step_ids: Array<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
};

export type NextStepsConfigurations = {
  __typename?: 'NextStepsConfigurations';
  config_id: Scalars['String'];
  draft_next_step_ids: Array<Scalars['String']>;
  name: Scalars['String'];
  next_step_ids: Array<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
};

export type NextStepsMetadata = {
  __typename?: 'NextStepsMetadata';
  category?: Maybe<Scalars['String']>;
  /** @deprecated field was replaced by `next_step_description` for returning data in string based on locale */
  description?: Maybe<Label>;
  goals?: Maybe<Array<Scalars['String']>>;
  is_completable: Scalars['Boolean'];
  link?: Maybe<Link>;
  next_step_description?: Maybe<Scalars['String']>;
  next_step_title?: Maybe<Scalars['String']>;
  next_step_type?: Maybe<Scalars['String']>;
  next_step_uuid: Scalars['String'];
  requireUpload?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
  /** @deprecated field was replaced by `next_step_title` for returning data in string based on locale */
  title?: Maybe<Label>;
  type?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  frequency?: Maybe<NotificationFrequencyEnum>;
  topic: NotificationTopicEnum;
  type?: Maybe<NotificationTypeEnum>;
};

export enum NotificationFrequencyEnum {
  DAILY = 'DAILY',
  EVENT = 'EVENT',
  WEEKLY = 'WEEKLY'
}

export type NotificationInput = {
  frequency?: InputMaybe<NotificationFrequencyEnum>;
  topic: NotificationTopicEnum;
  type?: InputMaybe<NotificationTypeEnum>;
};

export enum NotificationTopicEnum {
  NEW_USER_ASSIGNED = 'NEW_USER_ASSIGNED'
}

export enum NotificationTypeEnum {
  EMAIL = 'EMAIL'
}

export type OnboardingFormResponse = {
  __typename?: 'OnboardingFormResponse';
  components?: Maybe<Array<Maybe<InputField>>>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  form_id: Scalars['String'];
  form_version_id: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type Outcomes = {
  __typename?: 'Outcomes';
  disengaged?: Maybe<Scalars['Boolean']>;
  educated?: Maybe<EducationOutcome>;
  /** @deprecated This schema was wrong & is a bug */
  education_outcomes?: Maybe<Array<EducationOutcome>>;
  employed?: Maybe<EmployedOutcome>;
  /** @deprecated This schema was wrong & is a bug */
  employed_outcomes?: Maybe<Array<EmployedOutcome>>;
  other?: Maybe<Scalars['Boolean']>;
  /** @deprecated This schema was wrong & is a bug */
  referral_outcomes?: Maybe<Array<ReferralOutcome>>;
  referred?: Maybe<ReferralOutcome>;
};

export type OutcomesInput = {
  disengaged?: InputMaybe<Scalars['Boolean']>;
  educated?: InputMaybe<EducationOutcomeInput>;
  education_outcomes?: InputMaybe<Array<EducationOutcomeInput>>;
  employed?: InputMaybe<EmployedOutcomeInput>;
  employed_outcomes?: InputMaybe<Array<EmployedOutcomeInput>>;
  other?: InputMaybe<Scalars['Boolean']>;
  referral_outcomes?: InputMaybe<Array<ReferralOutcomeInput>>;
  referred?: InputMaybe<ReferralOutcomeInput>;
};

export type Pagination = {
  __typename?: 'Pagination';
  next?: Maybe<Scalars['String']>;
  prev?: Maybe<Scalars['String']>;
};

export enum PartnerType {
  EMPLOYER = 'employer',
  GROUP = 'group'
}

export type PhoneEmploymentOntario = {
  __typename?: 'PhoneEmploymentOntario';
  phone_number: Scalars['String'];
  tty: Scalars['Boolean'];
  type: Scalars['String'];
};

export type PipelineMetadataOutput = {
  __typename?: 'PipelineMetadataOutput';
  dag_run_id?: Maybe<Scalars['String']>;
  data_type?: Maybe<ResourceDataType>;
  errors_url?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  finished_at?: Maybe<Scalars['DateTime']>;
  input_url?: Maybe<Scalars['String']>;
  num_archived_records?: Maybe<Scalars['Float']>;
  num_error_records?: Maybe<Scalars['Float']>;
  num_new_records?: Maybe<Scalars['Float']>;
  num_total_records?: Maybe<Scalars['Float']>;
  num_updated_records?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['String']>;
};

export type PreSignedUrlForCustomerInput = {
  customerId: Scalars['String'];
  customerName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type PreSignedUrlForCustomerType = {
  __typename?: 'PreSignedURLForCustomerType';
  body?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PreSignedUrlInput = {
  file?: InputMaybe<Scalars['Upload']>;
  lastModifiedDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['String'];
  /** Uses LLM-parsed skills (rather than classified career skills) for updating user profile if set to true */
  useLLMParsedSkills?: InputMaybe<Scalars['Boolean']>;
  /** Uses the LLM parser if set to true. Otherwise, it will default to Sovren. */
  useLLMParser?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type PreSignedUrlType = {
  __typename?: 'PreSignedURLType';
  body?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PresignedPost = {
  __typename?: 'PresignedPost';
  body?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProcessQueue_200_Response = {
  __typename?: 'ProcessQueue_200_response';
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Float']>;
};

export type ProcessQueue_Response = ProcessQueue_200_Response | ServerError_Response;

export type ProcessResumeDto_Input = {
  lastModifiedDate: Scalars['String'];
  s3Key: Scalars['String'];
  useLLMParser?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type ProcessUserResumeQueue_200_Response = {
  __typename?: 'ProcessUserResumeQueue_200_response';
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Float']>;
};

export type ProcessUserResumeQueue_Response = ProcessUserResumeQueue_200_Response | ServerError_Response;

export type Profile = {
  __typename?: 'Profile';
  account_preferences?: Maybe<AccountPreferences>;
  anonymized_user_uuid?: Maybe<Scalars['String']>;
  career_area_interests: Array<CareerArea>;
  caseNotes: Array<CaseNote>;
  certifications?: Maybe<Array<Certification>>;
  coach_assigned?: Maybe<CoachAssigned>;
  communities?: Maybe<Array<Scalars['String']>>;
  communitiesObject?: Maybe<Array<DataSourceType>>;
  contact_history?: Maybe<Array<Contact>>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  date_of_birth?: Maybe<Scalars['String']>;
  education?: Maybe<DataSourceType>;
  education_experiences?: Maybe<Array<EducationExperience>>;
  email?: Maybe<Scalars['String']>;
  email_consent: Scalars['Boolean'];
  employer_id?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Array<DataSourceType>>;
  ethnicities?: Maybe<Array<DataSourceType>>;
  external_identities?: Maybe<ExternalIdentities>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  genderObject?: Maybe<DataSourceType>;
  goal?: Maybe<Scalars['String']>;
  help_response?: Maybe<Scalars['String']>;
  industries_of_interest?: Maybe<Array<Scalars['String']>>;
  /** the name for the corresponding tenant record, pulled from the tenant service */
  instance_name?: Maybe<Scalars['String']>;
  interest_profile?: Maybe<InterestProfile>;
  is_onboarded?: Maybe<Scalars['Boolean']>;
  job_applications?: Maybe<Array<JobApplication>>;
  last_career?: Maybe<WorkExperience>;
  last_education?: Maybe<EducationExperience>;
  last_name?: Maybe<Scalars['String']>;
  link_response?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  outcomes?: Maybe<Outcomes>;
  phone_number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  pref_lang?: Maybe<Scalars['String']>;
  previously_contacted: Scalars['Boolean'];
  profileStep?: Maybe<Scalars['Int']>;
  recruiter_outreach?: Maybe<Scalars['Boolean']>;
  services?: Maybe<Array<Service>>;
  signup_type?: Maybe<ExternalIdentityTypes>;
  skills?: Maybe<Array<UserSkill>>;
  support_needs?: Maybe<Array<Scalars['String']>>;
  support_needs_custom?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<UserTag>>;
  target_career?: Maybe<Scalars['String']>;
  target_career_label?: Maybe<Scalars['String']>;
  target_skills?: Maybe<Array<Scalars['String']>>;
  tenantRoles?: Maybe<Array<TenantRole>>;
  /** Deprecated, use tenantRoles instead */
  tenants: Array<Scalars['String']>;
  user_tenant_role?: Maybe<Scalars['String']>;
  user_uuid: Scalars['String'];
  work_experiences?: Maybe<Array<WorkExperience>>;
  year_of_birth?: Maybe<Scalars['Int']>;
};


export type ProfileCareer_Area_InterestsArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


export type ProfileEducation_ExperiencesArgs = {
  only_latest?: InputMaybe<Scalars['Boolean']>;
};


export type ProfilePreviously_ContactedArgs = {
  job_id?: InputMaybe<Scalars['String']>;
};


export type ProfileWork_ExperiencesArgs = {
  only_latest?: InputMaybe<Scalars['Boolean']>;
};

export type ProfilesQueryWrapper = {
  __typename?: 'ProfilesQueryWrapper';
  profiles: Array<Profile>;
  total_pages: Scalars['Float'];
  total_results: Scalars['Float'];
};

export type ProvisionTenantInput_Input = {
  SuperAdminEmail?: InputMaybe<Scalars['String']>;
  SuperAdminPassword?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentTenantId?: InputMaybe<Scalars['String']>;
};

export type PublishRoadmapInput = {
  locale?: InputMaybe<Scalars['String']>;
  roadmap_uuid: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  adminGetUserFeatures: Array<Feature>;
  careerAreaByUUIDS: Array<CareerArea>;
  careerAreas: Array<CareerArea>;
  /** Public endpoint to check whether a recruiter with the given email exists in partners service */
  checkExistenceByEmail?: Maybe<ExistenceCheckResponse>;
  checkProfileExistence: Scalars['Boolean'];
  /** Classify Job Title */
  classifyJobTitle?: Maybe<ClassifyJobTitle_Response>;
  /** Classify Job Titles */
  classifyJobTitles?: Maybe<Array<Maybe<ClassifyJobTitles_Response>>>;
  /** Classify Skill Title */
  classifySkillTitle?: Maybe<ClassifySkillTitle_Response>;
  /** Classify Skill Titles */
  classifySkillTitles?: Maybe<Array<Maybe<ClassifySkillTitles_Response>>>;
  downloadJsonFileContent: Scalars['String'];
  downloadNextStepDoc: DownloadNextStepDocsResponse;
  /** Extract Job Attributes */
  extractJobAttributes?: Maybe<Array<Maybe<ExtractJobAttributes_Response>>>;
  /** Get employer by id */
  findEmployer?: Maybe<Employer>;
  /** Get group by id */
  findGroup?: Maybe<Group>;
  findIntegrationById?: Maybe<Integration>;
  findIntegrationByType?: Maybe<Integration>;
  getActiveDefaultQuestions?: Maybe<Array<Maybe<DefaultQuestionsResponse>>>;
  getAllAssessments: Array<Assessment>;
  getAllCoaches: Array<Profile>;
  getAllFeatures: Array<Feature>;
  getAllNextStepsMetadata: Array<NextStepsMetadata>;
  getAllPolicyAssets: Array<AssetsDto>;
  getAllTags: Array<Tag>;
  getAllUserUuids: GetAllUserUuidsWrapper;
  getAssessmentsForReport: Array<ReportsAssessmentsData>;
  getAsyncUpdateStatuses: Array<AsyncUpdateStatus>;
  getBasicExportedReportProfiles: Array<BasicProfileData>;
  getCandidateRecommendations?: Maybe<CandidateRecommender_CandidateRecommenderOutput>;
  getCareer: CareerGraph;
  getCareerMatchByID?: Maybe<CareerRecommender_Career>;
  getCareerRecommendations?: Maybe<CareerRecommender_CareerRecommenderOutput>;
  getCareers: GetCareersOutput;
  /** A list of all child tenants of the requested tenant including the parent tenant */
  getChildTenants?: Maybe<Array<Maybe<Tenant>>>;
  getCombinedExportReports: Array<ExportReport>;
  /** Returns the specified field of the tenant configuration. */
  getConfiguration: FilterOptions;
  getCourse: Course;
  /** Retrieve recommended courses */
  getCourseRecommendations?: Maybe<CourseRecommender_CourseRecommenderOutput>;
  getCourses: CoursesResponse;
  getCurrentFormVersion?: Maybe<CurrentFormVersionResponse>;
  /** The user's current tenant */
  getCurrentUserTenant?: Maybe<Tenant>;
  /** The user's current tenant (old version: nested response under key: tenant) */
  getCurrentUserTenantNestedV1?: Maybe<CurrentUserTenantResponse>;
  getDefaultQuestions?: Maybe<Array<Maybe<DefaultQuestionsResponse>>>;
  /** Search employers */
  getEmployers?: Maybe<EmployerSearchResult>;
  getExportReport: ExportReport;
  getExportedProfilesCSV: Scalars['String'];
  getExportedReportProfiles: Array<ExportProfileData>;
  getFormAndLastSubmission?: Maybe<FormAndLastSubmissionResponse>;
  /** Fetches all the information for a given form and its most recent form version. */
  getFormDetails: Form;
  /**
   * Use the `getFormDetails` graphql query instead.
   * @deprecated deprecated
   */
  getFormVersionToFormBuilder?: Maybe<FormVersionToFormBuilderResponse>;
  /** Lists all the forms in the user's tenant. */
  getForms: Array<Form>;
  /**
   * Use the `getForms` graphql query instead.
   * @deprecated deprecated
   */
  getFormsLibrary?: Maybe<Array<Maybe<FormResponse>>>;
  /**
   * Use the `getForms` graphql query instead.
   * @deprecated deprecated
   */
  getFormsList?: Maybe<Array<Maybe<FormsListItem>>>;
  /** Get groups with optional coaches */
  getGroups?: Maybe<GroupsResponse>;
  getInvitation?: Maybe<Invitation>;
  getInvitations?: Maybe<GetInvitations_200_Response>;
  getInviteLink?: Maybe<InviteLink>;
  getInviteLinkByTenant?: Maybe<InviteLink>;
  getInviteLinks?: Maybe<GetInviteLinks_200_Response>;
  getJob: JobService_JobSearchJob;
  getJobConfiguration: JobConfigurationOutput;
  getJobRecommendations?: Maybe<JobRecommender_JobRecommenderOutput>;
  getJobRecommendationsByIds?: Maybe<JobRecommender_JobRecommenderOutput>;
  getJobs: JobService_GetJobsResponseWrapper;
  getJobsV2: JobService_GetJobsV2ResponseWrapper;
  getLearningProviders: GetLearningProvidersOutput;
  getLocations: Array<CountryDivisions>;
  getLookerSSOEmbedURL: GetLookerInsightOutput;
  getNextStepsConfigurations: NextStepsConfigurations;
  getNextStepsLibrary: LibraryQl;
  getNextStepsMetadata: NextStepsMetadata;
  /**
   * Use the `getForms` graphql query and filter by published and onboarding form.
   * @deprecated deprecated
   */
  getOnboardingForm?: Maybe<OnboardingFormResponse>;
  getPipelineMetadata: PipelineMetadataOutput;
  getPostObjectURL: GenereateUrlReponse;
  getPostObjectURLNextStepDoc: GenerateUrlResponseNextStepDoc;
  getProfileFilters: Filters;
  getProfilesFromEmails: Array<Profile>;
  getProfilesFromUuids: Array<Profile>;
  /** Get recruiter by email */
  getRecruiterByEmail?: Maybe<Recruiter>;
  /** Get recruiter by user uuid query string */
  getRecruiterByUserUuid?: Maybe<Recruiter>;
  /** Get all saved talents' user_uuids saved for a recruiter */
  getRecruiterSavedTalents?: Maybe<Array<Maybe<SavedTalent>>>;
  /** Get all saved talents' user_uuids saved by recruiter for a job_uuid */
  getRecruiterSavedTalentsByJob?: Maybe<Array<Maybe<SavedTalent>>>;
  /** Search recruiters */
  getRecruiters?: Maybe<RecruiterSearchResult>;
  getRegistrationCustomQuestionsResponses?: Maybe<Array<Maybe<RegistrationCustomQuestionsExportResponse>>>;
  /**
   * Use the `getForms` graphql query and filter by published and registration form.
   * @deprecated deprecated
   */
  getRegistrationForm?: Maybe<RegistrationForm>;
  getRegistrationSubmission?: Maybe<RegistrationSubmissionResponse>;
  getRegistrationSubmissionsForTenantUsers?: Maybe<Array<Maybe<RegistrationSubmissionResponse>>>;
  /** A list of all related tenants of the requested tenant */
  getRelatedTenants?: Maybe<Array<Maybe<Tenant>>>;
  getResource: Resource;
  getResourceRecommendations?: Maybe<ResourceRecommender_ResourceRecommenderOutput>;
  getResources: GetResourcesOutput;
  /** A single external REST API client */
  getRestApiClient?: Maybe<RestApiClient>;
  getRestClientConfigurations: Array<Configuration>;
  getRoadmap: Roadmap;
  getRoadmapStats: RoadmapStats;
  /** Get saved career entries for a user */
  getSavedCareers?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get list of career statuses */
  getSavedCareersStatus?: Maybe<Array<Maybe<SaveCareerStatusOutput>>>;
  /** Get saved learning entries for a user */
  getSavedLearnings?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get list of learning item statuses */
  getSavedLearningsStatus?: Maybe<Array<Maybe<SaveLearningStatusOutput>>>;
  /** Get saved resource entries for a user */
  getSavedResources?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get list of resource statuses */
  getSavedResourcesStatus?: Maybe<Array<Maybe<SaveResourceStatusOutput>>>;
  getTags: GetTagsResponse;
  getTenant?: Maybe<Tenant>;
  getTenantAssets: TenantAssetOutput;
  /** Return the tenant associated with a given client_id (cognito_client_id) */
  getTenantByClientId?: Maybe<ClientTenant>;
  /** Returns the entire configuration object. Use getConfiguration if you just need a specific field. */
  getTenantConfiguration: Configuration;
  getTenantConfigurationByTenantId: Configuration;
  getTenantFeatures: Array<Feature>;
  /** A list of leaf tenants of the given tenant */
  getTenantLeaves?: Maybe<Array<Maybe<Tenant>>>;
  getTenantPolicyAssets: Array<AssetsDto>;
  getTotalProfilesCount: Scalars['Int'];
  getTranslation: TranslationGraph;
  getTranslations: Array<TranslationGraph>;
  getUserCaseNotes: GetUserCaseNotesResponse;
  getUserFeatures: Array<Feature>;
  getUserNextSteps: UserNextStepsWrapper;
  getUserProfile: Profile;
  getUserProfiles: ProfilesQueryWrapper;
  getUserRole: Role;
  getUserTags: Array<TaggingUserTag>;
  getUsersSubmissionPDF?: Maybe<Scalars['JSON']>;
  getVervoeAssessments: Array<ExternalAssessment>;
  hasIntegrationOfType: Scalars['Boolean'];
  /** @deprecated Dummy query */
  hello: TestSchema;
  isTokenRevoked: Scalars['Boolean'];
  /** A list of members of a tenant */
  listMembers?: Maybe<ListMembers_200_Response>;
  listSubTenants?: Maybe<ListSubTenants_200_Response>;
  listTenants?: Maybe<ListTenants_200_Response>;
  listUserFormSubmissionsByFormId?: Maybe<Array<Maybe<Query_ListUserFormSubmissionsByFormId_Items>>>;
  savedJobsByUserUUID: Array<JobService_SavedJob>;
  /** @deprecated Use searchJobsV2 */
  searchJobs: JobService_JobSearchResponseWrapper;
  searchJobsV2: JobService_JobSearchV2ResponseWrapper;
  searchJobsV3: JobService_JobSearchV3ResponseWrapper;
  triggerReportingEtl: TriggerReportingEtlResponse;
};


export type QueryAdminGetUserFeaturesArgs = {
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type QueryCareerAreaByUuidsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  uuids: Array<Scalars['String']>;
};


export type QueryCareerAreasArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


export type QueryCheckExistenceByEmailArgs = {
  email: Scalars['String'];
};


export type QueryCheckProfileExistenceArgs = {
  searchInput: CheckProfileExistenceInput;
};


export type QueryClassifyJobTitleArgs = {
  job: Scalars['String'];
};


export type QueryClassifyJobTitlesArgs = {
  input?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryClassifySkillTitleArgs = {
  skill: Scalars['String'];
};


export type QueryClassifySkillTitlesArgs = {
  input?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDownloadJsonFileContentArgs = {
  file: Scalars['String'];
};


export type QueryDownloadNextStepDocArgs = {
  downloadNextStepDocInput: DownloadNextStepDocInput;
};


export type QueryExtractJobAttributesArgs = {
  input?: InputMaybe<ExtractorRequest_Input>;
};


export type QueryFindEmployerArgs = {
  id: Scalars['String'];
};


export type QueryFindGroupArgs = {
  id: Scalars['String'];
};


export type QueryFindIntegrationByIdArgs = {
  findIntegrationByIdInput: FindIntegrationInput;
};


export type QueryFindIntegrationByTypeArgs = {
  findIntegrationByTypeInput: FindIntegrationByTypeInput;
};


export type QueryGetActiveDefaultQuestionsArgs = {
  tenantUuid: Scalars['String'];
};


export type QueryGetAllAssessmentsArgs = {
  user_uuid: Scalars['String'];
};


export type QueryGetAllCoachesArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllNextStepsMetadataArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllPolicyAssetsArgs = {
  configurationId: Scalars['String'];
};


export type QueryGetAllUserUuidsArgs = {
  searchInput: FindProfilesSearchInput;
};


export type QueryGetAssessmentsForReportArgs = {
  userUuids: Array<Scalars['String']>;
};


export type QueryGetAsyncUpdateStatusesArgs = {
  input?: InputMaybe<FindAsyncUpdateStatusesInput>;
};


export type QueryGetBasicExportedReportProfilesArgs = {
  user_uuids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetCandidateRecommendationsArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<CandidateRecommender_CandidateRecommenderInput_Input>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetCareerArgs = {
  getCareerInput: GetCareerInput;
};


export type QueryGetCareerMatchByIdArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<CareerRecommender_RecommendedCareersRequest_Input>;
  lang?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type QueryGetCareerRecommendationsArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<CareerRecommender_RecommendedCareersRequest_Input>;
  lang?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<CareerRecommender_SortBy>;
};


export type QueryGetCareersArgs = {
  getCareersInput: GetCareersInput;
};


export type QueryGetChildTenantsArgs = {
  id: Scalars['String'];
};


export type QueryGetCombinedExportReportsArgs = {
  combinedExportReportsInput: CombinedExportReportInput;
};


export type QueryGetConfigurationArgs = {
  getConfigurationInput: GetConfigurationInput;
};


export type QueryGetCourseArgs = {
  getCourseInput: GetCourseInput;
};


export type QueryGetCourseRecommendationsArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<CourseRecommender_RecommendedCoursesRequest_Input>;
  lang?: InputMaybe<Scalars['CourseRecommender_queryInput_getCourseRecommendations_lang']>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  page?: InputMaybe<Scalars['PositiveInt']>;
};


export type QueryGetCoursesArgs = {
  getCoursesInput: GetCoursesInput;
};


export type QueryGetCurrentFormVersionArgs = {
  formUuid: Scalars['String'];
  tenantUuid: Scalars['String'];
};


export type QueryGetDefaultQuestionsArgs = {
  tenantUuid: Scalars['String'];
};


export type QueryGetEmployersArgs = {
  input?: InputMaybe<EmployerSearchRequestBody_Input>;
};


export type QueryGetExportReportArgs = {
  export_report_uuid: Scalars['String'];
};


export type QueryGetExportedProfilesCsvArgs = {
  user_uuids: Array<Scalars['String']>;
};


export type QueryGetExportedReportProfilesArgs = {
  user_uuids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetFormAndLastSubmissionArgs = {
  formUuid: Scalars['String'];
  tenantUuid: Scalars['String'];
  userUuid: Scalars['String'];
};


export type QueryGetFormDetailsArgs = {
  formUuid: Scalars['String'];
};


export type QueryGetFormVersionToFormBuilderArgs = {
  formVersionUuid: Scalars['String'];
  tenantUuid: Scalars['String'];
};


export type QueryGetFormsArgs = {
  filters?: InputMaybe<GetFormsInput>;
  tenantUuid: Scalars['String'];
};


export type QueryGetFormsLibraryArgs = {
  tenantUuid: Scalars['String'];
};


export type QueryGetFormsListArgs = {
  tenantUuid: Scalars['String'];
};


export type QueryGetGroupsArgs = {
  input?: InputMaybe<GroupsSearchRequestBody_Input>;
};


export type QueryGetInvitationArgs = {
  invitationId: Scalars['String'];
};


export type QueryGetInvitationsArgs = {
  email?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['String']>;
};


export type QueryGetInviteLinkArgs = {
  id: Scalars['String'];
};


export type QueryGetInviteLinkByTenantArgs = {
  role: Scalars['String'];
  tenantId: Scalars['String'];
};


export type QueryGetInviteLinksArgs = {
  excluded_roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  is_ascending?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<Scalars['String']>;
};


export type QueryGetJobArgs = {
  getJobInput: JobService_GetJobInput;
};


export type QueryGetJobConfigurationArgs = {
  configuration_id: Scalars['String'];
};


export type QueryGetJobRecommendationsArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<JobRecommender_RecommendedJobsRequest_Input>;
  lang?: InputMaybe<Scalars['JobRecommender_queryInput_get_job_recommendations_lang']>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  page?: InputMaybe<Scalars['PositiveInt']>;
};


export type QueryGetJobRecommendationsByIdsArgs = {
  input?: InputMaybe<JobRecommender_RecommendedJobsRequestByIds_Input>;
};


export type QueryGetJobsArgs = {
  getJobsInput: JobService_GetJobsInput;
};


export type QueryGetJobsV2Args = {
  getJobsInput: JobService_GetJobsInput;
};


export type QueryGetLearningProvidersArgs = {
  getLearningProvidersInput: GetLearningProvidersInput;
};


export type QueryGetLocationsArgs = {
  country_code?: InputMaybe<Scalars['String']>;
};


export type QueryGetLookerSsoEmbedUrlArgs = {
  lookerInput?: InputMaybe<GetLookerInsightInput>;
};


export type QueryGetNextStepsConfigurationsArgs = {
  tenant_id: Scalars['String'];
};


export type QueryGetNextStepsLibraryArgs = {
  libraryInput: LibraryInput;
};


export type QueryGetNextStepsMetadataArgs = {
  locale?: InputMaybe<Scalars['String']>;
  next_step_uuid: Scalars['String'];
};


export type QueryGetOnboardingFormArgs = {
  tenantUuid: Scalars['String'];
};


export type QueryGetPipelineMetadataArgs = {
  getPipelineMetadataInput: GetPipelineMetadata;
};


export type QueryGetPostObjectUrlArgs = {
  generateURL: GenerateUrl;
};


export type QueryGetPostObjectUrlNextStepDocArgs = {
  generateURLNextStepDocInput: GenerateUrlNextStepDocInput;
};


export type QueryGetProfileFiltersArgs = {
  locale?: InputMaybe<Scalars['String']>;
  tenant_uuids?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetProfilesFromEmailsArgs = {
  emails: Array<Scalars['String']>;
};


export type QueryGetProfilesFromUuidsArgs = {
  uuids: Array<Scalars['String']>;
};


export type QueryGetRecruiterByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetRecruiterByUserUuidArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetRecruiterSavedTalentsArgs = {
  user_uuid: Scalars['String'];
};


export type QueryGetRecruiterSavedTalentsByJobArgs = {
  job_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type QueryGetRecruitersArgs = {
  input?: InputMaybe<RecruiterSearchRequestBody_Input>;
};


export type QueryGetRegistrationCustomQuestionsResponsesArgs = {
  input?: InputMaybe<GetRegistrationCustomQuestionsResponses_Request_Input>;
  tenantUuid: Scalars['String'];
};


export type QueryGetRegistrationFormArgs = {
  tenantUuid: Scalars['String'];
};


export type QueryGetRegistrationSubmissionArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetRegistrationSubmissionsForTenantUsersArgs = {
  input?: InputMaybe<GetRegistrationSubmissionsForTenantUsers_Request_Input>;
  tenantUuid: Scalars['String'];
};


export type QueryGetRelatedTenantsArgs = {
  id: Scalars['String'];
};


export type QueryGetResourceArgs = {
  getResourceInput: GetResourceInput;
};


export type QueryGetResourceRecommendationsArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  input?: InputMaybe<ResourceRecommender_RecommendedResourcesRequest_Input>;
  lang?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  page?: InputMaybe<Scalars['PositiveInt']>;
};


export type QueryGetResourcesArgs = {
  getResourcesInput: GetResourcesInput;
};


export type QueryGetRestApiClientArgs = {
  client_id: Scalars['String'];
};


export type QueryGetRestClientConfigurationsArgs = {
  rest_client_id: Scalars['String'];
};


export type QueryGetRoadmapArgs = {
  getRoadmapInput: GetRoadmapInput;
};


export type QueryGetRoadmapStatsArgs = {
  roadmapId: Scalars['String'];
};


export type QueryGetSavedCareersArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetSavedCareersStatusArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetSavedLearningsArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetSavedLearningsStatusArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetSavedResourcesArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetSavedResourcesStatusArgs = {
  userUuid: Scalars['String'];
};


export type QueryGetTagsArgs = {
  getTagsInput: GetTagsInput;
};


export type QueryGetTenantArgs = {
  tenant_id: Scalars['String'];
};


export type QueryGetTenantAssetsArgs = {
  getTenantAssetsInput: GetTenantAssetsInput;
};


export type QueryGetTenantByClientIdArgs = {
  client_id: Scalars['String'];
};


export type QueryGetTenantConfigurationArgs = {
  configuration_id: Scalars['String'];
};


export type QueryGetTenantConfigurationByTenantIdArgs = {
  tenant_id: Scalars['String'];
};


export type QueryGetTenantFeaturesArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetTenantLeavesArgs = {
  id: Scalars['String'];
};


export type QueryGetTenantPolicyAssetsArgs = {
  getTenantPolicyAssetsInput: GetTenantPolicyAssetsInput;
};


export type QueryGetTranslationArgs = {
  getTranslationInput: GetTranslationInput;
};


export type QueryGetTranslationsArgs = {
  getTranslationsInput: GetTranslationsInput;
};


export type QueryGetUserCaseNotesArgs = {
  getUserCaseNotesInput: GetUserCaseNotesInput;
};


export type QueryGetUserNextStepsArgs = {
  user_uuid: Scalars['String'];
};


export type QueryGetUserProfileArgs = {
  getProfileInput: GetProfileInput;
};


export type QueryGetUserProfilesArgs = {
  searchInput: FindProfilesSearchInput;
};


export type QueryGetUserRoleArgs = {
  getUserRoleInput?: InputMaybe<GetUserRoleInput>;
};


export type QueryGetUserTagsArgs = {
  tag_uuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
  user_uuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGetUsersSubmissionPdfArgs = {
  formUuid: Scalars['String'];
  userUuid: Scalars['String'];
};


export type QueryGetVervoeAssessmentsArgs = {
  tenant_id: Scalars['String'];
};


export type QueryHasIntegrationOfTypeArgs = {
  hasIntegrationOfTypeInput: FindIntegrationByTypeInput;
};


export type QueryIsTokenRevokedArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type QueryListMembersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  tenant_id: Scalars['String'];
};


export type QueryListSubTenantsArgs = {
  tenant_id: Scalars['String'];
};


export type QueryListTenantsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
};


export type QueryListUserFormSubmissionsByFormIdArgs = {
  formId: Scalars['String'];
  input?: InputMaybe<ListUserFormSubmissionsByFormId_Request_Input>;
};


export type QuerySavedJobsByUserUuidArgs = {
  user_uuid?: InputMaybe<Scalars['String']>;
};


export type QuerySearchJobsArgs = {
  searchInput: JobService_JobSearchInput;
};


export type QuerySearchJobsV2Args = {
  searchInput: JobService_JobSearchInput;
};


export type QuerySearchJobsV3Args = {
  semanticSearchInput: JobService_JobSemanticSearchInput;
};


export type QueryTriggerReportingEtlArgs = {
  triggerReportingEtlInput: TriggerReportingEtlInput;
};

export type Recruiter = {
  __typename?: 'Recruiter';
  employer?: Maybe<RecruiterEmployerReference>;
  employer_uuid: Scalars['String'];
  recruiter_uuid: Scalars['String'];
  user?: Maybe<UserReference>;
};

export type RecruiterCreateRequestBody_Input = {
  email: Scalars['EmailAddress'];
  employer_uuid: Scalars['String'];
  first_name: Scalars['String'];
  /** The language displayed in the invitation email */
  invitation_email_locale?: InputMaybe<Scalars['String']>;
  invitation_role?: InputMaybe<InvitationRole>;
  last_name: Scalars['String'];
  tenant_uuid?: InputMaybe<Scalars['String']>;
};

export type RecruiterEmployerReference = {
  __typename?: 'RecruiterEmployerReference';
  employer_uuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type RecruiterInput_Input = {
  email: Scalars['EmailAddress'];
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export type RecruiterResponse = {
  __typename?: 'RecruiterResponse';
  employer?: Maybe<RecruiterEmployerReference>;
  recruiter_uuid: Scalars['String'];
  user?: Maybe<UserReference>;
};

export type RecruiterSearchRequestBody_Input = {
  employerId?: InputMaybe<Scalars['String']>;
  employerName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<RecruiterSortBy>;
  sortDirection?: InputMaybe<EmployerSortDirection>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
};

export type RecruiterSearchResult = {
  __typename?: 'RecruiterSearchResult';
  recruiters?: Maybe<Array<Maybe<RecruiterResponse>>>;
  totalPages?: Maybe<Scalars['Int']>;
  totalResults?: Maybe<Scalars['Int']>;
};

export enum RecruiterSortBy {
  CREATED_AT = 'created_at',
  EMAIL = 'email',
  EMPLOYER_NAME = 'employer_name',
  NAME = 'name',
  STATUS = 'status'
}

export type ReferralOutcome = {
  __typename?: 'ReferralOutcome';
  provider?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

export type ReferralOutcomeInput = {
  provider?: InputMaybe<Scalars['String']>;
  service_name?: InputMaybe<Scalars['String']>;
};

export type RegistrationCustomQuestionsExportResponse = {
  __typename?: 'RegistrationCustomQuestionsExportResponse';
  question: Scalars['String'];
  registrationresponses: Array<Maybe<RegistrationResponse>>;
};

export type RegistrationForm = {
  __typename?: 'RegistrationForm';
  components: Array<Maybe<RegistrationInputField>>;
  created_at?: Maybe<Scalars['DateTime']>;
  email_active: Scalars['Boolean'];
  first_name_active: Scalars['Boolean'];
  form_id: Scalars['String'];
  form_version_id: Scalars['String'];
  is_active: Scalars['Boolean'];
  last_name_active: Scalars['Boolean'];
  location_active: Scalars['Boolean'];
  phone_number_active: Scalars['Boolean'];
  tenant_uuid: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  zip_code_active: Scalars['Boolean'];
};

export type RegistrationFormInput = {
  firstNameActive?: InputMaybe<Scalars['Boolean']>;
  lastNameActive?: InputMaybe<Scalars['Boolean']>;
  locationActive?: InputMaybe<Scalars['Boolean']>;
  phoneNumberActive?: InputMaybe<Scalars['Boolean']>;
  zipCodeActive?: InputMaybe<Scalars['Boolean']>;
};

export type RegistrationFormInputFieldInput_Input = {
  defaultQuestionField?: InputMaybe<DefaultQuestionGraphInputField_Input>;
  dropdownInputFieldWithTags?: InputMaybe<DropdownInputFieldWithTagsInput_Input>;
  multiChoiceCheckboxInputFieldWithTags?: InputMaybe<MultiChoiceCheckboxInputFieldWithTagsInput_Input>;
  textInputField?: InputMaybe<RegistrationTextInputFieldInput_Input>;
};

export type RegistrationFormInput_Input = {
  components: Array<InputMaybe<RegistrationFormInputFieldInput_Input>>;
  firstNameActive?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastNameActive?: InputMaybe<Scalars['Boolean']>;
  locationActive?: InputMaybe<Scalars['Boolean']>;
  phoneNumberActive?: InputMaybe<Scalars['Boolean']>;
  tenantUuid: Scalars['String'];
  zipCodeActive?: InputMaybe<Scalars['Boolean']>;
};

export type RegistrationInputField = {
  __typename?: 'RegistrationInputField';
  defaultQuestionField?: Maybe<DefaultQuestionGraphInputField>;
  dropdownInputFieldWithTags?: Maybe<DropdownInputFieldWithTags>;
  multiChoiceCheckboxInputFieldWithTags?: Maybe<MultiChoiceCheckboxInputFieldWithTags>;
  textInputField?: Maybe<RegistrationTextInputField>;
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  response: Scalars['String'];
  userUuid: Scalars['String'];
};

export type RegistrationSubmissionResponse = {
  __typename?: 'RegistrationSubmissionResponse';
  created_by?: Maybe<Scalars['String']>;
  data: Array<Maybe<RegistrationInputField>>;
  description?: Maybe<Scalars['String']>;
  form_created_at?: Maybe<Scalars['String']>;
  form_id: Scalars['String'];
  form_updated_at?: Maybe<Scalars['String']>;
  form_version_id: Scalars['String'];
  is_assigned?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  submission_created_at?: Maybe<Scalars['DateTime']>;
  submission_id: Scalars['String'];
  submission_updated_at?: Maybe<Scalars['DateTime']>;
  tenant_uuid?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_uuid: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
};

export type RegistrationSubmissionUserResponseDefaultQuestionInput_Input = {
  questionUuid: Scalars['String'];
  response: DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags_Input;
  type: Scalars['String'];
};

export type RegistrationSubmissionUserResponseDropdownInput = {
  questionUuid: Scalars['String'];
  response: DropdownInputFieldUserResponseInput;
  type: Scalars['String'];
};

export type RegistrationSubmissionUserResponseDropdownInput_Input = {
  questionUuid: Scalars['String'];
  response: DropdownInputFieldUserResponseInput_Input;
  type: Scalars['String'];
};

export type RegistrationSubmissionUserResponseInput = {
  defaultQuestionField?: InputMaybe<RegistrationSubmissionUserResponseMultiChoiceInput>;
  dropdownUserResponseInputFieldWithTags?: InputMaybe<RegistrationSubmissionUserResponseDropdownInput>;
  multiChoiceCheckboxUserResponseInputFieldWithTags?: InputMaybe<RegistrationSubmissionUserResponseMultiChoiceInput>;
};

export type RegistrationSubmissionUserResponseInput_Input = {
  defaultQuestionField?: InputMaybe<RegistrationSubmissionUserResponseDefaultQuestionInput_Input>;
  dropdownUserResponseInputFieldWithTags?: InputMaybe<RegistrationSubmissionUserResponseDropdownInput_Input>;
  multiChoiceCheckboxUserResponseInputFieldWithTags?: InputMaybe<RegistrationSubmissionUserResponseMultiChoiceInput_Input>;
};

export type RegistrationSubmissionUserResponseMultiChoiceInput = {
  questionUuid: Scalars['String'];
  response: MultiChoiceCheckboxInputFieldUserResponseWithTagsInput;
  type: Scalars['String'];
};

export type RegistrationSubmissionUserResponseMultiChoiceInput_Input = {
  questionUuid: Scalars['String'];
  response: MultiChoiceCheckboxInputFieldUserResponseWithTagsInput_Input;
  type: Scalars['String'];
};

export type RegistrationTextInputField = {
  __typename?: 'RegistrationTextInputField';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<TextInputFieldUserResponse>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type RegistrationTextInputFieldInput_Input = {
  description?: InputMaybe<Scalars['String']>;
  hasDescription?: InputMaybe<Scalars['Boolean']>;
  isDeprecated?: InputMaybe<Scalars['Boolean']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  isStaff?: InputMaybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  questionText: Scalars['String'];
  type: Scalars['String'];
  userResponse?: InputMaybe<TextInputFieldUserResponse_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export enum RelationOperator {
  AND = 'AND',
  OR = 'OR'
}

export type RemoveUserSkillsInput = {
  userSkills: Array<Scalars['String']>;
  userUUID: Scalars['String'];
};

export type RemoveUsersFromFormBuilderInput_Input = {
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RemoveUsersFromFormBuilderResponse = {
  __typename?: 'RemoveUsersFromFormBuilderResponse';
  deletedCount: Scalars['Int'];
};

export type RemoveUsersInput_Input = {
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RemoveUsersOutput = {
  __typename?: 'RemoveUsersOutput';
  deleteCount?: Maybe<Scalars['Int']>;
};

export type ReportsAssessmentsData = {
  __typename?: 'ReportsAssessmentsData';
  assessment_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_uuid?: Maybe<Scalars['String']>;
};

export type Resource = {
  __typename?: 'Resource';
  age_range?: Maybe<AgeRange>;
  archived?: Maybe<Scalars['Boolean']>;
  career_interest?: Maybe<Array<Scalars['String']>>;
  community?: Maybe<Array<Scalars['String']>>;
  cost?: Maybe<Cost>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  credits_accepted?: Maybe<Scalars['Boolean']>;
  dag_run_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  input_url?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  lang_label?: Maybe<Scalars['String']>;
  last_updated_at?: Maybe<Scalars['DateTime']>;
  pref_label: Scalars['String'];
  provider?: Maybe<ResourceProvider>;
  source_id?: Maybe<Scalars['String']>;
  subscription_required?: Maybe<Scalars['Boolean']>;
  support_needs?: Maybe<Array<Scalars['String']>>;
  tenant_id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export enum ResourceDataType {
  COURSES = 'courses',
  RESOURCES = 'resources'
}

export type ResourceProvider = {
  __typename?: 'ResourceProvider';
  description?: Maybe<Scalars['String']>;
  pref_label: Scalars['String'];
  tenant_id?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type ResourceRecommender_CollectionLinks = {
  __typename?: 'ResourceRecommender_CollectionLinks';
  first?: Maybe<ResourceRecommender_Link>;
  last?: Maybe<ResourceRecommender_Link>;
  next?: Maybe<ResourceRecommender_Link>;
  prev?: Maybe<ResourceRecommender_Link>;
  self?: Maybe<ResourceRecommender_Link>;
};

export type ResourceRecommender_EmbeddedResources = {
  __typename?: 'ResourceRecommender_EmbeddedResources';
  resources?: Maybe<Array<Maybe<ResourceRecommender_Resource>>>;
};

export enum ResourceRecommender_HttpMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE'
}

export type ResourceRecommender_HealthResponse = {
  __typename?: 'ResourceRecommender_HealthResponse';
  status?: Maybe<ResourceRecommender_Query_Healthcheck_Status>;
};

export type ResourceRecommender_Link = {
  __typename?: 'ResourceRecommender_Link';
  href?: Maybe<Scalars['URL']>;
};

export type ResourceRecommender_RecommendedResourcesRequest_Input = {
  /**
   * The configuration ID to retrieve and set the recommender configuration
   * from. Normally, we would get this through the JWT token and the tenant
   * service, but for testing (locally and in case of issues), we can use
   * this field to override it. This shouldn't be used in normal situations.
   */
  configuration_id?: InputMaybe<Scalars['UUID']>;
  filters?: InputMaybe<ResourceRecommender_ResourceRecommenderFilters_Input>;
  parameters?: InputMaybe<ResourceRecommender_ResourceRecommenderParameters_Input>;
};

export type ResourceRecommender_Resource = {
  __typename?: 'ResourceRecommender_Resource';
  career_interest?: Maybe<Array<Maybe<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_career_interest_items']>>>;
  community?: Maybe<Array<Maybe<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_community_items']>>>;
  cost?: Maybe<ResourceRecommender_Resource_Cost>;
  country?: Maybe<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_country']>;
  credits_accepted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  label?: Maybe<Scalars['String']>;
  provider?: Maybe<ResourceRecommender_Resource_Provider>;
  subscription_required?: Maybe<Scalars['Boolean']>;
  support_needs?: Maybe<Array<Maybe<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_support_needs_items']>>>;
  url?: Maybe<Scalars['URL']>;
};

export type ResourceRecommender_ResourceRecommenderFilters_Input = {
  country?: InputMaybe<Scalars['String']>;
};

export type ResourceRecommender_ResourceRecommenderOutput = {
  __typename?: 'ResourceRecommender_ResourceRecommenderOutput';
  _embedded?: Maybe<ResourceRecommender_EmbeddedResources>;
  _links?: Maybe<ResourceRecommender_CollectionLinks>;
  count?: Maybe<Scalars['NonNegativeInt']>;
  total?: Maybe<Scalars['NonNegativeInt']>;
};

export type ResourceRecommender_ResourceRecommenderParameters_Input = {
  age?: InputMaybe<Scalars['Int']>;
  career_interest?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  communities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Scalars['String']>;
  support_needs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ResourceRecommender_Resource_Cost = {
  __typename?: 'ResourceRecommender_Resource_cost';
  currency?: Maybe<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_cost_currency']>;
  value?: Maybe<Scalars['Float']>;
};

export type ResourceRecommender_Resource_Provider = {
  __typename?: 'ResourceRecommender_Resource_provider';
  id?: Maybe<Scalars['UUID']>;
  label?: Maybe<Scalars['String']>;
};

export enum ResourceRecommender_Query_Healthcheck_Status {
  FAIL = 'fail',
  PASS = 'pass',
  WARN = 'warn'
}

export type Resources = {
  __typename?: 'Resources';
  description?: Maybe<Scalars['String']>;
  resource_id: Scalars['String'];
  resource_name: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['String']>;
};

export type ResourcesFiltersInput = {
  /** Config Status Filter type */
  status?: InputMaybe<ConfigStatusTypes>;
};

export type ResourcesSortObject = {
  /** Field to sort */
  field: Scalars['String'];
  /** Sort field by */
  value: Scalars['String'];
};

export type RestApiClient = {
  __typename?: 'RestApiClient';
  client_id?: Maybe<Scalars['String']>;
  tenant_uuid?: Maybe<Scalars['String']>;
};

export type ResumeBuilderRequest_Input = {
  certifications?: InputMaybe<Scalars['JSON']>;
  country?: InputMaybe<Scalars['JSON']>;
  education_experiences?: InputMaybe<Scalars['JSON']>;
  industries_of_interests?: InputMaybe<Scalars['JSON']>;
  location?: InputMaybe<Scalars['JSON']>;
  pref_lang?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Scalars['JSON']>;
  target_career_label?: InputMaybe<Scalars['JSON']>;
  work_experiences?: InputMaybe<Scalars['JSON']>;
};

export type ResumeBuilderResponse = {
  __typename?: 'ResumeBuilderResponse';
  results: Scalars['JSON'];
};

export type ResumeParserRequest_Input = {
  file_path: Scalars['String'];
};

export type ResumeParserResponse = {
  __typename?: 'ResumeParserResponse';
  user_attributes: Scalars['JSON'];
};

export enum ResumeUploadStatusEnum {
  COMPLETED = 'COMPLETED',
  PARSING = 'PARSING',
  UPDATING = 'UPDATING',
  UPLOADING = 'UPLOADING'
}

export type Roadmap = {
  __typename?: 'Roadmap';
  draft_next_steps: Array<NextStepsMetadata>;
  name: Scalars['String'];
  published_next_steps: Array<NextStepsMetadata>;
  roadmap_uuid: Scalars['String'];
  tenant_uuid?: Maybe<Scalars['String']>;
};

export type RoadmapStats = {
  __typename?: 'RoadmapStats';
  completedNextSteps: Scalars['Float'];
  completedRatio: Scalars['Float'];
};

export type Role = {
  __typename?: 'Role';
  label: Scalars['String'];
};

export enum SalaryTypeEnum {
  ANNUALLY = 'ANNUALLY',
  HOURLY = 'HOURLY'
}

export type SaveCareerInput_Input = {
  careerUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type SaveCareerOutput = {
  __typename?: 'SaveCareerOutput';
  careerUuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type SaveCareerStatusOutput = {
  __typename?: 'SaveCareerStatusOutput';
  careerUuid?: Maybe<Scalars['String']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type SaveLearningInput_Input = {
  learningUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type SaveLearningOutput = {
  __typename?: 'SaveLearningOutput';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  learningUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type SaveLearningStatusOutput = {
  __typename?: 'SaveLearningStatusOutput';
  isRemoved?: Maybe<Scalars['Boolean']>;
  learningUuid?: Maybe<Scalars['String']>;
};

export type SaveResourceInput_Input = {
  resourceUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type SaveResourceOutput = {
  __typename?: 'SaveResourceOutput';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  resourceUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type SaveResourceStatusOutput = {
  __typename?: 'SaveResourceStatusOutput';
  isRemoved?: Maybe<Scalars['Boolean']>;
  resourceUuid?: Maybe<Scalars['String']>;
};

export type SavedTalent = {
  __typename?: 'SavedTalent';
  created_at?: Maybe<Scalars['String']>;
  job_uuid?: Maybe<Scalars['String']>;
  talent_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type SearchQueryProcessingRequest_Input = {
  query: Scalars['String'];
};

export type SearchQueryProcessingResponse = {
  __typename?: 'SearchQueryProcessingResponse';
  alpha: Scalars['Float'];
  query_embedding: Array<Maybe<Scalars['Float']>>;
};

export type SearchUserNextStepInput = {
  locale: Scalars['String'];
  status: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type SearchedJob = {
  city?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  division_code?: InputMaybe<Scalars['String']>;
};

export type SelectedMultiChoiceCheckboxInputFieldOption = {
  __typename?: 'SelectedMultiChoiceCheckboxInputFieldOption';
  aditionalText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  textValue?: Maybe<Scalars['String']>;
};

export type SelectedMultiChoiceCheckboxInputFieldOptionInput_Input = {
  aditionalText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type SelectedMultiChoiceCheckboxInputFieldOptionWithTags = {
  __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags';
  aditionalText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  tags?: Maybe<Array<Maybe<FormBuilderTag>>>;
  textValue?: Maybe<Scalars['String']>;
};

export type SelectedMultiChoiceCheckboxInputFieldOptionWithTagsInput = {
  aditionalText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  tags?: InputMaybe<Array<FormBuilderTagInput>>;
};

export type SelectedMultiChoiceCheckboxInputFieldOptionWithTagsInput_Input = {
  aditionalText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  tags?: InputMaybe<Array<InputMaybe<FormBuilderTagInput_Input>>>;
};

export type SelectedMultiChoiceCheckboxInputFieldOptionWithTags_Input = {
  aditionalText?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  tags?: InputMaybe<Array<InputMaybe<FormBuilderTag_Input>>>;
};

export type ServerError = {
  __typename?: 'ServerError';
  error: Scalars['String'];
  message: Scalars['String'];
  statusCode: Scalars['Float'];
};

/** An internal server error occurred */
export type ServerError_Response = {
  __typename?: 'ServerError_response';
  error?: Maybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  end_date?: Maybe<Scalars['DateTime']>;
  provider?: Maybe<Scalars['String']>;
  service_uuid: Scalars['String'];
  stage?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type ServiceInput = {
  end_date?: InputMaybe<Scalars['DateTime']>;
  provider?: InputMaybe<Scalars['String']>;
  service_uuid?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Skill = {
  __typename?: 'Skill';
  id: Scalars['String'];
  label: Scalars['String'];
  /** @deprecated field was replaced by `label` for consistency */
  skill_label: Scalars['String'];
  /** @deprecated field was replaced by `skilltype` for consistency */
  skill_type?: Maybe<Scalars['String']>;
  /** @deprecated field was replaced by `id` for consistency */
  skill_uuid: Scalars['String'];
  skilltype?: Maybe<Scalars['String']>;
};

export type SkillClassifierResponse = {
  __typename?: 'SkillClassifierResponse';
  is_custom: Scalars['Boolean'];
  label: Scalars['String'];
  skill_requested: Scalars['String'];
  skill_type?: Maybe<SkillType>;
  uuid: Scalars['String'];
};

export type SkillInput = {
  id: Scalars['String'];
  is_custom?: InputMaybe<Scalars['Boolean']>;
  is_inferred: Scalars['Boolean'];
  label: Scalars['String'];
  skilltype: Scalars['String'];
};

export type SkillModification = {
  __typename?: 'SkillModification';
  modifiedSkills: Array<UserSkill>;
};

/** An enumeration. */
export enum SkillType {
  BASELINE = 'baseline',
  SPECIALIZED = 'specialized'
}

export type SkillUpdateInput = {
  id: Scalars['String'];
  proficiency: Scalars['Int'];
};

export enum SortTag {
  CREATED_AT = 'CREATED_AT',
  LABEL = 'LABEL',
  STATUS = 'STATUS'
}

export enum SourceTypes {
  ALL = 'ALL',
  CURRENT_TENANT = 'CURRENT_TENANT',
  DEFAULT = 'DEFAULT'
}

export type SubmissionResponse = {
  __typename?: 'SubmissionResponse';
  created_at?: Maybe<Scalars['DateTime']>;
  data: Array<Maybe<InputField>>;
  form_version_id?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  submission_id: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  user_uuid: Scalars['String'];
};

export type SubscribeUserCustomerLogo_200_Response = {
  __typename?: 'SubscribeUserCustomerLogo_200_response';
  ResponseMetadata?: Maybe<Query_SubscribeUserCustomerLogo_OneOf_0_ResponseMetadata>;
  SubscriptionArn?: Maybe<Scalars['String']>;
};

export type SubscribeUserCustomerLogo_Response = ServerError_Response | SubscribeUserCustomerLogo_200_Response;

export type SubscribeUserProfilePic_200_Response = {
  __typename?: 'SubscribeUserProfilePic_200_response';
  ResponseMetadata?: Maybe<Query_SubscribeUserProfilePic_OneOf_0_ResponseMetadata>;
  SubscriptionArn?: Maybe<Scalars['String']>;
};

export type SubscribeUserProfilePic_Response = ServerError_Response | SubscribeUserProfilePic_200_Response;

export type SubscribeUserResume_200_Response = {
  __typename?: 'SubscribeUserResume_200_response';
  ResponseMetadata?: Maybe<Query_SubscribeUserResume_OneOf_0_ResponseMetadata>;
  SubscriptionArn?: Maybe<Scalars['String']>;
};

export type SubscribeUserResume_Response = ServerError_Response | SubscribeUserResume_200_Response;

export enum SupportedAssetType {
  COOKIEPOLICY = 'CookiePolicy',
  PRIVACYPOLICY = 'PrivacyPolicy',
  TERMSOFSERVICE = 'TermsOfService'
}

export enum SupportedLanguages {
  EN_US = 'en_US',
  ES_ES = 'es_ES',
  FR_CA = 'fr_CA',
  PT_PT = 'pt_PT'
}

export type Tag = {
  __typename?: 'Tag';
  category: Scalars['String'];
  created_at: Scalars['DateTime'];
  created_by: Scalars['String'];
  created_by_full_name?: Maybe<Scalars['String']>;
  intercom_tag_id?: Maybe<Scalars['String']>;
  is_visible_to_recruiters?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  status: TagStatusEnum;
  tag_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  updated_at: Scalars['DateTime'];
  updated_by?: Maybe<Scalars['String']>;
  user_count?: Maybe<Scalars['Float']>;
};

export type TagFilter = {
  groups: Array<TagFilterGroup>;
  operation: GroupFilterOperation;
};

export type TagFilterGroup = {
  isNegated: Scalars['Boolean'];
  items: Array<Scalars['String']>;
  operation: GroupFilterOperation;
};

export type TagInput = {
  category?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  tag_uuid?: InputMaybe<Scalars['String']>;
};

export type TagRule = {
  __typename?: 'TagRule';
  groups: Array<TagRuleGroup>;
  operator: RelationOperator;
};

export type TagRuleGroup = {
  __typename?: 'TagRuleGroup';
  is_negated: Scalars['Boolean'];
  items: Array<Scalars['String']>;
  operator: RelationOperator;
};

export type TagRuleGroupInput = {
  is_negated: Scalars['Boolean'];
  /** Tag UUID string */
  items: Array<Scalars['String']>;
  operator: RelationOperator;
};

export type TagRuleInput = {
  groups: Array<TagRuleGroupInput>;
  operator: RelationOperator;
};

export enum TagStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export type TagUsersInput = {
  tags: Array<UserTagInput>;
  user_uuids: Array<Scalars['String']>;
};

export type TaggingUserTag = {
  __typename?: 'TaggingUserTag';
  category?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  label: Scalars['String'];
  tag_uuid: Scalars['String'];
  user_tag_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type TaggingUserTagInput = {
  category?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  tag_uuid?: InputMaybe<Scalars['String']>;
};

export type TargetCareer = {
  __typename?: 'TargetCareer';
  career_uuid?: Maybe<Scalars['String']>;
  pref_label?: Maybe<Scalars['String']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  /** Code used in sign-up links */
  code: Scalars['String'];
  /** Appears after UUID in the entry in MongoDB for this tenant in the configuration collection */
  configuration_id?: Maybe<Scalars['String']>;
  custom_domain?: Maybe<Scalars['String']>;
  /** Level in the tenant/sub-tenant hierarchy. */
  depth: Scalars['Int'];
  description_label?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** Must be true for sign-up links to work */
  legacy?: Maybe<Scalars['Boolean']>;
  /** Full LinkedIn redirect URL with placeholders */
  lil_sso_link_format?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  /** Image url for the sign up banner that is shown only on mobile screens */
  mobile_sign_up_image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** ID only of custom onboarding form */
  onboarding_form_link?: Maybe<Scalars['String']>;
  page_title?: Maybe<Scalars['String']>;
  /** ID of the parent tenant */
  parent_id?: Maybe<Scalars['String']>;
  registration_email_subject?: Maybe<Scalars['String']>;
  /** Description for sign up banner (up to 100 characters) */
  sign_up_description?: Maybe<Scalars['String']>;
  /** Image url for sign up banner */
  sign_up_image_url?: Maybe<Scalars['String']>;
  /** Link for sign up banner (up to 2048 characters) */
  sign_up_link?: Maybe<Scalars['String']>;
  text_overlay_description?: Maybe<Translation>;
  text_overlay_title?: Maybe<Translation>;
};

export type TenantAssetOutput = {
  __typename?: 'TenantAssetOutput';
  cookie_policy?: Maybe<Scalars['String']>;
  privacy_policy?: Maybe<Scalars['String']>;
  terms_of_service?: Maybe<Scalars['String']>;
};

export type TenantFeature = {
  /** Alternative to using feature_uuids */
  feature_slugs?: InputMaybe<Array<Scalars['String']>>;
  feature_uuids?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
};

export type TenantFeatureBulkInput = {
  /** Feature slugs to enable for all tenants */
  feature_slugs: Array<Scalars['String']>;
  /** tenant uuids to enable features for */
  tenant_uuids: Array<Scalars['String']>;
};

export type TenantFeatureBulkResult = {
  __typename?: 'TenantFeatureBulkResult';
  updated: Scalars['Float'];
};

export type TenantRole = {
  __typename?: 'TenantRole';
  role: Scalars['String'];
  tenant_id: Scalars['String'];
};

export type TenantRoleInput = {
  role: Scalars['String'];
  tenant_id: Scalars['String'];
};

export type TestSchema = {
  __typename?: 'TestSchema';
  /** @deprecated Dummy field */
  name: Scalars['String'];
};

export type TextInputField = {
  __typename?: 'TextInputField';
  description?: Maybe<Scalars['String']>;
  descriptionI18n?: Maybe<TranslatedField>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order: Scalars['Int'];
  placeholder: Scalars['String'];
  placeholderI18n?: Maybe<TranslatedField>;
  questionText: Scalars['String'];
  questionTextI18n?: Maybe<TranslatedField>;
  type: Scalars['String'];
  userResponse?: Maybe<InputFieldUserStringResponse>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type TextInputFieldInput_Input = {
  description?: InputMaybe<Scalars['String']>;
  descriptionI18n?: InputMaybe<TranslatedField_Input>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  order?: InputMaybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  placeholderI18n?: InputMaybe<TranslatedField_Input>;
  questionText: Scalars['String'];
  questionTextI18n?: InputMaybe<TranslatedField_Input>;
  type: Scalars['String'];
  userResponse?: InputMaybe<TextInputFieldUserResponseInput_Input>;
  uuid?: InputMaybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type TextInputFieldUserResponse = {
  __typename?: 'TextInputFieldUserResponse';
  aditionalResponse?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TextInputFieldUserResponseInput_Input = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TextInputFieldUserResponse_Input = {
  aditionalResponse?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type TranslatedField = {
  __typename?: 'TranslatedField';
  en_US: Scalars['String'];
  es_ES?: Maybe<Scalars['String']>;
  fr_CA: Scalars['String'];
  pt_PT?: Maybe<Scalars['String']>;
};

export type TranslatedField_Input = {
  en_US?: InputMaybe<Scalars['String']>;
  es_ES?: InputMaybe<Scalars['String']>;
  fr_CA?: InputMaybe<Scalars['String']>;
  pt_PT?: InputMaybe<Scalars['String']>;
};

export type Translation = {
  __typename?: 'Translation';
  en_US?: Maybe<Scalars['String']>;
  fr_CA?: Maybe<Scalars['String']>;
};

export type TranslationField = {
  __typename?: 'TranslationField';
  en_US?: Maybe<Scalars['String']>;
  es_ES?: Maybe<Scalars['String']>;
  fr_CA?: Maybe<Scalars['String']>;
  pt_PT?: Maybe<Scalars['String']>;
};

export type TranslationFieldInput = {
  en_US?: InputMaybe<Scalars['String']>;
  es_ES?: InputMaybe<Scalars['String']>;
  fr_CA?: InputMaybe<Scalars['String']>;
  pt_PT?: InputMaybe<Scalars['String']>;
};

export type TranslationGraph = {
  __typename?: 'TranslationGraph';
  sourceLanguage: Scalars['String'];
  text: Scalars['String'];
};

export type Translation_Input = {
  en_US?: InputMaybe<Scalars['String']>;
  fr_CA?: InputMaybe<Scalars['String']>;
};

export type TriggerReportingEtlInput = {
  arguments?: InputMaybe<NestedTriggerReportingEtlInput>;
};

export type TriggerReportingEtlResponse = {
  __typename?: 'TriggerReportingEtlResponse';
  body?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type UnassignCoachInput = {
  user_uuids: Array<Scalars['String']>;
};

export type UnassignGroupCoachesInput_Input = {
  /** Array of coach UUIDs to unassign */
  coach_uuids: Array<InputMaybe<Scalars['String']>>;
};

export type UnsaveCareerInput_Input = {
  careerUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type UnsaveCareerOutput = {
  __typename?: 'UnsaveCareerOutput';
  careerUuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type UnsaveLearningInput_Input = {
  learningUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type UnsaveLearningOutput = {
  __typename?: 'UnsaveLearningOutput';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  learningUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type UnsaveResourceInput_Input = {
  resourceUuid?: InputMaybe<Scalars['String']>;
  userUuid?: InputMaybe<Scalars['String']>;
};

export type UnsaveResourceOutput = {
  __typename?: 'UnsaveResourceOutput';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  resourceUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type UnsaveTalentsRequestBody_Input = {
  job_uuid?: InputMaybe<Scalars['String']>;
  talent_user_uuids: Array<InputMaybe<Scalars['String']>>;
};

export type UpdateAccountPreferencesInput = {
  marketing_consent?: InputMaybe<Scalars['Boolean']>;
  notifications?: InputMaybe<Array<NotificationInput>>;
};

export type UpdateAsyncUpdateStatusInput = {
  async_update_status_uuid: Scalars['String'];
  callback_url?: InputMaybe<Scalars['String']>;
  /**
   * The ID of the record to update. This should be the ID that is generated client-side (i.e. not the ID generated by the EO API).
   * For example, for EAPs this would be the case_management_eap_id, not gov_eap_id.
   */
  record_id?: InputMaybe<Scalars['String']>;
  /** The type of record the ID corresponds to. */
  record_type?: InputMaybe<EoAsyncUpdateStatusType>;
  /** The response from the callback URL. */
  response?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<EoAsyncUpdateStatus>;
};

export type UpdateConfigurationInput = {
  /** CAREERS | LEARNING_PROVIDERS | RESOURCES */
  category: ConfigurationCategory;
  /** Configuration id */
  configuration_id: Scalars['String'];
  /** List of ids to exclude */
  exclude_ids?: InputMaybe<Array<Scalars['String']>>;
  /** List of ids to include */
  include_ids: Array<Scalars['String']>;
};

export type UpdateConfigurationInputV2 = {
  /** overwrite the entire careers object */
  careers?: InputMaybe<FilterOptionsInput>;
  /** Configuration id */
  configuration_id: Scalars['String'];
  /** overwrite the entire course_providers object */
  course_providers?: InputMaybe<FilterOptionsInput>;
  /** Date posted range for job search in days (-1 for Anytime) */
  date_posted_range?: InputMaybe<Scalars['Float']>;
  deep_case_management?: InputMaybe<DeepCaseManagementMutationInput>;
  default_coach_visibility?: InputMaybe<DefaultCoachVisibilityEnum>;
  default_policy_language?: InputMaybe<SupportedLanguages>;
  featured_job_providers?: InputMaybe<Array<JobSource>>;
  insight_configuration?: InputMaybe<Array<InsightConfigurationInput>>;
  job_employer_restriction?: InputMaybe<Scalars['Boolean']>;
  job_language_restriction?: InputMaybe<Scalars['Boolean']>;
  job_sources?: InputMaybe<JobSourceOptionsInput>;
  /** Country Division ID string list */
  location_restrictions?: InputMaybe<Array<Scalars['String']>>;
  /** overwrite the entire resources object */
  resources?: InputMaybe<FilterOptionsInput>;
  rest_client_id?: InputMaybe<Scalars['String']>;
  rest_client_ids?: InputMaybe<Array<Scalars['String']>>;
  show_all_candidates?: InputMaybe<Scalars['Boolean']>;
  /** recruiter visibility restrict by tags. */
  show_candidate_tag_rule?: InputMaybe<TagRuleInput>;
  show_course_recs_for_jobs?: InputMaybe<Scalars['Boolean']>;
  supported_career_area_ids?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateDefaultQuestionsBodyRequest_Input = {
  questions?: InputMaybe<Array<InputMaybe<DefaultQuestionUpdateInputField_Input>>>;
  tenantUuid?: InputMaybe<Scalars['String']>;
};

export type UpdateDraftRoadmapInput = {
  draft_next_steps: Array<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  roadmap_uuid: Scalars['String'];
};

export type UpdateEmployerCaseNoteRequestBody_Input = {
  description: Scalars['String'];
  employer_uuid: Scalars['String'];
  /** an admin user uuid */
  modified_by: Scalars['String'];
};

export type UpdateEmployerInput_Input = {
  name: Scalars['String'];
};

export type UpdateExportReportInput = {
  export_report_uuid: Scalars['String'];
  failed_message?: InputMaybe<Scalars['String']>;
  failed_retry?: InputMaybe<Scalars['Float']>;
  logGroupName?: InputMaybe<Scalars['String']>;
  logStreamName?: InputMaybe<Scalars['String']>;
  s3key?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateFormVersionInput = {
  components?: InputMaybe<Array<Input_InputField>>;
  description?: InputMaybe<Scalars['String']>;
  formVersionUuid: Scalars['String'];
  /**
   * Input to specify which registration form fields are active.
   *       Only needed when forms-v3-default-questions flag is disabled.
   *       Note: This will be deprecated once the flag is removed.
   */
  registrationFormInput?: InputMaybe<RegistrationFormInput>;
  status: FormStatus;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupInput_Input = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateIntegrationInput = {
  api_key?: InputMaybe<Scalars['String']>;
  base_api_url?: InputMaybe<Scalars['String']>;
  integration_id: Scalars['ID'];
  is_active?: InputMaybe<Scalars['Boolean']>;
  tenant_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateInvitation_Input = {
  /** Whether this invitation has already been confirmed or not */
  is_valid?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateInviteLink_Input = {
  /** A list of email domains that are allowed to use this invite link */
  emailDomains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  employerId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  increaseCount?: InputMaybe<Scalars['Boolean']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateJobConfigurationInput = {
  configuration_id: Scalars['String'];
  exclude: Array<JobSource>;
  featured_job_providers: Array<JobSource>;
  include: Array<JobSource>;
};

export type UpdateLearningProviderLogoInput = {
  /** new logo url */
  logo_url: Scalars['String'];
  /** provider id to update */
  provider_id: Scalars['String'];
};

export type UpdateNextStepsMetadataInput = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  is_completable: Scalars['Boolean'];
  link?: InputMaybe<LinkInput>;
  locale: Scalars['String'];
  next_step_type?: InputMaybe<Scalars['String']>;
  next_step_uuid: Scalars['String'];
  requireUpload?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  career_area_interests?: InputMaybe<Array<Scalars['String']>>;
  caseNotes?: InputMaybe<Array<CaseNoteInput>>;
  certifications?: InputMaybe<Array<CertificationInput>>;
  communities?: InputMaybe<Array<Scalars['String']>>;
  communitiesObject?: InputMaybe<Array<DataSourceTypeInput>>;
  country?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<DataSourceTypeInput>;
  education_experiences?: InputMaybe<Array<EducationExperienceInput>>;
  email?: InputMaybe<Scalars['String']>;
  email_consent?: InputMaybe<Scalars['Boolean']>;
  employer_id?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Array<DataSourceTypeInput>>;
  ethnicities?: InputMaybe<Array<DataSourceTypeInput>>;
  external_identities?: InputMaybe<ExternalIdentitiesInput>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  genderObject?: InputMaybe<DataSourceTypeInput>;
  goal?: InputMaybe<Scalars['String']>;
  help_response?: InputMaybe<Scalars['String']>;
  industries_of_interest?: InputMaybe<Array<Scalars['String']>>;
  interest_profile?: InputMaybe<InterestProfileInput>;
  is_onboarded?: InputMaybe<Scalars['Boolean']>;
  job_applications?: InputMaybe<Array<JobApplicationInput>>;
  /** Whether we should keep skills that were entered manually or extracted from the resume. If set to false, changing work experience will replace those skills. */
  keep_non_inferred_skills?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  link_response?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  outcomes?: InputMaybe<OutcomesInput>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  pref_lang?: InputMaybe<Scalars['String']>;
  profileStep?: InputMaybe<Scalars['Int']>;
  recruiter_outreach?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<Array<ServiceInput>>;
  signup_type?: InputMaybe<ExternalIdentityTypes>;
  skills?: InputMaybe<Array<UserSkillInput>>;
  support_needs?: InputMaybe<Array<Scalars['String']>>;
  support_needs_custom?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<TagInput>>;
  target_career?: InputMaybe<Scalars['String']>;
  tenantRoles?: InputMaybe<Array<TenantRoleInput>>;
  tenants?: InputMaybe<Array<Scalars['String']>>;
  /** Uses LLM-parsed skills (rather than classified career skills) for updating user profile if set to true */
  use_llm_parsed_skills?: InputMaybe<Scalars['Boolean']>;
  user_uuid?: InputMaybe<Scalars['String']>;
  work_experiences?: InputMaybe<Array<WorkExperienceInput>>;
  year_of_birth?: InputMaybe<Scalars['Int']>;
};

export type UpdateProfileTagInput = {
  career_area_interests?: InputMaybe<Array<Scalars['String']>>;
  caseNotes?: InputMaybe<Array<CaseNoteInput>>;
  certifications?: InputMaybe<Array<CertificationInput>>;
  communities?: InputMaybe<Array<Scalars['String']>>;
  communitiesObject?: InputMaybe<Array<DataSourceTypeInput>>;
  country?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  education?: InputMaybe<DataSourceTypeInput>;
  education_experiences?: InputMaybe<Array<EducationExperienceInput>>;
  email?: InputMaybe<Scalars['String']>;
  email_consent?: InputMaybe<Scalars['Boolean']>;
  employer_id?: InputMaybe<Scalars['String']>;
  employment_status?: InputMaybe<Array<DataSourceTypeInput>>;
  ethnicities?: InputMaybe<Array<DataSourceTypeInput>>;
  external_identities?: InputMaybe<ExternalIdentitiesInput>;
  first_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  genderObject?: InputMaybe<DataSourceTypeInput>;
  goal?: InputMaybe<Scalars['String']>;
  help_response?: InputMaybe<Scalars['String']>;
  industries_of_interest?: InputMaybe<Array<Scalars['String']>>;
  interest_profile?: InputMaybe<InterestProfileInput>;
  is_onboarded?: InputMaybe<Scalars['Boolean']>;
  job_applications?: InputMaybe<Array<JobApplicationInput>>;
  /** Whether we should keep skills that were entered manually or extracted from the resume. If set to false, changing work experience will replace those skills. */
  keep_non_inferred_skills?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  link_response?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  outcomes?: InputMaybe<OutcomesInput>;
  phone_number?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  pref_lang?: InputMaybe<Scalars['String']>;
  profileStep?: InputMaybe<Scalars['Int']>;
  recruiter_outreach?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<Array<ServiceInput>>;
  signup_type?: InputMaybe<ExternalIdentityTypes>;
  skills?: InputMaybe<Array<UserSkillInput>>;
  support_needs?: InputMaybe<Array<Scalars['String']>>;
  support_needs_custom?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<TagInput>>;
  tags_to_verify?: InputMaybe<Array<TagInput>>;
  target_career?: InputMaybe<Scalars['String']>;
  tenantRoles?: InputMaybe<Array<TenantRoleInput>>;
  tenants?: InputMaybe<Array<Scalars['String']>>;
  /** Uses LLM-parsed skills (rather than classified career skills) for updating user profile if set to true */
  use_llm_parsed_skills?: InputMaybe<Scalars['Boolean']>;
  user_uuid?: InputMaybe<Scalars['String']>;
  work_experiences?: InputMaybe<Array<WorkExperienceInput>>;
  year_of_birth?: InputMaybe<Scalars['Int']>;
};

export type UpdateRecruiterRequestBody_Input = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export type UpdateRegistrationConfigurationInput = {
  banner?: InputMaybe<TranslationFieldInput>;
  bannerText?: InputMaybe<Scalars['String']>;
  configuration_id: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  marketingConsent?: InputMaybe<TranslationFieldInput>;
  overlayDescription?: InputMaybe<TranslationFieldInput>;
  overlayDescriptionText?: InputMaybe<Scalars['String']>;
  overlayTitle?: InputMaybe<TranslationFieldInput>;
  overlayTitleText?: InputMaybe<Scalars['String']>;
};

export type UpdateRoleInput = {
  email: Scalars['String'];
  label: Scalars['String'];
};

export type UpdateTenantInput_Input = {
  favicon?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  /** Image url for the sign up banner that is shown only on mobile screens */
  mobile_sign_up_image_url?: InputMaybe<Scalars['String']>;
  /** Onboarding form uuid */
  onboarding_form_link?: InputMaybe<Scalars['String']>;
  page_title?: InputMaybe<Scalars['String']>;
  registration_email_subject?: InputMaybe<Scalars['String']>;
  /** Description for sign up banner (up to 100 characters) */
  sign_up_description?: InputMaybe<Scalars['String']>;
  /** Image url for sign up banner */
  sign_up_image_url?: InputMaybe<Scalars['String']>;
  /** Link for sign up banner (up to 2048 characters) */
  sign_up_link?: InputMaybe<Scalars['String']>;
  text_overlay_description?: InputMaybe<Translation_Input>;
  text_overlay_title?: InputMaybe<Translation_Input>;
};

export type UpdateUserCaseNoteInput = {
  case_note_uuid: Scalars['ID'];
  note: Scalars['String'];
  user_uuid: Scalars['ID'];
};

export type UpdateUserSkillsInput = {
  userSkills: Array<SkillUpdateInput>;
  userUUID: Scalars['String'];
};

export type UpdateUserTenantBody_Input = {
  current_tenant: Scalars['String'];
  skip_cognito_update?: InputMaybe<Scalars['Boolean']>;
  target_tenant: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UpdateUserTenantInput = {
  destination_tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UpdateUserTenantOutput = {
  __typename?: 'UpdateUserTenantOutput';
  id: Scalars['String'];
  tenant_id: Scalars['String'];
};

export type UpsertAssessmentInput = {
  assessment_uuid?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  result?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_uuid?: InputMaybe<Scalars['String']>;
};

export type UpsertFeature = {
  default_enabled?: InputMaybe<Scalars['Boolean']>;
  feature_uuid?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpsertIrrelevantWorkExpSkillsInput = {
  irrelevant_skill_uuids: Array<Scalars['String']>;
  user_uuid?: InputMaybe<Scalars['String']>;
  work_exp_uuid: Scalars['String'];
};

export type UpsertRoleInput = {
  email: Scalars['String'];
  label: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UpsertTagInput = {
  category?: InputMaybe<Scalars['String']>;
  is_visible_to_recruiters?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TagStatusEnum>;
  tag_uuid?: InputMaybe<Scalars['String']>;
};

export type UpsertTagsInput = {
  tags?: InputMaybe<Array<TagInput>>;
};

export type UpsertTenantInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpsertUserNextStepInput = {
  integrationContext?: InputMaybe<Array<UserNextStepIntegrationInput>>;
  next_step_uuid: Scalars['String'];
  status: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UserDataInput = {
  user_email: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UserExperienceInput_Input = {
  cert_issue_date?: InputMaybe<Scalars['JSON']>;
  cert_name?: InputMaybe<Scalars['JSON']>;
  cert_provider?: InputMaybe<Scalars['JSON']>;
  edu_completion_year?: InputMaybe<Scalars['JSON']>;
  edu_degree_type?: InputMaybe<Scalars['JSON']>;
  edu_institution_name?: InputMaybe<Scalars['JSON']>;
  edu_is_current?: InputMaybe<Scalars['JSON']>;
  edu_major?: InputMaybe<Scalars['JSON']>;
  edu_starting_year?: InputMaybe<Scalars['JSON']>;
  edu_status?: InputMaybe<Scalars['JSON']>;
};

export enum UserExperienceType {
  CERTIFICATION = 'certification',
  EDUCATION = 'education',
  WORK = 'work'
}

export type UserFeature = {
  feature_uuids?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UserInfoInput = {
  email: Scalars['String'];
  id: Scalars['String'];
};

export type UserInput = {
  S3Key?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isDuplicate?: InputMaybe<Scalars['Boolean']>;
  /** The current status of the resume upload process i.e. resume is being parsed, profile is being updated, etc... */
  resumeUploadStatus?: InputMaybe<ResumeUploadStatusEnum>;
  userId: Scalars['String'];
};

export type UserNextStepIntegration = {
  __typename?: 'UserNextStepIntegration';
  external_friendly_name?: Maybe<Scalars['String']>;
  external_id: Scalars['String'];
  ffai_resource_id?: Maybe<Scalars['String']>;
  integration_type: Scalars['String'];
};

export type UserNextStepIntegrationInput = {
  external_friendly_name?: InputMaybe<Scalars['String']>;
  external_id: Scalars['String'];
  ffai_resource_id?: InputMaybe<Scalars['String']>;
  integration_type: Scalars['String'];
};

export type UserNextSteps = {
  __typename?: 'UserNextSteps';
  assigned?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  coach_uuid?: Maybe<Scalars['String']>;
  completed_at?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<Scalars['String']>>;
  integrationContext?: Maybe<Array<UserNextStepIntegration>>;
  is_completable: Scalars['Boolean'];
  link?: Maybe<Link>;
  next_step_uuid: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  requireUpload?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_uuid?: Maybe<Scalars['String']>;
};

export type UserNextStepsWrapper = {
  __typename?: 'UserNextStepsWrapper';
  next_steps: Array<UserNextSteps>;
  number_of_completed_steps?: Maybe<Scalars['Int']>;
  total_number_of_steps?: Maybe<Scalars['Int']>;
};


export type UserNextStepsWrapperNext_StepsArgs = {
  searchUserNextStepInput: SearchUserNextStepInput;
};

export type UserProfileRole = {
  __typename?: 'UserProfileRole';
  role: Scalars['String'];
  tenant_id: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UserProfileRoleInput = {
  role: Scalars['String'];
  tenant_id: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UserReference = {
  __typename?: 'UserReference';
  created_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invitation?: Maybe<Array<Maybe<Invitation>>>;
  is_registered?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  type?: Maybe<Mutation_CreateEmployer_Recruiters_Items_User_Type>;
  user_uuid?: Maybe<Scalars['String']>;
};

export type UserSkill = {
  __typename?: 'UserSkill';
  id: Scalars['String'];
  /** Indicate whether the skill label is a custom label added by the user (i.e. not from our skills ontology). */
  is_custom?: Maybe<Scalars['Boolean']>;
  is_inferred: Scalars['Boolean'];
  label: Scalars['String'];
  proficiency?: Maybe<Scalars['Int']>;
  /** @deprecated field was replaced by `label` for consistency */
  skill_label: Scalars['String'];
  /** @deprecated field was replaced by `skilltype` for consistency */
  skill_type?: Maybe<Scalars['String']>;
  /** @deprecated field was replaced by `id` for consistency */
  skill_uuid: Scalars['String'];
  skilltype?: Maybe<Scalars['String']>;
};

export type UserSkillInput = {
  id?: InputMaybe<Scalars['String']>;
  is_custom?: InputMaybe<Scalars['Boolean']>;
  is_inferred: Scalars['Boolean'];
  label?: InputMaybe<Scalars['String']>;
  proficiency?: InputMaybe<Scalars['Int']>;
  skill_label: Scalars['String'];
  skill_type?: InputMaybe<Scalars['String']>;
  skill_uuid: Scalars['String'];
  skilltype?: InputMaybe<Scalars['String']>;
};

export type UserSkillsGenerationRequest_Input = {
  experience: UserExperienceInput_Input;
  experience_type: UserExperienceType;
  lang: IsoLanguageCode;
};

export type UserSkillsGenerationResponse = {
  __typename?: 'UserSkillsGenerationResponse';
  skills: Array<Maybe<Scalars['String']>>;
};

export type UserTag = {
  __typename?: 'UserTag';
  category?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  label?: Maybe<Scalars['String']>;
  tag_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UserTagInput = {
  label?: InputMaybe<Scalars['String']>;
  tag_uuid?: InputMaybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  S3Key?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDuplicate?: Maybe<Scalars['Boolean']>;
  /** The current status of the resume upload process i.e. resume is being parsed, profile is being updated, etc... */
  resumeUploadStatus?: Maybe<ResumeUploadStatusEnum>;
  updatedAt?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  loc: Array<Maybe<Query_ExtractJobAttributes_OneOf_1_Detail_Items_Loc_Items>>;
  msg: Scalars['String'];
  type: Scalars['String'];
};

export type VervoeInviteToAssessmentRequest = {
  assessmentSlug: Scalars['String'];
  candidateEmail: Scalars['String'];
  candidateFirstName: Scalars['String'];
  redirectCandidateUrl: Scalars['String'];
};

export type VervoeInviteToAssessmentResponse = {
  __typename?: 'VervoeInviteToAssessmentResponse';
  candidateAssessmentUuid: Scalars['String'];
  candidateUrl: Scalars['String'];
};

export type WorkExperience = {
  __typename?: 'WorkExperience';
  career: Career;
  career_uuid: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  completion_date?: Maybe<Scalars['DateTime']>;
  /** Confidence level of the job title classification. 0 <= x <= 1. */
  confidence_score?: Maybe<Scalars['Float']>;
  experience_level?: Maybe<Scalars['String']>;
  experience_type?: Maybe<Scalars['String']>;
  from_resume?: Maybe<Scalars['Boolean']>;
  /** Skill ids that are irrelevant to this work experience (sourced by user selection) */
  irrelevant_skill_uuids?: Maybe<Array<Scalars['String']>>;
  is_current?: Maybe<Scalars['Boolean']>;
  job_title: Scalars['String'];
  /** Work experience skills generated by LLM resume parser */
  llm_generated_skills?: Maybe<Array<LlmGeneratedWorkExpSkills>>;
  starting_date?: Maybe<Scalars['DateTime']>;
  work_exp_uuid: Scalars['String'];
};


export type WorkExperienceCareerArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type WorkExperienceForBuildResume = {
  __typename?: 'WorkExperienceForBuildResume';
  achievements?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  date_range: Scalars['String'];
  job_title: Scalars['String'];
};

export type WorkExperienceInput = {
  career_uuid: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  completion_date?: InputMaybe<Scalars['DateTime']>;
  confidence_score?: InputMaybe<Scalars['Float']>;
  experience_level?: InputMaybe<Scalars['String']>;
  experience_type?: InputMaybe<Scalars['String']>;
  from_resume?: InputMaybe<Scalars['Boolean']>;
  /** Skill ids that are irrelevant to this work experience */
  irrelevant_skill_uuids?: InputMaybe<Array<Scalars['String']>>;
  is_current?: InputMaybe<Scalars['Boolean']>;
  job_title: Scalars['String'];
  /** Work experience skills generated by LLM resume parser */
  llm_generated_skills?: InputMaybe<Array<LlmGeneratedWorkExpSkillsInput>>;
  starting_date?: InputMaybe<Scalars['DateTime']>;
  work_exp_uuid?: InputMaybe<Scalars['String']>;
};

export type AddMember_Request_Input = {
  user?: InputMaybe<MutationInput_AddMember_Input_User_Input>;
};

export type ClassifyJobTitle_Response = JobsClassifierResponse;

export type ClassifyJobTitles_Response = JobsClassifierResponse;

export type ClassifySkillTitle_Response = SkillClassifierResponse;

export type ClassifySkillTitles_Response = SkillClassifierResponse;

export type ComprehensiveDeleteTenant_200_Response = {
  __typename?: 'comprehensiveDeleteTenant_200_response';
  deletionSuccess?: Maybe<Scalars['Boolean']>;
};

export type CreateNewDraft_Request_Input = {
  components?: InputMaybe<Array<InputMaybe<InputFieldInput_Input>>>;
  deletedQuestionUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  formId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateRegistrationSubmission_Request_Input = {
  data?: InputMaybe<RegistrationSubmissionUserResponseInput_Input>;
  form_version_id?: InputMaybe<Scalars['String']>;
};

export type CreateRestApiClient_Request_Input = {
  client_id: Scalars['String'];
  tenant_id: Scalars['String'];
};

export type DeleteCachedResumeData_200_Response = {
  __typename?: 'deleteCachedResumeData_200_response';
  S3Key?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeleteCachedResumeData_Request_Input = {
  userId?: InputMaybe<Scalars['String']>;
};

export type DeleteFormBuilderData_200_Response = {
  __typename?: 'deleteFormBuilderData_200_response';
  deleteStatus?: Maybe<Scalars['String']>;
};

export type DeleteForm_Request_Input = {
  formVersionId?: InputMaybe<Scalars['String']>;
};

export type DeleteResume_200_Response = {
  __typename?: 'deleteResume_200_response';
  S3Key?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeleteResume_Request_Input = {
  userId: Scalars['String'];
};

export type ExtractJobAttributes_Response = JobAttributes;

export type GetInvitations_200_Response = {
  __typename?: 'getInvitations_200_response';
  count: Scalars['Int'];
  items?: Maybe<Array<Maybe<Invitation>>>;
  next?: Maybe<Scalars['Int']>;
};

export type GetInviteLinks_200_Response = {
  __typename?: 'getInviteLinks_200_response';
  inviteLinks?: Maybe<Array<Maybe<InviteLink>>>;
};

export type GetRegistrationCustomQuestionsResponses_Request_Input = {
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GetRegistrationSubmissionsForTenantUsers_Request_Input = {
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Health_200_Response = {
  __typename?: 'health_200_response';
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Int']>;
};

export type LanguageMappingPair = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ListMembers_200_Response = {
  __typename?: 'listMembers_200_response';
  items?: Maybe<Array<Maybe<Member>>>;
  links?: Maybe<Pagination>;
};

export type ListSubTenants_200_Response = {
  __typename?: 'listSubTenants_200_response';
  items?: Maybe<Array<Maybe<Tenant>>>;
};

export type ListTenants_200_Response = {
  __typename?: 'listTenants_200_response';
  items?: Maybe<Array<Maybe<Tenant>>>;
  links?: Maybe<Pagination>;
};

export type ListUserFormSubmissionsByFormId_Request_Input = {
  created_before: Scalars['DateTime'];
  created_since: Scalars['DateTime'];
};

export type MutationInput_AddMember_Input_User_Input = {
  user_id?: InputMaybe<Scalars['String']>;
};

export type MutationInput_AssignGroupCoaches_Input_Groups_Items_Input = {
  /** Array of coaches to associate with the group */
  coaches?: InputMaybe<Array<InputMaybe<MutationInput_AssignGroupCoaches_Input_Groups_Items_Coaches_Items_Input>>>;
  /** UUID of the group */
  employer_uuid: Scalars['String'];
};

export type MutationInput_AssignGroupCoaches_Input_Groups_Items_Coaches_Items_Input = {
  /** UUID of the coach */
  coach_uuid: Scalars['String'];
};

export type Mutation_BulkDeleteUserData_OneOf_0_Msg = {
  __typename?: 'mutation_BulkDeleteUserData_oneOf_0_msg';
  deletedCount?: Maybe<Scalars['Float']>;
};

export type Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs = {
  __typename?: 'mutation_BulkDeleteUserData_oneOf_0_nextStepDocs';
  deletedCount?: Maybe<Scalars['Float']>;
  filesDeleted?: Maybe<Array<Maybe<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs_FilesDeleted_Items>>>;
  statusCode?: Maybe<Scalars['Float']>;
};

export type Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs_FilesDeleted_Items = {
  __typename?: 'mutation_BulkDeleteUserData_oneOf_0_nextStepDocs_filesDeleted_items';
  S3Key?: Maybe<Scalars['String']>;
};

export type Mutation_BulkDeleteUserData_OneOf_0_UserResumes = {
  __typename?: 'mutation_BulkDeleteUserData_oneOf_0_userResumes';
  deletedCount?: Maybe<Scalars['Float']>;
  filesDeleted?: Maybe<Array<Maybe<Mutation_BulkDeleteUserData_OneOf_0_UserResumes_FilesDeleted_Items>>>;
  statusCode?: Maybe<Scalars['Float']>;
};

export type Mutation_BulkDeleteUserData_OneOf_0_UserResumes_FilesDeleted_Items = {
  __typename?: 'mutation_BulkDeleteUserData_oneOf_0_userResumes_filesDeleted_items';
  S3Key?: Maybe<Scalars['String']>;
};

export type Mutation_LlmGenerateSkills_OneOf_1_Detail_Items_Loc_Items = {
  __typename?: 'mutation_LLMGenerateSkills_oneOf_1_detail_items_loc_items';
  Int?: Maybe<Scalars['Int']>;
  String?: Maybe<Scalars['String']>;
};

export enum Mutation_CreateEmployer_Recruiters_Items_User_Type {
  COACH = 'coach',
  RECRUITER = 'recruiter'
}

export type Mutation_DeleteRecruiters_Items = {
  __typename?: 'mutation_deleteRecruiters_items';
  employer_uuid?: Maybe<Scalars['String']>;
  recruiter_uuid?: Maybe<Scalars['String']>;
};

export type ProcessSearchQuery_Response = SearchQueryProcessingResponse;

export type QueryInput_GetEmployers_Input_User_Selection_Input = {
  created_at?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['Boolean']>;
  first_name?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['Boolean']>;
  user_uuid?: InputMaybe<Scalars['String']>;
};

export enum QueryInput_GetGroups_Input_SortBy {
  CREATED_AT = 'created_at',
  NAME = 'name'
}

export enum QueryInput_GetGroups_Input_SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export type Query_SubscribeUserCustomerLogo_OneOf_0_ResponseMetadata = {
  __typename?: 'query_SubscribeUserCustomerLogo_oneOf_0_ResponseMetadata';
  RequestId?: Maybe<Scalars['String']>;
};

export type Query_SubscribeUserProfilePic_OneOf_0_ResponseMetadata = {
  __typename?: 'query_SubscribeUserProfilePic_oneOf_0_ResponseMetadata';
  RequestId?: Maybe<Scalars['String']>;
};

export type Query_SubscribeUserResume_OneOf_0_ResponseMetadata = {
  __typename?: 'query_SubscribeUserResume_oneOf_0_ResponseMetadata';
  RequestId?: Maybe<Scalars['String']>;
};

export type Query_ClassifyJobTitle_OneOf_1_Detail_Items_Loc_Items = {
  __typename?: 'query_classifyJobTitle_oneOf_1_detail_items_loc_items';
  Int?: Maybe<Scalars['Int']>;
  String?: Maybe<Scalars['String']>;
};

export type Query_ExtractJobAttributes_OneOf_1_Detail_Items_Loc_Items = {
  __typename?: 'query_extractJobAttributes_oneOf_1_detail_items_loc_items';
  Int?: Maybe<Scalars['Int']>;
  String?: Maybe<Scalars['String']>;
};

export type Query_ListUserFormSubmissionsByFormId_Items = {
  __typename?: 'query_listUserFormSubmissionsByFormId_items';
  created_at?: Maybe<Scalars['DateTime']>;
  data: Array<Maybe<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>>;
  form_version_id?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  submission_id: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  user_uuid: Scalars['String'];
};

export type Query_ListUserFormSubmissionsByFormId_Items_Data_Items = {
  __typename?: 'query_listUserFormSubmissionsByFormId_items_data_items';
  defaultOption: DropdownInputFieldOption;
  description?: Maybe<Scalars['String']>;
  hasDescription: Scalars['Boolean'];
  isDeprecated: Scalars['Boolean'];
  isOptional: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  options: Array<Maybe<DropdownInputFieldOption>>;
  order?: Maybe<Scalars['Int']>;
  placeholder: Scalars['String'];
  questionText: Scalars['String'];
  type: Scalars['String'];
  userResponse?: Maybe<DropdownInputFieldUserResponse>;
  uuid?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

export type RequestSubmissionsExportation_Request_Input = {
  formUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SaveFormVersionDraft_Request_Input = {
  components?: InputMaybe<Array<InputMaybe<InputFieldInput_Input>>>;
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SaveSubmission_Request_Input = {
  data?: InputMaybe<Array<InputMaybe<InputFieldInput_Input>>>;
  form_version_id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  /** Needed in the body in addition to being in the route */
  user_uuid?: InputMaybe<Scalars['String']>;
};

export type UpdateOnboardingFormActivationStatus_Request_Input = {
  activate?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSubmission_Request_Input = {
  data?: InputMaybe<Array<InputMaybe<InputFieldInput_Input>>>;
  form_version_id?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  /** Needed in the body in addition to being in the route */
  user_uuid?: InputMaybe<Scalars['String']>;
};

export type AllContactInfoFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'contacted_by_name' | 'employer_name' | 'job_title' | 'created_at' | 'contact_uuid' | 'employer_uuid' | 'contacted_by_uuid' | 'job_id'>
);

export type ContactIdsFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'contact_uuid' | 'employer_uuid' | 'contacted_by_uuid' | 'job_id'>
);

export type TranslationFieldsFragment = (
  { __typename?: 'TranslatedField' }
  & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
);

export type TranslationCurrencyInputFieldsFragment = (
  { __typename?: 'CurrencyInputField' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationDateInputFieldsFragment = (
  { __typename?: 'DateInputField' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationDropdownInputFieldsFragment = (
  { __typename?: 'DropdownInputField' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationDropdownInputFieldsWithTagsFragment = (
  { __typename?: 'DropdownInputFieldWithTags' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationFixedTextFieldsFragment = (
  { __typename?: 'FixedText' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationBaseInputFieldsFragment = (
  { __typename?: 'FixedText' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationMultiChoiceCheckboxInputFieldsFragment = (
  { __typename?: 'MultiChoiceCheckboxInputField' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationMultiChoiceCheckboxInputFieldsWithTagsFragment = (
  { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationTextInputFieldsFragment = (
  { __typename?: 'TextInputField' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type TranslationDefaultQuestionGraphInputFieldsFragment = (
  { __typename?: 'DefaultQuestionGraphInputField' }
  & { questionTextI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, descriptionI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )>, placeholderI18n?: Maybe<(
    { __typename?: 'TranslatedField' }
    & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
  )> }
);

export type ExportProfileAuthorizeMutationVariables = Exact<{
  exportProfileAuthorizeInput: ExportProfileAuthorizeInput;
}>;


export type ExportProfileAuthorizeMutation = (
  { __typename?: 'Mutation' }
  & { exportProfileAuthorize: (
    { __typename?: 'ExportProfileAuthorizeResponseData' }
    & Pick<ExportProfileAuthorizeResponseData, 'body' | 'statusCode' | 'export_report_uuid'>
  ) }
);

export type AddContactMutationVariables = Exact<{
  user_uuid?: InputMaybe<Scalars['String']>;
  contacted_by_uuid: Scalars['String'];
  employer_uuid?: InputMaybe<Scalars['String']>;
  job_id: Scalars['String'];
}>;


export type AddContactMutation = (
  { __typename?: 'Mutation' }
  & { addContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'contacted_by_name' | 'employer_name' | 'job_title' | 'created_at' | 'contact_uuid' | 'employer_uuid' | 'contacted_by_uuid' | 'job_id'>
  ) }
);

export type CreateEmployerCaseNoteMutationVariables = Exact<{
  employer_uuid: Scalars['String'];
  description: Scalars['String'];
  created_by: Scalars['String'];
}>;


export type CreateEmployerCaseNoteMutation = (
  { __typename?: 'Mutation' }
  & { createEmployerCaseNote?: Maybe<(
    { __typename?: 'EmployerCaseNote' }
    & Pick<EmployerCaseNote, 'id' | 'employer_uuid' | 'modified_by' | 'created_at' | 'description' | 'updated_at'>
  )> }
);

export type AddTenantAssetMutationVariables = Exact<{
  addTenantAssetInput: AddTenantAssetInput;
}>;


export type AddTenantAssetMutation = (
  { __typename?: 'Mutation' }
  & { addTenantAsset: (
    { __typename?: 'AssetsDto' }
    & Pick<AssetsDto, 'url' | 'asset_id' | 'asset_type' | 'language' | 'tenant_id' | 'updated_at' | 'uploaded_by'>
  ) }
);

export type AddToNextStepsLibraryMutationVariables = Exact<{
  addNextStepToLibraryInput: LibraryInput;
}>;


export type AddToNextStepsLibraryMutation = (
  { __typename?: 'Mutation' }
  & { addNextStepToLibrary: (
    { __typename?: 'LibraryQL' }
    & Pick<LibraryQl, 'library_uuid' | 'tenant_uuid' | 'type' | 'updated_at' | 'updated_by'>
    & { next_steps?: Maybe<Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )>> }
  ) }
);

export type AddUserCaseNoteMutationVariables = Exact<{
  createUserCaseNoteInput: CreateUserCaseNoteInput;
}>;


export type AddUserCaseNoteMutation = (
  { __typename?: 'Mutation' }
  & { addUserCaseNote: (
    { __typename: 'CaseNote' }
    & Pick<CaseNote, 'id' | 'created_at' | 'note'>
    & { created_by: (
      { __typename: 'CaseNoteEditor' }
      & Pick<CaseNoteEditor, 'user_uuid' | 'user_name'>
    ), modified_history: Array<(
      { __typename?: 'CaseNoteModify' }
      & Pick<CaseNoteModify, 'modified_at'>
      & { modified_by: (
        { __typename: 'CaseNoteEditor' }
        & Pick<CaseNoteEditor, 'user_uuid' | 'user_name'>
      ) }
    )> }
  ) }
);

export type AddUserSkillsMutationVariables = Exact<{
  addUserSkillsInput: AddUserSkillsInput;
}>;


export type AddUserSkillsMutation = (
  { __typename?: 'Mutation' }
  & { addUserSkills: (
    { __typename?: 'SkillModification' }
    & { modifiedSkills: Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'id' | 'is_inferred' | 'label' | 'proficiency' | 'skilltype' | 'is_custom'>
    )> }
  ) }
);

export type ArchiveAllJobsMutationVariables = Exact<{ [key: string]: never; }>;


export type ArchiveAllJobsMutation = (
  { __typename?: 'Mutation' }
  & { archiveAllJobs: (
    { __typename?: 'JobService_ArchiveAllManualJobsResponse' }
    & Pick<JobService_ArchiveAllManualJobsResponse, 'numArchived'>
  ) }
);

export type ArchiveFormMutationVariables = Exact<{
  formUuid: Scalars['String'];
  formVersionUuid: Scalars['String'];
}>;


export type ArchiveFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveForm'>
);

export type ArchiveManualJobMutationVariables = Exact<{
  archiveManualJobInput: JobService_ArchiveManualJobInput;
}>;


export type ArchiveManualJobMutation = (
  { __typename?: 'Mutation' }
  & { archiveManualJob: (
    { __typename?: 'JobService_ManualJobResponse' }
    & Pick<JobService_ManualJobResponse, 'message'>
    & { job?: Maybe<(
      { __typename?: 'JobService_JobSearchJob' }
      & Pick<JobService_JobSearchJob, 'active' | 'archived' | 'job_id' | 'job_title' | 'created_at' | 'expiry_date'>
    )>, jobDto?: Maybe<(
      { __typename?: 'JobService_JobDto' }
      & Pick<JobService_JobDto, 'active' | 'archived' | 'job_id' | 'job_title' | 'created_at' | 'expiry_date'>
    )> }
  ) }
);

export type AssignCoachMutationVariables = Exact<{
  assignCoachInput: AssignCoachInput;
}>;


export type AssignCoachMutation = (
  { __typename?: 'Mutation' }
  & { assignCoach: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
  )> }
);

export type AssignGroupCoachesMutationVariables = Exact<{
  input: AssignGroupCoachesInput_Input;
}>;


export type AssignGroupCoachesMutation = (
  { __typename?: 'Mutation' }
  & { assignGroupCoaches?: Maybe<(
    { __typename?: 'GroupCoachesResult' }
    & { successful: Array<Maybe<(
      { __typename?: 'GroupCoachSuccess' }
      & Pick<GroupCoachSuccess, 'coach_uuid'>
    )>>, failed: Array<Maybe<(
      { __typename?: 'GroupCoachFailure' }
      & Pick<GroupCoachFailure, 'coach_uuid' | 'error'>
    )>> }
  )> }
);

export type BuildUserResumeMutationVariables = Exact<{ [key: string]: never; }>;


export type BuildUserResumeMutation = (
  { __typename?: 'Mutation' }
  & { buildUserResume: (
    { __typename?: 'BuildUserResumeResponse' }
    & Pick<BuildUserResumeResponse, 'base64File'>
  ) }
);

export type BulkUpdateProfilesMutationVariables = Exact<{
  input: BulkProfileUpdateInput;
}>;


export type BulkUpdateProfilesMutation = (
  { __typename?: 'Mutation' }
  & { bulkUpdateProfiles: (
    { __typename?: 'BulkProfileUpdateResult' }
    & Pick<BulkProfileUpdateResult, 'successful'>
    & { failed: Array<(
      { __typename?: 'BulkUpdateFailure' }
      & Pick<BulkUpdateFailure, 'user_uuid' | 'error'>
    )> }
  ) }
);

export type ClearCandidateCacheMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearCandidateCacheMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'clearCandidateCache'>
);

export type ConfirmNextStepDocMutationVariables = Exact<{
  confirmNextStepDocInput: ConfirmNextStepDocInput;
}>;


export type ConfirmNextStepDocMutation = (
  { __typename?: 'Mutation' }
  & { confirmNextStepDoc: (
    { __typename?: 'ConfirmNextStepDocResponse' }
    & Pick<ConfirmNextStepDocResponse, 'statusCode'>
    & { finalDocs?: Maybe<Array<(
      { __typename?: 'NextStepDoc' }
      & Pick<NextStepDoc, 's3Key' | 'name'>
    )>> }
  ) }
);

export type CreateCustomerObjectUrlMutationVariables = Exact<{
  input: PreSignedUrlForCustomerInput;
}>;


export type CreateCustomerObjectUrlMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerObjectUrl: (
    { __typename?: 'PreSignedURLForCustomerType' }
    & Pick<PreSignedUrlForCustomerType, 'body' | 'url' | 'fields'>
  ) }
);

export type CreateDefaultQuestionsMutationVariables = Exact<{
  tenant_uuid: Scalars['String'];
  input: CreateNewDefaultQuestionsBodyRequest_Input;
}>;


export type CreateDefaultQuestionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDefaultQuestions'>
);

export type CreateEmployerMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
  recruiters?: InputMaybe<Array<InputMaybe<RecruiterInput_Input>> | InputMaybe<RecruiterInput_Input>>;
  invitation_email_locale?: InputMaybe<Scalars['String']>;
}>;


export type CreateEmployerMutation = (
  { __typename?: 'Mutation' }
  & { createEmployer?: Maybe<(
    { __typename?: 'Employer' }
    & Pick<Employer, 'created_at' | 'employer_uuid' | 'name'>
    & { recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'Recruiter' }
      & Pick<Recruiter, 'recruiter_uuid'>
      & { user?: Maybe<(
        { __typename?: 'UserReference' }
        & Pick<UserReference, 'created_at' | 'email' | 'first_name' | 'last_name' | 'user_uuid' | 'is_registered'>
        & { invitation?: Maybe<Array<Maybe<(
          { __typename?: 'Invitation' }
          & Pick<Invitation, 'invitation_link' | 'invitation_uuid'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type CreateFormMutationVariables = Exact<{
  input: CreateFormInput;
}>;


export type CreateFormMutation = (
  { __typename?: 'Mutation' }
  & { createForm: (
    { __typename?: 'Form' }
    & Pick<Form, 'form_id' | 'form_version_id'>
  ) }
);

export type CreateFormVersionMutationVariables = Exact<{
  input: CreateFormVersionInput;
}>;


export type CreateFormVersionMutation = (
  { __typename?: 'Mutation' }
  & { createFormVersion: (
    { __typename?: 'Form' }
    & Pick<Form, 'form_id' | 'form_version_id'>
  ) }
);

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput_Input;
}>;


export type CreateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'employer_uuid' | 'name' | 'created_at'>
    & { coaches?: Maybe<Array<Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'coach_uuid' | 'group_uuid'>
      & { user?: Maybe<(
        { __typename?: 'UserReference' }
        & Pick<UserReference, 'id' | 'first_name' | 'last_name' | 'email'>
      )> }
    )>>> }
  )> }
);

export type CreateInvitationMutationVariables = Exact<{
  email: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  invitation_email_locale?: InputMaybe<Scalars['String']>;
}>;


export type CreateInvitationMutation = (
  { __typename?: 'Mutation' }
  & { createInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'invitation_uuid' | 'email'>
  )> }
);

export type CreateInviteLinkMutationVariables = Exact<{
  newInviteLinkInput?: InputMaybe<NewInviteLink_Input>;
}>;


export type CreateInviteLinkMutation = (
  { __typename?: 'Mutation' }
  & { createInviteLink?: Maybe<(
    { __typename?: 'InviteLink' }
    & Pick<InviteLink, 'code' | 'created_at' | 'id' | 'is_active' | 'name' | 'role' | 'tag_id' | 'tenant_id' | 'type' | 'updated_at' | 'user_count' | 'email_domains' | 'employer_id'>
  )> }
);

export type CreateManualJobMutationVariables = Exact<{
  createManualPostJobInput: JobService_CreateManualJobInput;
}>;


export type CreateManualJobMutation = (
  { __typename?: 'Mutation' }
  & { createManualPostJob: (
    { __typename?: 'JobService_ManualJobResponse' }
    & Pick<JobService_ManualJobResponse, 'message'>
    & { job?: Maybe<(
      { __typename: 'JobService_JobSearchJob' }
      & Pick<JobService_JobSearchJob, 'job_id' | 'job_title' | 'location' | 'country' | 'provider' | 'company' | 'created_at' | 'active' | 'archived' | 'description' | 'application_url' | 'application_email' | 'job_type' | 'employer_id' | 'posting_source' | 'department' | 'seniority' | 'expiry_date' | 'education_level' | 'work_experience_level'>
      & { salary_range?: Maybe<(
        { __typename?: 'JobService_JobSalaryRange' }
        & Pick<JobService_JobSalaryRange, 'max' | 'min' | 'currency' | 'pay_period'>
      )>, skills?: Maybe<Array<(
        { __typename: 'JobService_JobSearchSkill' }
        & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'skilltype' | 'pref_label'>
      )>>, additional_skills?: Maybe<Array<(
        { __typename: 'JobService_AdditionalSkill' }
        & Pick<JobService_AdditionalSkill, 'skill_uuid' | 'custom_label' | 'skilltype' | 'classified_skill_existed'>
      )>>, career?: Maybe<(
        { __typename: 'JobService_JobSearchCareer' }
        & Pick<JobService_JobSearchCareer, 'career_uuid'>
        & { baseline_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>> }
      )> }
    )>, jobDto?: Maybe<(
      { __typename: 'JobService_JobDto' }
      & Pick<JobService_JobDto, 'job_id' | 'job_title' | 'location' | 'country' | 'provider' | 'company' | 'created_at' | 'active' | 'archived' | 'description' | 'application_url' | 'application_email' | 'job_type' | 'employer_id' | 'posting_source' | 'department' | 'seniority' | 'expiry_date' | 'education_level' | 'work_experience_level' | 'classified_job_type' | 'classified_seniority_type' | 'remote_type'>
      & { salary_range?: Maybe<(
        { __typename?: 'JobService_JobSalaryRange' }
        & Pick<JobService_JobSalaryRange, 'max' | 'min' | 'currency' | 'pay_period'>
      )>, skills_objects?: Maybe<Array<(
        { __typename: 'JobService_JobSearchSkill' }
        & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'skilltype' | 'pref_label'>
      )>>, additional_skills?: Maybe<Array<(
        { __typename: 'JobService_AdditionalSkill' }
        & Pick<JobService_AdditionalSkill, 'skill_uuid' | 'custom_label' | 'skilltype' | 'classified_skill_existed'>
      )>>, career_object?: Maybe<(
        { __typename: 'JobService_JobSearchCareer' }
        & Pick<JobService_JobSearchCareer, 'career_uuid'>
        & { baseline_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>> }
      )> }
    )> }
  ) }
);

export type CreateMergeLinkTokenMutationVariables = Exact<{
  create_link: JobService_CreateLink;
}>;


export type CreateMergeLinkTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMergeLinkToken'>
);

export type CreateNextStepsMetadataMutationVariables = Exact<{
  createNextStepsMetadataInput: CreateNextStepsMetadataInput;
}>;


export type CreateNextStepsMetadataMutation = (
  { __typename?: 'Mutation' }
  & { createNextStepsMetadata: (
    { __typename?: 'NextStepsMetadata' }
    & Pick<NextStepsMetadata, 'category' | 'goals' | 'is_completable' | 'next_step_title' | 'next_step_description' | 'next_step_uuid' | 'tenant_id' | 'type'>
    & { link?: Maybe<(
      { __typename?: 'Link' }
      & Pick<Link, 'target' | 'type' | 'uri'>
    )> }
  ) }
);

export type CreatePostObjectUrlMutationVariables = Exact<{
  input: PreSignedUrlInput;
}>;


export type CreatePostObjectUrlMutation = (
  { __typename?: 'Mutation' }
  & { createPostObjectURL: (
    { __typename?: 'PreSignedURLType' }
    & Pick<PreSignedUrlType, 'url' | 'fields'>
  ) }
);

export type CreateRecruiterMutationVariables = Exact<{
  createRecruiterInput: RecruiterCreateRequestBody_Input;
}>;


export type CreateRecruiterMutation = (
  { __typename?: 'Mutation' }
  & { createRecruiter?: Maybe<(
    { __typename?: 'Recruiter' }
    & Pick<Recruiter, 'employer_uuid' | 'recruiter_uuid'>
    & { user?: Maybe<(
      { __typename?: 'UserReference' }
      & Pick<UserReference, 'email' | 'created_at' | 'first_name' | 'last_name' | 'user_uuid'>
      & { invitation?: Maybe<Array<Maybe<(
        { __typename?: 'Invitation' }
        & Pick<Invitation, 'invitation_uuid' | 'invitation_link'>
      )>>> }
    )> }
  )> }
);

export type CreateRegistrationFormMutationVariables = Exact<{
  tenantUuid: Scalars['String'];
  createRegistrationForm?: InputMaybe<RegistrationFormInput_Input>;
}>;


export type CreateRegistrationFormMutation = (
  { __typename?: 'Mutation' }
  & { createRegistrationForm?: Maybe<(
    { __typename?: 'RegistrationForm' }
    & Pick<RegistrationForm, 'tenant_uuid'>
  )> }
);

export type CreateReportExportMutationVariables = Exact<{
  createReportExport: CreateReportExportInput;
}>;


export type CreateReportExportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createReportExport'>
);

export type CreateUserTagsMutationVariables = Exact<{
  createUserTagsInput: CreateUserTagsInput;
}>;


export type CreateUserTagsMutation = (
  { __typename?: 'Mutation' }
  & { createUserTags: Array<(
    { __typename?: 'TaggingUserTag' }
    & Pick<TaggingUserTag, 'category' | 'created_at' | 'label' | 'tag_uuid' | 'user_tag_uuid' | 'user_uuid'>
  )> }
);

export type CreateUserTagsForTenantMutationVariables = Exact<{
  tags?: InputMaybe<Array<InputMaybe<TaggingUserTagInput>> | InputMaybe<TaggingUserTagInput>>;
}>;


export type CreateUserTagsForTenantMutation = (
  { __typename?: 'Mutation' }
  & { createUserTagsForTenant: Array<(
    { __typename?: 'TaggingUserTag' }
    & Pick<TaggingUserTag, 'user_tag_uuid'>
  )> }
);

export type DelNextStepDocMutationVariables = Exact<{
  delNextStepDocInput: DelNextStepDocInput;
}>;


export type DelNextStepDocMutation = (
  { __typename?: 'Mutation' }
  & { delNextStepDoc: (
    { __typename?: 'DelNextStepDocResponse' }
    & Pick<DelNextStepDocResponse, 'statusCode' | 'msg' | 'docDeleted'>
  ) }
);

export type DeleteContactsFromUserMutationVariables = Exact<{
  contactUuids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  userUuid: Scalars['String'];
}>;


export type DeleteContactsFromUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteContactsFromUser: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'contact_uuid' | 'employer_uuid' | 'contacted_by_uuid' | 'job_id'>
  )> }
);

export type DeleteEmployerCaseNoteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteEmployerCaseNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmployerCaseNote?: Maybe<(
    { __typename?: 'EmployerCaseNote' }
    & Pick<EmployerCaseNote, 'id'>
  )> }
);

export type DeleteEmployerMutationVariables = Exact<{
  deleteEmployerInput: DeleteEmployersRequestBody_Input;
}>;


export type DeleteEmployerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEmployer'>
);

export type DeletePolicyAssetsMutationVariables = Exact<{
  deleteTenantPolicyAssetInput: DeleteTenantPolicyAssetInput;
}>;


export type DeletePolicyAssetsMutation = (
  { __typename?: 'Mutation' }
  & { deletePolicyAssets: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'default_policy_language'>
    & { assets?: Maybe<(
      { __typename?: 'AssetMetadata' }
      & { privacy_policy: (
        { __typename?: 'TranslationField' }
        & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
      ), terms_of_service: (
        { __typename?: 'TranslationField' }
        & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
      ) }
    )> }
  ) }
);

export type DeleteRecruitersMutationVariables = Exact<{
  deleteRecruitersBodyInput: DeleteRecruitersRequestBody_Input;
}>;


export type DeleteRecruitersMutation = (
  { __typename?: 'Mutation' }
  & { deleteRecruiters?: Maybe<Array<Maybe<(
    { __typename?: 'mutation_deleteRecruiters_items' }
    & Pick<Mutation_DeleteRecruiters_Items, 'employer_uuid' | 'recruiter_uuid'>
  )>>> }
);

export type DeleteSavedJobMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteSavedJobMutation = (
  { __typename?: 'Mutation' }
  & { deleteSavedJob: (
    { __typename?: 'JobService_SavedJob' }
    & Pick<JobService_SavedJob, 'company' | 'created_at' | 'description' | 'location' | 'notes' | 'title' | 'uuid'>
    & { stage_history: Array<(
      { __typename?: 'JobService_Stage' }
      & Pick<JobService_Stage, 'created_at' | 'stage'>
    )>, job: (
      { __typename?: 'JobService_Job' }
      & Pick<JobService_Job, 'uuid'>
    ) }
  ) }
);

export type DeleteTenantFeaturesMutationVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type DeleteTenantFeaturesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTenantFeatures'>
);

export type DeleteUserCaseNoteMutationVariables = Exact<{
  deleteUserCaseNoteInput: DeleteUserCaseNoteInput;
}>;


export type DeleteUserCaseNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserCaseNote'>
);

export type DeleteUserNextStepMutationVariables = Exact<{
  deleteUserNextStep: DeleteUserStepInput;
}>;


export type DeleteUserNextStepMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserNextStep: (
    { __typename?: 'DeleteUserNextStepsOutput' }
    & Pick<DeleteUserNextStepsOutput, 'deletedCount'>
  ) }
);

export type DeleteUsersMutationVariables = Exact<{
  deleteUsersInput: DeleteUsersInput;
}>;


export type DeleteUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUsers'>
);

export type DisableTenantFeaturesMutationVariables = Exact<{
  tenantFeatures: TenantFeature;
}>;


export type DisableTenantFeaturesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disableTenantFeatures'>
);

export type EasyApplyToAtsJobMutationVariables = Exact<{
  easyApplyToATSJobInput: JobService_EasyApplyToAtsJobInput;
}>;


export type EasyApplyToAtsJobMutation = (
  { __typename?: 'Mutation' }
  & { easyApplyToATSJob: (
    { __typename?: 'JobService_EasyApplyToATSJobResponse' }
    & Pick<JobService_EasyApplyToAtsJobResponse, 'job_id' | 'applied_at' | 'application_id'>
  ) }
);

export type EnableTenantFeaturesMutationVariables = Exact<{
  tenantFeatures: TenantFeature;
}>;


export type EnableTenantFeaturesMutation = (
  { __typename?: 'Mutation' }
  & { enableTenantFeatures: Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'feature_uuid' | 'label' | 'slug'>
  )> }
);

export type ExchangeMergeAccountTokenMutationVariables = Exact<{
  exchange: JobService_ExchangeAccountToken;
}>;


export type ExchangeMergeAccountTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exchangeMergeAccountToken'>
);

export type ExportProfileDownloadCsvFileMutationVariables = Exact<{
  export_report_uuid: Scalars['String'];
}>;


export type ExportProfileDownloadCsvFileMutation = (
  { __typename?: 'Mutation' }
  & { exportProfileDownloadCsvFile: (
    { __typename?: 'ExportProfileDownloadCsvFileResponseData' }
    & Pick<ExportProfileDownloadCsvFileResponseData, 'statusCode' | 'url'>
  ) }
);

export type GetSkillsFromCareerMutationVariables = Exact<{
  career_id: Scalars['String'];
  pref_lang?: InputMaybe<Scalars['String']>;
}>;


export type GetSkillsFromCareerMutation = (
  { __typename?: 'Mutation' }
  & { getSkillsFromCareer: (
    { __typename?: 'JobService_SkillsResponse' }
    & Pick<JobService_SkillsResponse, 'message'>
    & { skills?: Maybe<Array<(
      { __typename: 'JobService_JobSearchSkill' }
      & Pick<JobService_JobSearchSkill, 'description' | 'pref_label' | 'skilltype' | 'skill_uuid'>
    )>> }
  ) }
);

export type InviteToVervoeAssessmentMutationVariables = Exact<{
  inviteToVervoeAssessmentInput: VervoeInviteToAssessmentRequest;
}>;


export type InviteToVervoeAssessmentMutation = (
  { __typename?: 'Mutation' }
  & { inviteToVervoeAssessment: (
    { __typename?: 'VervoeInviteToAssessmentResponse' }
    & Pick<VervoeInviteToAssessmentResponse, 'candidateUrl' | 'candidateAssessmentUuid'>
  ) }
);

export type LeadSignUpMutationVariables = Exact<{
  profile_info: LeadSignUpInput;
}>;


export type LeadSignUpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leadSignUp'>
);

export type MoveUsersMutationVariables = Exact<{
  moveUsersInput: MoveUsersInput;
}>;


export type MoveUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'moveUsers'>
);

export type PublishRoadmapMutationVariables = Exact<{
  publishRoadmapInput: PublishRoadmapInput;
}>;


export type PublishRoadmapMutation = (
  { __typename?: 'Mutation' }
  & { publishRoadmap: (
    { __typename?: 'Roadmap' }
    & Pick<Roadmap, 'roadmap_uuid' | 'tenant_uuid'>
    & { draft_next_steps: Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )>, published_next_steps: Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )> }
  ) }
);

export type RemoveUserSkillsMutationVariables = Exact<{
  removeUserSkillsInput: RemoveUserSkillsInput;
}>;


export type RemoveUserSkillsMutation = (
  { __typename?: 'Mutation' }
  & { removeUserSkills: (
    { __typename?: 'SkillModification' }
    & { modifiedSkills: Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'id' | 'label' | 'proficiency' | 'skilltype' | 'is_inferred'>
    )> }
  ) }
);

export type ResetUserNextStepsMutationVariables = Exact<{
  user_uuid: Scalars['String'];
  searchUserNextStepInput: SearchUserNextStepInput;
}>;


export type ResetUserNextStepsMutation = (
  { __typename?: 'Mutation' }
  & { resetProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
  ), resetUserNextSteps: (
    { __typename?: 'UserNextStepsWrapper' }
    & Pick<UserNextStepsWrapper, 'number_of_completed_steps' | 'total_number_of_steps'>
    & { next_steps: Array<(
      { __typename?: 'UserNextSteps' }
      & Pick<UserNextSteps, 'title' | 'status' | 'next_step_uuid'>
    )> }
  ) }
);

export type SaveCareerMutationVariables = Exact<{
  saveCareerInput: SaveCareerInput_Input;
}>;


export type SaveCareerMutation = (
  { __typename?: 'Mutation' }
  & { saveCareer?: Maybe<(
    { __typename?: 'SaveCareerOutput' }
    & Pick<SaveCareerOutput, 'careerUuid'>
  )> }
);

export type SaveFormSubmissionMutationVariables = Exact<{
  userUuid: Scalars['String'];
  saveSubmissionInput: SaveSubmission_Request_Input;
}>;


export type SaveFormSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { saveSubmission?: Maybe<(
    { __typename?: 'SubmissionResponse' }
    & Pick<SubmissionResponse, 'submission_id'>
  )> }
);

export type SaveJobMutationVariables = Exact<{
  save: JobService_SaveJobInput;
}>;


export type SaveJobMutation = (
  { __typename?: 'Mutation' }
  & { saveJob: (
    { __typename?: 'JobService_SavedJob' }
    & Pick<JobService_SavedJob, 'company' | 'created_at' | 'description' | 'location' | 'notes' | 'title' | 'uuid'>
    & { stage_history: Array<(
      { __typename?: 'JobService_Stage' }
      & Pick<JobService_Stage, 'created_at' | 'stage'>
    )>, job: (
      { __typename?: 'JobService_Job' }
      & Pick<JobService_Job, 'uuid' | 'source' | 'source_type' | 'ingested_at'>
    ) }
  ) }
);

export type SaveLearningMutationVariables = Exact<{
  saveLearningInput: SaveLearningInput_Input;
}>;


export type SaveLearningMutation = (
  { __typename?: 'Mutation' }
  & { saveLearning?: Maybe<(
    { __typename?: 'SaveLearningOutput' }
    & Pick<SaveLearningOutput, 'learningUuid'>
  )> }
);

export type SaveResourceMutationVariables = Exact<{
  saveResourceInput: SaveResourceInput_Input;
}>;


export type SaveResourceMutation = (
  { __typename?: 'Mutation' }
  & { saveResource?: Maybe<(
    { __typename?: 'SaveResourceOutput' }
    & Pick<SaveResourceOutput, 'resourceUuid'>
  )> }
);

export type SaveTalentsMutationVariables = Exact<{
  user_uuid: Scalars['String'];
  savedTalentsInput: CreateSavedTalentsRequestBody_Input;
}>;


export type SaveTalentsMutation = (
  { __typename?: 'Mutation' }
  & { saveTalents?: Maybe<Array<Maybe<(
    { __typename: 'SavedTalent' }
    & Pick<SavedTalent, 'user_uuid' | 'talent_uuid' | 'created_at' | 'job_uuid'>
  )>>> }
);

export type ToggleTagVisibilityMutationVariables = Exact<{
  tag_uuid: Scalars['String'];
}>;


export type ToggleTagVisibilityMutation = (
  { __typename?: 'Mutation' }
  & { toggleTagVisibility: (
    { __typename?: 'Tag' }
    & Pick<Tag, 'tag_uuid' | 'is_visible_to_recruiters' | 'label'>
  ) }
);

export type UnassignCoachMutationVariables = Exact<{
  unassignCoachInput: UnassignCoachInput;
}>;


export type UnassignCoachMutation = (
  { __typename?: 'Mutation' }
  & { unassignCoach: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
  )> }
);

export type UnassignGroupCoachesMutationVariables = Exact<{
  input: UnassignGroupCoachesInput_Input;
}>;


export type UnassignGroupCoachesMutation = (
  { __typename?: 'Mutation' }
  & { unassignGroupCoaches?: Maybe<(
    { __typename?: 'GroupCoachesResult' }
    & { successful: Array<Maybe<(
      { __typename?: 'GroupCoachSuccess' }
      & Pick<GroupCoachSuccess, 'coach_uuid'>
    )>>, failed: Array<Maybe<(
      { __typename?: 'GroupCoachFailure' }
      & Pick<GroupCoachFailure, 'coach_uuid' | 'error'>
    )>> }
  )> }
);

export type UnsaveCareerMutationVariables = Exact<{
  unsaveCareerInput: UnsaveCareerInput_Input;
}>;


export type UnsaveCareerMutation = (
  { __typename?: 'Mutation' }
  & { unsaveCareer?: Maybe<(
    { __typename?: 'UnsaveCareerOutput' }
    & Pick<UnsaveCareerOutput, 'careerUuid'>
  )> }
);

export type UnsaveLearningMutationVariables = Exact<{
  unsaveLearningInput: UnsaveLearningInput_Input;
}>;


export type UnsaveLearningMutation = (
  { __typename?: 'Mutation' }
  & { unsaveLearning?: Maybe<(
    { __typename?: 'UnsaveLearningOutput' }
    & Pick<UnsaveLearningOutput, 'learningUuid'>
  )> }
);

export type UnsaveResourceMutationVariables = Exact<{
  unsaveResourceInput: UnsaveResourceInput_Input;
}>;


export type UnsaveResourceMutation = (
  { __typename?: 'Mutation' }
  & { unsaveResource?: Maybe<(
    { __typename?: 'UnsaveResourceOutput' }
    & Pick<UnsaveResourceOutput, 'resourceUuid'>
  )> }
);

export type UnsaveTalentsMutationVariables = Exact<{
  user_uuid: Scalars['String'];
  unsavedTalentsInput: UnsaveTalentsRequestBody_Input;
}>;


export type UnsaveTalentsMutation = (
  { __typename?: 'Mutation' }
  & { unsaveTalents?: Maybe<Array<Maybe<(
    { __typename?: 'SavedTalent' }
    & Pick<SavedTalent, 'user_uuid' | 'talent_uuid'>
  )>>> }
);

export type UpdateAccountPreferencesMutationVariables = Exact<{
  updateAccountPreferencesInput: UpdateAccountPreferencesInput;
}>;


export type UpdateAccountPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { updateAccountPreferences: (
    { __typename?: 'AccountPreferencesProfile' }
    & Pick<AccountPreferencesProfile, 'user_uuid'>
    & { account_preferences?: Maybe<(
      { __typename?: 'AccountPreferences' }
      & { notifications?: Maybe<Array<(
        { __typename?: 'Notification' }
        & Pick<Notification, 'topic' | 'frequency' | 'type'>
      )>>, marketing_consent_history?: Maybe<Array<(
        { __typename?: 'MarketingConsentHistory' }
        & Pick<MarketingConsentHistory, 'is_consented' | 'created_at'>
      )>> }
    )> }
  ) }
);

export type UpdateConfigurationMutationVariables = Exact<{
  updateConfigurationInput: UpdateConfigurationInput;
}>;


export type UpdateConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateConfiguration'>
);

export type UpdateTenantConfigurationMutationVariables = Exact<{
  input: UpdateConfigurationInputV2;
}>;


export type UpdateTenantConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateConfigurationV2: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'tenant_id' | 'configuration_id' | 'link' | 'show_all_candidates' | 'featured_job_providers' | 'job_language_restriction' | 'job_employer_restriction' | 'date_posted_range' | 'default_policy_language' | 'default_coach_visibility' | 'show_course_recs_for_jobs'>
    & { job_sources?: Maybe<(
      { __typename?: 'JobSourceConfiguration' }
      & Pick<JobSourceConfiguration, 'include' | 'exclude'>
    )>, location_restrictions?: Maybe<Array<(
      { __typename?: 'CountryDivisions' }
      & Pick<CountryDivisions, 'country_code' | 'division_code' | 'country_label'>
    )>>, insight_configuration?: Maybe<Array<(
      { __typename?: 'InsightConfiguration' }
      & Pick<InsightConfiguration, 'label' | 'dashboard_id'>
    )>>, show_candidate_tag_rule?: Maybe<(
      { __typename?: 'TagRule' }
      & Pick<TagRule, 'operator'>
      & { groups: Array<(
        { __typename?: 'TagRuleGroup' }
        & Pick<TagRuleGroup, 'operator' | 'items' | 'is_negated'>
      )> }
    )> }
  ) }
);

export type UpdateDefaultQuestionsMutationVariables = Exact<{
  tenant_uuid: Scalars['String'];
  input?: InputMaybe<UpdateDefaultQuestionsBodyRequest_Input>;
}>;


export type UpdateDefaultQuestionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDefaultQuestions'>
);

export type UpdateDraftRoadmapMutationVariables = Exact<{
  updateDraftRoadmapInput: UpdateDraftRoadmapInput;
}>;


export type UpdateDraftRoadmapMutation = (
  { __typename?: 'Mutation' }
  & { updateDraftRoadmap: (
    { __typename?: 'Roadmap' }
    & Pick<Roadmap, 'roadmap_uuid' | 'tenant_uuid'>
    & { draft_next_steps: Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )>, published_next_steps: Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )> }
  ) }
);

export type UpdateEducationExperienceMutationVariables = Exact<{
  updateProfileInput: UpdateProfileInput;
}>;


export type UpdateEducationExperienceMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
    & { education_experiences?: Maybe<Array<(
      { __typename?: 'EducationExperience' }
      & Pick<EducationExperience, 'major' | 'institution_name' | 'degree_type' | 'status' | 'starting_year' | 'completion_year' | 'is_current'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedSkill' }
        & Pick<LlmGeneratedSkill, 'classified_skill_uuid' | 'llm_generated_label'>
      )>> }
    )>> }
  ) }
);

export type UpdateEmployerMutationVariables = Exact<{
  id: Scalars['String'];
  updateEmployerInput: UpdateEmployerInput_Input;
}>;


export type UpdateEmployerMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployer?: Maybe<(
    { __typename?: 'Employer' }
    & Pick<Employer, 'created_at' | 'employer_uuid' | 'name'>
    & { recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'Recruiter' }
      & Pick<Recruiter, 'employer_uuid' | 'recruiter_uuid'>
    )>>> }
  )> }
);

export type UpdateEmployerCaseNoteMutationVariables = Exact<{
  id: Scalars['String'];
  description: Scalars['String'];
  modified_by: Scalars['String'];
  employer_uuid: Scalars['String'];
}>;


export type UpdateEmployerCaseNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployerCaseNote?: Maybe<(
    { __typename?: 'EmployerCaseNote' }
    & Pick<EmployerCaseNote, 'id' | 'employer_uuid' | 'description' | 'created_at'>
  )> }
);

export type UpdateFormSubmissionMutationVariables = Exact<{
  userUuid: Scalars['String'];
  submission_id: Scalars['String'];
  saveSubmissionInput: UpdateSubmission_Request_Input;
}>;


export type UpdateFormSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { updateSubmission?: Maybe<(
    { __typename?: 'SubmissionResponse' }
    & Pick<SubmissionResponse, 'submission_id'>
  )> }
);

export type UpdateFormVersionMutationVariables = Exact<{
  input: UpdateFormVersionInput;
}>;


export type UpdateFormVersionMutation = (
  { __typename?: 'Mutation' }
  & { updateFormVersion: (
    { __typename?: 'Form' }
    & Pick<Form, 'form_version_id' | 'form_id'>
  ) }
);

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['String'];
  updateGroupInput: UpdateGroupInput_Input;
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'created_at' | 'employer_uuid' | 'name'>
    & { coaches?: Maybe<Array<Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'coach_uuid' | 'group_uuid'>
      & { user?: Maybe<(
        { __typename?: 'UserReference' }
        & Pick<UserReference, 'id' | 'first_name' | 'last_name' | 'email'>
      )> }
    )>>> }
  )> }
);

export type UpdateInviteLinkMutationVariables = Exact<{
  updateInviteLinkInput?: InputMaybe<UpdateInviteLink_Input>;
}>;


export type UpdateInviteLinkMutation = (
  { __typename?: 'Mutation' }
  & { updateInviteLink?: Maybe<(
    { __typename?: 'InviteLink' }
    & Pick<InviteLink, 'created_at' | 'id' | 'is_active' | 'name' | 'role' | 'tag_id' | 'tenant_id' | 'type' | 'updated_at' | 'user_count' | 'email_domains' | 'employer_id'>
  )> }
);

export type UpdateJobConfigurationMutationVariables = Exact<{
  input: UpdateJobConfigurationInput;
}>;


export type UpdateJobConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateJobConfiguration: (
    { __typename?: 'JobConfigurationOutput' }
    & Pick<JobConfigurationOutput, 'featured_job_providers'>
    & { job_sources?: Maybe<(
      { __typename?: 'JobSourceConfiguration' }
      & Pick<JobSourceConfiguration, 'exclude' | 'include'>
    )> }
  ) }
);

export type UpdateJobSearchHistoryMutationVariables = Exact<{
  user_uuid: Scalars['String'];
  searched_job: SearchedJob;
}>;


export type UpdateJobSearchHistoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateJobSearchHistory'>
);

export type UpdateLearningProviderLogoMutationVariables = Exact<{
  updateLearningProviderLogoInput: UpdateLearningProviderLogoInput;
}>;


export type UpdateLearningProviderLogoMutation = (
  { __typename?: 'Mutation' }
  & { updateLearningProviderLogo: (
    { __typename?: 'LearningProviders' }
    & Pick<LearningProviders, 'logo_url'>
  ) }
);

export type UpdateManualJobMutationVariables = Exact<{
  updateManualPostJobInput: JobService_UpdateManualJobInput;
}>;


export type UpdateManualJobMutation = (
  { __typename?: 'Mutation' }
  & { updateManualJob: (
    { __typename?: 'JobService_ManualJobResponse' }
    & Pick<JobService_ManualJobResponse, 'message'>
    & { job?: Maybe<(
      { __typename: 'JobService_JobSearchJob' }
      & Pick<JobService_JobSearchJob, 'job_id' | 'job_title' | 'location' | 'country' | 'provider' | 'company' | 'created_at' | 'active' | 'archived' | 'description' | 'application_url' | 'application_email' | 'job_type' | 'employer_id' | 'posting_source' | 'department' | 'seniority' | 'expiry_date' | 'education_level' | 'work_experience_level' | 'classified_job_type' | 'classified_seniority_type' | 'remote_type'>
      & { salary_range?: Maybe<(
        { __typename?: 'JobService_JobSalaryRange' }
        & Pick<JobService_JobSalaryRange, 'max' | 'min' | 'currency' | 'pay_period'>
      )>, skills?: Maybe<Array<(
        { __typename: 'JobService_JobSearchSkill' }
        & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'skilltype' | 'pref_label'>
      )>>, additional_skills?: Maybe<Array<(
        { __typename: 'JobService_AdditionalSkill' }
        & Pick<JobService_AdditionalSkill, 'skill_uuid' | 'custom_label' | 'skilltype' | 'classified_skill_existed'>
      )>>, career?: Maybe<(
        { __typename: 'JobService_JobSearchCareer' }
        & Pick<JobService_JobSearchCareer, 'career_uuid'>
        & { baseline_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>> }
      )> }
    )>, jobDto?: Maybe<(
      { __typename: 'JobService_JobDto' }
      & Pick<JobService_JobDto, 'job_id' | 'job_title' | 'location' | 'country' | 'provider' | 'company' | 'created_at' | 'active' | 'archived' | 'description' | 'application_url' | 'application_email' | 'job_type' | 'employer_id' | 'posting_source' | 'department' | 'seniority' | 'expiry_date' | 'education_level' | 'work_experience_level'>
      & { salary_range?: Maybe<(
        { __typename?: 'JobService_JobSalaryRange' }
        & Pick<JobService_JobSalaryRange, 'max' | 'min' | 'currency' | 'pay_period'>
      )>, skills_objects?: Maybe<Array<(
        { __typename: 'JobService_JobSearchSkill' }
        & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'skilltype' | 'pref_label'>
      )>>, additional_skills?: Maybe<Array<(
        { __typename: 'JobService_AdditionalSkill' }
        & Pick<JobService_AdditionalSkill, 'skill_uuid' | 'custom_label' | 'skilltype' | 'classified_skill_existed'>
      )>>, career_object?: Maybe<(
        { __typename: 'JobService_JobSearchCareer' }
        & Pick<JobService_JobSearchCareer, 'career_uuid'>
        & { baseline_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename: 'JobService_JobSearchSkill' }
          & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
        )>> }
      )> }
    )> }
  ) }
);

export type UpdateNextStepsMetadataMutationVariables = Exact<{
  updateNextStepsMetadataInput: UpdateNextStepsMetadataInput;
}>;


export type UpdateNextStepsMetadataMutation = (
  { __typename?: 'Mutation' }
  & { updateNextStepsMetadata: (
    { __typename?: 'NextStepsMetadata' }
    & Pick<NextStepsMetadata, 'category' | 'goals' | 'is_completable' | 'next_step_title' | 'next_step_description' | 'next_step_uuid' | 'tenant_id' | 'type'>
    & { link?: Maybe<(
      { __typename?: 'Link' }
      & Pick<Link, 'target' | 'type' | 'uri'>
    )> }
  ) }
);

export type UpdateNextStepsLibraryMutationVariables = Exact<{
  updateLibraryInput: LibraryInput;
}>;


export type UpdateNextStepsLibraryMutation = (
  { __typename?: 'Mutation' }
  & { updateLibrary: (
    { __typename?: 'LibraryQL' }
    & Pick<LibraryQl, 'library_uuid' | 'tenant_uuid' | 'type' | 'updated_at' | 'updated_by'>
    & { next_steps?: Maybe<Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )>> }
  ) }
);

export type UpdateOnboardingFormActivationStatusMutationVariables = Exact<{
  tenant_id: Scalars['String'];
  input?: InputMaybe<UpdateOnboardingFormActivationStatus_Request_Input>;
}>;


export type UpdateOnboardingFormActivationStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOnboardingFormActivationStatus'>
);

export type UpdateRecruiterMutationVariables = Exact<{
  id: Scalars['String'];
  updateRecruiterBody: UpdateRecruiterRequestBody_Input;
}>;


export type UpdateRecruiterMutation = (
  { __typename?: 'Mutation' }
  & { updateRecruiter?: Maybe<(
    { __typename?: 'Recruiter' }
    & Pick<Recruiter, 'employer_uuid' | 'recruiter_uuid'>
    & { user?: Maybe<(
      { __typename?: 'UserReference' }
      & Pick<UserReference, 'created_at' | 'email' | 'first_name' | 'last_name' | 'user_uuid'>
    )> }
  )> }
);

export type UpdateRegistrationConfigurationMutationVariables = Exact<{
  updateRegistrationConfigurationInput: UpdateRegistrationConfigurationInput;
}>;


export type UpdateRegistrationConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { updateRegistrationConfiguration: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'tenant_id' | 'link'>
    & { overlayTitle?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, overlayDescription?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, banner?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, marketingConsent?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )> }
  ) }
);

export type UpdateRegistrationFormMutationVariables = Exact<{
  tenantUuid: Scalars['String'];
  updateRegistrationForm?: InputMaybe<RegistrationFormInput_Input>;
}>;


export type UpdateRegistrationFormMutation = (
  { __typename?: 'Mutation' }
  & { updateRegistrationForm?: Maybe<(
    { __typename?: 'RegistrationForm' }
    & Pick<RegistrationForm, 'created_at' | 'email_active' | 'first_name_active' | 'form_id' | 'is_active' | 'last_name_active' | 'location_active' | 'phone_number_active' | 'tenant_uuid' | 'updated_at' | 'zip_code_active'>
    & { components: Array<Maybe<(
      { __typename?: 'RegistrationInputField' }
      & { dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'type' | 'uuid' | 'version'>
        & { options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>> }
        )>> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'type' | 'uuid' | 'version'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'label'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>> }
        )>> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )> }
      )> }
    )>> }
  )> }
);

export type UpdateSkillsMutationVariables = Exact<{
  updateProfileInput: UpdateProfileInput;
}>;


export type UpdateSkillsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
    & { skills?: Maybe<Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'is_inferred' | 'id' | 'label' | 'skilltype' | 'skill_type' | 'skill_label' | 'skill_uuid' | 'is_custom'>
    )>> }
  ) }
);

export type UpdateTargetCareerMutationVariables = Exact<{
  updateProfileInput: UpdateProfileInput;
}>;


export type UpdateTargetCareerMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid' | 'target_career' | 'target_career_label' | 'target_skills'>
  ) }
);

export type UpdateTenantMutationVariables = Exact<{
  tenant_id: Scalars['String'];
  body?: InputMaybe<UpdateTenantInput_Input>;
}>;


export type UpdateTenantMutation = (
  { __typename?: 'Mutation' }
  & { updateTenant?: Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'favicon' | 'id' | 'logo_url' | 'name' | 'page_title' | 'registration_email_subject'>
  )> }
);

export type UpdateTenantWorkConfigurationMutationVariables = Exact<{
  configuration_id: Scalars['String'];
  location_restrictions: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
}>;


export type UpdateTenantWorkConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTenantWorkConfiguration'>
);

export type UpdateUserCaseNoteMutationVariables = Exact<{
  updateUserCaseNoteInput: UpdateUserCaseNoteInput;
}>;


export type UpdateUserCaseNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateUserCaseNote: (
    { __typename: 'CaseNote' }
    & Pick<CaseNote, 'id' | 'created_at' | 'note'>
    & { created_by: (
      { __typename: 'CaseNoteEditor' }
      & Pick<CaseNoteEditor, 'user_uuid' | 'user_name'>
    ), modified_history: Array<(
      { __typename?: 'CaseNoteModify' }
      & Pick<CaseNoteModify, 'modified_at'>
      & { modified_by: (
        { __typename: 'CaseNoteEditor' }
        & Pick<CaseNoteEditor, 'user_uuid' | 'user_name'>
      ) }
    )> }
  ) }
);

export type UpdateUserProfileMutationVariables = Exact<{
  updateProfileInput: UpdateProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid' | 'communities' | 'first_name' | 'postal_code' | 'gender' | 'date_of_birth' | 'goal' | 'industries_of_interest' | 'is_onboarded' | 'last_name' | 'support_needs' | 'support_needs_custom' | 'target_career' | 'target_career_label' | 'target_skills' | 'year_of_birth' | 'location' | 'country' | 'phone_number' | 'pref_lang' | 'profileStep' | 'recruiter_outreach'>
    & { genderObject?: Maybe<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource'>
    )>, ethnicities?: Maybe<Array<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource'>
    )>>, education?: Maybe<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource'>
    )>, communitiesObject?: Maybe<Array<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource'>
    )>>, employment_status?: Maybe<Array<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource'>
    )>>, interest_profile?: Maybe<(
      { __typename?: 'InterestProfile' }
      & Pick<InterestProfile, 'artistic' | 'enterprising' | 'conventional' | 'social' | 'investigative' | 'realistic'>
    )>, skills?: Maybe<Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'is_inferred' | 'id' | 'label' | 'skilltype' | 'skill_type' | 'skill_label' | 'skill_uuid' | 'is_custom'>
    )>>, work_experiences?: Maybe<Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'career_uuid' | 'company' | 'completion_date' | 'starting_date' | 'experience_level' | 'experience_type' | 'is_current' | 'job_title' | 'work_exp_uuid' | 'irrelevant_skill_uuids'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedWorkExpSkills' }
        & Pick<LlmGeneratedWorkExpSkills, 'classified_skill_uuid' | 'llm_generated_label'>
      )>>, career: (
        { __typename?: 'Career' }
        & Pick<Career, 'id'>
        & { baseline_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>> }
      ) }
    )>>, education_experiences?: Maybe<Array<(
      { __typename?: 'EducationExperience' }
      & Pick<EducationExperience, 'major' | 'institution_name' | 'degree_type' | 'status' | 'starting_year' | 'completion_year' | 'is_current'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedSkill' }
        & Pick<LlmGeneratedSkill, 'classified_skill_uuid' | 'llm_generated_label'>
      )>> }
    )>>, certifications?: Maybe<Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'certification_uuid' | 'name' | 'provider' | 'issue_date'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedSkill' }
        & Pick<LlmGeneratedSkill, 'classified_skill_uuid' | 'llm_generated_label'>
      )>> }
    )>>, career_area_interests: Array<(
      { __typename?: 'CareerArea' }
      & Pick<CareerArea, 'id' | 'label'>
    )>, services?: Maybe<Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service_uuid' | 'title' | 'provider' | 'stage' | 'start_date' | 'end_date'>
    )>>, tags?: Maybe<Array<(
      { __typename?: 'UserTag' }
      & Pick<UserTag, 'tag_uuid' | 'label' | 'category'>
    )>>, outcomes?: Maybe<(
      { __typename?: 'Outcomes' }
      & Pick<Outcomes, 'disengaged' | 'other'>
      & { educated?: Maybe<(
        { __typename?: 'EducationOutcome' }
        & Pick<EducationOutcome, 'degree_type' | 'institution_name' | 'program_name'>
      )>, employed?: Maybe<(
        { __typename?: 'EmployedOutcome' }
        & Pick<EmployedOutcome, 'company' | 'job_title' | 'salary' | 'salary_type' | 'salary_currency_code' | 'employment_type'>
      )>, referred?: Maybe<(
        { __typename?: 'ReferralOutcome' }
        & Pick<ReferralOutcome, 'provider' | 'service_name'>
      )> }
    )>, account_preferences?: Maybe<(
      { __typename?: 'AccountPreferences' }
      & { notifications?: Maybe<Array<(
        { __typename?: 'Notification' }
        & Pick<Notification, 'topic' | 'frequency' | 'type'>
      )>>, marketing_consent_history?: Maybe<Array<(
        { __typename?: 'MarketingConsentHistory' }
        & Pick<MarketingConsentHistory, 'is_consented' | 'created_at'>
      )>> }
    )> }
  ) }
);

export type UpdateUserSkillsMutationVariables = Exact<{
  updateUserSkillsInput: UpdateUserSkillsInput;
}>;


export type UpdateUserSkillsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSkills: (
    { __typename?: 'SkillModification' }
    & { modifiedSkills: Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'id' | 'is_inferred' | 'label' | 'proficiency' | 'skilltype'>
    )> }
  ) }
);

export type UpdateUserTenantMutationVariables = Exact<{
  tenant_id: Scalars['String'];
  user_id: Scalars['String'];
  body: UpdateUserTenantBody_Input;
}>;


export type UpdateUserTenantMutation = (
  { __typename?: 'Mutation' }
  & { updateUserTenant?: Maybe<Array<Maybe<(
    { __typename?: 'UpdateUserTenantOutput' }
    & Pick<UpdateUserTenantOutput, 'id' | 'tenant_id'>
  )>>> }
);

export type UpdateWorkExperienceMutationVariables = Exact<{
  updateProfileInput: UpdateProfileInput;
}>;


export type UpdateWorkExperienceMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
    & { skills?: Maybe<Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'is_inferred' | 'id' | 'label' | 'skilltype' | 'skill_type' | 'skill_label' | 'skill_uuid' | 'is_custom'>
    )>>, work_experiences?: Maybe<Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'career_uuid' | 'company' | 'completion_date' | 'starting_date' | 'experience_level' | 'experience_type' | 'is_current' | 'job_title' | 'irrelevant_skill_uuids'>
      & { career: (
        { __typename?: 'Career' }
        & Pick<Career, 'id'>
        & { baseline_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>> }
      ) }
    )>> }
  ) }
);

export type UpsertAssessmentMutationVariables = Exact<{
  upsertAssessmentInput: UpsertAssessmentInput;
}>;


export type UpsertAssessmentMutation = (
  { __typename?: 'Mutation' }
  & { upsertAssessment: (
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'assessment_uuid' | 'created_at' | 'created_by' | 'link' | 'provider' | 'result' | 'title' | 'type' | 'updated_at' | 'updated_by' | 'user_uuid'>
  ) }
);

export type UpsertIrrelevantWorkExpSkillsMutationVariables = Exact<{
  upsertIrrelevantWorkExpSkills: UpsertIrrelevantWorkExpSkillsInput;
}>;


export type UpsertIrrelevantWorkExpSkillsMutation = (
  { __typename?: 'Mutation' }
  & { upsertIrrelevantWorkExperienceSkills: (
    { __typename?: 'WorkExperience' }
    & Pick<WorkExperience, 'work_exp_uuid' | 'job_title' | 'irrelevant_skill_uuids' | 'career_uuid'>
    & { career: (
      { __typename?: 'Career' }
      & Pick<Career, 'id'>
      & { baseline_skills?: Maybe<Array<(
        { __typename?: 'Skill' }
        & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
      )>>, specialized_skills?: Maybe<Array<(
        { __typename?: 'Skill' }
        & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
      )>> }
    ) }
  ) }
);

export type UpsertTagMutationVariables = Exact<{
  upsertTagInput: UpsertTagInput;
}>;


export type UpsertTagMutation = (
  { __typename?: 'Mutation' }
  & { upsertTag: (
    { __typename?: 'Tag' }
    & Pick<Tag, 'tag_uuid'>
  ) }
);

export type UpsertUserNextStepMutationVariables = Exact<{
  upsertUserNextStepInput: UpsertUserNextStepInput;
  searchUserNextStepInput: SearchUserNextStepInput;
}>;


export type UpsertUserNextStepMutation = (
  { __typename?: 'Mutation' }
  & { upsertUserNextStep: (
    { __typename?: 'UserNextStepsWrapper' }
    & Pick<UserNextStepsWrapper, 'number_of_completed_steps' | 'total_number_of_steps'>
    & { next_steps: Array<(
      { __typename?: 'UserNextSteps' }
      & Pick<UserNextSteps, 'category' | 'created_at' | 'description' | 'goals' | 'is_completable' | 'next_step_uuid' | 'coach_uuid' | 'order' | 'status' | 'title' | 'type' | 'assigned' | 'updated_at' | 'user_uuid'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'type' | 'uri'>
      )> }
    )> }
  ) }
);

export type UpsertUserRoleMutationVariables = Exact<{
  upsertRoleInput: UpsertRoleInput;
}>;


export type UpsertUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { upsertUserRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'label'>
  ) }
);

export type GetCareerRequiredSkillsQueryVariables = Exact<{
  getCareerInput: GetCareerInput;
}>;


export type GetCareerRequiredSkillsQuery = (
  { __typename?: 'Query' }
  & { getCareer: (
    { __typename?: 'CareerGraph' }
    & { baselineSkills: Array<(
      { __typename?: 'CareerSkillGraph' }
      & Pick<CareerSkillGraph, 'id' | 'label' | 'proficiency' | 'skilltype'>
    )>, specializedSkills: Array<(
      { __typename?: 'CareerSkillGraph' }
      & Pick<CareerSkillGraph, 'id' | 'label' | 'proficiency' | 'skilltype'>
    )> }
  ) }
);

export type CheckProfileExistenceQueryVariables = Exact<{
  searchInput: CheckProfileExistenceInput;
}>;


export type CheckProfileExistenceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkProfileExistence'>
);

export type ClassifyJobTitleQueryVariables = Exact<{
  job: Scalars['String'];
}>;


export type ClassifyJobTitleQuery = (
  { __typename?: 'Query' }
  & { classifyJobTitle?: Maybe<(
    { __typename?: 'JobsClassifierResponse' }
    & Pick<JobsClassifierResponse, 'top_candidate' | 'role_uuid' | 'uuid' | 'confidence_score'>
  )> }
);

export type ClassifyJobTitlesQueryVariables = Exact<{
  jobs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ClassifyJobTitlesQuery = (
  { __typename?: 'Query' }
  & { classifyJobTitles?: Maybe<Array<Maybe<(
    { __typename?: 'JobsClassifierResponse' }
    & Pick<JobsClassifierResponse, 'top_candidate' | 'role_uuid' | 'uuid'>
  )>>> }
);

export type ClassifySkillTitleQueryVariables = Exact<{
  skill: Scalars['String'];
}>;


export type ClassifySkillTitleQuery = (
  { __typename?: 'Query' }
  & { classifySkillTitle?: Maybe<(
    { __typename?: 'SkillClassifierResponse' }
    & Pick<SkillClassifierResponse, 'label' | 'uuid' | 'skill_type'>
  )> }
);

export type DeleteResumeMutationVariables = Exact<{
  input?: InputMaybe<DeleteResume_Request_Input>;
}>;


export type DeleteResumeMutation = (
  { __typename?: 'Mutation' }
  & { deleteResume?: Maybe<(
    { __typename?: 'deleteResume_200_response' }
    & Pick<DeleteResume_200_Response, 'userId' | 'S3Key'>
  )> }
);

export type DownloadJsonErrorFileContentQueryVariables = Exact<{
  file_name: Scalars['String'];
}>;


export type DownloadJsonErrorFileContentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadJsonFileContent'>
);

export type DownloadNextStepDocQueryVariables = Exact<{
  downloadNextStepDocInput: DownloadNextStepDocInput;
}>;


export type DownloadNextStepDocQuery = (
  { __typename?: 'Query' }
  & { downloadNextStepDoc: (
    { __typename?: 'DownloadNextStepDocsResponse' }
    & Pick<DownloadNextStepDocsResponse, 'statusCode' | 'msg'>
    & { docUrls?: Maybe<Array<(
      { __typename?: 'NextStepDocDownload' }
      & Pick<NextStepDocDownload, 'url' | 's3Key' | 'fileName'>
    )>> }
  ) }
);

export type ExtractJobAttributesQueryVariables = Exact<{
  extractJobAttributesInput?: InputMaybe<ExtractorRequest_Input>;
}>;


export type ExtractJobAttributesQuery = (
  { __typename?: 'Query' }
  & { extractJobAttributes?: Maybe<Array<Maybe<(
    { __typename?: 'JobAttributes' }
    & Pick<JobAttributes, 'attributes' | 'job_id'>
  )>>> }
);

export type FindEmployerQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindEmployerQuery = (
  { __typename?: 'Query' }
  & { findEmployer?: Maybe<(
    { __typename?: 'Employer' }
    & Pick<Employer, 'employer_uuid' | 'name' | 'created_at'>
    & { CaseNote?: Maybe<Array<Maybe<(
      { __typename?: 'EmployerCaseNote' }
      & Pick<EmployerCaseNote, 'id' | 'description' | 'employer_uuid' | 'created_at' | 'updated_at' | 'created_by' | 'modified_by'>
    )>>>, recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'Recruiter' }
      & Pick<Recruiter, 'recruiter_uuid' | 'employer_uuid'>
      & { user?: Maybe<(
        { __typename?: 'UserReference' }
        & Pick<UserReference, 'is_registered' | 'first_name' | 'last_name' | 'user_uuid' | 'email'>
        & { invitation?: Maybe<Array<Maybe<(
          { __typename?: 'Invitation' }
          & Pick<Invitation, 'invitation_uuid' | 'invitation_link'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type FindGroupQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindGroupQuery = (
  { __typename?: 'Query' }
  & { findGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'employer_uuid' | 'name' | 'created_at' | 'updated_at'>
    & { coaches?: Maybe<Array<Maybe<(
      { __typename?: 'Coach' }
      & Pick<Coach, 'coach_uuid' | 'group_uuid'>
      & { user?: Maybe<(
        { __typename?: 'UserReference' }
        & Pick<UserReference, 'id' | 'first_name' | 'last_name' | 'email' | 'is_registered'>
        & { invitation?: Maybe<Array<Maybe<(
          { __typename?: 'Invitation' }
          & Pick<Invitation, 'invitation_link'>
        )>>> }
      )> }
    )>>> }
  )> }
);

export type GetActiveDefaultQuestionsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetActiveDefaultQuestionsQuery = (
  { __typename?: 'Query' }
  & { getActiveDefaultQuestions?: Maybe<Array<Maybe<(
    { __typename?: 'DefaultQuestionsResponse' }
    & Pick<DefaultQuestionsResponse, 'uuid' | 'type'>
    & { question?: Maybe<(
      { __typename?: 'DefaultQuestionField' }
      & Pick<DefaultQuestionField, 'questionText' | 'description'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'DefaultQuestionOptionWithTags' }
        & Pick<DefaultQuestionOptionWithTags, 'text' | 'value' | 'additionalText' | 'requiredAditionalTextBox' | 'isChecked' | 'dataSource'>
        & { textI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, additionalTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>>>, questionTextI18n?: Maybe<(
        { __typename?: 'TranslatedField' }
        & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
      )>, descriptionI18n?: Maybe<(
        { __typename?: 'TranslatedField' }
        & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
      )> }
    )> }
  )>>> }
);

export type GetAllAssessmentsQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetAllAssessmentsQuery = (
  { __typename?: 'Query' }
  & { getAllAssessments: Array<(
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'assessment_uuid' | 'created_at' | 'created_by' | 'link' | 'provider' | 'result' | 'title' | 'type' | 'updated_at' | 'updated_by' | 'user_uuid'>
  )> }
);

export type GetAllCareerAreasQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
}>;


export type GetAllCareerAreasQuery = (
  { __typename?: 'Query' }
  & { careerAreas: Array<(
    { __typename?: 'CareerArea' }
    & Pick<CareerArea, 'id' | 'pref_label' | 'machine_key'>
  )> }
);

export type GetAllCareerIndustriesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
}>;


export type GetAllCareerIndustriesQuery = (
  { __typename?: 'Query' }
  & { careerAreas: Array<(
    { __typename?: 'CareerArea' }
    & Pick<CareerArea, 'description' | 'id' | 'machine_key' | 'pref_label'>
  )> }
);

export type GetAllCoachesQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetAllCoachesQuery = (
  { __typename?: 'Query' }
  & { getAllCoaches: Array<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'first_name' | 'last_name' | 'user_tenant_role' | 'user_uuid'>
    & { tenantRoles?: Maybe<Array<(
      { __typename?: 'TenantRole' }
      & Pick<TenantRole, 'role'>
    )>> }
  )> }
);

export type GetAllNextStepsMetadataQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
}>;


export type GetAllNextStepsMetadataQuery = (
  { __typename?: 'Query' }
  & { getAllNextStepsMetadata: Array<(
    { __typename?: 'NextStepsMetadata' }
    & Pick<NextStepsMetadata, 'category' | 'next_step_uuid' | 'next_step_title' | 'tenant_id' | 'is_completable' | 'goals' | 'type' | 'next_step_description'>
    & { link?: Maybe<(
      { __typename?: 'Link' }
      & Pick<Link, 'target' | 'type' | 'uri'>
    )> }
  )> }
);

export type GetAllPolicyAssetsQueryVariables = Exact<{
  configurationId: Scalars['String'];
}>;


export type GetAllPolicyAssetsQuery = (
  { __typename?: 'Query' }
  & { getAllPolicyAssets: Array<(
    { __typename?: 'AssetsDto' }
    & Pick<AssetsDto, 'asset_id' | 'asset_type' | 'language' | 'url'>
  )> }
);

export type GetAllTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTagsQuery = (
  { __typename?: 'Query' }
  & { getAllTags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'label' | 'tag_uuid' | 'category' | 'status' | 'is_visible_to_recruiters'>
  )> }
);

export type GetAllUserUuidsQueryVariables = Exact<{
  searchInput: FindProfilesSearchInput;
}>;


export type GetAllUserUuidsQuery = (
  { __typename?: 'Query' }
  & { getAllUserUuids: (
    { __typename?: 'GetAllUserUuidsWrapper' }
    & Pick<GetAllUserUuidsWrapper, 'user_uuids'>
  ) }
);

export type GetCandidateProfilesQueryVariables = Exact<{
  user_uuids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  job_id?: InputMaybe<Scalars['String']>;
}>;


export type GetCandidateProfilesQuery = (
  { __typename?: 'Query' }
  & { getUserProfiles: (
    { __typename?: 'ProfilesQueryWrapper' }
    & { profiles: Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'user_uuid' | 'employer_id' | 'first_name' | 'last_name' | 'email' | 'location' | 'previously_contacted'>
      & { contact_history?: Maybe<Array<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'contact_uuid' | 'employer_uuid' | 'job_id' | 'contacted_by_uuid'>
      )>>, skills?: Maybe<Array<(
        { __typename?: 'UserSkill' }
        & Pick<UserSkill, 'id' | 'label' | 'is_inferred' | 'proficiency'>
      )>>, tags?: Maybe<Array<(
        { __typename?: 'UserTag' }
        & Pick<UserTag, 'label'>
      )>>, last_career?: Maybe<(
        { __typename?: 'WorkExperience' }
        & Pick<WorkExperience, 'career_uuid' | 'company' | 'job_title'>
      )>, last_education?: Maybe<(
        { __typename?: 'EducationExperience' }
        & Pick<EducationExperience, 'institution_name' | 'major' | 'degree_type'>
      )> }
    )> }
  ) }
);

export type GetCandidateRecommendationsQueryVariables = Exact<{
  contact_job_id?: InputMaybe<Scalars['String']>;
  job_career_id: Scalars['String'];
  job_skill_ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  job_edu_level?: InputMaybe<Array<Scalars['Float']> | Scalars['Float']>;
  job_exp_level?: InputMaybe<Array<Scalars['Float']> | Scalars['Float']>;
  job_role_id?: InputMaybe<Scalars['String']>;
  location: Scalars['String'];
  required_skills?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  required_careers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  required_tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  trainable?: InputMaybe<Scalars['Boolean']>;
  candidates?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetCandidateRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getCandidateRecommendations?: Maybe<(
    { __typename?: 'CandidateRecommender_CandidateRecommenderOutput' }
    & Pick<CandidateRecommender_CandidateRecommenderOutput, 'count' | 'total'>
    & { _embedded?: Maybe<(
      { __typename?: 'CandidateRecommender_EmbeddedCandidates' }
      & { candidates?: Maybe<Array<Maybe<(
        { __typename?: 'CandidateRecommender_Candidate' }
        & Pick<CandidateRecommender_Candidate, 'id' | 'trainable' | 'match'>
      )>>> }
    )> }
  )> }
);

export type GetCareerIndustriesByUuidQueryVariables = Exact<{
  uuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetCareerIndustriesByUuidQuery = (
  { __typename?: 'Query' }
  & { careerAreaByUUIDS: Array<(
    { __typename?: 'CareerArea' }
    & Pick<CareerArea, 'description' | 'id' | 'machine_key' | 'pref_label'>
  )> }
);

export type GetCareerMatchByIdQueryVariables = Exact<{
  career_id: Scalars['String'];
  lang?: InputMaybe<Scalars['String']>;
  career_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  role_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  current_skill_ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCareerMatchByIdQuery = (
  { __typename?: 'Query' }
  & { getCareerMatchByID?: Maybe<(
    { __typename?: 'CareerRecommender_Career' }
    & Pick<CareerRecommender_Career, 'match' | 'label' | 'interest_profile_code' | 'id' | 'description' | 'demand'>
    & { career_area?: Maybe<(
      { __typename?: 'CareerRecommender_CareerArea' }
      & Pick<CareerRecommender_CareerArea, 'id' | 'label'>
    )>, salary?: Maybe<(
      { __typename?: 'CareerRecommender_MonetaryValue' }
      & Pick<CareerRecommender_MonetaryValue, 'value' | 'currency'>
    )> }
  )> }
);

export type GetCareerRecommendationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<CareerRecommender_SortBy>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  lang?: InputMaybe<Scalars['String']>;
  career_ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  role_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  current_skill_ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  career_area_interest?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetCareerRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getCareerRecommendations?: Maybe<(
    { __typename: 'CareerRecommender_CareerRecommenderOutput' }
    & Pick<CareerRecommender_CareerRecommenderOutput, 'count' | 'total'>
    & { _embedded?: Maybe<(
      { __typename: 'CareerRecommender_EmbeddedCareers' }
      & { careers?: Maybe<Array<Maybe<(
        { __typename: 'CareerRecommender_Career' }
        & Pick<CareerRecommender_Career, 'id' | 'label' | 'description' | 'demand' | 'interest_profile_code' | 'match'>
        & { career_area?: Maybe<(
          { __typename?: 'CareerRecommender_CareerArea' }
          & Pick<CareerRecommender_CareerArea, 'id' | 'label'>
        )>, salary?: Maybe<(
          { __typename: 'CareerRecommender_MonetaryValue' }
          & Pick<CareerRecommender_MonetaryValue, 'currency' | 'value'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetCareersQueryVariables = Exact<{
  getCareersInput: GetCareersInput;
}>;


export type GetCareersQuery = (
  { __typename?: 'Query' }
  & { getCareers: (
    { __typename?: 'GetCareersOutput' }
    & Pick<GetCareersOutput, 'count' | 'allCareerIds'>
    & { careers: Array<(
      { __typename?: 'Careers' }
      & Pick<Careers, 'career_name' | 'career_id'>
      & { career_area?: Maybe<(
        { __typename?: 'CareerArea' }
        & Pick<CareerArea, 'career_area_id' | 'career_area_name'>
      )> }
    )> }
  ) }
);

export type GetChildTenantsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetChildTenantsQuery = (
  { __typename?: 'Query' }
  & { getChildTenants?: Maybe<Array<Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'name' | 'depth'>
  )>>> }
);

export type GetCombinedExportReportsQueryVariables = Exact<{
  combinedExportReportsInput: CombinedExportReportInput;
}>;


export type GetCombinedExportReportsQuery = (
  { __typename?: 'Query' }
  & { getCombinedExportReports: Array<(
    { __typename?: 'ExportReport' }
    & Pick<ExportReport, 'created_at' | 'export_report_uuid' | 'name' | 'num_users' | 'status' | 'tenant_id' | 'tenant_ids' | 'type' | 'user_specified_name'>
  )> }
);

export type GetConfigurationQueryVariables = Exact<{
  getConfigurationInput: GetConfigurationInput;
}>;


export type GetConfigurationQuery = (
  { __typename?: 'Query' }
  & { getConfiguration: (
    { __typename?: 'FilterOptions' }
    & Pick<FilterOptions, 'include'>
  ) }
);

export type GetContactHistoryQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
}>;


export type GetContactHistoryQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
    & { contact_history?: Maybe<Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'contacted_by_name' | 'employer_name' | 'job_title' | 'created_at' | 'contact_uuid' | 'employer_uuid' | 'contacted_by_uuid' | 'job_id'>
    )>> }
  ) }
);

export type GetCourseQueryVariables = Exact<{
  getCourseInput: GetCourseInput;
}>;


export type GetCourseQuery = (
  { __typename?: 'Query' }
  & { getCourse: (
    { __typename: 'Course' }
    & Pick<Course, 'uuid' | 'pref_label' | 'description' | 'url' | 'lang_label' | 'duration_hours' | 'duration_unit' | 'duration_value' | 'diplomas' | 'delivery' | 'location' | 'credits_accepted' | 'subscription_required' | 'level' | 'course_type'>
    & { provider?: Maybe<(
      { __typename: 'CourseProvider' }
      & Pick<CourseProvider, '_id' | 'pref_label'>
    )>, cost?: Maybe<(
      { __typename?: 'CourseCost' }
      & Pick<CourseCost, 'value' | 'currency'>
    )>, subjects?: Maybe<Array<(
      { __typename: 'CourseSubject' }
      & Pick<CourseSubject, '_id' | 'pref_label'>
    )>>, course_results?: Maybe<Array<(
      { __typename?: 'CourseResult' }
      & Pick<CourseResult, 'result_type' | 'result'>
    )>>, skills?: Maybe<Array<(
      { __typename: 'CourseSkillGraph' }
      & Pick<CourseSkillGraph, 'skill_uuid' | 'pref_label' | 'skilltype'>
    )>>, careers?: Maybe<Array<(
      { __typename: 'CareerGraph' }
      & Pick<CareerGraph, '_id' | 'label' | 'description'>
    )>> }
  ) }
);

export type GetCourseRecommendationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['PositiveInt']>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  lang?: InputMaybe<Scalars['CourseRecommender_queryInput_getCourseRecommendations_lang']>;
  target_career_id?: InputMaybe<Scalars['String']>;
  target_skill_ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  most_recent_career_id?: InputMaybe<Scalars['String']>;
  user_skill_ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCourseRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getCourseRecommendations?: Maybe<(
    { __typename?: 'CourseRecommender_CourseRecommenderOutput' }
    & Pick<CourseRecommender_CourseRecommenderOutput, 'count' | 'total'>
    & { _embedded?: Maybe<(
      { __typename?: 'CourseRecommender_EmbeddedCourses' }
      & { courses?: Maybe<Array<Maybe<(
        { __typename?: 'CourseRecommender_Course' }
        & Pick<CourseRecommender_Course, 'id' | 'label' | 'url' | 'duration_hours' | 'duration_value' | 'duration_unit' | 'credits_accepted' | 'subscription_required'>
        & { provider?: Maybe<(
          { __typename?: 'CourseRecommender_Provider' }
          & Pick<CourseRecommender_Provider, 'id' | 'label' | 'logo_url'>
        )>, owner?: Maybe<(
          { __typename?: 'CourseRecommender_Provider' }
          & Pick<CourseRecommender_Provider, 'id' | 'label'>
        )>, cost?: Maybe<(
          { __typename?: 'CourseRecommender_Course_cost' }
          & Pick<CourseRecommender_Course_Cost, 'currency' | 'value'>
        )>, skills?: Maybe<Array<Maybe<(
          { __typename?: 'CourseRecommender_Skill' }
          & Pick<CourseRecommender_Skill, 'id' | 'label' | 'skilltype'>
        )>>>, careers?: Maybe<Array<Maybe<(
          { __typename?: 'CourseRecommender_Career' }
          & Pick<CourseRecommender_Career, 'id' | 'label'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetCourseRecommendationsForJobQueryVariables = Exact<{
  page?: InputMaybe<Scalars['PositiveInt']>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  lang?: InputMaybe<Scalars['CourseRecommender_queryInput_getCourseRecommendations_lang']>;
  job_career_id?: InputMaybe<Scalars['String']>;
  job_skills_gap?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCourseRecommendationsForJobQuery = (
  { __typename?: 'Query' }
  & { getCourseRecommendations?: Maybe<(
    { __typename?: 'CourseRecommender_CourseRecommenderOutput' }
    & Pick<CourseRecommender_CourseRecommenderOutput, 'count' | 'total'>
    & { _embedded?: Maybe<(
      { __typename?: 'CourseRecommender_EmbeddedCourses' }
      & { courses?: Maybe<Array<Maybe<(
        { __typename?: 'CourseRecommender_Course' }
        & Pick<CourseRecommender_Course, 'id' | 'label' | 'url' | 'duration_hours' | 'duration_value' | 'duration_unit' | 'credits_accepted' | 'subscription_required'>
        & { provider?: Maybe<(
          { __typename?: 'CourseRecommender_Provider' }
          & Pick<CourseRecommender_Provider, 'id' | 'label' | 'logo_url'>
        )>, owner?: Maybe<(
          { __typename?: 'CourseRecommender_Provider' }
          & Pick<CourseRecommender_Provider, 'id' | 'label'>
        )>, cost?: Maybe<(
          { __typename?: 'CourseRecommender_Course_cost' }
          & Pick<CourseRecommender_Course_Cost, 'currency' | 'value'>
        )>, skills?: Maybe<Array<Maybe<(
          { __typename?: 'CourseRecommender_Skill' }
          & Pick<CourseRecommender_Skill, 'id' | 'label' | 'skilltype'>
        )>>>, careers?: Maybe<Array<Maybe<(
          { __typename?: 'CourseRecommender_Career' }
          & Pick<CourseRecommender_Career, 'id' | 'label'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type GetCoursesQueryVariables = Exact<{
  getCoursesInput: GetCoursesInput;
}>;


export type GetCoursesQuery = (
  { __typename?: 'Query' }
  & { getCourses: (
    { __typename?: 'CoursesResponse' }
    & Pick<CoursesResponse, 'count'>
    & { courses: Array<(
      { __typename?: 'Course' }
      & Pick<Course, 'uuid' | 'pref_label' | 'url' | 'duration_hours' | 'duration_value' | 'duration_unit' | 'credits_accepted' | 'subscription_required' | 'course_type' | 'description' | 'tenant_id' | 'source_id' | 'lang'>
      & { provider?: Maybe<(
        { __typename?: 'CourseProvider' }
        & Pick<CourseProvider, '_id' | 'pref_label'>
      )>, cost?: Maybe<(
        { __typename?: 'CourseCost' }
        & Pick<CourseCost, 'currency' | 'value'>
      )>, skills?: Maybe<Array<(
        { __typename?: 'CourseSkillGraph' }
        & Pick<CourseSkillGraph, 'skill_uuid' | 'pref_label' | 'skilltype'>
      )>>, careers?: Maybe<Array<(
        { __typename?: 'CareerGraph' }
        & Pick<CareerGraph, '_id' | 'label'>
      )>> }
    )> }
  ) }
);

export type GetCurrentFormVersionQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
  formUuid: Scalars['String'];
}>;


export type GetCurrentFormVersionQuery = (
  { __typename?: 'Query' }
  & { getCurrentFormVersion?: Maybe<(
    { __typename?: 'CurrentFormVersionResponse' }
    & Pick<CurrentFormVersionResponse, 'title' | 'description' | 'formUuid' | 'formVersionUuid'>
    & { title_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, description_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, components?: Maybe<Array<Maybe<(
      { __typename?: 'InputField' }
      & { currencyInputField?: Maybe<(
        { __typename?: 'CurrencyInputField' }
        & Pick<CurrencyInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserCurrencyResponse' }
          & Pick<InputFieldUserCurrencyResponse, 'min' | 'max'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dateInputField?: Maybe<(
        { __typename?: 'DateInputField' }
        & Pick<DateInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserDateResponse' }
          & Pick<InputFieldUserDateResponse, 'startDate' | 'endDate'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputField?: Maybe<(
        { __typename?: 'DropdownInputField' }
        & Pick<DropdownInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserNumberResponse' }
          & Pick<InputFieldUserNumberResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, fixedText?: Maybe<(
        { __typename?: 'FixedText' }
        & Pick<FixedText, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'uuid' | 'type' | 'questionText' | 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'version'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserResponseWithTags' }
          & Pick<InputFieldUserResponseWithTags, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputField?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputField' }
        & Pick<MultiChoiceCheckboxInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOption' }
          & Pick<MultiChoiceCheckboxInputFieldOption, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponse' }
          & Pick<InputFieldUserMultiChoiceResponse, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOption' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOption, 'aditionalText' | 'id'>
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'description' | 'hasDescription' | 'order' | 'placeholder' | 'type' | 'uuid' | 'version'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>>, labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponseWithTags' }
          & Pick<InputFieldUserMultiChoiceResponseWithTags, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, textInputField?: Maybe<(
        { __typename?: 'TextInputField' }
        & Pick<TextInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserStringResponse' }
          & Pick<InputFieldUserStringResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )> }
    )>>> }
  )> }
);

export type GetCurrentUserTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserTenantQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserTenant?: Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'name' | 'logo_url' | 'favicon' | 'page_title' | 'onboarding_form_link' | 'code' | 'lil_sso_link_format' | 'registration_email_subject' | 'configuration_id' | 'sign_up_description' | 'sign_up_link' | 'sign_up_image_url' | 'mobile_sign_up_image_url'>
    & { text_overlay_title?: Maybe<(
      { __typename?: 'Translation' }
      & Pick<Translation, 'en_US' | 'fr_CA'>
    )>, text_overlay_description?: Maybe<(
      { __typename?: 'Translation' }
      & Pick<Translation, 'en_US' | 'fr_CA'>
    )> }
  )> }
);

export type GetDefaultQuestionsQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type GetDefaultQuestionsQuery = (
  { __typename?: 'Query' }
  & { getDefaultQuestions?: Maybe<Array<Maybe<(
    { __typename?: 'DefaultQuestionsResponse' }
    & Pick<DefaultQuestionsResponse, 'uuid' | 'tenant_uuid' | 'type' | 'is_active' | 'is_assigned' | 'assigned_form_title'>
    & { question?: Maybe<(
      { __typename?: 'DefaultQuestionField' }
      & Pick<DefaultQuestionField, 'questionText' | 'description' | 'hasDescription'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'DefaultQuestionOptionWithTags' }
        & Pick<DefaultQuestionOptionWithTags, 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox' | 'isChecked'>
        & { tags?: Maybe<Array<(
          { __typename?: 'FormBuilderTag' }
          & Pick<FormBuilderTag, 'uuid' | 'label'>
        )>>, textI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, additionalTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>>>, questionTextI18n?: Maybe<(
        { __typename?: 'TranslatedField' }
        & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
      )>, descriptionI18n?: Maybe<(
        { __typename?: 'TranslatedField' }
        & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
      )> }
    )> }
  )>>> }
);

export type GetEmployersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  recruiters?: InputMaybe<Scalars['Boolean']>;
  searchString?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EmployerSortBy>;
  sortDirection?: InputMaybe<EmployerSortDirection>;
  tenant_uuid: Scalars['String'];
  type?: InputMaybe<PartnerType>;
}>;


export type GetEmployersQuery = (
  { __typename?: 'Query' }
  & { getEmployers?: Maybe<(
    { __typename?: 'EmployerSearchResult' }
    & Pick<EmployerSearchResult, 'tenantCode' | 'totalPages' | 'totalResults'>
    & { employers?: Maybe<Array<Maybe<(
      { __typename?: 'Employer' }
      & Pick<Employer, 'created_at' | 'employer_uuid' | 'name' | 'type'>
      & { recruiters?: Maybe<Array<Maybe<(
        { __typename?: 'Recruiter' }
        & Pick<Recruiter, 'recruiter_uuid'>
        & { user?: Maybe<(
          { __typename?: 'UserReference' }
          & Pick<UserReference, 'email' | 'first_name' | 'last_name' | 'user_uuid' | 'created_at' | 'is_registered'>
          & { invitation?: Maybe<Array<Maybe<(
            { __typename?: 'Invitation' }
            & Pick<Invitation, 'invitation_link' | 'invitation_uuid'>
          )>>> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type GetExportedProfilesCsvQueryVariables = Exact<{
  user_uuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetExportedProfilesCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExportedProfilesCSV'>
);

export type GetFormAndLastSubmissionQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
  formUuid: Scalars['String'];
  userUuid: Scalars['String'];
}>;


export type GetFormAndLastSubmissionQuery = (
  { __typename?: 'Query' }
  & { getFormAndLastSubmission?: Maybe<(
    { __typename?: 'FormAndLastSubmissionResponse' }
    & Pick<FormAndLastSubmissionResponse, 'submissionUuid' | 'title' | 'description' | 'userUuid' | 'formUuid' | 'formVersionUuid'>
    & { titleI18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, descriptionI18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, data: Array<Maybe<(
      { __typename?: 'InputField' }
      & { currencyInputField?: Maybe<(
        { __typename?: 'CurrencyInputField' }
        & Pick<CurrencyInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserCurrencyResponse' }
          & Pick<InputFieldUserCurrencyResponse, 'min' | 'max'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dateInputField?: Maybe<(
        { __typename?: 'DateInputField' }
        & Pick<DateInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserDateResponse' }
          & Pick<InputFieldUserDateResponse, 'startDate' | 'endDate'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputField?: Maybe<(
        { __typename?: 'DropdownInputField' }
        & Pick<DropdownInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserNumberResponse' }
          & Pick<InputFieldUserNumberResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, fixedText?: Maybe<(
        { __typename?: 'FixedText' }
        & Pick<FixedText, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'uuid' | 'type' | 'questionText' | 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'version'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserResponseWithTags' }
          & Pick<InputFieldUserResponseWithTags, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputField?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputField' }
        & Pick<MultiChoiceCheckboxInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOption' }
          & Pick<MultiChoiceCheckboxInputFieldOption, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponse' }
          & Pick<InputFieldUserMultiChoiceResponse, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOption' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOption, 'aditionalText' | 'id'>
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'description' | 'hasDescription' | 'order' | 'placeholder' | 'type' | 'uuid' | 'version'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>>, labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponseWithTags' }
          & Pick<InputFieldUserMultiChoiceResponseWithTags, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, textInputField?: Maybe<(
        { __typename?: 'TextInputField' }
        & Pick<TextInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserStringResponse' }
          & Pick<InputFieldUserStringResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'description' | 'hasDescription' | 'order' | 'placeholder' | 'type' | 'uuid' | 'version'>
        & { options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )>, additionalTextI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetFormDetailsQueryVariables = Exact<{
  formUuid: Scalars['String'];
}>;


export type GetFormDetailsQuery = (
  { __typename?: 'Query' }
  & { getFormDetails: (
    { __typename?: 'Form' }
    & Pick<Form, 'form_id' | 'title' | 'form_version_id' | 'status' | 'type' | 'description' | 'version' | 'first_name_active' | 'last_name_active' | 'location_active' | 'zip_code_active' | 'phone_number_active'>
    & { title_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, description_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, components: Array<Maybe<(
      { __typename?: 'InputField' }
      & { currencyInputField?: Maybe<(
        { __typename?: 'CurrencyInputField' }
        & Pick<CurrencyInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserCurrencyResponse' }
          & Pick<InputFieldUserCurrencyResponse, 'min' | 'max'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dateInputField?: Maybe<(
        { __typename?: 'DateInputField' }
        & Pick<DateInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserDateResponse' }
          & Pick<InputFieldUserDateResponse, 'startDate' | 'endDate'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputField?: Maybe<(
        { __typename?: 'DropdownInputField' }
        & Pick<DropdownInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserNumberResponse' }
          & Pick<InputFieldUserNumberResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, fixedText?: Maybe<(
        { __typename?: 'FixedText' }
        & Pick<FixedText, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'uuid' | 'type' | 'questionText' | 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'version'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserResponseWithTags' }
          & Pick<InputFieldUserResponseWithTags, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputField?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputField' }
        & Pick<MultiChoiceCheckboxInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOption' }
          & Pick<MultiChoiceCheckboxInputFieldOption, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponse' }
          & Pick<InputFieldUserMultiChoiceResponse, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOption' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOption, 'aditionalText' | 'id'>
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'description' | 'hasDescription' | 'order' | 'placeholder' | 'type' | 'uuid' | 'version'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>>, labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponseWithTags' }
          & Pick<InputFieldUserMultiChoiceResponseWithTags, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, textInputField?: Maybe<(
        { __typename?: 'TextInputField' }
        & Pick<TextInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserStringResponse' }
          & Pick<InputFieldUserStringResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA'>
        )>, options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )> }
      )> }
    )>> }
  ) }
);

export type GetFormsQueryVariables = Exact<{
  filters?: InputMaybe<GetFormsInput>;
  tenantUuid: Scalars['String'];
}>;


export type GetFormsQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'form_id' | 'form_version_id' | 'title' | 'tenant_uuid' | 'status' | 'type' | 'created_at' | 'updated_at'>
  )> }
);

export type GetGroupsQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<QueryInput_GetGroups_Input_SortBy>;
  sortDirection?: InputMaybe<QueryInput_GetGroups_Input_SortDirection>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  coaches: Scalars['Boolean'];
}>;


export type GetGroupsQuery = (
  { __typename?: 'Query' }
  & { getGroups?: Maybe<(
    { __typename?: 'GroupsResponse' }
    & Pick<GroupsResponse, 'limit' | 'totalResults' | 'totalPages'>
    & { groups?: Maybe<Array<Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'employer_uuid' | 'name' | 'created_at' | 'updated_at'>
      & { coaches?: Maybe<Array<Maybe<(
        { __typename?: 'Coach' }
        & Pick<Coach, 'coach_uuid' | 'group_uuid'>
        & { user?: Maybe<(
          { __typename?: 'UserReference' }
          & Pick<UserReference, 'id' | 'first_name' | 'last_name' | 'email'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type GetInviteLinkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetInviteLinkQuery = (
  { __typename?: 'Query' }
  & { getInviteLink?: Maybe<(
    { __typename: 'InviteLink' }
    & Pick<InviteLink, 'id' | 'role' | 'name' | 'type' | 'tag_id' | 'is_active' | 'email_domains' | 'tenant_id' | 'code' | 'user_count' | 'employer_id' | 'created_at' | 'updated_at'>
  )> }
);

export type GetInviteLinksQueryVariables = Exact<{
  is_ascending: Scalars['Boolean'];
  sort_by: Scalars['String'];
  excluded_roles?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetInviteLinksQuery = (
  { __typename?: 'Query' }
  & { getInviteLinks?: Maybe<(
    { __typename: 'getInviteLinks_200_response' }
    & { inviteLinks?: Maybe<Array<Maybe<(
      { __typename: 'InviteLink' }
      & Pick<InviteLink, 'code' | 'created_at' | 'id' | 'is_active' | 'email_domains' | 'name' | 'role' | 'tag_id' | 'tenant_id' | 'type' | 'employer_id' | 'updated_at' | 'user_count'>
    )>>> }
  )> }
);

export type GetJobQueryVariables = Exact<{
  getJobInput: JobService_GetJobInput;
}>;


export type GetJobQuery = (
  { __typename?: 'Query' }
  & { getJob: (
    { __typename: 'JobService_JobSearchJob' }
    & Pick<JobService_JobSearchJob, 'job_id' | 'job_title' | 'location' | 'country' | 'posting_source' | 'provider' | 'provider_id' | 'allows_easy_apply' | 'company' | 'created_at' | 'active' | 'description' | 'application_url' | 'application_email' | 'job_type' | 'employer_id' | 'department' | 'seniority' | 'expiry_date' | 'education_level' | 'work_experience_level' | 'classified_job_type' | 'classified_seniority_type' | 'remote_type'>
    & { salary_range?: Maybe<(
      { __typename?: 'JobService_JobSalaryRange' }
      & Pick<JobService_JobSalaryRange, 'max' | 'min' | 'currency' | 'pay_period'>
    )>, skills?: Maybe<Array<(
      { __typename: 'JobService_JobSearchSkill' }
      & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'skilltype' | 'pref_label'>
    )>>, skills_needed?: Maybe<Array<(
      { __typename: 'JobService_JobSearchSkill' }
      & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'skilltype' | 'pref_label'>
    )>>, additional_skills?: Maybe<Array<(
      { __typename: 'JobService_AdditionalSkill' }
      & Pick<JobService_AdditionalSkill, 'skill_uuid' | 'custom_label' | 'skilltype' | 'classified_skill_existed'>
    )>>, career?: Maybe<(
      { __typename: 'JobService_JobSearchCareer' }
      & Pick<JobService_JobSearchCareer, 'career_uuid'>
      & { baseline_skills?: Maybe<Array<(
        { __typename: 'JobService_JobSearchSkill' }
        & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
      )>>, specialized_skills?: Maybe<Array<(
        { __typename: 'JobService_JobSearchSkill' }
        & Pick<JobService_JobSearchSkill, 'skill_uuid' | 'pref_label'>
      )>> }
    )>, inferred_attributes?: Maybe<Array<(
      { __typename?: 'JobService_JobSearchInferredJobAttribute' }
      & Pick<JobService_JobSearchInferredJobAttribute, 'label' | 'level' | 'phrase' | 'skilltype' | 'uuid' | 'type'>
    )>> }
  ) }
);

export type GetJobConfigurationQueryVariables = Exact<{
  configuration_id: Scalars['String'];
}>;


export type GetJobConfigurationQuery = (
  { __typename?: 'Query' }
  & { getJobConfiguration: (
    { __typename?: 'JobConfigurationOutput' }
    & Pick<JobConfigurationOutput, 'featured_job_providers'>
    & { job_sources?: Maybe<(
      { __typename?: 'JobSourceConfiguration' }
      & Pick<JobSourceConfiguration, 'include' | 'exclude'>
    )> }
  ) }
);

export type GetJobRecommendationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['PositiveInt']>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  input?: InputMaybe<JobRecommender_RecommendedJobsRequest_Input>;
}>;


export type GetJobRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getJobRecommendations?: Maybe<(
    { __typename?: 'JobRecommender_JobRecommenderOutput' }
    & Pick<JobRecommender_JobRecommenderOutput, 'count' | 'total'>
    & { _embedded?: Maybe<(
      { __typename?: 'JobRecommender_EmbeddedJobs' }
      & { jobs?: Maybe<Array<Maybe<(
        { __typename?: 'JobRecommender_Job' }
        & Pick<JobRecommender_Job, 'job_id' | 'match' | 'skill_match' | 'education_match' | 'experience_match'>
      )>>> }
    )> }
  )> }
);

export type GetJobRecommendationsByIdsQueryVariables = Exact<{
  input?: InputMaybe<JobRecommender_RecommendedJobsRequestByIds_Input>;
}>;


export type GetJobRecommendationsByIdsQuery = (
  { __typename?: 'Query' }
  & { getJobRecommendationsByIds?: Maybe<(
    { __typename: 'JobRecommender_JobRecommenderOutput' }
    & Pick<JobRecommender_JobRecommenderOutput, 'total' | 'count'>
    & { _embedded?: Maybe<(
      { __typename?: 'JobRecommender_EmbeddedJobs' }
      & { jobs?: Maybe<Array<Maybe<(
        { __typename: 'JobRecommender_Job' }
        & Pick<JobRecommender_Job, 'job_id' | 'match' | 'education_match' | 'experience_match' | 'skill_match'>
      )>>> }
    )> }
  )> }
);

export type GetJobsV2QueryVariables = Exact<{
  getJobsInput: JobService_GetJobsInput;
}>;


export type GetJobsV2Query = (
  { __typename?: 'Query' }
  & { getJobsV2: (
    { __typename: 'JobService_GetJobsV2ResponseWrapper' }
    & Pick<JobService_GetJobsV2ResponseWrapper, 'total_results'>
    & { jobs: Array<(
      { __typename?: 'JobService_JobDto' }
      & Pick<JobService_JobDto, 'job_id' | 'job_title' | 'location' | 'posting_source' | 'provider' | 'company' | 'created_at' | 'description' | 'application_url' | 'application_email' | 'score' | 'classified_job_type' | 'classified_seniority_type' | 'remote_type'>
    )> }
  ) }
);

export type GetLearningProvidersQueryVariables = Exact<{
  getLearningProvidersInput: GetLearningProvidersInput;
}>;


export type GetLearningProvidersQuery = (
  { __typename?: 'Query' }
  & { getLearningProviders: (
    { __typename?: 'GetLearningProvidersOutput' }
    & Pick<GetLearningProvidersOutput, 'count' | 'allLearningProvidersIds'>
    & { providers: Array<(
      { __typename?: 'LearningProviders' }
      & Pick<LearningProviders, 'provider_id' | 'provider_name' | 'description' | 'tenant_id' | 'logo_url'>
    )> }
  ) }
);

export type GetLocationsQueryVariables = Exact<{
  locale: Scalars['String'];
  country_code?: InputMaybe<Scalars['String']>;
}>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { getLocations: Array<(
    { __typename?: 'CountryDivisions' }
    & Pick<CountryDivisions, 'id' | 'division_label' | 'country_label' | 'division_code' | 'country_code'>
  )> }
);

export type GetLookerSsoEmbedUrlQueryVariables = Exact<{
  getLookerInsightInput?: InputMaybe<GetLookerInsightInput>;
}>;


export type GetLookerSsoEmbedUrlQuery = (
  { __typename?: 'Query' }
  & { getLookerSSOEmbedURL: (
    { __typename?: 'GetLookerInsightOutput' }
    & Pick<GetLookerInsightOutput, 'url'>
  ) }
);

export type GetNextStepsLibraryQueryVariables = Exact<{
  libraryInput: LibraryInput;
}>;


export type GetNextStepsLibraryQuery = (
  { __typename?: 'Query' }
  & { getNextStepsLibrary: (
    { __typename?: 'LibraryQL' }
    & Pick<LibraryQl, 'library_uuid' | 'tenant_uuid' | 'type' | 'updated_at' | 'updated_by'>
    & { next_steps?: Maybe<Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )>> }
  ) }
);

export type GetOnboardingFormQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type GetOnboardingFormQuery = (
  { __typename?: 'Query' }
  & { getOnboardingForm?: Maybe<(
    { __typename?: 'OnboardingFormResponse' }
    & Pick<OnboardingFormResponse, 'title' | 'type' | 'status' | 'form_version_id' | 'form_id' | 'description' | 'created_by' | 'created_at' | 'updated_at' | 'version'>
  )> }
);

export type GetPipelineMetadataQueryVariables = Exact<{
  file_name: Scalars['String'];
}>;


export type GetPipelineMetadataQuery = (
  { __typename?: 'Query' }
  & { getPipelineMetadata: (
    { __typename?: 'PipelineMetadataOutput' }
    & Pick<PipelineMetadataOutput, 'dag_run_id' | 'data_type' | 'errors_url' | 'filename' | 'finished_at' | 'input_url' | 'num_archived_records' | 'num_error_records' | 'num_new_records' | 'num_total_records' | 'num_updated_records' | 'tenant_id'>
  ) }
);

export type GetPostObjectUrlQueryVariables = Exact<{
  input: GenerateUrl;
}>;


export type GetPostObjectUrlQuery = (
  { __typename?: 'Query' }
  & { getPostObjectURL: (
    { __typename?: 'GenereateURLReponse' }
    & Pick<GenereateUrlReponse, 'body' | 'msg' | 'url' | 's3Key'>
  ) }
);

export type GetPostObjectUrlNextStepDocQueryVariables = Exact<{
  generateURLNextStepDocInput: GenerateUrlNextStepDocInput;
}>;


export type GetPostObjectUrlNextStepDocQuery = (
  { __typename?: 'Query' }
  & { getPostObjectURLNextStepDoc: (
    { __typename?: 'GenerateURLResponseNextStepDoc' }
    & Pick<GenerateUrlResponseNextStepDoc, 'statusCode'>
    & { presignedPosts: Array<(
      { __typename?: 'PresignedPost' }
      & Pick<PresignedPost, 'url' | 'body' | 'msg' | 's3Key' | 'fileName'>
    )> }
  ) }
);

export type GetProfileFiltersQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  tenant_uuids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetProfileFiltersQuery = (
  { __typename?: 'Query' }
  & { getProfileFilters: (
    { __typename?: 'Filters' }
    & Pick<Filters, 'location'>
    & { target_careers?: Maybe<Array<(
      { __typename?: 'TargetCareer' }
      & Pick<TargetCareer, 'career_uuid' | 'pref_label'>
    )>> }
  ) }
);

export type GetPublishedOnboardingFormQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type GetPublishedOnboardingFormQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'form_id' | 'form_version_id' | 'title' | 'description' | 'tenant_uuid' | 'status' | 'type' | 'created_at' | 'updated_at'>
    & { title_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, description_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, components: Array<Maybe<(
      { __typename?: 'InputField' }
      & { currencyInputField?: Maybe<(
        { __typename?: 'CurrencyInputField' }
        & Pick<CurrencyInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserCurrencyResponse' }
          & Pick<InputFieldUserCurrencyResponse, 'min' | 'max'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dateInputField?: Maybe<(
        { __typename?: 'DateInputField' }
        & Pick<DateInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserDateResponse' }
          & Pick<InputFieldUserDateResponse, 'startDate' | 'endDate'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputField?: Maybe<(
        { __typename?: 'DropdownInputField' }
        & Pick<DropdownInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserNumberResponse' }
          & Pick<InputFieldUserNumberResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, fixedText?: Maybe<(
        { __typename?: 'FixedText' }
        & Pick<FixedText, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'uuid' | 'type' | 'questionText' | 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'version'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserResponseWithTags' }
          & Pick<InputFieldUserResponseWithTags, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputField?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputField' }
        & Pick<MultiChoiceCheckboxInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOption' }
          & Pick<MultiChoiceCheckboxInputFieldOption, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponse' }
          & Pick<InputFieldUserMultiChoiceResponse, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOption' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOption, 'aditionalText' | 'id'>
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'description' | 'hasDescription' | 'order' | 'placeholder' | 'type' | 'uuid' | 'version'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>>, labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponseWithTags' }
          & Pick<InputFieldUserMultiChoiceResponseWithTags, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, textInputField?: Maybe<(
        { __typename?: 'TextInputField' }
        & Pick<TextInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserStringResponse' }
          & Pick<InputFieldUserStringResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA'>
        )>, options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetPublishedRegistrationFormQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type GetPublishedRegistrationFormQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'form_id' | 'form_version_id' | 'tenant_uuid' | 'created_at' | 'updated_at' | 'is_active' | 'first_name_active' | 'last_name_active' | 'phone_number_active' | 'zip_code_active' | 'location_active'>
    & { title_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, description_i18n?: Maybe<(
      { __typename?: 'TranslatedField' }
      & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, components: Array<Maybe<(
      { __typename?: 'InputField' }
      & { currencyInputField?: Maybe<(
        { __typename?: 'CurrencyInputField' }
        & Pick<CurrencyInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserCurrencyResponse' }
          & Pick<InputFieldUserCurrencyResponse, 'min' | 'max'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dateInputField?: Maybe<(
        { __typename?: 'DateInputField' }
        & Pick<DateInputField, 'type' | 'version' | 'questionText' | 'hasRange' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserDateResponse' }
          & Pick<InputFieldUserDateResponse, 'startDate' | 'endDate'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputField?: Maybe<(
        { __typename?: 'DropdownInputField' }
        & Pick<DropdownInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOption' }
          & Pick<DropdownInputFieldOption, 'text' | 'value'>
          & { textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserNumberResponse' }
          & Pick<InputFieldUserNumberResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, fixedText?: Maybe<(
        { __typename?: 'FixedText' }
        & Pick<FixedText, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'uuid' | 'type' | 'questionText' | 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'version'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserResponseWithTags' }
          & Pick<InputFieldUserResponseWithTags, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputField?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputField' }
        & Pick<MultiChoiceCheckboxInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOption' }
          & Pick<MultiChoiceCheckboxInputFieldOption, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponse' }
          & Pick<InputFieldUserMultiChoiceResponse, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOption' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOption, 'aditionalText' | 'id'>
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'isDeprecated' | 'isOptional' | 'isStaff' | 'questionText' | 'description' | 'hasDescription' | 'order' | 'placeholder' | 'type' | 'uuid' | 'version'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'isChecked' | 'label' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>>, labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponseWithTags' }
          & Pick<InputFieldUserMultiChoiceResponseWithTags, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, textInputField?: Maybe<(
        { __typename?: 'TextInputField' }
        & Pick<TextInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'InputFieldUserStringResponse' }
          & Pick<InputFieldUserStringResponse, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetRecruiterByUserUuidQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetRecruiterByUserUuidQuery = (
  { __typename?: 'Query' }
  & { getRecruiterByUserUuid?: Maybe<(
    { __typename?: 'Recruiter' }
    & Pick<Recruiter, 'employer_uuid' | 'recruiter_uuid'>
    & { user?: Maybe<(
      { __typename?: 'UserReference' }
      & Pick<UserReference, 'email' | 'first_name' | 'user_uuid'>
      & { invitation?: Maybe<Array<Maybe<(
        { __typename?: 'Invitation' }
        & Pick<Invitation, 'invitation_link'>
      )>>> }
    )>, employer?: Maybe<(
      { __typename?: 'RecruiterEmployerReference' }
      & Pick<RecruiterEmployerReference, 'name'>
    )> }
  )> }
);

export type GetRecruiterProfilesQueryVariables = Exact<{
  searchInput: FindProfilesSearchInput;
}>;


export type GetRecruiterProfilesQuery = (
  { __typename?: 'Query' }
  & { getUserProfiles: (
    { __typename?: 'ProfilesQueryWrapper' }
    & { profiles: Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'user_uuid' | 'first_name' | 'last_name'>
    )> }
  ) }
);

export type GetRecruiterSavedTalentsQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetRecruiterSavedTalentsQuery = (
  { __typename?: 'Query' }
  & { getRecruiterSavedTalents?: Maybe<Array<Maybe<(
    { __typename: 'SavedTalent' }
    & Pick<SavedTalent, 'talent_uuid' | 'user_uuid' | 'created_at'>
  )>>> }
);

export type GetRecruiterSavedTalentsByJobQueryVariables = Exact<{
  user_uuid: Scalars['String'];
  job_uuid: Scalars['String'];
}>;


export type GetRecruiterSavedTalentsByJobQuery = (
  { __typename?: 'Query' }
  & { getRecruiterSavedTalentsByJob?: Maybe<Array<Maybe<(
    { __typename: 'SavedTalent' }
    & Pick<SavedTalent, 'talent_uuid' | 'user_uuid' | 'job_uuid' | 'created_at'>
  )>>> }
);

export type GetRecruitersQueryVariables = Exact<{
  searchString?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  employerName?: InputMaybe<Scalars['String']>;
  employerId?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<RecruiterSortBy>;
  sortDirection?: InputMaybe<EmployerSortDirection>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
}>;


export type GetRecruitersQuery = (
  { __typename?: 'Query' }
  & { getRecruiters?: Maybe<(
    { __typename?: 'RecruiterSearchResult' }
    & Pick<RecruiterSearchResult, 'totalPages' | 'totalResults'>
    & { recruiters?: Maybe<Array<Maybe<(
      { __typename?: 'RecruiterResponse' }
      & Pick<RecruiterResponse, 'recruiter_uuid'>
      & { user?: Maybe<(
        { __typename?: 'UserReference' }
        & Pick<UserReference, 'id' | 'email' | 'first_name' | 'last_name' | 'user_uuid' | 'created_at' | 'is_registered'>
        & { invitation?: Maybe<Array<Maybe<(
          { __typename?: 'Invitation' }
          & Pick<Invitation, 'invitation_link' | 'invitation_uuid'>
        )>>> }
      )>, employer?: Maybe<(
        { __typename?: 'RecruiterEmployerReference' }
        & Pick<RecruiterEmployerReference, 'employer_uuid' | 'name'>
      )> }
    )>>> }
  )> }
);

export type GetRegistrationFormQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type GetRegistrationFormQuery = (
  { __typename?: 'Query' }
  & { getRegistrationForm?: Maybe<(
    { __typename?: 'RegistrationForm' }
    & Pick<RegistrationForm, 'form_id' | 'form_version_id' | 'tenant_uuid' | 'created_at' | 'updated_at' | 'is_active' | 'first_name_active' | 'last_name_active' | 'phone_number_active' | 'zip_code_active' | 'location_active'>
    & { components: Array<Maybe<(
      { __typename?: 'RegistrationInputField' }
      & { textInputField?: Maybe<(
        { __typename?: 'RegistrationTextInputField' }
        & Pick<RegistrationTextInputField, 'type' | 'questionText' | 'description'>
        & { questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'uuid' | 'type' | 'questionText' | 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserResponseWithTags' }
          & Pick<InputFieldUserResponseWithTags, 'aditionalResponse' | 'value'>
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'uuid' | 'type' | 'questionText' | 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'label' | 'isChecked' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>>, labelI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponseWithTags' }
          & Pick<InputFieldUserMultiChoiceResponseWithTags, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'label' | 'uuid'>
            )>>> }
          )> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>>, textI18n?: Maybe<(
            { __typename?: 'TranslatedField' }
            & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
          )> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )>, questionTextI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, descriptionI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )>, placeholderI18n?: Maybe<(
          { __typename?: 'TranslatedField' }
          & Pick<TranslatedField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetRegistrationSubmissionQueryVariables = Exact<{
  userUuid: Scalars['String'];
}>;


export type GetRegistrationSubmissionQuery = (
  { __typename?: 'Query' }
  & { getRegistrationSubmission?: Maybe<(
    { __typename?: 'RegistrationSubmissionResponse' }
    & Pick<RegistrationSubmissionResponse, 'form_id' | 'tenant_uuid' | 'form_created_at' | 'form_updated_at' | 'form_version_id' | 'version' | 'is_assigned' | 'created_by' | 'description' | 'status' | 'title' | 'submission_id' | 'user_uuid' | 'is_deleted' | 'submission_created_at' | 'submission_updated_at'>
    & { data: Array<Maybe<(
      { __typename?: 'RegistrationInputField' }
      & { textInputField?: Maybe<(
        { __typename?: 'RegistrationTextInputField' }
        & Pick<RegistrationTextInputField, 'type' | 'version' | 'questionText' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'placeholder' | 'uuid'>
        & { userResponse?: Maybe<(
          { __typename?: 'TextInputFieldUserResponse' }
          & Pick<TextInputFieldUserResponse, 'value'>
        )> }
      )>, dropdownInputFieldWithTags?: Maybe<(
        { __typename?: 'DropdownInputFieldWithTags' }
        & Pick<DropdownInputFieldWithTags, 'type' | 'version' | 'questionText' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'placeholder' | 'uuid'>
        & { defaultOption: (
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>> }
        ), options: Array<Maybe<(
          { __typename?: 'DropdownInputFieldOptionWithTags' }
          & Pick<DropdownInputFieldOptionWithTags, 'text' | 'value'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserResponseWithTags' }
          & Pick<InputFieldUserResponseWithTags, 'value' | 'aditionalResponse'>
        )> }
      )>, multiChoiceCheckboxInputFieldWithTags?: Maybe<(
        { __typename?: 'MultiChoiceCheckboxInputFieldWithTags' }
        & Pick<MultiChoiceCheckboxInputFieldWithTags, 'type' | 'version' | 'questionText' | 'hasDescription' | 'description' | 'isOptional' | 'isStaff' | 'isDeprecated' | 'order' | 'placeholder' | 'uuid'>
        & { options: Array<Maybe<(
          { __typename?: 'MultiChoiceCheckboxInputFieldOptionWithTags' }
          & Pick<MultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'label' | 'isChecked' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<Maybe<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'uuid' | 'label'>
          )>>> }
        )>>, userResponse?: Maybe<(
          { __typename?: 'InputFieldUserMultiChoiceResponseWithTags' }
          & Pick<InputFieldUserMultiChoiceResponseWithTags, 'aditionalResponse'>
          & { value: Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'id' | 'aditionalText'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )> }
        )> }
      )>, defaultQuestionField?: Maybe<(
        { __typename?: 'DefaultQuestionGraphInputField' }
        & Pick<DefaultQuestionGraphInputField, 'description' | 'hasDescription' | 'isDeprecated' | 'isOptional' | 'isStaff' | 'order' | 'placeholder' | 'questionText' | 'type' | 'version' | 'uuid'>
        & { options?: Maybe<Array<Maybe<(
          { __typename?: 'DefaultQuestionOptionWithTags' }
          & Pick<DefaultQuestionOptionWithTags, 'isChecked' | 'text' | 'value' | 'dataSource' | 'additionalText' | 'requiredAditionalTextBox'>
          & { tags?: Maybe<Array<(
            { __typename?: 'FormBuilderTag' }
            & Pick<FormBuilderTag, 'label' | 'uuid'>
          )>> }
        )>>>, userResponse?: Maybe<(
          { __typename?: 'DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags' }
          & Pick<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags, 'textValue' | 'aditionalResponse'>
          & { value?: Maybe<Array<(
            { __typename?: 'SelectedMultiChoiceCheckboxInputFieldOptionWithTags' }
            & Pick<SelectedMultiChoiceCheckboxInputFieldOptionWithTags, 'aditionalText' | 'id'>
            & { tags?: Maybe<Array<Maybe<(
              { __typename?: 'FormBuilderTag' }
              & Pick<FormBuilderTag, 'uuid' | 'label'>
            )>>> }
          )>> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetRelatedCareersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  lang?: InputMaybe<Scalars['String']>;
  career_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetRelatedCareersQuery = (
  { __typename?: 'Query' }
  & { getCareerRecommendations?: Maybe<(
    { __typename?: 'CareerRecommender_CareerRecommenderOutput' }
    & Pick<CareerRecommender_CareerRecommenderOutput, 'count' | 'total'>
    & { _embedded?: Maybe<(
      { __typename?: 'CareerRecommender_EmbeddedCareers' }
      & { careers?: Maybe<Array<Maybe<(
        { __typename?: 'CareerRecommender_Career' }
        & Pick<CareerRecommender_Career, 'id' | 'label' | 'description' | 'demand'>
        & { salary?: Maybe<(
          { __typename?: 'CareerRecommender_MonetaryValue' }
          & Pick<CareerRecommender_MonetaryValue, 'currency' | 'value'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetRelatedTenantsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRelatedTenantsQuery = (
  { __typename?: 'Query' }
  & { getRelatedTenants?: Maybe<Array<Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'name' | 'depth'>
  )>>> }
);

export type GetResourceQueryVariables = Exact<{
  resourceId: Scalars['String'];
  locale: Scalars['String'];
}>;


export type GetResourceQuery = (
  { __typename?: 'Query' }
  & { getResource: (
    { __typename?: 'Resource' }
    & Pick<Resource, 'uuid' | 'pref_label' | 'description' | 'url' | 'credits_accepted' | 'subscription_required' | 'lang_label' | 'country' | 'support_needs' | 'career_interest'>
    & { provider?: Maybe<(
      { __typename?: 'ResourceProvider' }
      & Pick<ResourceProvider, 'uuid' | 'pref_label' | 'description'>
    )>, cost?: Maybe<(
      { __typename?: 'Cost' }
      & Pick<Cost, 'value' | 'currency'>
    )>, age_range?: Maybe<(
      { __typename?: 'AgeRange' }
      & Pick<AgeRange, 'min_age' | 'max_age'>
    )> }
  ) }
);

export type GetResourceRecommendationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['PositiveInt']>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  lang?: InputMaybe<Scalars['String']>;
  support_needs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  communities?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  career_interest?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  age?: InputMaybe<Scalars['Int']>;
}>;


export type GetResourceRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getResourceRecommendations?: Maybe<(
    { __typename?: 'ResourceRecommender_ResourceRecommenderOutput' }
    & Pick<ResourceRecommender_ResourceRecommenderOutput, 'count' | 'total'>
    & { _embedded?: Maybe<(
      { __typename?: 'ResourceRecommender_EmbeddedResources' }
      & { resources?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceRecommender_Resource' }
        & Pick<ResourceRecommender_Resource, 'id' | 'label' | 'country' | 'description' | 'url' | 'credits_accepted' | 'subscription_required' | 'career_interest' | 'support_needs' | 'community'>
        & { provider?: Maybe<(
          { __typename?: 'ResourceRecommender_Resource_provider' }
          & Pick<ResourceRecommender_Resource_Provider, 'id' | 'label'>
        )>, cost?: Maybe<(
          { __typename?: 'ResourceRecommender_Resource_cost' }
          & Pick<ResourceRecommender_Resource_Cost, 'currency' | 'value'>
        )> }
      )>>> }
    )> }
  )> }
);

export type GetResourcesQueryVariables = Exact<{
  getResourcesInput: GetResourcesInput;
}>;


export type GetResourcesQuery = (
  { __typename?: 'Query' }
  & { getResources: (
    { __typename?: 'GetResourcesOutput' }
    & Pick<GetResourcesOutput, 'count' | 'allResourcesIds'>
    & { resources: Array<(
      { __typename?: 'Resources' }
      & Pick<Resources, 'description' | 'resource_id' | 'resource_name' | 'source' | 'source_id' | 'tenant_id'>
    )> }
  ) }
);

export type GetRoadmapQueryVariables = Exact<{
  getRoadmapInput: GetRoadmapInput;
}>;


export type GetRoadmapQuery = (
  { __typename?: 'Query' }
  & { getRoadmap: (
    { __typename?: 'Roadmap' }
    & Pick<Roadmap, 'roadmap_uuid' | 'tenant_uuid'>
    & { draft_next_steps: Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )>, published_next_steps: Array<(
      { __typename?: 'NextStepsMetadata' }
      & Pick<NextStepsMetadata, 'source' | 'next_step_type' | 'category' | 'goals' | 'is_completable' | 'next_step_description' | 'next_step_title' | 'next_step_uuid' | 'requireUpload' | 'type'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'uri' | 'type'>
      )> }
    )> }
  ) }
);

export type GetSavedCareersQueryVariables = Exact<{
  userUuid: Scalars['String'];
}>;


export type GetSavedCareersQuery = (
  { __typename?: 'Query' }
  & { getSavedCareersStatus?: Maybe<Array<Maybe<(
    { __typename?: 'SaveCareerStatusOutput' }
    & Pick<SaveCareerStatusOutput, 'careerUuid' | 'isRemoved'>
  )>>> }
);

export type GetSavedJobsByUserUuidQueryVariables = Exact<{
  userUuid: Scalars['String'];
}>;


export type GetSavedJobsByUserUuidQuery = (
  { __typename?: 'Query' }
  & { savedJobsByUserUUID: Array<(
    { __typename: 'JobService_SavedJob' }
    & Pick<JobService_SavedJob, 'company' | 'created_at' | 'description' | 'location' | 'notes' | 'title' | 'uuid' | 'url'>
    & { stage_history: Array<(
      { __typename?: 'JobService_Stage' }
      & Pick<JobService_Stage, 'created_at' | 'stage'>
    )>, job: (
      { __typename?: 'JobService_Job' }
      & Pick<JobService_Job, 'uuid' | 'source' | 'source_type' | 'ingested_at'>
    ) }
  )> }
);

export type GetSavedLearningQueryVariables = Exact<{
  userUuid: Scalars['String'];
}>;


export type GetSavedLearningQuery = (
  { __typename?: 'Query' }
  & { getSavedLearningsStatus?: Maybe<Array<Maybe<(
    { __typename?: 'SaveLearningStatusOutput' }
    & Pick<SaveLearningStatusOutput, 'learningUuid' | 'isRemoved'>
  )>>> }
);

export type GetSavedResourceQueryVariables = Exact<{
  userUuid: Scalars['String'];
}>;


export type GetSavedResourceQuery = (
  { __typename?: 'Query' }
  & { getSavedResourcesStatus?: Maybe<Array<Maybe<(
    { __typename?: 'SaveResourceStatusOutput' }
    & Pick<SaveResourceStatusOutput, 'resourceUuid' | 'isRemoved'>
  )>>> }
);

export type GetTagsQueryVariables = Exact<{
  getTagsInput: GetTagsInput;
}>;


export type GetTagsQuery = (
  { __typename?: 'Query' }
  & { getTags: (
    { __typename?: 'GetTagsResponse' }
    & Pick<GetTagsResponse, 'count' | 'next'>
    & { items: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'created_at' | 'created_by' | 'created_by_full_name' | 'is_visible_to_recruiters' | 'user_count' | 'label' | 'status' | 'tag_uuid' | 'tenant_uuid' | 'updated_at' | 'updated_by'>
    )> }
  ) }
);

export type GetTargetCareerQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
}>;


export type GetTargetCareerQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid' | 'target_career' | 'target_career_label' | 'target_skills'>
  ) }
);

export type GetTenantQueryVariables = Exact<{
  tenantCode: Scalars['String'];
}>;


export type GetTenantQuery = (
  { __typename?: 'Query' }
  & { getTenant?: Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'name' | 'logo_url' | 'favicon' | 'page_title' | 'code' | 'configuration_id' | 'sign_up_description' | 'sign_up_link' | 'sign_up_image_url' | 'mobile_sign_up_image_url'>
  )> }
);

export type GetTenantAssetsQueryVariables = Exact<{
  getTenantAssetsInput: GetTenantAssetsInput;
}>;


export type GetTenantAssetsQuery = (
  { __typename?: 'Query' }
  & { getTenantAssets: (
    { __typename?: 'TenantAssetOutput' }
    & Pick<TenantAssetOutput, 'cookie_policy' | 'privacy_policy' | 'terms_of_service'>
  ) }
);

export type GetTenantConfigurationQueryVariables = Exact<{
  configuration_id: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
}>;


export type GetTenantConfigurationQuery = (
  { __typename?: 'Query' }
  & { getTenantConfiguration: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'configuration_id' | 'tenant_id' | 'featured_job_providers' | 'link' | 'show_all_candidates' | 'job_language_restriction' | 'job_employer_restriction' | 'date_posted_range' | 'default_policy_language' | 'default_coach_visibility' | 'supported_career_area_ids' | 'show_course_recs_for_jobs'>
    & { job_sources?: Maybe<(
      { __typename?: 'JobSourceConfiguration' }
      & Pick<JobSourceConfiguration, 'include' | 'exclude'>
    )>, overlayTitle?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, overlayDescription?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, banner?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, marketingConsent?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'fr_CA' | 'es_ES' | 'pt_PT'>
    )>, location_restrictions?: Maybe<Array<(
      { __typename?: 'CountryDivisions' }
      & Pick<CountryDivisions, 'id' | 'country_code' | 'country_label' | 'division_code' | 'division_label'>
    )>>, insight_configuration?: Maybe<Array<(
      { __typename?: 'InsightConfiguration' }
      & Pick<InsightConfiguration, 'label' | 'dashboard_id'>
    )>>, show_candidate_tag_rule?: Maybe<(
      { __typename?: 'TagRule' }
      & Pick<TagRule, 'operator'>
      & { groups: Array<(
        { __typename?: 'TagRuleGroup' }
        & Pick<TagRuleGroup, 'operator' | 'items' | 'is_negated'>
      )> }
    )> }
  ) }
);

export type GetTenantConfigurationByTenantIdQueryVariables = Exact<{
  tenant_id: Scalars['String'];
}>;


export type GetTenantConfigurationByTenantIdQuery = (
  { __typename?: 'Query' }
  & { getTenantConfigurationByTenantId: (
    { __typename?: 'Configuration' }
    & Pick<Configuration, 'configuration_id' | 'date_posted_range' | 'default_policy_language' | 'default_coach_visibility' | 'featured_job_providers' | 'job_employer_restriction' | 'job_language_restriction' | 'label' | 'link' | 'rest_client_id' | 'rest_client_ids' | 'show_all_candidates' | 'supported_career_area_ids' | 'tenant_id' | 'show_course_recs_for_jobs'>
    & { assets?: Maybe<(
      { __typename?: 'AssetMetadata' }
      & { cookie_policy: (
        { __typename?: 'TranslationField' }
        & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
      ), privacy_policy: (
        { __typename?: 'TranslationField' }
        & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
      ), terms_of_service: (
        { __typename?: 'TranslationField' }
        & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
      ) }
    )>, banner?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
    )>, careers?: Maybe<(
      { __typename?: 'FilterOptions' }
      & Pick<FilterOptions, 'exclude' | 'include'>
    )>, course_providers?: Maybe<(
      { __typename?: 'FilterOptions' }
      & Pick<FilterOptions, 'exclude' | 'include'>
    )>, deep_case_management?: Maybe<(
      { __typename?: 'DeepCaseManagement' }
      & { gov_client_configuration?: Maybe<(
        { __typename?: 'GovClientConfiguration' }
        & Pick<GovClientConfiguration, 'api_key' | 'base_url' | 'config_type' | 'env' | 'organization_id'>
      )> }
    )>, insight_configuration?: Maybe<Array<(
      { __typename?: 'InsightConfiguration' }
      & Pick<InsightConfiguration, 'dashboard_id' | 'label'>
    )>>, job_sources?: Maybe<(
      { __typename?: 'JobSourceConfiguration' }
      & Pick<JobSourceConfiguration, 'exclude' | 'include'>
    )>, location_restrictions?: Maybe<Array<(
      { __typename?: 'CountryDivisions' }
      & Pick<CountryDivisions, 'country_code' | 'country_label' | 'division_code' | 'division_label' | 'id'>
    )>>, marketingConsent?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
    )>, overlayDescription?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
    )>, overlayTitle?: Maybe<(
      { __typename?: 'TranslationField' }
      & Pick<TranslationField, 'en_US' | 'es_ES' | 'fr_CA' | 'pt_PT'>
    )>, resources?: Maybe<(
      { __typename?: 'FilterOptions' }
      & Pick<FilterOptions, 'exclude' | 'include'>
    )>, show_candidate_tag_rule?: Maybe<(
      { __typename?: 'TagRule' }
      & Pick<TagRule, 'operator'>
      & { groups: Array<(
        { __typename?: 'TagRuleGroup' }
        & Pick<TagRuleGroup, 'is_negated' | 'items' | 'operator'>
      )> }
    )> }
  ) }
);

export type GetTenantFeaturesQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetTenantFeaturesQuery = (
  { __typename?: 'Query' }
  & { getTenantFeatures: Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'feature_uuid' | 'slug' | 'label'>
  )> }
);

export type GetTenantLeavesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTenantLeavesQuery = (
  { __typename?: 'Query' }
  & { getTenantLeaves?: Maybe<Array<Maybe<(
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'code' | 'configuration_id' | 'depth' | 'id' | 'legacy' | 'logo_url' | 'name' | 'parent_id' | 'description_label'>
  )>>> }
);

export type GetTenantPolicyAssetsQueryVariables = Exact<{
  getTenantPolicyAssetsInput: GetTenantPolicyAssetsInput;
}>;


export type GetTenantPolicyAssetsQuery = (
  { __typename?: 'Query' }
  & { getTenantPolicyAssets: Array<(
    { __typename?: 'AssetsDto' }
    & Pick<AssetsDto, 'asset_id' | 'asset_type' | 'language' | 'tenant_id' | 'updated_at' | 'url' | 'uploaded_by'>
  )> }
);

export type GetUserCaseNotesQueryVariables = Exact<{
  getUserCaseNotesInput: GetUserCaseNotesInput;
}>;


export type GetUserCaseNotesQuery = (
  { __typename?: 'Query' }
  & { getUserCaseNotes: (
    { __typename: 'GetUserCaseNotesResponse' }
    & Pick<GetUserCaseNotesResponse, 'count' | 'next'>
    & { items: Array<(
      { __typename: 'CaseNote' }
      & Pick<CaseNote, 'id' | 'note' | 'created_at'>
      & { created_by: (
        { __typename: 'CaseNoteEditor' }
        & Pick<CaseNoteEditor, 'user_uuid' | 'user_name'>
      ), modified_history: Array<(
        { __typename?: 'CaseNoteModify' }
        & Pick<CaseNoteModify, 'modified_at'>
        & { modified_by: (
          { __typename: 'CaseNoteEditor' }
          & Pick<CaseNoteEditor, 'user_uuid' | 'user_name'>
        ) }
      )> }
    )> }
  ) }
);

export type GetUserFeaturesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFeaturesQuery = (
  { __typename?: 'Query' }
  & { getUserFeatures: Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'feature_uuid' | 'label' | 'slug'>
  )> }
);

export type GetUserNextStepsQueryVariables = Exact<{
  user_uuid: Scalars['String'];
  searchUserNextStepInput: SearchUserNextStepInput;
}>;


export type GetUserNextStepsQuery = (
  { __typename?: 'Query' }
  & { getUserNextSteps: (
    { __typename?: 'UserNextStepsWrapper' }
    & Pick<UserNextStepsWrapper, 'number_of_completed_steps' | 'total_number_of_steps'>
    & { next_steps: Array<(
      { __typename?: 'UserNextSteps' }
      & Pick<UserNextSteps, 'category' | 'requireUpload' | 'created_at' | 'description' | 'goals' | 'is_completable' | 'next_step_uuid' | 'coach_uuid' | 'order' | 'assigned' | 'status' | 'title' | 'source' | 'type' | 'updated_at' | 'user_uuid'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'type' | 'uri'>
      )>, integrationContext?: Maybe<Array<(
        { __typename?: 'UserNextStepIntegration' }
        & Pick<UserNextStepIntegration, 'integration_type' | 'external_id' | 'external_friendly_name' | 'ffai_resource_id'>
      )>> }
    )> }
  ) }
);

export type GetUserProfileQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
}>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid' | 'anonymized_user_uuid' | 'communities' | 'first_name' | 'gender' | 'goal' | 'date_of_birth' | 'previously_contacted' | 'employer_id' | 'industries_of_interest' | 'postal_code' | 'is_onboarded' | 'last_name' | 'support_needs' | 'support_needs_custom' | 'target_career' | 'target_career_label' | 'target_skills' | 'year_of_birth' | 'location' | 'country' | 'email' | 'phone_number' | 'pref_lang' | 'profileStep' | 'tenants' | 'email_consent' | 'created_at' | 'recruiter_outreach'>
    & { genderObject?: Maybe<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource' | 'additionalText'>
    )>, ethnicities?: Maybe<Array<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource' | 'additionalText'>
    )>>, education?: Maybe<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource' | 'additionalText'>
    )>, communitiesObject?: Maybe<Array<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource' | 'additionalText'>
    )>>, employment_status?: Maybe<Array<(
      { __typename?: 'DataSourceType' }
      & Pick<DataSourceType, 'label' | 'dataSource' | 'additionalText'>
    )>>, interest_profile?: Maybe<(
      { __typename?: 'InterestProfile' }
      & Pick<InterestProfile, 'artistic' | 'enterprising' | 'conventional' | 'social' | 'investigative' | 'realistic'>
    )>, skills?: Maybe<Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'is_inferred' | 'id' | 'label' | 'proficiency' | 'skilltype' | 'skill_type' | 'skill_label' | 'skill_uuid' | 'is_custom'>
    )>>, tags?: Maybe<Array<(
      { __typename?: 'UserTag' }
      & Pick<UserTag, 'category' | 'label' | 'tag_uuid'>
    )>>, work_experiences?: Maybe<Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'career_uuid' | 'confidence_score' | 'company' | 'completion_date' | 'starting_date' | 'experience_level' | 'experience_type' | 'is_current' | 'job_title' | 'work_exp_uuid' | 'irrelevant_skill_uuids'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedWorkExpSkills' }
        & Pick<LlmGeneratedWorkExpSkills, 'classified_skill_uuid' | 'llm_generated_label'>
      )>>, career: (
        { __typename?: 'Career' }
        & Pick<Career, 'id'>
        & { baseline_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>> }
      ) }
    )>>, education_experiences?: Maybe<Array<(
      { __typename?: 'EducationExperience' }
      & Pick<EducationExperience, 'major' | 'institution_name' | 'degree_type' | 'status' | 'starting_year' | 'completion_year' | 'is_current' | 'education_exp_uuid'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedSkill' }
        & Pick<LlmGeneratedSkill, 'classified_skill_uuid' | 'llm_generated_label'>
      )>> }
    )>>, certifications?: Maybe<Array<(
      { __typename?: 'Certification' }
      & Pick<Certification, 'certification_uuid' | 'name' | 'provider' | 'issue_date'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedSkill' }
        & Pick<LlmGeneratedSkill, 'classified_skill_uuid' | 'llm_generated_label'>
      )>> }
    )>>, career_area_interests: Array<(
      { __typename?: 'CareerArea' }
      & Pick<CareerArea, 'id' | 'label'>
    )>, services?: Maybe<Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'service_uuid' | 'title' | 'provider' | 'stage' | 'start_date' | 'end_date'>
    )>>, outcomes?: Maybe<(
      { __typename?: 'Outcomes' }
      & Pick<Outcomes, 'disengaged' | 'other'>
      & { educated?: Maybe<(
        { __typename?: 'EducationOutcome' }
        & Pick<EducationOutcome, 'degree_type' | 'institution_name' | 'program_name'>
      )>, employed?: Maybe<(
        { __typename?: 'EmployedOutcome' }
        & Pick<EmployedOutcome, 'company' | 'job_title' | 'salary' | 'salary_type' | 'salary_currency_code' | 'employment_type'>
      )>, referred?: Maybe<(
        { __typename?: 'ReferralOutcome' }
        & Pick<ReferralOutcome, 'provider' | 'service_name'>
      )> }
    )>, job_applications?: Maybe<Array<(
      { __typename?: 'JobApplication' }
      & Pick<JobApplication, 'job_id' | 'applied_at'>
    )>>, coach_assigned?: Maybe<(
      { __typename?: 'CoachAssigned' }
      & Pick<CoachAssigned, 'coach_uuid' | 'updated_at'>
      & { coach_data?: Maybe<Array<(
        { __typename?: 'CoachData' }
        & Pick<CoachData, 'first_name' | 'last_name'>
      )>> }
    )>, account_preferences?: Maybe<(
      { __typename?: 'AccountPreferences' }
      & { notifications?: Maybe<Array<(
        { __typename?: 'Notification' }
        & Pick<Notification, 'topic' | 'frequency' | 'type'>
      )>>, marketing_consent_history?: Maybe<Array<(
        { __typename?: 'MarketingConsentHistory' }
        & Pick<MarketingConsentHistory, 'is_consented' | 'created_at'>
      )>> }
    )> }
  ) }
);

export type GetUserProfilesQueryVariables = Exact<{
  searchInput: FindProfilesSearchInput;
}>;


export type GetUserProfilesQuery = (
  { __typename?: 'Query' }
  & { getUserProfiles: (
    { __typename?: 'ProfilesQueryWrapper' }
    & Pick<ProfilesQueryWrapper, 'total_results' | 'total_pages'>
    & { profiles: Array<(
      { __typename?: 'Profile' }
      & Pick<Profile, 'user_uuid' | 'postal_code' | 'employer_id' | 'instance_name' | 'first_name' | 'last_name' | 'email' | 'user_tenant_role' | 'location' | 'created_at' | 'tenants'>
      & { coach_assigned?: Maybe<(
        { __typename?: 'CoachAssigned' }
        & Pick<CoachAssigned, 'coach_uuid' | 'updated_at'>
        & { coach_data?: Maybe<Array<(
          { __typename?: 'CoachData' }
          & Pick<CoachData, 'first_name' | 'last_name'>
        )>> }
      )>, tags?: Maybe<Array<(
        { __typename?: 'UserTag' }
        & Pick<UserTag, 'tag_uuid' | 'label' | 'category'>
      )>> }
    )> }
  ) }
);

export type GetUserRoadmapQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
  searchUserNextStepInput: SearchUserNextStepInput;
}>;


export type GetUserRoadmapQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid' | 'first_name'>
  ), getUserNextSteps: (
    { __typename?: 'UserNextStepsWrapper' }
    & Pick<UserNextStepsWrapper, 'number_of_completed_steps' | 'total_number_of_steps'>
    & { next_steps: Array<(
      { __typename?: 'UserNextSteps' }
      & Pick<UserNextSteps, 'category' | 'created_at' | 'requireUpload' | 'description' | 'goals' | 'is_completable' | 'next_step_uuid' | 'coach_uuid' | 'order' | 'status' | 'title' | 'type' | 'source' | 'assigned' | 'updated_at' | 'user_uuid'>
      & { link?: Maybe<(
        { __typename?: 'Link' }
        & Pick<Link, 'target' | 'type' | 'uri'>
      )> }
    )> }
  ) }
);

export type GetUserRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRoleQuery = (
  { __typename?: 'Query' }
  & { getUserRole: (
    { __typename?: 'Role' }
    & Pick<Role, 'label'>
  ) }
);

export type GetUsersSubmissionPdfQueryVariables = Exact<{
  formUuid: Scalars['String'];
  userUuid: Scalars['String'];
}>;


export type GetUsersSubmissionPdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUsersSubmissionPDF'>
);

export type GetVervoeAssessmentsQueryVariables = Exact<{
  tenant_id: Scalars['String'];
}>;


export type GetVervoeAssessmentsQuery = (
  { __typename?: 'Query' }
  & { getVervoeAssessments: Array<(
    { __typename?: 'ExternalAssessment' }
    & Pick<ExternalAssessment, 'externalId' | 'name' | 'integrationType'>
  )> }
);

export type GetWorkExperienceQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
}>;


export type GetWorkExperienceQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
    & { work_experiences?: Maybe<Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'career_uuid'>
    )>> }
  ) }
);

export type GetWorkExperienceAndSkillsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
}>;


export type GetWorkExperienceAndSkillsQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'Profile' }
    & Pick<Profile, 'user_uuid'>
    & { skills?: Maybe<Array<(
      { __typename?: 'UserSkill' }
      & Pick<UserSkill, 'is_inferred' | 'id' | 'label' | 'proficiency' | 'skilltype' | 'skill_type' | 'skill_label' | 'skill_uuid' | 'is_custom'>
    )>>, work_experiences?: Maybe<Array<(
      { __typename?: 'WorkExperience' }
      & Pick<WorkExperience, 'career_uuid' | 'confidence_score' | 'company' | 'starting_date' | 'completion_date' | 'experience_level' | 'experience_type' | 'is_current' | 'from_resume' | 'job_title' | 'work_exp_uuid' | 'irrelevant_skill_uuids'>
      & { llm_generated_skills?: Maybe<Array<(
        { __typename?: 'LlmGeneratedWorkExpSkills' }
        & Pick<LlmGeneratedWorkExpSkills, 'classified_skill_uuid' | 'llm_generated_label'>
      )>>, career: (
        { __typename?: 'Career' }
        & Pick<Career, 'id'>
        & { baseline_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>>, specialized_skills?: Maybe<Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'label' | 'skilltype' | 'skill_label' | 'skill_type' | 'skill_uuid'>
        )>> }
      ) }
    )>> }
  ) }
);

export type HasIntegrationOfTypeQueryVariables = Exact<{
  hasIntegrationOfTypeInput: FindIntegrationByTypeInput;
}>;


export type HasIntegrationOfTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasIntegrationOfType'>
);

export type SearchJobsV2QueryVariables = Exact<{
  searchInput: JobService_JobSearchInput;
}>;


export type SearchJobsV2Query = (
  { __typename?: 'Query' }
  & { searchJobsV2: (
    { __typename?: 'JobService_JobSearchV2ResponseWrapper' }
    & Pick<JobService_JobSearchV2ResponseWrapper, 'total_results' | 'total_pages'>
    & { jobs: Array<(
      { __typename: 'JobService_JobDto' }
      & Pick<JobService_JobDto, 'job_id' | 'job_title' | 'location' | 'country' | 'company' | 'created_at' | 'active' | 'archived' | 'description' | 'application_url' | 'application_email' | 'job_type' | 'posting_source' | 'department' | 'seniority' | 'expiry_date' | 'education_level' | 'work_experience_level' | 'classified_job_type' | 'classified_seniority_type' | 'remote_type'>
    )> }
  ) }
);

export type TriggerReportingEtlQueryVariables = Exact<{
  triggerReportingEtlInput: TriggerReportingEtlInput;
}>;


export type TriggerReportingEtlQuery = (
  { __typename?: 'Query' }
  & { triggerReportingEtl: (
    { __typename?: 'TriggerReportingEtlResponse' }
    & Pick<TriggerReportingEtlResponse, 'statusCode' | 'body'>
  ) }
);

export const ContactIdsFragmentDoc = gql`
    fragment ContactIds on Contact {
  contact_uuid
  employer_uuid
  contacted_by_uuid
  job_id
}
    `;
export const AllContactInfoFragmentDoc = gql`
    fragment AllContactInfo on Contact {
  ...ContactIds
  contacted_by_name
  employer_name
  job_title
  created_at
}
    `;
export const TranslationFieldsFragmentDoc = gql`
    fragment TranslationFields on TranslatedField {
  en_US
  fr_CA
  es_ES
  pt_PT
}
    `;
export const TranslationCurrencyInputFieldsFragmentDoc = gql`
    fragment TranslationCurrencyInputFields on CurrencyInputField {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationDateInputFieldsFragmentDoc = gql`
    fragment TranslationDateInputFields on DateInputField {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationDropdownInputFieldsFragmentDoc = gql`
    fragment TranslationDropdownInputFields on DropdownInputField {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationDropdownInputFieldsWithTagsFragmentDoc = gql`
    fragment TranslationDropdownInputFieldsWithTags on DropdownInputFieldWithTags {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationFixedTextFieldsFragmentDoc = gql`
    fragment TranslationFixedTextFields on FixedText {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationBaseInputFieldsFragmentDoc = gql`
    fragment TranslationBaseInputFields on FixedText {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationMultiChoiceCheckboxInputFieldsFragmentDoc = gql`
    fragment TranslationMultiChoiceCheckboxInputFields on MultiChoiceCheckboxInputField {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationMultiChoiceCheckboxInputFieldsWithTagsFragmentDoc = gql`
    fragment TranslationMultiChoiceCheckboxInputFieldsWithTags on MultiChoiceCheckboxInputFieldWithTags {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationTextInputFieldsFragmentDoc = gql`
    fragment TranslationTextInputFields on TextInputField {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const TranslationDefaultQuestionGraphInputFieldsFragmentDoc = gql`
    fragment TranslationDefaultQuestionGraphInputFields on DefaultQuestionGraphInputField {
  questionTextI18n {
    ...TranslationFields
  }
  descriptionI18n {
    ...TranslationFields
  }
  placeholderI18n {
    ...TranslationFields
  }
}
    `;
export const ExportProfileAuthorizeDocument = gql`
    mutation ExportProfileAuthorize($exportProfileAuthorizeInput: ExportProfileAuthorizeInput!) {
  exportProfileAuthorize(
    exportProfileAuthorizeInput: $exportProfileAuthorizeInput
  ) {
    body
    statusCode
    export_report_uuid
  }
}
    `;

export function useExportProfileAuthorizeMutation() {
  return Urql.useMutation<ExportProfileAuthorizeMutation, ExportProfileAuthorizeMutationVariables>(ExportProfileAuthorizeDocument);
};
export const AddContactDocument = gql`
    mutation AddContact($user_uuid: String, $contacted_by_uuid: String!, $employer_uuid: String, $job_id: String!) {
  addContact(
    addContactInput: {user_uuid: $user_uuid, contacted_by_uuid: $contacted_by_uuid, employer_uuid: $employer_uuid, job_id: $job_id}
  ) {
    ...AllContactInfo
  }
}
    ${AllContactInfoFragmentDoc}
${ContactIdsFragmentDoc}`;

export function useAddContactMutation() {
  return Urql.useMutation<AddContactMutation, AddContactMutationVariables>(AddContactDocument);
};
export const CreateEmployerCaseNoteDocument = gql`
    mutation CreateEmployerCaseNote($employer_uuid: String!, $description: String!, $created_by: String!) {
  createEmployerCaseNote(
    input: {employer_uuid: $employer_uuid, created_by: $created_by, description: $description}
  ) {
    id
    employer_uuid
    modified_by
    created_at
    description
    updated_at
  }
}
    `;

export function useCreateEmployerCaseNoteMutation() {
  return Urql.useMutation<CreateEmployerCaseNoteMutation, CreateEmployerCaseNoteMutationVariables>(CreateEmployerCaseNoteDocument);
};
export const AddTenantAssetDocument = gql`
    mutation addTenantAsset($addTenantAssetInput: AddTenantAssetInput!) {
  addTenantAsset(addTenantAssetInput: $addTenantAssetInput) {
    url
    asset_id
    asset_type
    language
    tenant_id
    updated_at
    uploaded_by
  }
}
    `;

export function useAddTenantAssetMutation() {
  return Urql.useMutation<AddTenantAssetMutation, AddTenantAssetMutationVariables>(AddTenantAssetDocument);
};
export const AddToNextStepsLibraryDocument = gql`
    mutation AddToNextStepsLibrary($addNextStepToLibraryInput: LibraryInput!) {
  addNextStepToLibrary(addNextStepToLibraryInput: $addNextStepToLibraryInput) {
    library_uuid
    tenant_uuid
    type
    updated_at
    updated_by
    next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
  }
}
    `;

export function useAddToNextStepsLibraryMutation() {
  return Urql.useMutation<AddToNextStepsLibraryMutation, AddToNextStepsLibraryMutationVariables>(AddToNextStepsLibraryDocument);
};
export const AddUserCaseNoteDocument = gql`
    mutation AddUserCaseNote($createUserCaseNoteInput: CreateUserCaseNoteInput!) {
  addUserCaseNote(createUserCaseNoteInput: $createUserCaseNoteInput) {
    id
    created_at
    created_by {
      user_uuid
      user_name
      __typename
    }
    note
    modified_history {
      modified_at
      modified_by {
        user_uuid
        user_name
        __typename
      }
    }
    __typename
  }
}
    `;

export function useAddUserCaseNoteMutation() {
  return Urql.useMutation<AddUserCaseNoteMutation, AddUserCaseNoteMutationVariables>(AddUserCaseNoteDocument);
};
export const AddUserSkillsDocument = gql`
    mutation AddUserSkills($addUserSkillsInput: AddUserSkillsInput!) {
  addUserSkills(addUserSkillsInput: $addUserSkillsInput) {
    modifiedSkills {
      id
      is_inferred
      label
      proficiency
      skilltype
      is_custom
    }
  }
}
    `;

export function useAddUserSkillsMutation() {
  return Urql.useMutation<AddUserSkillsMutation, AddUserSkillsMutationVariables>(AddUserSkillsDocument);
};
export const ArchiveAllJobsDocument = gql`
    mutation ArchiveAllJobs {
  archiveAllJobs {
    numArchived
  }
}
    `;

export function useArchiveAllJobsMutation() {
  return Urql.useMutation<ArchiveAllJobsMutation, ArchiveAllJobsMutationVariables>(ArchiveAllJobsDocument);
};
export const ArchiveFormDocument = gql`
    mutation ArchiveForm($formUuid: String!, $formVersionUuid: String!) {
  archiveForm(formUuid: $formUuid, formVersionUuid: $formVersionUuid)
}
    `;

export function useArchiveFormMutation() {
  return Urql.useMutation<ArchiveFormMutation, ArchiveFormMutationVariables>(ArchiveFormDocument);
};
export const ArchiveManualJobDocument = gql`
    mutation ArchiveManualJob($archiveManualJobInput: JobService_ArchiveManualJobInput!) {
  archiveManualJob(archiveManualJobInput: $archiveManualJobInput) {
    job {
      active
      archived
      job_id
      job_title
      created_at
      expiry_date
    }
    jobDto {
      active
      archived
      job_id
      job_title
      created_at
      expiry_date
    }
    message
  }
}
    `;

export function useArchiveManualJobMutation() {
  return Urql.useMutation<ArchiveManualJobMutation, ArchiveManualJobMutationVariables>(ArchiveManualJobDocument);
};
export const AssignCoachDocument = gql`
    mutation AssignCoach($assignCoachInput: AssignCoachInput!) {
  assignCoach(assignCoachInput: $assignCoachInput) {
    user_uuid
  }
}
    `;

export function useAssignCoachMutation() {
  return Urql.useMutation<AssignCoachMutation, AssignCoachMutationVariables>(AssignCoachDocument);
};
export const AssignGroupCoachesDocument = gql`
    mutation AssignGroupCoaches($input: AssignGroupCoachesInput_Input!) {
  assignGroupCoaches(input: $input) {
    successful {
      coach_uuid
    }
    failed {
      coach_uuid
      error
    }
  }
}
    `;

export function useAssignGroupCoachesMutation() {
  return Urql.useMutation<AssignGroupCoachesMutation, AssignGroupCoachesMutationVariables>(AssignGroupCoachesDocument);
};
export const BuildUserResumeDocument = gql`
    mutation BuildUserResume {
  buildUserResume {
    base64File
  }
}
    `;

export function useBuildUserResumeMutation() {
  return Urql.useMutation<BuildUserResumeMutation, BuildUserResumeMutationVariables>(BuildUserResumeDocument);
};
export const BulkUpdateProfilesDocument = gql`
    mutation BulkUpdateProfiles($input: BulkProfileUpdateInput!) {
  bulkUpdateProfiles(bulkUpdateInput: $input) {
    successful
    failed {
      user_uuid
      error
    }
  }
}
    `;

export function useBulkUpdateProfilesMutation() {
  return Urql.useMutation<BulkUpdateProfilesMutation, BulkUpdateProfilesMutationVariables>(BulkUpdateProfilesDocument);
};
export const ClearCandidateCacheDocument = gql`
    mutation ClearCandidateCache {
  clearCandidateCache
}
    `;

export function useClearCandidateCacheMutation() {
  return Urql.useMutation<ClearCandidateCacheMutation, ClearCandidateCacheMutationVariables>(ClearCandidateCacheDocument);
};
export const ConfirmNextStepDocDocument = gql`
    mutation ConfirmNextStepDoc($confirmNextStepDocInput: ConfirmNextStepDocInput!) {
  confirmNextStepDoc(confirmNextStepDocInput: $confirmNextStepDocInput) {
    statusCode
    finalDocs {
      s3Key
      name
    }
  }
}
    `;

export function useConfirmNextStepDocMutation() {
  return Urql.useMutation<ConfirmNextStepDocMutation, ConfirmNextStepDocMutationVariables>(ConfirmNextStepDocDocument);
};
export const CreateCustomerObjectUrlDocument = gql`
    mutation createCustomerObjectUrl($input: PreSignedURLForCustomerInput!) {
  createCustomerObjectUrl(input: $input) {
    body
    url
    fields
  }
}
    `;

export function useCreateCustomerObjectUrlMutation() {
  return Urql.useMutation<CreateCustomerObjectUrlMutation, CreateCustomerObjectUrlMutationVariables>(CreateCustomerObjectUrlDocument);
};
export const CreateDefaultQuestionsDocument = gql`
    mutation CreateDefaultQuestions($tenant_uuid: String!, $input: CreateNewDefaultQuestionsBodyRequest_Input!) {
  createDefaultQuestions(tenant_uuid: $tenant_uuid, input: $input)
}
    `;

export function useCreateDefaultQuestionsMutation() {
  return Urql.useMutation<CreateDefaultQuestionsMutation, CreateDefaultQuestionsMutationVariables>(CreateDefaultQuestionsDocument);
};
export const CreateEmployerDocument = gql`
    mutation CreateEmployer($name: String, $tenant_uuid: String, $recruiters: [RecruiterInput_Input], $invitation_email_locale: String) {
  createEmployer(
    input: {name: $name, tenant_uuid: $tenant_uuid, recruiters: $recruiters, invitation_email_locale: $invitation_email_locale}
  ) {
    created_at
    employer_uuid
    name
    recruiters {
      recruiter_uuid
      user {
        created_at
        email
        first_name
        last_name
        user_uuid
        is_registered
        invitation {
          invitation_link
          invitation_uuid
        }
      }
    }
  }
}
    `;

export function useCreateEmployerMutation() {
  return Urql.useMutation<CreateEmployerMutation, CreateEmployerMutationVariables>(CreateEmployerDocument);
};
export const CreateFormDocument = gql`
    mutation CreateForm($input: CreateFormInput!) {
  createForm(input: $input) {
    form_id
    form_version_id
  }
}
    `;

export function useCreateFormMutation() {
  return Urql.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument);
};
export const CreateFormVersionDocument = gql`
    mutation CreateFormVersion($input: CreateFormVersionInput!) {
  createFormVersion(input: $input) {
    form_id
    form_version_id
  }
}
    `;

export function useCreateFormVersionMutation() {
  return Urql.useMutation<CreateFormVersionMutation, CreateFormVersionMutationVariables>(CreateFormVersionDocument);
};
export const CreateGroupDocument = gql`
    mutation CreateGroup($input: CreateGroupInput_Input!) {
  createGroup(input: $input) {
    employer_uuid
    name
    created_at
    coaches {
      coach_uuid
      group_uuid
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
}
    `;

export function useCreateGroupMutation() {
  return Urql.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument);
};
export const CreateInvitationDocument = gql`
    mutation CreateInvitation($email: String!, $role: String, $invitation_email_locale: String) {
  createInvitation(
    input: {email: $email, role: $role, invitation_email_locale: $invitation_email_locale}
  ) {
    invitation_uuid
    email
  }
}
    `;

export function useCreateInvitationMutation() {
  return Urql.useMutation<CreateInvitationMutation, CreateInvitationMutationVariables>(CreateInvitationDocument);
};
export const CreateInviteLinkDocument = gql`
    mutation createInviteLink($newInviteLinkInput: NewInviteLink_Input) {
  createInviteLink(input: $newInviteLinkInput) {
    code
    created_at
    id
    is_active
    name
    role
    tag_id
    tenant_id
    type
    updated_at
    user_count
    email_domains
    employer_id
  }
}
    `;

export function useCreateInviteLinkMutation() {
  return Urql.useMutation<CreateInviteLinkMutation, CreateInviteLinkMutationVariables>(CreateInviteLinkDocument);
};
export const CreateManualJobDocument = gql`
    mutation createManualJob($createManualPostJobInput: JobService_CreateManualJobInput!) {
  createManualPostJob(createManualPostJobInput: $createManualPostJobInput) {
    job {
      job_id
      job_title
      location
      country
      provider
      company
      created_at
      active
      archived
      description
      application_url
      application_email
      job_type
      employer_id
      posting_source
      department
      seniority
      salary_range {
        max
        min
        currency
        pay_period
      }
      expiry_date
      education_level
      work_experience_level
      skills {
        skill_uuid
        skilltype
        pref_label
        __typename
      }
      additional_skills {
        skill_uuid
        custom_label
        skilltype
        classified_skill_existed
        __typename
      }
      career {
        career_uuid
        baseline_skills {
          skill_uuid
          pref_label
          __typename
        }
        specialized_skills {
          skill_uuid
          pref_label
          __typename
        }
        __typename
      }
      posting_source
      __typename
    }
    jobDto {
      job_id
      job_title
      location
      country
      provider
      company
      created_at
      active
      archived
      description
      application_url
      application_email
      job_type
      employer_id
      posting_source
      department
      seniority
      salary_range {
        max
        min
        currency
        pay_period
      }
      expiry_date
      education_level
      work_experience_level
      classified_job_type
      classified_seniority_type
      remote_type
      skills_objects {
        skill_uuid
        skilltype
        pref_label
        __typename
      }
      additional_skills {
        skill_uuid
        custom_label
        skilltype
        classified_skill_existed
        __typename
      }
      career_object {
        career_uuid
        baseline_skills {
          skill_uuid
          pref_label
          __typename
        }
        specialized_skills {
          skill_uuid
          pref_label
          __typename
        }
        __typename
      }
      posting_source
      __typename
    }
    message
  }
}
    `;

export function useCreateManualJobMutation() {
  return Urql.useMutation<CreateManualJobMutation, CreateManualJobMutationVariables>(CreateManualJobDocument);
};
export const CreateMergeLinkTokenDocument = gql`
    mutation CreateMergeLinkToken($create_link: JobService_CreateLink!) {
  createMergeLinkToken(create_link: $create_link)
}
    `;

export function useCreateMergeLinkTokenMutation() {
  return Urql.useMutation<CreateMergeLinkTokenMutation, CreateMergeLinkTokenMutationVariables>(CreateMergeLinkTokenDocument);
};
export const CreateNextStepsMetadataDocument = gql`
    mutation createNextStepsMetadata($createNextStepsMetadataInput: CreateNextStepsMetadataInput!) {
  createNextStepsMetadata(
    createNextStepsMetadataInput: $createNextStepsMetadataInput
  ) {
    category
    goals
    is_completable
    next_step_title
    next_step_description
    link {
      target
      type
      uri
    }
    next_step_uuid
    tenant_id
    type
  }
}
    `;

export function useCreateNextStepsMetadataMutation() {
  return Urql.useMutation<CreateNextStepsMetadataMutation, CreateNextStepsMetadataMutationVariables>(CreateNextStepsMetadataDocument);
};
export const CreatePostObjectUrlDocument = gql`
    mutation createPostObjectURL($input: PreSignedURLInput!) {
  createPostObjectURL(input: $input) {
    url
    fields
  }
}
    `;

export function useCreatePostObjectUrlMutation() {
  return Urql.useMutation<CreatePostObjectUrlMutation, CreatePostObjectUrlMutationVariables>(CreatePostObjectUrlDocument);
};
export const CreateRecruiterDocument = gql`
    mutation CreateRecruiter($createRecruiterInput: RecruiterCreateRequestBody_Input!) {
  createRecruiter(input: $createRecruiterInput) {
    employer_uuid
    recruiter_uuid
    user {
      email
      created_at
      first_name
      last_name
      user_uuid
      invitation {
        invitation_uuid
        invitation_link
      }
    }
  }
}
    `;

export function useCreateRecruiterMutation() {
  return Urql.useMutation<CreateRecruiterMutation, CreateRecruiterMutationVariables>(CreateRecruiterDocument);
};
export const CreateRegistrationFormDocument = gql`
    mutation CreateRegistrationForm($tenantUuid: String!, $createRegistrationForm: RegistrationFormInput_Input) {
  createRegistrationForm(tenantUuid: $tenantUuid, input: $createRegistrationForm) {
    tenant_uuid
  }
}
    `;

export function useCreateRegistrationFormMutation() {
  return Urql.useMutation<CreateRegistrationFormMutation, CreateRegistrationFormMutationVariables>(CreateRegistrationFormDocument);
};
export const CreateReportExportDocument = gql`
    mutation CreateReportExport($createReportExport: CreateReportExportInput!) {
  createReportExport(createReportExportInput: $createReportExport)
}
    `;

export function useCreateReportExportMutation() {
  return Urql.useMutation<CreateReportExportMutation, CreateReportExportMutationVariables>(CreateReportExportDocument);
};
export const CreateUserTagsDocument = gql`
    mutation CreateUserTags($createUserTagsInput: CreateUserTagsInput!) {
  createUserTags(createUserTagsInput: $createUserTagsInput) {
    category
    created_at
    label
    tag_uuid
    user_tag_uuid
    user_uuid
  }
}
    `;

export function useCreateUserTagsMutation() {
  return Urql.useMutation<CreateUserTagsMutation, CreateUserTagsMutationVariables>(CreateUserTagsDocument);
};
export const CreateUserTagsForTenantDocument = gql`
    mutation CreateUserTagsForTenant($tags: [TaggingUserTagInput]) {
  createUserTagsForTenant(tags: $tags) {
    user_tag_uuid
  }
}
    `;

export function useCreateUserTagsForTenantMutation() {
  return Urql.useMutation<CreateUserTagsForTenantMutation, CreateUserTagsForTenantMutationVariables>(CreateUserTagsForTenantDocument);
};
export const DelNextStepDocDocument = gql`
    mutation DelNextStepDoc($delNextStepDocInput: DelNextStepDocInput!) {
  delNextStepDoc(delNextStepDocInput: $delNextStepDocInput) {
    statusCode
    msg
    docDeleted
  }
}
    `;

export function useDelNextStepDocMutation() {
  return Urql.useMutation<DelNextStepDocMutation, DelNextStepDocMutationVariables>(DelNextStepDocDocument);
};
export const DeleteContactsFromUserDocument = gql`
    mutation DeleteContactsFromUser($contactUuids: [String!], $userUuid: String!) {
  deleteContactsFromUser(contact_uuids: $contactUuids, user_uuid: $userUuid) {
    ...ContactIds
  }
}
    ${ContactIdsFragmentDoc}`;

export function useDeleteContactsFromUserMutation() {
  return Urql.useMutation<DeleteContactsFromUserMutation, DeleteContactsFromUserMutationVariables>(DeleteContactsFromUserDocument);
};
export const DeleteEmployerCaseNoteDocument = gql`
    mutation DeleteEmployerCaseNote($id: String!) {
  deleteEmployerCaseNote(id: $id) {
    id
  }
}
    `;

export function useDeleteEmployerCaseNoteMutation() {
  return Urql.useMutation<DeleteEmployerCaseNoteMutation, DeleteEmployerCaseNoteMutationVariables>(DeleteEmployerCaseNoteDocument);
};
export const DeleteEmployerDocument = gql`
    mutation DeleteEmployer($deleteEmployerInput: DeleteEmployersRequestBody_Input!) {
  deleteEmployer(input: $deleteEmployerInput)
}
    `;

export function useDeleteEmployerMutation() {
  return Urql.useMutation<DeleteEmployerMutation, DeleteEmployerMutationVariables>(DeleteEmployerDocument);
};
export const DeletePolicyAssetsDocument = gql`
    mutation deletePolicyAssets($deleteTenantPolicyAssetInput: DeleteTenantPolicyAssetInput!) {
  deletePolicyAssets(deleteTenantPolicyAssetInput: $deleteTenantPolicyAssetInput) {
    assets {
      privacy_policy {
        en_US
        es_ES
        fr_CA
        pt_PT
      }
      terms_of_service {
        en_US
        es_ES
        fr_CA
        pt_PT
      }
    }
    default_policy_language
  }
}
    `;

export function useDeletePolicyAssetsMutation() {
  return Urql.useMutation<DeletePolicyAssetsMutation, DeletePolicyAssetsMutationVariables>(DeletePolicyAssetsDocument);
};
export const DeleteRecruitersDocument = gql`
    mutation DeleteRecruiters($deleteRecruitersBodyInput: DeleteRecruitersRequestBody_Input!) {
  deleteRecruiters(input: $deleteRecruitersBodyInput) {
    employer_uuid
    recruiter_uuid
  }
}
    `;

export function useDeleteRecruitersMutation() {
  return Urql.useMutation<DeleteRecruitersMutation, DeleteRecruitersMutationVariables>(DeleteRecruitersDocument);
};
export const DeleteSavedJobDocument = gql`
    mutation deleteSavedJob($uuid: String!) {
  deleteSavedJob(uuid: $uuid) {
    company
    created_at
    description
    location
    notes
    stage_history {
      created_at
      stage
    }
    job {
      uuid
    }
    title
    uuid
  }
}
    `;

export function useDeleteSavedJobMutation() {
  return Urql.useMutation<DeleteSavedJobMutation, DeleteSavedJobMutationVariables>(DeleteSavedJobDocument);
};
export const DeleteTenantFeaturesDocument = gql`
    mutation DeleteTenantFeatures($tenantId: String!) {
  deleteTenantFeatures(tenantId: $tenantId)
}
    `;

export function useDeleteTenantFeaturesMutation() {
  return Urql.useMutation<DeleteTenantFeaturesMutation, DeleteTenantFeaturesMutationVariables>(DeleteTenantFeaturesDocument);
};
export const DeleteUserCaseNoteDocument = gql`
    mutation DeleteUserCaseNote($deleteUserCaseNoteInput: DeleteUserCaseNoteInput!) {
  deleteUserCaseNote(deleteUserCaseNoteInput: $deleteUserCaseNoteInput)
}
    `;

export function useDeleteUserCaseNoteMutation() {
  return Urql.useMutation<DeleteUserCaseNoteMutation, DeleteUserCaseNoteMutationVariables>(DeleteUserCaseNoteDocument);
};
export const DeleteUserNextStepDocument = gql`
    mutation DeleteUserNextStep($deleteUserNextStep: DeleteUserStepInput!) {
  deleteUserNextStep(deleteUserStepInput: $deleteUserNextStep) {
    deletedCount
  }
}
    `;

export function useDeleteUserNextStepMutation() {
  return Urql.useMutation<DeleteUserNextStepMutation, DeleteUserNextStepMutationVariables>(DeleteUserNextStepDocument);
};
export const DeleteUsersDocument = gql`
    mutation DeleteUsers($deleteUsersInput: DeleteUsersInput!) {
  deleteUsers(delete_users_input: $deleteUsersInput)
}
    `;

export function useDeleteUsersMutation() {
  return Urql.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument);
};
export const DisableTenantFeaturesDocument = gql`
    mutation DisableTenantFeatures($tenantFeatures: TenantFeature!) {
  disableTenantFeatures(tenant_features: $tenantFeatures)
}
    `;

export function useDisableTenantFeaturesMutation() {
  return Urql.useMutation<DisableTenantFeaturesMutation, DisableTenantFeaturesMutationVariables>(DisableTenantFeaturesDocument);
};
export const EasyApplyToAtsJobDocument = gql`
    mutation easyApplyToATSJob($easyApplyToATSJobInput: JobService_EasyApplyToATSJobInput!) {
  easyApplyToATSJob(easyApplyToATSJobInput: $easyApplyToATSJobInput) {
    job_id
    applied_at
    application_id
  }
}
    `;

export function useEasyApplyToAtsJobMutation() {
  return Urql.useMutation<EasyApplyToAtsJobMutation, EasyApplyToAtsJobMutationVariables>(EasyApplyToAtsJobDocument);
};
export const EnableTenantFeaturesDocument = gql`
    mutation EnableTenantFeatures($tenantFeatures: TenantFeature!) {
  enableTenantFeatures(tenant_features: $tenantFeatures) {
    feature_uuid
    label
    slug
  }
}
    `;

export function useEnableTenantFeaturesMutation() {
  return Urql.useMutation<EnableTenantFeaturesMutation, EnableTenantFeaturesMutationVariables>(EnableTenantFeaturesDocument);
};
export const ExchangeMergeAccountTokenDocument = gql`
    mutation ExchangeMergeAccountToken($exchange: JobService_ExchangeAccountToken!) {
  exchangeMergeAccountToken(exchange: $exchange)
}
    `;

export function useExchangeMergeAccountTokenMutation() {
  return Urql.useMutation<ExchangeMergeAccountTokenMutation, ExchangeMergeAccountTokenMutationVariables>(ExchangeMergeAccountTokenDocument);
};
export const ExportProfileDownloadCsvFileDocument = gql`
    mutation ExportProfileDownloadCsvFile($export_report_uuid: String!) {
  exportProfileDownloadCsvFile(
    createExportProfileInput: {export_report_uuid: $export_report_uuid}
  ) {
    statusCode
    url
  }
}
    `;

export function useExportProfileDownloadCsvFileMutation() {
  return Urql.useMutation<ExportProfileDownloadCsvFileMutation, ExportProfileDownloadCsvFileMutationVariables>(ExportProfileDownloadCsvFileDocument);
};
export const GetSkillsFromCareerDocument = gql`
    mutation getSkillsFromCareer($career_id: String!, $pref_lang: String) {
  getSkillsFromCareer(
    getSkillsInput: {career_id: $career_id, pref_lang: $pref_lang}
  ) {
    message
    skills {
      description
      pref_label
      skilltype
      skill_uuid
      __typename
    }
  }
}
    `;

export function useGetSkillsFromCareerMutation() {
  return Urql.useMutation<GetSkillsFromCareerMutation, GetSkillsFromCareerMutationVariables>(GetSkillsFromCareerDocument);
};
export const InviteToVervoeAssessmentDocument = gql`
    mutation InviteToVervoeAssessment($inviteToVervoeAssessmentInput: VervoeInviteToAssessmentRequest!) {
  inviteToVervoeAssessment(
    inviteToVervoeAssessmentInput: $inviteToVervoeAssessmentInput
  ) {
    candidateUrl
    candidateAssessmentUuid
  }
}
    `;

export function useInviteToVervoeAssessmentMutation() {
  return Urql.useMutation<InviteToVervoeAssessmentMutation, InviteToVervoeAssessmentMutationVariables>(InviteToVervoeAssessmentDocument);
};
export const LeadSignUpDocument = gql`
    mutation LeadSignUp($profile_info: LeadSignUpInput!) {
  leadSignUp(profile_info: $profile_info)
}
    `;

export function useLeadSignUpMutation() {
  return Urql.useMutation<LeadSignUpMutation, LeadSignUpMutationVariables>(LeadSignUpDocument);
};
export const MoveUsersDocument = gql`
    mutation MoveUsers($moveUsersInput: MoveUsersInput!) {
  moveUsers(move_users_input: $moveUsersInput)
}
    `;

export function useMoveUsersMutation() {
  return Urql.useMutation<MoveUsersMutation, MoveUsersMutationVariables>(MoveUsersDocument);
};
export const PublishRoadmapDocument = gql`
    mutation PublishRoadmap($publishRoadmapInput: PublishRoadmapInput!) {
  publishRoadmap(publishRoadmapInput: $publishRoadmapInput) {
    roadmap_uuid
    tenant_uuid
    draft_next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
    published_next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
  }
}
    `;

export function usePublishRoadmapMutation() {
  return Urql.useMutation<PublishRoadmapMutation, PublishRoadmapMutationVariables>(PublishRoadmapDocument);
};
export const RemoveUserSkillsDocument = gql`
    mutation RemoveUserSkills($removeUserSkillsInput: RemoveUserSkillsInput!) {
  removeUserSkills(removeUserSkillsInput: $removeUserSkillsInput) {
    modifiedSkills {
      id
      label
      proficiency
      skilltype
      is_inferred
    }
  }
}
    `;

export function useRemoveUserSkillsMutation() {
  return Urql.useMutation<RemoveUserSkillsMutation, RemoveUserSkillsMutationVariables>(RemoveUserSkillsDocument);
};
export const ResetUserNextStepsDocument = gql`
    mutation ResetUserNextSteps($user_uuid: String!, $searchUserNextStepInput: SearchUserNextStepInput!) {
  resetProfile(user_uuid: $user_uuid) {
    user_uuid
  }
  resetUserNextSteps(user_uuid: $user_uuid) {
    number_of_completed_steps
    total_number_of_steps
    next_steps(searchUserNextStepInput: $searchUserNextStepInput) {
      title
      status
      next_step_uuid
    }
  }
}
    `;

export function useResetUserNextStepsMutation() {
  return Urql.useMutation<ResetUserNextStepsMutation, ResetUserNextStepsMutationVariables>(ResetUserNextStepsDocument);
};
export const SaveCareerDocument = gql`
    mutation SaveCareer($saveCareerInput: SaveCareerInput_Input!) {
  saveCareer(input: $saveCareerInput) {
    careerUuid
  }
}
    `;

export function useSaveCareerMutation() {
  return Urql.useMutation<SaveCareerMutation, SaveCareerMutationVariables>(SaveCareerDocument);
};
export const SaveFormSubmissionDocument = gql`
    mutation SaveFormSubmission($userUuid: String!, $saveSubmissionInput: saveSubmission_request_Input!) {
  saveSubmission(userUuid: $userUuid, input: $saveSubmissionInput) {
    submission_id
  }
}
    `;

export function useSaveFormSubmissionMutation() {
  return Urql.useMutation<SaveFormSubmissionMutation, SaveFormSubmissionMutationVariables>(SaveFormSubmissionDocument);
};
export const SaveJobDocument = gql`
    mutation SaveJob($save: JobService_SaveJobInput!) {
  saveJob(save: $save) {
    company
    created_at
    description
    location
    notes
    stage_history {
      created_at
      stage
    }
    job {
      uuid
      source
      source_type
      ingested_at
    }
    title
    uuid
  }
}
    `;

export function useSaveJobMutation() {
  return Urql.useMutation<SaveJobMutation, SaveJobMutationVariables>(SaveJobDocument);
};
export const SaveLearningDocument = gql`
    mutation SaveLearning($saveLearningInput: SaveLearningInput_Input!) {
  saveLearning(input: $saveLearningInput) {
    learningUuid
  }
}
    `;

export function useSaveLearningMutation() {
  return Urql.useMutation<SaveLearningMutation, SaveLearningMutationVariables>(SaveLearningDocument);
};
export const SaveResourceDocument = gql`
    mutation SaveResource($saveResourceInput: SaveResourceInput_Input!) {
  saveResource(input: $saveResourceInput) {
    resourceUuid
  }
}
    `;

export function useSaveResourceMutation() {
  return Urql.useMutation<SaveResourceMutation, SaveResourceMutationVariables>(SaveResourceDocument);
};
export const SaveTalentsDocument = gql`
    mutation SaveTalents($user_uuid: String!, $savedTalentsInput: CreateSavedTalentsRequestBody_Input!) {
  saveTalents(user_uuid: $user_uuid, input: $savedTalentsInput) {
    user_uuid
    talent_uuid
    created_at
    job_uuid
    __typename
  }
}
    `;

export function useSaveTalentsMutation() {
  return Urql.useMutation<SaveTalentsMutation, SaveTalentsMutationVariables>(SaveTalentsDocument);
};
export const ToggleTagVisibilityDocument = gql`
    mutation ToggleTagVisibility($tag_uuid: String!) {
  toggleTagVisibility(tag_uuid: $tag_uuid) {
    tag_uuid
    is_visible_to_recruiters
    label
  }
}
    `;

export function useToggleTagVisibilityMutation() {
  return Urql.useMutation<ToggleTagVisibilityMutation, ToggleTagVisibilityMutationVariables>(ToggleTagVisibilityDocument);
};
export const UnassignCoachDocument = gql`
    mutation UnassignCoach($unassignCoachInput: UnassignCoachInput!) {
  unassignCoach(unassignCoachInput: $unassignCoachInput) {
    user_uuid
  }
}
    `;

export function useUnassignCoachMutation() {
  return Urql.useMutation<UnassignCoachMutation, UnassignCoachMutationVariables>(UnassignCoachDocument);
};
export const UnassignGroupCoachesDocument = gql`
    mutation UnassignGroupCoaches($input: UnassignGroupCoachesInput_Input!) {
  unassignGroupCoaches(input: $input) {
    successful {
      coach_uuid
    }
    failed {
      coach_uuid
      error
    }
  }
}
    `;

export function useUnassignGroupCoachesMutation() {
  return Urql.useMutation<UnassignGroupCoachesMutation, UnassignGroupCoachesMutationVariables>(UnassignGroupCoachesDocument);
};
export const UnsaveCareerDocument = gql`
    mutation UnsaveCareer($unsaveCareerInput: UnsaveCareerInput_Input!) {
  unsaveCareer(input: $unsaveCareerInput) {
    careerUuid
  }
}
    `;

export function useUnsaveCareerMutation() {
  return Urql.useMutation<UnsaveCareerMutation, UnsaveCareerMutationVariables>(UnsaveCareerDocument);
};
export const UnsaveLearningDocument = gql`
    mutation UnsaveLearning($unsaveLearningInput: UnsaveLearningInput_Input!) {
  unsaveLearning(input: $unsaveLearningInput) {
    learningUuid
  }
}
    `;

export function useUnsaveLearningMutation() {
  return Urql.useMutation<UnsaveLearningMutation, UnsaveLearningMutationVariables>(UnsaveLearningDocument);
};
export const UnsaveResourceDocument = gql`
    mutation UnsaveResource($unsaveResourceInput: UnsaveResourceInput_Input!) {
  unsaveResource(input: $unsaveResourceInput) {
    resourceUuid
  }
}
    `;

export function useUnsaveResourceMutation() {
  return Urql.useMutation<UnsaveResourceMutation, UnsaveResourceMutationVariables>(UnsaveResourceDocument);
};
export const UnsaveTalentsDocument = gql`
    mutation UnsaveTalents($user_uuid: String!, $unsavedTalentsInput: UnsaveTalentsRequestBody_Input!) {
  unsaveTalents(user_uuid: $user_uuid, input: $unsavedTalentsInput) {
    user_uuid
    talent_uuid
  }
}
    `;

export function useUnsaveTalentsMutation() {
  return Urql.useMutation<UnsaveTalentsMutation, UnsaveTalentsMutationVariables>(UnsaveTalentsDocument);
};
export const UpdateAccountPreferencesDocument = gql`
    mutation UpdateAccountPreferences($updateAccountPreferencesInput: UpdateAccountPreferencesInput!) {
  updateAccountPreferences(
    updateAccountPreferencesInput: $updateAccountPreferencesInput
  ) {
    user_uuid
    account_preferences {
      notifications {
        topic
        frequency
        type
      }
      marketing_consent_history {
        is_consented
        created_at
      }
    }
  }
}
    `;

export function useUpdateAccountPreferencesMutation() {
  return Urql.useMutation<UpdateAccountPreferencesMutation, UpdateAccountPreferencesMutationVariables>(UpdateAccountPreferencesDocument);
};
export const UpdateConfigurationDocument = gql`
    mutation UpdateConfiguration($updateConfigurationInput: UpdateConfigurationInput!) {
  updateConfiguration(updateConfigurationInput: $updateConfigurationInput)
}
    `;

export function useUpdateConfigurationMutation() {
  return Urql.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument);
};
export const UpdateTenantConfigurationDocument = gql`
    mutation UpdateTenantConfiguration($input: UpdateConfigurationInputV2!) {
  updateConfigurationV2(updateConfigurationInput: $input) {
    tenant_id
    configuration_id
    link
    show_all_candidates
    tenant_id
    job_sources {
      include
      exclude
    }
    location_restrictions {
      country_code
      division_code
      country_label
    }
    featured_job_providers
    job_language_restriction
    job_employer_restriction
    insight_configuration {
      label
      dashboard_id
    }
    date_posted_range
    default_policy_language
    default_coach_visibility
    show_candidate_tag_rule {
      operator
      groups {
        operator
        items
        is_negated
      }
    }
    show_course_recs_for_jobs
  }
}
    `;

export function useUpdateTenantConfigurationMutation() {
  return Urql.useMutation<UpdateTenantConfigurationMutation, UpdateTenantConfigurationMutationVariables>(UpdateTenantConfigurationDocument);
};
export const UpdateDefaultQuestionsDocument = gql`
    mutation UpdateDefaultQuestions($tenant_uuid: String!, $input: UpdateDefaultQuestionsBodyRequest_Input) {
  updateDefaultQuestions(tenant_uuid: $tenant_uuid, input: $input)
}
    `;

export function useUpdateDefaultQuestionsMutation() {
  return Urql.useMutation<UpdateDefaultQuestionsMutation, UpdateDefaultQuestionsMutationVariables>(UpdateDefaultQuestionsDocument);
};
export const UpdateDraftRoadmapDocument = gql`
    mutation UpdateDraftRoadmap($updateDraftRoadmapInput: UpdateDraftRoadmapInput!) {
  updateDraftRoadmap(updateDraftRoadmapInput: $updateDraftRoadmapInput) {
    roadmap_uuid
    tenant_uuid
    draft_next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
    published_next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
  }
}
    `;

export function useUpdateDraftRoadmapMutation() {
  return Urql.useMutation<UpdateDraftRoadmapMutation, UpdateDraftRoadmapMutationVariables>(UpdateDraftRoadmapDocument);
};
export const UpdateEducationExperienceDocument = gql`
    mutation UpdateEducationExperience($updateProfileInput: UpdateProfileInput!) {
  updateUserProfile(updateProfileInput: $updateProfileInput) {
    user_uuid
    education_experiences {
      major
      institution_name
      degree_type
      status
      starting_year
      completion_year
      is_current
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
    }
  }
}
    `;

export function useUpdateEducationExperienceMutation() {
  return Urql.useMutation<UpdateEducationExperienceMutation, UpdateEducationExperienceMutationVariables>(UpdateEducationExperienceDocument);
};
export const UpdateEmployerDocument = gql`
    mutation UpdateEmployer($id: String!, $updateEmployerInput: UpdateEmployerInput_Input!) {
  updateEmployer(id: $id, input: $updateEmployerInput) {
    created_at
    employer_uuid
    name
    recruiters {
      employer_uuid
      recruiter_uuid
    }
  }
}
    `;

export function useUpdateEmployerMutation() {
  return Urql.useMutation<UpdateEmployerMutation, UpdateEmployerMutationVariables>(UpdateEmployerDocument);
};
export const UpdateEmployerCaseNoteDocument = gql`
    mutation UpdateEmployerCaseNote($id: String!, $description: String!, $modified_by: String!, $employer_uuid: String!) {
  updateEmployerCaseNote(
    id: $id
    input: {description: $description, employer_uuid: $employer_uuid, modified_by: $modified_by}
  ) {
    id
    employer_uuid
    description
    created_at
  }
}
    `;

export function useUpdateEmployerCaseNoteMutation() {
  return Urql.useMutation<UpdateEmployerCaseNoteMutation, UpdateEmployerCaseNoteMutationVariables>(UpdateEmployerCaseNoteDocument);
};
export const UpdateFormSubmissionDocument = gql`
    mutation UpdateFormSubmission($userUuid: String!, $submission_id: String!, $saveSubmissionInput: updateSubmission_request_Input!) {
  updateSubmission(
    userUuid: $userUuid
    submission_id: $submission_id
    input: $saveSubmissionInput
  ) {
    submission_id
  }
}
    `;

export function useUpdateFormSubmissionMutation() {
  return Urql.useMutation<UpdateFormSubmissionMutation, UpdateFormSubmissionMutationVariables>(UpdateFormSubmissionDocument);
};
export const UpdateFormVersionDocument = gql`
    mutation UpdateFormVersion($input: UpdateFormVersionInput!) {
  updateFormVersion(input: $input) {
    form_version_id
    form_id
  }
}
    `;

export function useUpdateFormVersionMutation() {
  return Urql.useMutation<UpdateFormVersionMutation, UpdateFormVersionMutationVariables>(UpdateFormVersionDocument);
};
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($id: String!, $updateGroupInput: UpdateGroupInput_Input!) {
  updateGroup(id: $id, input: $updateGroupInput) {
    created_at
    employer_uuid
    name
    coaches {
      coach_uuid
      group_uuid
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
}
    `;

export function useUpdateGroupMutation() {
  return Urql.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument);
};
export const UpdateInviteLinkDocument = gql`
    mutation updateInviteLink($updateInviteLinkInput: UpdateInviteLink_Input) {
  updateInviteLink(input: $updateInviteLinkInput) {
    created_at
    id
    is_active
    name
    role
    tag_id
    tenant_id
    type
    updated_at
    user_count
    email_domains
    employer_id
  }
}
    `;

export function useUpdateInviteLinkMutation() {
  return Urql.useMutation<UpdateInviteLinkMutation, UpdateInviteLinkMutationVariables>(UpdateInviteLinkDocument);
};
export const UpdateJobConfigurationDocument = gql`
    mutation updateJobConfiguration($input: UpdateJobConfigurationInput!) {
  updateJobConfiguration(updateJobConfigurationInput: $input) {
    job_sources {
      exclude
      include
    }
    featured_job_providers
  }
}
    `;

export function useUpdateJobConfigurationMutation() {
  return Urql.useMutation<UpdateJobConfigurationMutation, UpdateJobConfigurationMutationVariables>(UpdateJobConfigurationDocument);
};
export const UpdateJobSearchHistoryDocument = gql`
    mutation UpdateJobSearchHistory($user_uuid: String!, $searched_job: SearchedJob!) {
  updateJobSearchHistory(user_uuid: $user_uuid, searched_job: $searched_job)
}
    `;

export function useUpdateJobSearchHistoryMutation() {
  return Urql.useMutation<UpdateJobSearchHistoryMutation, UpdateJobSearchHistoryMutationVariables>(UpdateJobSearchHistoryDocument);
};
export const UpdateLearningProviderLogoDocument = gql`
    mutation updateLearningProviderLogo($updateLearningProviderLogoInput: UpdateLearningProviderLogoInput!) {
  updateLearningProviderLogo(
    updateLearningProviderLogoInput: $updateLearningProviderLogoInput
  ) {
    logo_url
  }
}
    `;

export function useUpdateLearningProviderLogoMutation() {
  return Urql.useMutation<UpdateLearningProviderLogoMutation, UpdateLearningProviderLogoMutationVariables>(UpdateLearningProviderLogoDocument);
};
export const UpdateManualJobDocument = gql`
    mutation updateManualJob($updateManualPostJobInput: JobService_UpdateManualJobInput!) {
  updateManualJob(updateManualJobInput: $updateManualPostJobInput) {
    job {
      job_id
      job_title
      location
      country
      provider
      company
      created_at
      active
      archived
      description
      application_url
      application_email
      job_type
      employer_id
      posting_source
      department
      seniority
      salary_range {
        max
        min
        currency
        pay_period
      }
      expiry_date
      education_level
      work_experience_level
      classified_job_type
      classified_seniority_type
      remote_type
      skills {
        skill_uuid
        skilltype
        pref_label
        __typename
      }
      additional_skills {
        skill_uuid
        custom_label
        skilltype
        classified_skill_existed
        __typename
      }
      career {
        career_uuid
        baseline_skills {
          skill_uuid
          pref_label
          __typename
        }
        specialized_skills {
          skill_uuid
          pref_label
          __typename
        }
        __typename
      }
      posting_source
      __typename
    }
    jobDto {
      job_id
      job_title
      location
      country
      provider
      company
      created_at
      active
      archived
      description
      application_url
      application_email
      job_type
      employer_id
      posting_source
      department
      seniority
      salary_range {
        max
        min
        currency
        pay_period
      }
      expiry_date
      education_level
      work_experience_level
      skills_objects {
        skill_uuid
        skilltype
        pref_label
        __typename
      }
      additional_skills {
        skill_uuid
        custom_label
        skilltype
        classified_skill_existed
        __typename
      }
      career_object {
        career_uuid
        baseline_skills {
          skill_uuid
          pref_label
          __typename
        }
        specialized_skills {
          skill_uuid
          pref_label
          __typename
        }
        __typename
      }
      posting_source
      __typename
    }
    message
  }
}
    `;

export function useUpdateManualJobMutation() {
  return Urql.useMutation<UpdateManualJobMutation, UpdateManualJobMutationVariables>(UpdateManualJobDocument);
};
export const UpdateNextStepsMetadataDocument = gql`
    mutation updateNextStepsMetadata($updateNextStepsMetadataInput: UpdateNextStepsMetadataInput!) {
  updateNextStepsMetadata(
    updateNextStepsMetadataInput: $updateNextStepsMetadataInput
  ) {
    category
    goals
    is_completable
    next_step_title
    next_step_description
    link {
      target
      type
      uri
    }
    next_step_uuid
    tenant_id
    type
  }
}
    `;

export function useUpdateNextStepsMetadataMutation() {
  return Urql.useMutation<UpdateNextStepsMetadataMutation, UpdateNextStepsMetadataMutationVariables>(UpdateNextStepsMetadataDocument);
};
export const UpdateNextStepsLibraryDocument = gql`
    mutation UpdateNextStepsLibrary($updateLibraryInput: LibraryInput!) {
  updateLibrary(updateLibraryInput: $updateLibraryInput) {
    library_uuid
    tenant_uuid
    type
    updated_at
    updated_by
    next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
  }
}
    `;

export function useUpdateNextStepsLibraryMutation() {
  return Urql.useMutation<UpdateNextStepsLibraryMutation, UpdateNextStepsLibraryMutationVariables>(UpdateNextStepsLibraryDocument);
};
export const UpdateOnboardingFormActivationStatusDocument = gql`
    mutation updateOnboardingFormActivationStatus($tenant_id: String!, $input: updateOnboardingFormActivationStatus_request_Input) {
  updateOnboardingFormActivationStatus(tenantUuid: $tenant_id, input: $input)
}
    `;

export function useUpdateOnboardingFormActivationStatusMutation() {
  return Urql.useMutation<UpdateOnboardingFormActivationStatusMutation, UpdateOnboardingFormActivationStatusMutationVariables>(UpdateOnboardingFormActivationStatusDocument);
};
export const UpdateRecruiterDocument = gql`
    mutation UpdateRecruiter($id: String!, $updateRecruiterBody: UpdateRecruiterRequestBody_Input!) {
  updateRecruiter(id: $id, input: $updateRecruiterBody) {
    employer_uuid
    recruiter_uuid
    user {
      created_at
      email
      first_name
      last_name
      user_uuid
    }
  }
}
    `;

export function useUpdateRecruiterMutation() {
  return Urql.useMutation<UpdateRecruiterMutation, UpdateRecruiterMutationVariables>(UpdateRecruiterDocument);
};
export const UpdateRegistrationConfigurationDocument = gql`
    mutation updateRegistrationConfiguration($updateRegistrationConfigurationInput: UpdateRegistrationConfigurationInput!) {
  updateRegistrationConfiguration(
    updateRegistrationConfigurationInput: $updateRegistrationConfigurationInput
  ) {
    tenant_id
    link
    overlayTitle {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
    overlayDescription {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
    banner {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
    marketingConsent {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
  }
}
    `;

export function useUpdateRegistrationConfigurationMutation() {
  return Urql.useMutation<UpdateRegistrationConfigurationMutation, UpdateRegistrationConfigurationMutationVariables>(UpdateRegistrationConfigurationDocument);
};
export const UpdateRegistrationFormDocument = gql`
    mutation UpdateRegistrationForm($tenantUuid: String!, $updateRegistrationForm: RegistrationFormInput_Input) {
  updateRegistrationForm(tenantUuid: $tenantUuid, input: $updateRegistrationForm) {
    created_at
    email_active
    first_name_active
    form_id
    is_active
    last_name_active
    location_active
    phone_number_active
    tenant_uuid
    updated_at
    zip_code_active
    components {
      dropdownInputFieldWithTags {
        isDeprecated
        isOptional
        isStaff
        options {
          tags {
            label
            uuid
          }
          text
          value
        }
        questionText
        type
        uuid
        version
      }
      multiChoiceCheckboxInputFieldWithTags {
        isDeprecated
        isOptional
        isStaff
        options {
          label
          tags {
            label
            uuid
          }
        }
        questionText
        type
        uuid
        version
      }
      defaultQuestionField {
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
        }
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
        version
        uuid
      }
    }
  }
}
    `;

export function useUpdateRegistrationFormMutation() {
  return Urql.useMutation<UpdateRegistrationFormMutation, UpdateRegistrationFormMutationVariables>(UpdateRegistrationFormDocument);
};
export const UpdateSkillsDocument = gql`
    mutation UpdateSkills($updateProfileInput: UpdateProfileInput!) {
  updateUserProfile(updateProfileInput: $updateProfileInput) {
    user_uuid
    skills {
      is_inferred
      id
      label
      skilltype
      skill_type
      skill_label
      skill_uuid
      is_custom
    }
  }
}
    `;

export function useUpdateSkillsMutation() {
  return Urql.useMutation<UpdateSkillsMutation, UpdateSkillsMutationVariables>(UpdateSkillsDocument);
};
export const UpdateTargetCareerDocument = gql`
    mutation UpdateTargetCareer($updateProfileInput: UpdateProfileInput!) {
  updateUserProfile(updateProfileInput: $updateProfileInput) {
    user_uuid
    target_career
    target_career_label
    target_skills
  }
}
    `;

export function useUpdateTargetCareerMutation() {
  return Urql.useMutation<UpdateTargetCareerMutation, UpdateTargetCareerMutationVariables>(UpdateTargetCareerDocument);
};
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($tenant_id: String!, $body: UpdateTenantInput_Input) {
  updateTenant(tenant_id: $tenant_id, input: $body) {
    favicon
    id
    logo_url
    name
    page_title
    registration_email_subject
  }
}
    `;

export function useUpdateTenantMutation() {
  return Urql.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument);
};
export const UpdateTenantWorkConfigurationDocument = gql`
    mutation UpdateTenantWorkConfiguration($configuration_id: String!, $location_restrictions: [String]!) {
  updateTenantWorkConfiguration(
    configuration_id: $configuration_id
    location_restrictions: $location_restrictions
  )
}
    `;

export function useUpdateTenantWorkConfigurationMutation() {
  return Urql.useMutation<UpdateTenantWorkConfigurationMutation, UpdateTenantWorkConfigurationMutationVariables>(UpdateTenantWorkConfigurationDocument);
};
export const UpdateUserCaseNoteDocument = gql`
    mutation UpdateUserCaseNote($updateUserCaseNoteInput: UpdateUserCaseNoteInput!) {
  updateUserCaseNote(updateUserCaseNoteInput: $updateUserCaseNoteInput) {
    id
    created_at
    created_by {
      user_uuid
      user_name
      __typename
    }
    note
    modified_history {
      modified_at
      modified_by {
        user_uuid
        user_name
        __typename
      }
    }
    __typename
  }
}
    `;

export function useUpdateUserCaseNoteMutation() {
  return Urql.useMutation<UpdateUserCaseNoteMutation, UpdateUserCaseNoteMutationVariables>(UpdateUserCaseNoteDocument);
};
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($updateProfileInput: UpdateProfileInput!) {
  updateUserProfile(updateProfileInput: $updateProfileInput) {
    user_uuid
    communities
    first_name
    postal_code
    gender
    date_of_birth
    genderObject {
      label
      dataSource
    }
    ethnicities {
      label
      dataSource
    }
    education {
      label
      dataSource
    }
    communitiesObject {
      label
      dataSource
    }
    employment_status {
      label
      dataSource
    }
    goal
    industries_of_interest
    interest_profile {
      artistic
      enterprising
      conventional
      social
      investigative
      realistic
    }
    is_onboarded
    last_name
    skills {
      is_inferred
      id
      label
      skilltype
      skill_type
      skill_label
      skill_uuid
      is_custom
    }
    support_needs
    support_needs_custom
    target_career
    target_career_label
    target_skills
    work_experiences {
      career_uuid
      company
      completion_date
      starting_date
      experience_level
      experience_type
      is_current
      job_title
      work_exp_uuid
      irrelevant_skill_uuids
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
      career {
        id
        baseline_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
        specialized_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
      }
    }
    education_experiences {
      major
      institution_name
      degree_type
      status
      starting_year
      completion_year
      is_current
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
    }
    certifications {
      certification_uuid
      name
      provider
      issue_date
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
    }
    career_area_interests {
      id
      label
    }
    services {
      service_uuid
      title
      provider
      stage
      start_date
      end_date
    }
    tags {
      tag_uuid
      label
      category
    }
    year_of_birth
    location
    country
    phone_number
    postal_code
    pref_lang
    profileStep
    outcomes {
      disengaged
      other
      educated {
        degree_type
        institution_name
        program_name
      }
      employed {
        company
        job_title
        salary
        salary_type
        salary_currency_code
        employment_type
      }
      referred {
        provider
        service_name
      }
    }
    recruiter_outreach
    account_preferences {
      notifications {
        topic
        frequency
        type
      }
      marketing_consent_history {
        is_consented
        created_at
      }
    }
  }
}
    `;

export function useUpdateUserProfileMutation() {
  return Urql.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument);
};
export const UpdateUserSkillsDocument = gql`
    mutation UpdateUserSkills($updateUserSkillsInput: UpdateUserSkillsInput!) {
  updateUserSkills(updateUserSkillsInput: $updateUserSkillsInput) {
    modifiedSkills {
      id
      is_inferred
      label
      proficiency
      skilltype
    }
  }
}
    `;

export function useUpdateUserSkillsMutation() {
  return Urql.useMutation<UpdateUserSkillsMutation, UpdateUserSkillsMutationVariables>(UpdateUserSkillsDocument);
};
export const UpdateUserTenantDocument = gql`
    mutation UpdateUserTenant($tenant_id: String!, $user_id: String!, $body: UpdateUserTenantBody_Input!) {
  updateUserTenant(tenant_id: $tenant_id, user_id: $user_id, input: $body) {
    id
    tenant_id
  }
}
    `;

export function useUpdateUserTenantMutation() {
  return Urql.useMutation<UpdateUserTenantMutation, UpdateUserTenantMutationVariables>(UpdateUserTenantDocument);
};
export const UpdateWorkExperienceDocument = gql`
    mutation UpdateWorkExperience($updateProfileInput: UpdateProfileInput!) {
  updateUserProfile(updateProfileInput: $updateProfileInput) {
    user_uuid
    skills {
      is_inferred
      id
      label
      skilltype
      skill_type
      skill_label
      skill_uuid
      is_custom
    }
    work_experiences {
      career_uuid
      company
      completion_date
      starting_date
      experience_level
      experience_type
      is_current
      job_title
      irrelevant_skill_uuids
      career {
        id
        baseline_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
        specialized_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
      }
    }
  }
}
    `;

export function useUpdateWorkExperienceMutation() {
  return Urql.useMutation<UpdateWorkExperienceMutation, UpdateWorkExperienceMutationVariables>(UpdateWorkExperienceDocument);
};
export const UpsertAssessmentDocument = gql`
    mutation UpsertAssessment($upsertAssessmentInput: UpsertAssessmentInput!) {
  upsertAssessment(upsertAssessmentInput: $upsertAssessmentInput) {
    assessment_uuid
    created_at
    created_by
    link
    provider
    result
    title
    type
    updated_at
    updated_by
    user_uuid
  }
}
    `;

export function useUpsertAssessmentMutation() {
  return Urql.useMutation<UpsertAssessmentMutation, UpsertAssessmentMutationVariables>(UpsertAssessmentDocument);
};
export const UpsertIrrelevantWorkExpSkillsDocument = gql`
    mutation UpsertIrrelevantWorkExpSkills($upsertIrrelevantWorkExpSkills: UpsertIrrelevantWorkExpSkillsInput!) {
  upsertIrrelevantWorkExperienceSkills(
    upsertIrrelevantWorkExpSkills: $upsertIrrelevantWorkExpSkills
  ) {
    work_exp_uuid
    job_title
    irrelevant_skill_uuids
    career_uuid
    career {
      id
      baseline_skills {
        id
        label
        skilltype
        skill_label
        skill_type
        skill_uuid
      }
      specialized_skills {
        id
        label
        skilltype
        skill_label
        skill_type
        skill_uuid
      }
    }
  }
}
    `;

export function useUpsertIrrelevantWorkExpSkillsMutation() {
  return Urql.useMutation<UpsertIrrelevantWorkExpSkillsMutation, UpsertIrrelevantWorkExpSkillsMutationVariables>(UpsertIrrelevantWorkExpSkillsDocument);
};
export const UpsertTagDocument = gql`
    mutation UpsertTag($upsertTagInput: UpsertTagInput!) {
  upsertTag(upsertTagInput: $upsertTagInput) {
    tag_uuid
  }
}
    `;

export function useUpsertTagMutation() {
  return Urql.useMutation<UpsertTagMutation, UpsertTagMutationVariables>(UpsertTagDocument);
};
export const UpsertUserNextStepDocument = gql`
    mutation UpsertUserNextStep($upsertUserNextStepInput: UpsertUserNextStepInput!, $searchUserNextStepInput: SearchUserNextStepInput!) {
  upsertUserNextStep(upsertUserNextStepInput: $upsertUserNextStepInput) {
    next_steps(searchUserNextStepInput: $searchUserNextStepInput) {
      category
      created_at
      description
      goals
      is_completable
      next_step_uuid
      coach_uuid
      order
      status
      title
      type
      assigned
      updated_at
      user_uuid
      link {
        target
        type
        uri
      }
    }
    number_of_completed_steps
    total_number_of_steps
  }
}
    `;

export function useUpsertUserNextStepMutation() {
  return Urql.useMutation<UpsertUserNextStepMutation, UpsertUserNextStepMutationVariables>(UpsertUserNextStepDocument);
};
export const UpsertUserRoleDocument = gql`
    mutation UpsertUserRole($upsertRoleInput: UpsertRoleInput!) {
  upsertUserRole(upsertRoleInput: $upsertRoleInput) {
    label
  }
}
    `;

export function useUpsertUserRoleMutation() {
  return Urql.useMutation<UpsertUserRoleMutation, UpsertUserRoleMutationVariables>(UpsertUserRoleDocument);
};
export const GetCareerRequiredSkillsDocument = gql`
    query GetCareerRequiredSkills($getCareerInput: GetCareerInput!) {
  getCareer(getCareerInput: $getCareerInput) {
    baselineSkills {
      id
      label
      proficiency
      skilltype
    }
    specializedSkills {
      id
      label
      proficiency
      skilltype
    }
  }
}
    `;

export function useGetCareerRequiredSkillsQuery(options: Omit<Urql.UseQueryArgs<GetCareerRequiredSkillsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCareerRequiredSkillsQuery, GetCareerRequiredSkillsQueryVariables>({ query: GetCareerRequiredSkillsDocument, ...options });
};
export const CheckProfileExistenceDocument = gql`
    query CheckProfileExistence($searchInput: CheckProfileExistenceInput!) {
  checkProfileExistence(searchInput: $searchInput)
}
    `;

export function useCheckProfileExistenceQuery(options: Omit<Urql.UseQueryArgs<CheckProfileExistenceQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckProfileExistenceQuery, CheckProfileExistenceQueryVariables>({ query: CheckProfileExistenceDocument, ...options });
};
export const ClassifyJobTitleDocument = gql`
    query classifyJobTitle($job: String!) {
  classifyJobTitle(job: $job) {
    ... on JobsClassifierResponse {
      top_candidate
      role_uuid
      uuid
      confidence_score
    }
  }
}
    `;

export function useClassifyJobTitleQuery(options: Omit<Urql.UseQueryArgs<ClassifyJobTitleQueryVariables>, 'query'>) {
  return Urql.useQuery<ClassifyJobTitleQuery, ClassifyJobTitleQueryVariables>({ query: ClassifyJobTitleDocument, ...options });
};
export const ClassifyJobTitlesDocument = gql`
    query classifyJobTitles($jobs: [String!]) {
  classifyJobTitles(input: $jobs) {
    ... on JobsClassifierResponse {
      top_candidate
      role_uuid
      uuid
    }
  }
}
    `;

export function useClassifyJobTitlesQuery(options?: Omit<Urql.UseQueryArgs<ClassifyJobTitlesQueryVariables>, 'query'>) {
  return Urql.useQuery<ClassifyJobTitlesQuery, ClassifyJobTitlesQueryVariables>({ query: ClassifyJobTitlesDocument, ...options });
};
export const ClassifySkillTitleDocument = gql`
    query classifySkillTitle($skill: String!) {
  classifySkillTitle(skill: $skill) {
    ... on SkillClassifierResponse {
      label
      uuid
      skill_type
    }
  }
}
    `;

export function useClassifySkillTitleQuery(options: Omit<Urql.UseQueryArgs<ClassifySkillTitleQueryVariables>, 'query'>) {
  return Urql.useQuery<ClassifySkillTitleQuery, ClassifySkillTitleQueryVariables>({ query: ClassifySkillTitleDocument, ...options });
};
export const DeleteResumeDocument = gql`
    mutation DeleteResume($input: deleteResume_request_Input) {
  deleteResume(input: $input) {
    userId
    S3Key
  }
}
    `;

export function useDeleteResumeMutation() {
  return Urql.useMutation<DeleteResumeMutation, DeleteResumeMutationVariables>(DeleteResumeDocument);
};
export const DownloadJsonErrorFileContentDocument = gql`
    query DownloadJsonErrorFileContent($file_name: String!) {
  downloadJsonFileContent(file: $file_name)
}
    `;

export function useDownloadJsonErrorFileContentQuery(options: Omit<Urql.UseQueryArgs<DownloadJsonErrorFileContentQueryVariables>, 'query'>) {
  return Urql.useQuery<DownloadJsonErrorFileContentQuery, DownloadJsonErrorFileContentQueryVariables>({ query: DownloadJsonErrorFileContentDocument, ...options });
};
export const DownloadNextStepDocDocument = gql`
    query DownloadNextStepDoc($downloadNextStepDocInput: DownloadNextStepDocInput!) {
  downloadNextStepDoc(downloadNextStepDocInput: $downloadNextStepDocInput) {
    docUrls {
      url
      s3Key
      fileName
    }
    statusCode
    msg
  }
}
    `;

export function useDownloadNextStepDocQuery(options: Omit<Urql.UseQueryArgs<DownloadNextStepDocQueryVariables>, 'query'>) {
  return Urql.useQuery<DownloadNextStepDocQuery, DownloadNextStepDocQueryVariables>({ query: DownloadNextStepDocDocument, ...options });
};
export const ExtractJobAttributesDocument = gql`
    query ExtractJobAttributes($extractJobAttributesInput: ExtractorRequest_Input) {
  extractJobAttributes(input: $extractJobAttributesInput) {
    ... on JobAttributes {
      attributes
      job_id
    }
  }
}
    `;

export function useExtractJobAttributesQuery(options?: Omit<Urql.UseQueryArgs<ExtractJobAttributesQueryVariables>, 'query'>) {
  return Urql.useQuery<ExtractJobAttributesQuery, ExtractJobAttributesQueryVariables>({ query: ExtractJobAttributesDocument, ...options });
};
export const FindEmployerDocument = gql`
    query FindEmployer($id: String!) {
  findEmployer(id: $id) {
    employer_uuid
    name
    created_at
    CaseNote {
      id
      description
      employer_uuid
      created_at
      updated_at
      created_by
      modified_by
    }
    recruiters {
      recruiter_uuid
      employer_uuid
      user {
        is_registered
        first_name
        last_name
        user_uuid
        email
        invitation {
          invitation_uuid
          invitation_link
        }
      }
    }
  }
}
    `;

export function useFindEmployerQuery(options: Omit<Urql.UseQueryArgs<FindEmployerQueryVariables>, 'query'>) {
  return Urql.useQuery<FindEmployerQuery, FindEmployerQueryVariables>({ query: FindEmployerDocument, ...options });
};
export const FindGroupDocument = gql`
    query findGroup($id: String!) {
  findGroup(id: $id) {
    employer_uuid
    name
    coaches {
      coach_uuid
      group_uuid
      user {
        id
        first_name
        last_name
        email
        is_registered
        invitation {
          invitation_link
        }
      }
    }
    created_at
    updated_at
  }
}
    `;

export function useFindGroupQuery(options: Omit<Urql.UseQueryArgs<FindGroupQueryVariables>, 'query'>) {
  return Urql.useQuery<FindGroupQuery, FindGroupQueryVariables>({ query: FindGroupDocument, ...options });
};
export const GetActiveDefaultQuestionsDocument = gql`
    query GetActiveDefaultQuestions($tenant_uuid: String!) {
  getActiveDefaultQuestions(tenantUuid: $tenant_uuid) {
    question {
      options {
        text
        value
        additionalText
        requiredAditionalTextBox
        isChecked
        dataSource
        textI18n {
          ...TranslationFields
        }
        additionalTextI18n {
          ...TranslationFields
        }
      }
      questionText
      description
      questionTextI18n {
        ...TranslationFields
      }
      descriptionI18n {
        ...TranslationFields
      }
    }
    uuid
    type
  }
}
    ${TranslationFieldsFragmentDoc}`;

export function useGetActiveDefaultQuestionsQuery(options: Omit<Urql.UseQueryArgs<GetActiveDefaultQuestionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveDefaultQuestionsQuery, GetActiveDefaultQuestionsQueryVariables>({ query: GetActiveDefaultQuestionsDocument, ...options });
};
export const GetAllAssessmentsDocument = gql`
    query GetAllAssessments($user_uuid: String!) {
  getAllAssessments(user_uuid: $user_uuid) {
    assessment_uuid
    created_at
    created_by
    link
    provider
    result
    title
    type
    updated_at
    updated_by
    user_uuid
  }
}
    `;

export function useGetAllAssessmentsQuery(options: Omit<Urql.UseQueryArgs<GetAllAssessmentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllAssessmentsQuery, GetAllAssessmentsQueryVariables>({ query: GetAllAssessmentsDocument, ...options });
};
export const GetAllCareerAreasDocument = gql`
    query GetAllCareerAreas($locale: String) {
  careerAreas(locale: $locale) {
    id
    pref_label
    machine_key
    pref_label
  }
}
    `;

export function useGetAllCareerAreasQuery(options?: Omit<Urql.UseQueryArgs<GetAllCareerAreasQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCareerAreasQuery, GetAllCareerAreasQueryVariables>({ query: GetAllCareerAreasDocument, ...options });
};
export const GetAllCareerIndustriesDocument = gql`
    query GetAllCareerIndustries($locale: String) {
  careerAreas(locale: $locale) {
    description
    id
    machine_key
    pref_label
  }
}
    `;

export function useGetAllCareerIndustriesQuery(options?: Omit<Urql.UseQueryArgs<GetAllCareerIndustriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCareerIndustriesQuery, GetAllCareerIndustriesQueryVariables>({ query: GetAllCareerIndustriesDocument, ...options });
};
export const GetAllCoachesDocument = gql`
    query GetAllCoaches($tenant_uuid: String!) {
  getAllCoaches(tenant_uuid: $tenant_uuid) {
    first_name
    last_name
    user_tenant_role
    user_uuid
    tenantRoles {
      role
    }
  }
}
    `;

export function useGetAllCoachesQuery(options: Omit<Urql.UseQueryArgs<GetAllCoachesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCoachesQuery, GetAllCoachesQueryVariables>({ query: GetAllCoachesDocument, ...options });
};
export const GetAllNextStepsMetadataDocument = gql`
    query GetAllNextStepsMetadata($locale: String) {
  getAllNextStepsMetadata(locale: $locale) {
    category
    next_step_uuid
    next_step_title
    tenant_id
    is_completable
    goals
    type
    next_step_description
    link {
      target
      type
      uri
    }
  }
}
    `;

export function useGetAllNextStepsMetadataQuery(options?: Omit<Urql.UseQueryArgs<GetAllNextStepsMetadataQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllNextStepsMetadataQuery, GetAllNextStepsMetadataQueryVariables>({ query: GetAllNextStepsMetadataDocument, ...options });
};
export const GetAllPolicyAssetsDocument = gql`
    query getAllPolicyAssets($configurationId: String!) {
  getAllPolicyAssets(configurationId: $configurationId) {
    asset_id
    asset_type
    language
    url
  }
}
    `;

export function useGetAllPolicyAssetsQuery(options: Omit<Urql.UseQueryArgs<GetAllPolicyAssetsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>({ query: GetAllPolicyAssetsDocument, ...options });
};
export const GetAllTagsDocument = gql`
    query GetAllTags {
  getAllTags {
    label
    tag_uuid
    category
    status
    is_visible_to_recruiters
  }
}
    `;

export function useGetAllTagsQuery(options?: Omit<Urql.UseQueryArgs<GetAllTagsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllTagsQuery, GetAllTagsQueryVariables>({ query: GetAllTagsDocument, ...options });
};
export const GetAllUserUuidsDocument = gql`
    query getAllUserUuids($searchInput: FindProfilesSearchInput!) {
  getAllUserUuids(searchInput: $searchInput) {
    user_uuids
  }
}
    `;

export function useGetAllUserUuidsQuery(options: Omit<Urql.UseQueryArgs<GetAllUserUuidsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllUserUuidsQuery, GetAllUserUuidsQueryVariables>({ query: GetAllUserUuidsDocument, ...options });
};
export const GetCandidateProfilesDocument = gql`
    query GetCandidateProfiles($user_uuids: [String!], $job_id: String) {
  getUserProfiles(
    searchInput: {filters: {user_uuids: $user_uuids}, retrieve_all_fields: false}
  ) {
    profiles {
      user_uuid
      employer_id
      first_name
      last_name
      email
      location
      previously_contacted(job_id: $job_id)
      contact_history {
        contact_uuid
        employer_uuid
        job_id
        contacted_by_uuid
      }
      skills {
        id
        label
        is_inferred
        proficiency
      }
      tags {
        label
      }
      last_career {
        career_uuid
        company
        job_title
      }
      last_education {
        institution_name
        major
        degree_type
      }
    }
  }
}
    `;

export function useGetCandidateProfilesQuery(options?: Omit<Urql.UseQueryArgs<GetCandidateProfilesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCandidateProfilesQuery, GetCandidateProfilesQueryVariables>({ query: GetCandidateProfilesDocument, ...options });
};
export const GetCandidateRecommendationsDocument = gql`
    query getCandidateRecommendations($contact_job_id: String, $job_career_id: String!, $job_skill_ids: [String!], $job_edu_level: [Float!], $job_exp_level: [Float!], $job_role_id: String, $location: String!, $required_skills: [String!], $required_careers: [String!], $required_tags: [String!], $trainable: Boolean, $candidates: [String!], $limit: Int, $page: Int) {
  getCandidateRecommendations(
    limit: $limit
    page: $page
    input: {contact_job_id: $contact_job_id, parameters: {job_role_id: $job_role_id, job_career_id: $job_career_id, job_skill_ids: $job_skill_ids, job_edu_level: $job_edu_level, job_exp_level: $job_exp_level}, filters: {location: $location, required_skills: $required_skills, required_careers: $required_careers, required_tags: $required_tags, trainable: $trainable, candidates: $candidates}}
  ) {
    count
    total
    _embedded {
      candidates {
        id
        trainable
        match
      }
    }
  }
}
    `;

export function useGetCandidateRecommendationsQuery(options: Omit<Urql.UseQueryArgs<GetCandidateRecommendationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCandidateRecommendationsQuery, GetCandidateRecommendationsQueryVariables>({ query: GetCandidateRecommendationsDocument, ...options });
};
export const GetCareerIndustriesByUuidDocument = gql`
    query GetCareerIndustriesByUUID($uuids: [String!]!) {
  careerAreaByUUIDS(uuids: $uuids) {
    description
    id
    machine_key
    pref_label
  }
}
    `;

export function useGetCareerIndustriesByUuidQuery(options: Omit<Urql.UseQueryArgs<GetCareerIndustriesByUuidQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCareerIndustriesByUuidQuery, GetCareerIndustriesByUuidQueryVariables>({ query: GetCareerIndustriesByUuidDocument, ...options });
};
export const GetCareerMatchByIdDocument = gql`
    query GetCareerMatchById($career_id: String!, $lang: String, $career_ids: [String], $role_ids: [String], $current_skill_ids: [String!]) {
  getCareerMatchByID(
    uuid: $career_id
    lang: $lang
    input: {parameters: {role_ids: $role_ids, career_ids: $career_ids, current_skill_ids: $current_skill_ids}}
  ) {
    match
    label
    interest_profile_code
    id
    description
    demand
    career_area {
      id
      label
    }
    salary {
      value
      currency
    }
  }
}
    `;

export function useGetCareerMatchByIdQuery(options: Omit<Urql.UseQueryArgs<GetCareerMatchByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCareerMatchByIdQuery, GetCareerMatchByIdQueryVariables>({ query: GetCareerMatchByIdDocument, ...options });
};
export const GetCareerRecommendationsDocument = gql`
    query GetCareerRecommendations($page: Int, $limit: Int, $sort_by: CareerRecommender_SortBy, $fields: [String], $lang: String, $career_ids: [String!], $role_ids: [String], $current_skill_ids: [String!], $career_area_interest: [String]) {
  getCareerRecommendations(
    page: $page
    limit: $limit
    sort_by: $sort_by
    fields: $fields
    lang: $lang
    input: {parameters: {career_ids: $career_ids, current_skill_ids: $current_skill_ids, career_area_interest: $career_area_interest, role_ids: $role_ids}}
  ) {
    count
    total
    _embedded {
      careers {
        id
        label
        description
        career_area {
          id
          label
        }
        salary {
          currency
          value
          __typename
        }
        demand
        interest_profile_code
        match
        __typename
      }
      __typename
    }
    __typename
  }
}
    `;

export function useGetCareerRecommendationsQuery(options?: Omit<Urql.UseQueryArgs<GetCareerRecommendationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCareerRecommendationsQuery, GetCareerRecommendationsQueryVariables>({ query: GetCareerRecommendationsDocument, ...options });
};
export const GetCareersDocument = gql`
    query GetCareers($getCareersInput: GetCareersInput!) {
  getCareers(getCareersInput: $getCareersInput) {
    count
    allCareerIds
    careers {
      career_name
      career_id
      career_area {
        career_area_id
        career_area_name
      }
    }
  }
}
    `;

export function useGetCareersQuery(options: Omit<Urql.UseQueryArgs<GetCareersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCareersQuery, GetCareersQueryVariables>({ query: GetCareersDocument, ...options });
};
export const GetChildTenantsDocument = gql`
    query GetChildTenants($id: String!) {
  getChildTenants(id: $id) {
    id
    name
    depth
  }
}
    `;

export function useGetChildTenantsQuery(options: Omit<Urql.UseQueryArgs<GetChildTenantsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChildTenantsQuery, GetChildTenantsQueryVariables>({ query: GetChildTenantsDocument, ...options });
};
export const GetCombinedExportReportsDocument = gql`
    query GetCombinedExportReports($combinedExportReportsInput: CombinedExportReportInput!) {
  getCombinedExportReports(
    combinedExportReportsInput: $combinedExportReportsInput
  ) {
    created_at
    created_at
    export_report_uuid
    name
    num_users
    status
    tenant_id
    tenant_ids
    type
    user_specified_name
  }
}
    `;

export function useGetCombinedExportReportsQuery(options: Omit<Urql.UseQueryArgs<GetCombinedExportReportsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCombinedExportReportsQuery, GetCombinedExportReportsQueryVariables>({ query: GetCombinedExportReportsDocument, ...options });
};
export const GetConfigurationDocument = gql`
    query GetConfiguration($getConfigurationInput: GetConfigurationInput!) {
  getConfiguration(getConfigurationInput: $getConfigurationInput) {
    include
  }
}
    `;

export function useGetConfigurationQuery(options: Omit<Urql.UseQueryArgs<GetConfigurationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>({ query: GetConfigurationDocument, ...options });
};
export const GetContactHistoryDocument = gql`
    query GetContactHistory($locale: String, $user_uuid: String!) {
  getUserProfile(getProfileInput: {locale: $locale, user_uuid: $user_uuid}) {
    user_uuid
    contact_history {
      ...AllContactInfo
    }
  }
}
    ${AllContactInfoFragmentDoc}
${ContactIdsFragmentDoc}`;

export function useGetContactHistoryQuery(options: Omit<Urql.UseQueryArgs<GetContactHistoryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetContactHistoryQuery, GetContactHistoryQueryVariables>({ query: GetContactHistoryDocument, ...options });
};
export const GetCourseDocument = gql`
    query GetCourse($getCourseInput: GetCourseInput!) {
  getCourse(getCourseInput: $getCourseInput) {
    __typename
    uuid
    pref_label
    description
    url
    lang_label
    duration_hours
    duration_unit
    duration_value
    diplomas
    provider {
      _id
      pref_label
      __typename
    }
    cost {
      value
      currency
    }
    delivery
    location
    credits_accepted
    subscription_required
    level
    subjects {
      _id
      pref_label
      __typename
    }
    course_type
    course_results {
      result_type
      result
    }
    skills {
      skill_uuid
      pref_label
      skilltype
      __typename
    }
    careers {
      _id
      label
      description
      __typename
    }
  }
}
    `;

export function useGetCourseQuery(options: Omit<Urql.UseQueryArgs<GetCourseQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCourseQuery, GetCourseQueryVariables>({ query: GetCourseDocument, ...options });
};
export const GetCourseRecommendationsDocument = gql`
    query GetCourseRecommendations($page: PositiveInt, $limit: NonNegativeInt, $fields: [String], $lang: CourseRecommender_queryInput_getCourseRecommendations_lang, $target_career_id: String, $target_skill_ids: [String!], $most_recent_career_id: String, $user_skill_ids: [String!]) {
  getCourseRecommendations(
    page: $page
    limit: $limit
    fields: $fields
    lang: $lang
    input: {parameters: {target_career_id: $target_career_id, target_skill_ids: $target_skill_ids, most_recent_career_id: $most_recent_career_id, user_skill_ids: $user_skill_ids}}
  ) {
    count
    total
    _embedded {
      courses {
        id
        label
        url
        provider {
          id
          label
          logo_url
        }
        owner {
          id
          label
        }
        duration_hours
        duration_value
        duration_unit
        credits_accepted
        subscription_required
        cost {
          currency
          value
        }
        skills {
          id
          label
          skilltype
        }
        careers {
          id
          label
        }
      }
    }
  }
}
    `;

export function useGetCourseRecommendationsQuery(options?: Omit<Urql.UseQueryArgs<GetCourseRecommendationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCourseRecommendationsQuery, GetCourseRecommendationsQueryVariables>({ query: GetCourseRecommendationsDocument, ...options });
};
export const GetCourseRecommendationsForJobDocument = gql`
    query GetCourseRecommendationsForJob($page: PositiveInt, $limit: NonNegativeInt, $fields: [String], $lang: CourseRecommender_queryInput_getCourseRecommendations_lang, $job_career_id: String, $job_skills_gap: [String!]) {
  getCourseRecommendations(
    page: $page
    limit: $limit
    fields: $fields
    lang: $lang
    input: {parameters: {target_career_id: $job_career_id, target_skill_ids: $job_skills_gap}}
  ) {
    count
    total
    _embedded {
      courses {
        id
        label
        url
        provider {
          id
          label
          logo_url
        }
        owner {
          id
          label
        }
        duration_hours
        duration_value
        duration_unit
        credits_accepted
        subscription_required
        cost {
          currency
          value
        }
        skills {
          id
          label
          skilltype
        }
        careers {
          id
          label
        }
      }
    }
  }
}
    `;

export function useGetCourseRecommendationsForJobQuery(options?: Omit<Urql.UseQueryArgs<GetCourseRecommendationsForJobQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCourseRecommendationsForJobQuery, GetCourseRecommendationsForJobQueryVariables>({ query: GetCourseRecommendationsForJobDocument, ...options });
};
export const GetCoursesDocument = gql`
    query GetCourses($getCoursesInput: GetCoursesInput!) {
  getCourses(getCoursesInput: $getCoursesInput) {
    count
    courses {
      uuid
      pref_label
      url
      provider {
        _id
        pref_label
      }
      duration_hours
      duration_value
      duration_unit
      credits_accepted
      subscription_required
      course_type
      description
      tenant_id
      source_id
      cost {
        currency
        value
      }
      skills {
        skill_uuid
        pref_label
        skilltype
      }
      careers {
        _id
        label
      }
      lang
    }
  }
}
    `;

export function useGetCoursesQuery(options: Omit<Urql.UseQueryArgs<GetCoursesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCoursesQuery, GetCoursesQueryVariables>({ query: GetCoursesDocument, ...options });
};
export const GetCurrentFormVersionDocument = gql`
    query GetCurrentFormVersion($tenantUuid: String!, $formUuid: String!) {
  getCurrentFormVersion(tenantUuid: $tenantUuid, formUuid: $formUuid) {
    title
    description
    formUuid
    formVersionUuid
    title_i18n {
      ...TranslationFields
    }
    description_i18n {
      ...TranslationFields
    }
    components {
      currencyInputField {
        ...TranslationCurrencyInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          min
          max
        }
      }
      dateInputField {
        ...TranslationDateInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          startDate
          endDate
        }
      }
      dropdownInputField {
        ...TranslationDropdownInputFields
        defaultOption {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      fixedText {
        ...TranslationBaseInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        questionText
        type
        version
        uuid
      }
      dropdownInputFieldWithTags {
        ...TranslationDropdownInputFieldsWithTags
        uuid
        type
        questionText
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        defaultOption {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        options {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        userResponse {
          aditionalResponse
          value
        }
        version
      }
      multiChoiceCheckboxInputField {
        ...TranslationMultiChoiceCheckboxInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          labelI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
          }
        }
        version
        uuid
      }
      multiChoiceCheckboxInputFieldWithTags {
        ...TranslationMultiChoiceCheckboxInputFieldsWithTags
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          labelI18n {
            ...TranslationFields
          }
        }
        questionText
        description
        hasDescription
        order
        placeholder
        type
        uuid
        version
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
      }
      textInputField {
        ...TranslationTextInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      defaultQuestionField {
        ...TranslationDefaultQuestionGraphInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          textI18n {
            ...TranslationFields
          }
        }
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
        version
        uuid
      }
    }
  }
}
    ${TranslationFieldsFragmentDoc}
${TranslationCurrencyInputFieldsFragmentDoc}
${TranslationDateInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsFragmentDoc}
${TranslationBaseInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsWithTagsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsWithTagsFragmentDoc}
${TranslationTextInputFieldsFragmentDoc}
${TranslationDefaultQuestionGraphInputFieldsFragmentDoc}`;

export function useGetCurrentFormVersionQuery(options: Omit<Urql.UseQueryArgs<GetCurrentFormVersionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCurrentFormVersionQuery, GetCurrentFormVersionQueryVariables>({ query: GetCurrentFormVersionDocument, ...options });
};
export const GetCurrentUserTenantDocument = gql`
    query GetCurrentUserTenant {
  getCurrentUserTenant {
    id
    name
    logo_url
    favicon
    page_title
    onboarding_form_link
    code
    lil_sso_link_format
    registration_email_subject
    configuration_id
    sign_up_description
    sign_up_link
    sign_up_image_url
    mobile_sign_up_image_url
    text_overlay_title {
      en_US
      fr_CA
    }
    text_overlay_description {
      en_US
      fr_CA
    }
  }
}
    `;

export function useGetCurrentUserTenantQuery(options?: Omit<Urql.UseQueryArgs<GetCurrentUserTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCurrentUserTenantQuery, GetCurrentUserTenantQueryVariables>({ query: GetCurrentUserTenantDocument, ...options });
};
export const GetDefaultQuestionsDocument = gql`
    query GetDefaultQuestions($tenantUuid: String!) {
  getDefaultQuestions(tenantUuid: $tenantUuid) {
    uuid
    tenant_uuid
    type
    is_active
    is_assigned
    assigned_form_title
    question {
      questionText
      description
      hasDescription
      options {
        text
        value
        dataSource
        additionalText
        requiredAditionalTextBox
        isChecked
        tags {
          uuid
          label
        }
        textI18n {
          ...TranslationFields
        }
        additionalTextI18n {
          ...TranslationFields
        }
      }
      questionTextI18n {
        ...TranslationFields
      }
      descriptionI18n {
        ...TranslationFields
      }
    }
  }
}
    ${TranslationFieldsFragmentDoc}`;

export function useGetDefaultQuestionsQuery(options: Omit<Urql.UseQueryArgs<GetDefaultQuestionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetDefaultQuestionsQuery, GetDefaultQuestionsQueryVariables>({ query: GetDefaultQuestionsDocument, ...options });
};
export const GetEmployersDocument = gql`
    query GetEmployers($limit: Int, $page: Int, $recruiters: Boolean, $searchString: String, $sortBy: EmployerSortBy, $sortDirection: EmployerSortDirection, $tenant_uuid: String!, $type: PartnerType) {
  getEmployers(
    input: {limit: $limit, page: $page, recruiters: $recruiters, searchString: $searchString, sortBy: $sortBy, sortDirection: $sortDirection, tenant_uuid: $tenant_uuid, type: $type}
  ) {
    employers {
      created_at
      employer_uuid
      name
      type
      recruiters {
        recruiter_uuid
        user {
          email
          first_name
          last_name
          user_uuid
          created_at
          is_registered
          invitation {
            invitation_link
            invitation_uuid
          }
        }
      }
    }
    tenantCode
    totalPages
    totalResults
  }
}
    `;

export function useGetEmployersQuery(options: Omit<Urql.UseQueryArgs<GetEmployersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEmployersQuery, GetEmployersQueryVariables>({ query: GetEmployersDocument, ...options });
};
export const GetExportedProfilesCsvDocument = gql`
    query GetExportedProfilesCSV($user_uuids: [String!]!) {
  getExportedProfilesCSV(user_uuids: $user_uuids)
}
    `;

export function useGetExportedProfilesCsvQuery(options: Omit<Urql.UseQueryArgs<GetExportedProfilesCsvQueryVariables>, 'query'>) {
  return Urql.useQuery<GetExportedProfilesCsvQuery, GetExportedProfilesCsvQueryVariables>({ query: GetExportedProfilesCsvDocument, ...options });
};
export const GetFormAndLastSubmissionDocument = gql`
    query GetFormAndLastSubmission($tenantUuid: String!, $formUuid: String!, $userUuid: String!) {
  getFormAndLastSubmission(
    tenantUuid: $tenantUuid
    formUuid: $formUuid
    userUuid: $userUuid
  ) {
    submissionUuid
    title
    description
    userUuid
    formUuid
    formVersionUuid
    titleI18n {
      ...TranslationFields
    }
    descriptionI18n {
      ...TranslationFields
    }
    data {
      currencyInputField {
        ...TranslationCurrencyInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          min
          max
        }
      }
      dateInputField {
        ...TranslationDateInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          startDate
          endDate
        }
      }
      dropdownInputField {
        ...TranslationDropdownInputFields
        defaultOption {
          text
          value
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      fixedText {
        ...TranslationBaseInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        questionText
        type
        version
        uuid
      }
      dropdownInputFieldWithTags {
        ...TranslationDropdownInputFieldsWithTags
        uuid
        type
        questionText
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        defaultOption {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        options {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        userResponse {
          aditionalResponse
          value
        }
        version
      }
      multiChoiceCheckboxInputField {
        ...TranslationMultiChoiceCheckboxInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          labelI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
          }
        }
        version
        uuid
      }
      multiChoiceCheckboxInputFieldWithTags {
        ...TranslationMultiChoiceCheckboxInputFieldsWithTags
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          labelI18n {
            ...TranslationFields
          }
        }
        questionText
        description
        hasDescription
        order
        placeholder
        type
        uuid
        version
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
      }
      textInputField {
        ...TranslationTextInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      defaultQuestionField {
        ...TranslationDefaultQuestionGraphInputFields
        isDeprecated
        isOptional
        isStaff
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          textI18n {
            ...TranslationFields
          }
          additionalTextI18n {
            ...TranslationFields
          }
        }
        questionText
        description
        hasDescription
        order
        placeholder
        type
        uuid
        version
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
      }
    }
  }
}
    ${TranslationFieldsFragmentDoc}
${TranslationCurrencyInputFieldsFragmentDoc}
${TranslationDateInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsFragmentDoc}
${TranslationBaseInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsWithTagsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsWithTagsFragmentDoc}
${TranslationTextInputFieldsFragmentDoc}
${TranslationDefaultQuestionGraphInputFieldsFragmentDoc}`;

export function useGetFormAndLastSubmissionQuery(options: Omit<Urql.UseQueryArgs<GetFormAndLastSubmissionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormAndLastSubmissionQuery, GetFormAndLastSubmissionQueryVariables>({ query: GetFormAndLastSubmissionDocument, ...options });
};
export const GetFormDetailsDocument = gql`
    query GetFormDetails($formUuid: String!) {
  getFormDetails(formUuid: $formUuid) {
    form_id
    title
    form_version_id
    status
    type
    description
    version
    title_i18n {
      ...TranslationFields
    }
    description_i18n {
      ...TranslationFields
    }
    first_name_active
    last_name_active
    location_active
    zip_code_active
    phone_number_active
    components {
      currencyInputField {
        ...TranslationCurrencyInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          min
          max
        }
      }
      dateInputField {
        ...TranslationDateInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          startDate
          endDate
        }
      }
      dropdownInputField {
        ...TranslationDropdownInputFields
        defaultOption {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      fixedText {
        ...TranslationBaseInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        questionText
        type
        version
        uuid
      }
      dropdownInputFieldWithTags {
        ...TranslationDropdownInputFieldsWithTags
        uuid
        type
        questionText
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        defaultOption {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        options {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        userResponse {
          aditionalResponse
          value
        }
        version
      }
      multiChoiceCheckboxInputField {
        ...TranslationMultiChoiceCheckboxInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          labelI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
          }
        }
        version
        uuid
      }
      multiChoiceCheckboxInputFieldWithTags {
        ...TranslationMultiChoiceCheckboxInputFieldsWithTags
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          labelI18n {
            ...TranslationFields
          }
        }
        questionText
        description
        hasDescription
        order
        placeholder
        type
        uuid
        version
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
      }
      textInputField {
        ...TranslationTextInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      defaultQuestionField {
        descriptionI18n {
          en_US
          fr_CA
        }
        placeholderI18n {
          en_US
          fr_CA
        }
        questionTextI18n {
          en_US
          fr_CA
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          textI18n {
            ...TranslationFields
          }
        }
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
        version
        uuid
      }
    }
  }
}
    ${TranslationFieldsFragmentDoc}
${TranslationCurrencyInputFieldsFragmentDoc}
${TranslationDateInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsFragmentDoc}
${TranslationBaseInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsWithTagsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsWithTagsFragmentDoc}
${TranslationTextInputFieldsFragmentDoc}`;

export function useGetFormDetailsQuery(options: Omit<Urql.UseQueryArgs<GetFormDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormDetailsQuery, GetFormDetailsQueryVariables>({ query: GetFormDetailsDocument, ...options });
};
export const GetFormsDocument = gql`
    query GetForms($filters: GetFormsInput, $tenantUuid: String!) {
  getForms(filters: $filters, tenantUuid: $tenantUuid) {
    form_id
    form_version_id
    title
    tenant_uuid
    status
    type
    created_at
    updated_at
  }
}
    `;

export function useGetFormsQuery(options: Omit<Urql.UseQueryArgs<GetFormsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormsQuery, GetFormsQueryVariables>({ query: GetFormsDocument, ...options });
};
export const GetGroupsDocument = gql`
    query getGroups($searchString: String, $sortBy: queryInput_getGroups_input_sortBy, $sortDirection: queryInput_getGroups_input_sortDirection, $page: Int, $limit: Int, $coaches: Boolean!) {
  getGroups(
    input: {searchString: $searchString, sortBy: $sortBy, sortDirection: $sortDirection, page: $page, limit: $limit, coaches: $coaches}
  ) {
    limit
    totalResults
    totalPages
    groups {
      employer_uuid
      name
      coaches @include(if: $coaches) {
        coach_uuid
        group_uuid
        user {
          id
          first_name
          last_name
          email
        }
      }
      created_at
      updated_at
    }
  }
}
    `;

export function useGetGroupsQuery(options: Omit<Urql.UseQueryArgs<GetGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGroupsQuery, GetGroupsQueryVariables>({ query: GetGroupsDocument, ...options });
};
export const GetInviteLinkDocument = gql`
    query getInviteLink($id: String!) {
  getInviteLink(id: $id) {
    id
    role
    name
    type
    tag_id
    is_active
    email_domains
    tenant_id
    code
    user_count
    employer_id
    created_at
    updated_at
    __typename
  }
}
    `;

export function useGetInviteLinkQuery(options: Omit<Urql.UseQueryArgs<GetInviteLinkQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInviteLinkQuery, GetInviteLinkQueryVariables>({ query: GetInviteLinkDocument, ...options });
};
export const GetInviteLinksDocument = gql`
    query getInviteLinks($is_ascending: Boolean!, $sort_by: String!, $excluded_roles: [String]) {
  getInviteLinks(
    is_ascending: $is_ascending
    sort_by: $sort_by
    excluded_roles: $excluded_roles
  ) {
    inviteLinks {
      code
      created_at
      id
      is_active
      email_domains
      name
      role
      tag_id
      tenant_id
      type
      employer_id
      updated_at
      user_count
      __typename
    }
    __typename
  }
}
    `;

export function useGetInviteLinksQuery(options: Omit<Urql.UseQueryArgs<GetInviteLinksQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInviteLinksQuery, GetInviteLinksQueryVariables>({ query: GetInviteLinksDocument, ...options });
};
export const GetJobDocument = gql`
    query GetJob($getJobInput: JobService_GetJobInput!) {
  getJob(getJobInput: $getJobInput) {
    job_id
    job_title
    location
    country
    posting_source
    provider
    provider_id
    allows_easy_apply
    company
    created_at
    active
    description
    application_url
    application_email
    job_type
    employer_id
    posting_source
    department
    seniority
    salary_range {
      max
      min
      currency
      pay_period
    }
    expiry_date
    education_level
    work_experience_level
    classified_job_type
    classified_seniority_type
    remote_type
    skills {
      skill_uuid
      skilltype
      pref_label
      __typename
    }
    skills_needed {
      skill_uuid
      skilltype
      pref_label
      __typename
    }
    additional_skills {
      skill_uuid
      custom_label
      skilltype
      classified_skill_existed
      __typename
    }
    career {
      career_uuid
      baseline_skills {
        skill_uuid
        pref_label
        __typename
      }
      specialized_skills {
        skill_uuid
        pref_label
        __typename
      }
      __typename
    }
    posting_source
    inferred_attributes {
      label
      level
      phrase
      skilltype
      uuid
      type
    }
    __typename
  }
}
    `;

export function useGetJobQuery(options: Omit<Urql.UseQueryArgs<GetJobQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobQuery, GetJobQueryVariables>({ query: GetJobDocument, ...options });
};
export const GetJobConfigurationDocument = gql`
    query getJobConfiguration($configuration_id: String!) {
  getJobConfiguration(configuration_id: $configuration_id) {
    job_sources {
      include
      exclude
    }
    featured_job_providers
  }
}
    `;

export function useGetJobConfigurationQuery(options: Omit<Urql.UseQueryArgs<GetJobConfigurationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobConfigurationQuery, GetJobConfigurationQueryVariables>({ query: GetJobConfigurationDocument, ...options });
};
export const GetJobRecommendationsDocument = gql`
    query GetJobRecommendations($page: PositiveInt, $limit: NonNegativeInt, $fields: [String], $input: JobRecommender_RecommendedJobsRequest_Input) {
  getJobRecommendations(
    page: $page
    limit: $limit
    fields: $fields
    input: $input
  ) {
    count
    total
    _embedded {
      jobs {
        job_id
        match
        skill_match
        education_match
        experience_match
      }
    }
  }
}
    `;

export function useGetJobRecommendationsQuery(options?: Omit<Urql.UseQueryArgs<GetJobRecommendationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobRecommendationsQuery, GetJobRecommendationsQueryVariables>({ query: GetJobRecommendationsDocument, ...options });
};
export const GetJobRecommendationsByIdsDocument = gql`
    query getJobRecommendationsByIds($input: JobRecommender_RecommendedJobsRequestByIds_Input) {
  getJobRecommendationsByIds(input: $input) {
    total
    count
    __typename
    _embedded {
      jobs {
        job_id
        match
        education_match
        experience_match
        skill_match
        __typename
      }
    }
  }
}
    `;

export function useGetJobRecommendationsByIdsQuery(options?: Omit<Urql.UseQueryArgs<GetJobRecommendationsByIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobRecommendationsByIdsQuery, GetJobRecommendationsByIdsQueryVariables>({ query: GetJobRecommendationsByIdsDocument, ...options });
};
export const GetJobsV2Document = gql`
    query GetJobsV2($getJobsInput: JobService_GetJobsInput!) {
  getJobsV2(getJobsInput: $getJobsInput) {
    total_results
    jobs {
      job_id
      job_title
      location
      posting_source
      provider
      company
      created_at
      description
      application_url
      application_email
      score
      classified_job_type
      classified_seniority_type
      remote_type
    }
    __typename
  }
}
    `;

export function useGetJobsV2Query(options: Omit<Urql.UseQueryArgs<GetJobsV2QueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobsV2Query, GetJobsV2QueryVariables>({ query: GetJobsV2Document, ...options });
};
export const GetLearningProvidersDocument = gql`
    query GetLearningProviders($getLearningProvidersInput: GetLearningProvidersInput!) {
  getLearningProviders(getLearningProvidersInput: $getLearningProvidersInput) {
    count
    allLearningProvidersIds
    providers {
      provider_id
      provider_name
      description
      tenant_id
      logo_url
    }
  }
}
    `;

export function useGetLearningProvidersQuery(options: Omit<Urql.UseQueryArgs<GetLearningProvidersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLearningProvidersQuery, GetLearningProvidersQueryVariables>({ query: GetLearningProvidersDocument, ...options });
};
export const GetLocationsDocument = gql`
    query GetLocations($locale: String!, $country_code: String) {
  getLocations(country_code: $country_code) {
    id
    division_label(locale: $locale)
    country_label(locale: $locale)
    division_code
    country_code
  }
}
    `;

export function useGetLocationsQuery(options: Omit<Urql.UseQueryArgs<GetLocationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLocationsQuery, GetLocationsQueryVariables>({ query: GetLocationsDocument, ...options });
};
export const GetLookerSsoEmbedUrlDocument = gql`
    query getLookerSSOEmbedURL($getLookerInsightInput: GetLookerInsightInput) {
  getLookerSSOEmbedURL(lookerInput: $getLookerInsightInput) {
    url
  }
}
    `;

export function useGetLookerSsoEmbedUrlQuery(options?: Omit<Urql.UseQueryArgs<GetLookerSsoEmbedUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLookerSsoEmbedUrlQuery, GetLookerSsoEmbedUrlQueryVariables>({ query: GetLookerSsoEmbedUrlDocument, ...options });
};
export const GetNextStepsLibraryDocument = gql`
    query GetNextStepsLibrary($libraryInput: LibraryInput!) {
  getNextStepsLibrary(libraryInput: $libraryInput) {
    library_uuid
    tenant_uuid
    type
    updated_at
    updated_by
    next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
  }
}
    `;

export function useGetNextStepsLibraryQuery(options: Omit<Urql.UseQueryArgs<GetNextStepsLibraryQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNextStepsLibraryQuery, GetNextStepsLibraryQueryVariables>({ query: GetNextStepsLibraryDocument, ...options });
};
export const GetOnboardingFormDocument = gql`
    query GetOnboardingForm($tenantUuid: String!) {
  getOnboardingForm(tenantUuid: $tenantUuid) {
    title
    type
    status
    form_version_id
    form_id
    description
    created_by
    created_at
    updated_at
    version
  }
}
    `;

export function useGetOnboardingFormQuery(options: Omit<Urql.UseQueryArgs<GetOnboardingFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOnboardingFormQuery, GetOnboardingFormQueryVariables>({ query: GetOnboardingFormDocument, ...options });
};
export const GetPipelineMetadataDocument = gql`
    query GetPipelineMetadata($file_name: String!) {
  getPipelineMetadata(getPipelineMetadataInput: {file_name: $file_name}) {
    dag_run_id
    data_type
    errors_url
    filename
    finished_at
    input_url
    num_archived_records
    num_error_records
    num_new_records
    num_total_records
    num_updated_records
    tenant_id
  }
}
    `;

export function useGetPipelineMetadataQuery(options: Omit<Urql.UseQueryArgs<GetPipelineMetadataQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPipelineMetadataQuery, GetPipelineMetadataQueryVariables>({ query: GetPipelineMetadataDocument, ...options });
};
export const GetPostObjectUrlDocument = gql`
    query getPostObjectURL($input: GenerateURL!) {
  getPostObjectURL(generateURL: $input) {
    body
    msg
    url
    s3Key
  }
}
    `;

export function useGetPostObjectUrlQuery(options: Omit<Urql.UseQueryArgs<GetPostObjectUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPostObjectUrlQuery, GetPostObjectUrlQueryVariables>({ query: GetPostObjectUrlDocument, ...options });
};
export const GetPostObjectUrlNextStepDocDocument = gql`
    query GetPostObjectURLNextStepDoc($generateURLNextStepDocInput: GenerateURLNextStepDocInput!) {
  getPostObjectURLNextStepDoc(
    generateURLNextStepDocInput: $generateURLNextStepDocInput
  ) {
    statusCode
    presignedPosts {
      url
      body
      msg
      s3Key
      fileName
    }
  }
}
    `;

export function useGetPostObjectUrlNextStepDocQuery(options: Omit<Urql.UseQueryArgs<GetPostObjectUrlNextStepDocQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPostObjectUrlNextStepDocQuery, GetPostObjectUrlNextStepDocQueryVariables>({ query: GetPostObjectUrlNextStepDocDocument, ...options });
};
export const GetProfileFiltersDocument = gql`
    query GetProfileFilters($locale: String, $tenant_uuids: [String!]) {
  getProfileFilters(locale: $locale, tenant_uuids: $tenant_uuids) {
    location
    target_careers {
      career_uuid
      pref_label
    }
  }
}
    `;

export function useGetProfileFiltersQuery(options?: Omit<Urql.UseQueryArgs<GetProfileFiltersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProfileFiltersQuery, GetProfileFiltersQueryVariables>({ query: GetProfileFiltersDocument, ...options });
};
export const GetPublishedOnboardingFormDocument = gql`
    query GetPublishedOnboardingForm($tenantUuid: String!) {
  getForms(
    filters: {types: [ONBOARDING_FORM], statuses: [PUBLISHED]}
    tenantUuid: $tenantUuid
  ) {
    form_id
    form_version_id
    title
    description
    title_i18n {
      ...TranslationFields
    }
    description_i18n {
      ...TranslationFields
    }
    tenant_uuid
    status
    type
    created_at
    updated_at
    components {
      currencyInputField {
        ...TranslationCurrencyInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          min
          max
        }
      }
      dateInputField {
        ...TranslationDateInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          startDate
          endDate
        }
      }
      dropdownInputField {
        ...TranslationDropdownInputFields
        defaultOption {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      fixedText {
        ...TranslationBaseInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        questionText
        type
        version
        uuid
      }
      dropdownInputFieldWithTags {
        ...TranslationDropdownInputFieldsWithTags
        uuid
        type
        questionText
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        defaultOption {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        options {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        userResponse {
          aditionalResponse
          value
        }
        version
      }
      multiChoiceCheckboxInputField {
        ...TranslationMultiChoiceCheckboxInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          labelI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
          }
        }
        version
        uuid
      }
      multiChoiceCheckboxInputFieldWithTags {
        ...TranslationMultiChoiceCheckboxInputFieldsWithTags
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          labelI18n {
            ...TranslationFields
          }
        }
        questionText
        description
        hasDescription
        order
        placeholder
        type
        uuid
        version
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
      }
      textInputField {
        ...TranslationTextInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      defaultQuestionField {
        descriptionI18n {
          en_US
          fr_CA
        }
        placeholderI18n {
          en_US
          fr_CA
        }
        questionTextI18n {
          en_US
          fr_CA
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          textI18n {
            ...TranslationFields
          }
        }
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
        version
        uuid
      }
    }
  }
}
    ${TranslationFieldsFragmentDoc}
${TranslationCurrencyInputFieldsFragmentDoc}
${TranslationDateInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsFragmentDoc}
${TranslationBaseInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsWithTagsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsWithTagsFragmentDoc}
${TranslationTextInputFieldsFragmentDoc}`;

export function useGetPublishedOnboardingFormQuery(options: Omit<Urql.UseQueryArgs<GetPublishedOnboardingFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPublishedOnboardingFormQuery, GetPublishedOnboardingFormQueryVariables>({ query: GetPublishedOnboardingFormDocument, ...options });
};
export const GetPublishedRegistrationFormDocument = gql`
    query GetPublishedRegistrationForm($tenantUuid: String!) {
  getForms(
    filters: {types: [REGISTRATION_FORM], statuses: [PUBLISHED]}
    tenantUuid: $tenantUuid
  ) {
    form_id
    form_version_id
    tenant_uuid
    created_at
    updated_at
    title_i18n {
      ...TranslationFields
    }
    description_i18n {
      ...TranslationFields
    }
    is_active
    first_name_active
    last_name_active
    phone_number_active
    zip_code_active
    location_active
    components {
      currencyInputField {
        ...TranslationCurrencyInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          min
          max
        }
      }
      dateInputField {
        ...TranslationDateInputFields
        type
        version
        questionText
        hasRange
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        uuid
        userResponse {
          startDate
          endDate
        }
      }
      dropdownInputField {
        ...TranslationDropdownInputFields
        defaultOption {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          text
          value
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      fixedText {
        ...TranslationBaseInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        questionText
        type
        version
        uuid
      }
      dropdownInputFieldWithTags {
        ...TranslationDropdownInputFieldsWithTags
        uuid
        type
        questionText
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        defaultOption {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        options {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        userResponse {
          aditionalResponse
          value
        }
        version
      }
      multiChoiceCheckboxInputField {
        ...TranslationMultiChoiceCheckboxInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          labelI18n {
            ...TranslationFields
          }
        }
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
          }
        }
        version
        uuid
      }
      multiChoiceCheckboxInputFieldWithTags {
        ...TranslationMultiChoiceCheckboxInputFieldsWithTags
        isDeprecated
        isOptional
        isStaff
        options {
          id
          isChecked
          label
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          labelI18n {
            ...TranslationFields
          }
        }
        questionText
        description
        hasDescription
        order
        placeholder
        type
        uuid
        version
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
      }
      textInputField {
        ...TranslationTextInputFields
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        userResponse {
          aditionalResponse
          value
        }
        version
        uuid
      }
      defaultQuestionField {
        descriptionI18n {
          ...TranslationFields
        }
        placeholderI18n {
          ...TranslationFields
        }
        questionTextI18n {
          ...TranslationFields
        }
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          textI18n {
            ...TranslationFields
          }
        }
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
        version
        uuid
      }
    }
  }
}
    ${TranslationFieldsFragmentDoc}
${TranslationCurrencyInputFieldsFragmentDoc}
${TranslationDateInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsFragmentDoc}
${TranslationBaseInputFieldsFragmentDoc}
${TranslationDropdownInputFieldsWithTagsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsFragmentDoc}
${TranslationMultiChoiceCheckboxInputFieldsWithTagsFragmentDoc}
${TranslationTextInputFieldsFragmentDoc}`;

export function useGetPublishedRegistrationFormQuery(options: Omit<Urql.UseQueryArgs<GetPublishedRegistrationFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPublishedRegistrationFormQuery, GetPublishedRegistrationFormQueryVariables>({ query: GetPublishedRegistrationFormDocument, ...options });
};
export const GetRecruiterByUserUuidDocument = gql`
    query GetRecruiterByUserUuid($user_uuid: String!) {
  getRecruiterByUserUuid(userUuid: $user_uuid) {
    employer_uuid
    recruiter_uuid
    user {
      email
      first_name
      invitation {
        invitation_link
      }
      user_uuid
    }
    employer {
      name
    }
  }
}
    `;

export function useGetRecruiterByUserUuidQuery(options: Omit<Urql.UseQueryArgs<GetRecruiterByUserUuidQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRecruiterByUserUuidQuery, GetRecruiterByUserUuidQueryVariables>({ query: GetRecruiterByUserUuidDocument, ...options });
};
export const GetRecruiterProfilesDocument = gql`
    query GetRecruiterProfiles($searchInput: FindProfilesSearchInput!) {
  getUserProfiles(searchInput: $searchInput) {
    profiles {
      user_uuid
      first_name
      last_name
    }
  }
}
    `;

export function useGetRecruiterProfilesQuery(options: Omit<Urql.UseQueryArgs<GetRecruiterProfilesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRecruiterProfilesQuery, GetRecruiterProfilesQueryVariables>({ query: GetRecruiterProfilesDocument, ...options });
};
export const GetRecruiterSavedTalentsDocument = gql`
    query GetRecruiterSavedTalents($user_uuid: String!) {
  getRecruiterSavedTalents(user_uuid: $user_uuid) {
    talent_uuid
    user_uuid
    created_at
    __typename
  }
}
    `;

export function useGetRecruiterSavedTalentsQuery(options: Omit<Urql.UseQueryArgs<GetRecruiterSavedTalentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRecruiterSavedTalentsQuery, GetRecruiterSavedTalentsQueryVariables>({ query: GetRecruiterSavedTalentsDocument, ...options });
};
export const GetRecruiterSavedTalentsByJobDocument = gql`
    query getRecruiterSavedTalentsByJob($user_uuid: String!, $job_uuid: String!) {
  getRecruiterSavedTalentsByJob(user_uuid: $user_uuid, job_uuid: $job_uuid) {
    talent_uuid
    user_uuid
    job_uuid
    created_at
    __typename
  }
}
    `;

export function useGetRecruiterSavedTalentsByJobQuery(options: Omit<Urql.UseQueryArgs<GetRecruiterSavedTalentsByJobQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRecruiterSavedTalentsByJobQuery, GetRecruiterSavedTalentsByJobQueryVariables>({ query: GetRecruiterSavedTalentsByJobDocument, ...options });
};
export const GetRecruitersDocument = gql`
    query GetRecruiters($searchString: String, $limit: Int, $page: Int, $employerName: String, $employerId: String, $sortBy: RecruiterSortBy, $sortDirection: EmployerSortDirection, $tenant_uuid: String) {
  getRecruiters(
    input: {searchString: $searchString, limit: $limit, page: $page, employerName: $employerName, employerId: $employerId, sortBy: $sortBy, sortDirection: $sortDirection, tenant_uuid: $tenant_uuid}
  ) {
    totalPages
    totalResults
    recruiters {
      recruiter_uuid
      user {
        id
        email
        first_name
        last_name
        user_uuid
        created_at
        is_registered
        invitation {
          invitation_link
          invitation_uuid
        }
      }
      employer {
        employer_uuid
        name
      }
    }
  }
}
    `;

export function useGetRecruitersQuery(options?: Omit<Urql.UseQueryArgs<GetRecruitersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRecruitersQuery, GetRecruitersQueryVariables>({ query: GetRecruitersDocument, ...options });
};
export const GetRegistrationFormDocument = gql`
    query GetRegistrationForm($tenantUuid: String!) {
  getRegistrationForm(tenantUuid: $tenantUuid) {
    form_id
    form_version_id
    tenant_uuid
    created_at
    updated_at
    is_active
    first_name_active
    last_name_active
    phone_number_active
    zip_code_active
    location_active
    components {
      textInputField {
        type
        questionText
        description
        questionTextI18n {
          ...TranslationFields
        }
        descriptionI18n {
          ...TranslationFields
        }
      }
      dropdownInputFieldWithTags {
        uuid
        type
        questionText
        description
        hasDescription
        isDeprecated
        isOptional
        defaultOption {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        options {
          text
          value
          tags {
            uuid
            label
          }
          textI18n {
            ...TranslationFields
          }
        }
        userResponse {
          aditionalResponse
          value
        }
        questionTextI18n {
          ...TranslationFields
        }
        descriptionI18n {
          ...TranslationFields
        }
        placeholderI18n {
          ...TranslationFields
        }
      }
      multiChoiceCheckboxInputFieldWithTags {
        uuid
        type
        questionText
        description
        hasDescription
        isDeprecated
        isOptional
        options {
          id
          label
          isChecked
          requiredAditionalTextBox
          tags {
            uuid
            label
          }
          labelI18n {
            ...TranslationFields
          }
        }
        userResponse {
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              label
              uuid
            }
          }
        }
        questionTextI18n {
          ...TranslationFields
        }
        descriptionI18n {
          ...TranslationFields
        }
        placeholderI18n {
          ...TranslationFields
        }
      }
      defaultQuestionField {
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
          textI18n {
            ...TranslationFields
          }
        }
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
        version
        uuid
        questionTextI18n {
          ...TranslationFields
        }
        descriptionI18n {
          ...TranslationFields
        }
        placeholderI18n {
          ...TranslationFields
        }
      }
    }
  }
}
    ${TranslationFieldsFragmentDoc}`;

export function useGetRegistrationFormQuery(options: Omit<Urql.UseQueryArgs<GetRegistrationFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRegistrationFormQuery, GetRegistrationFormQueryVariables>({ query: GetRegistrationFormDocument, ...options });
};
export const GetRegistrationSubmissionDocument = gql`
    query GetRegistrationSubmission($userUuid: String!) {
  getRegistrationSubmission(userUuid: $userUuid) {
    form_id
    tenant_uuid
    form_created_at
    form_updated_at
    form_version_id
    version
    is_assigned
    created_by
    description
    status
    title
    submission_id
    user_uuid
    is_deleted
    submission_created_at
    submission_updated_at
    data {
      textInputField {
        type
        version
        questionText
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        placeholder
        uuid
        userResponse {
          value
        }
      }
      dropdownInputFieldWithTags {
        type
        version
        questionText
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        placeholder
        defaultOption {
          text
          value
          tags {
            uuid
            label
          }
        }
        options {
          text
          value
          tags {
            uuid
            label
          }
        }
        uuid
        userResponse {
          value
          aditionalResponse
        }
      }
      multiChoiceCheckboxInputFieldWithTags {
        type
        version
        questionText
        hasDescription
        description
        isOptional
        isStaff
        isDeprecated
        order
        placeholder
        options {
          id
          label
          isChecked
          requiredAditionalTextBox
          tags {
            uuid
            label
          }
        }
        userResponse {
          value {
            id
            aditionalText
            tags {
              uuid
              label
            }
          }
          aditionalResponse
        }
        uuid
      }
      defaultQuestionField {
        description
        hasDescription
        isDeprecated
        isOptional
        isStaff
        order
        placeholder
        questionText
        type
        options {
          isChecked
          text
          value
          dataSource
          additionalText
          requiredAditionalTextBox
          tags {
            label
            uuid
          }
        }
        userResponse {
          textValue
          aditionalResponse
          value {
            aditionalText
            id
            tags {
              uuid
              label
            }
          }
        }
        version
        uuid
      }
    }
  }
}
    `;

export function useGetRegistrationSubmissionQuery(options: Omit<Urql.UseQueryArgs<GetRegistrationSubmissionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRegistrationSubmissionQuery, GetRegistrationSubmissionQueryVariables>({ query: GetRegistrationSubmissionDocument, ...options });
};
export const GetRelatedCareersDocument = gql`
    query GetRelatedCareers($limit: Int, $lang: String, $career_ids: [String]) {
  getCareerRecommendations(
    limit: $limit
    lang: $lang
    input: {parameters: {career_ids: $career_ids}}
  ) {
    count
    total
    _embedded {
      careers {
        id
        label
        description
        salary {
          currency
          value
        }
        demand
      }
    }
  }
}
    `;

export function useGetRelatedCareersQuery(options?: Omit<Urql.UseQueryArgs<GetRelatedCareersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRelatedCareersQuery, GetRelatedCareersQueryVariables>({ query: GetRelatedCareersDocument, ...options });
};
export const GetRelatedTenantsDocument = gql`
    query GetRelatedTenants($id: String!) {
  getRelatedTenants(id: $id) {
    id
    name
    depth
  }
}
    `;

export function useGetRelatedTenantsQuery(options: Omit<Urql.UseQueryArgs<GetRelatedTenantsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRelatedTenantsQuery, GetRelatedTenantsQueryVariables>({ query: GetRelatedTenantsDocument, ...options });
};
export const GetResourceDocument = gql`
    query GetResource($resourceId: String!, $locale: String!) {
  getResource(getResourceInput: {resourceId: $resourceId, locale: $locale}) {
    uuid
    pref_label
    description
    provider {
      uuid
      pref_label
      description
    }
    url
    credits_accepted
    subscription_required
    cost {
      value
      currency
    }
    age_range {
      min_age
      max_age
    }
    lang_label
    country
    support_needs
    career_interest
  }
}
    `;

export function useGetResourceQuery(options: Omit<Urql.UseQueryArgs<GetResourceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetResourceQuery, GetResourceQueryVariables>({ query: GetResourceDocument, ...options });
};
export const GetResourceRecommendationsDocument = gql`
    query GetResourceRecommendations($page: PositiveInt, $limit: NonNegativeInt, $fields: [String], $lang: String, $support_needs: [String!], $communities: [String!], $career_interest: [String!], $gender: String, $age: Int) {
  getResourceRecommendations(
    page: $page
    limit: $limit
    fields: $fields
    lang: $lang
    input: {parameters: {support_needs: $support_needs, communities: $communities, career_interest: $career_interest, gender: $gender, age: $age}}
  ) {
    count
    total
    _embedded {
      resources {
        id
        label
        provider {
          id
          label
        }
        country
        description
        url
        cost {
          currency
          value
        }
        credits_accepted
        subscription_required
        career_interest
        support_needs
        community
      }
    }
  }
}
    `;

export function useGetResourceRecommendationsQuery(options?: Omit<Urql.UseQueryArgs<GetResourceRecommendationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetResourceRecommendationsQuery, GetResourceRecommendationsQueryVariables>({ query: GetResourceRecommendationsDocument, ...options });
};
export const GetResourcesDocument = gql`
    query GetResources($getResourcesInput: GetResourcesInput!) {
  getResources(getResourcesInput: $getResourcesInput) {
    count
    allResourcesIds
    resources {
      description
      resource_id
      resource_name
      source
      source_id
      tenant_id
    }
  }
}
    `;

export function useGetResourcesQuery(options: Omit<Urql.UseQueryArgs<GetResourcesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetResourcesQuery, GetResourcesQueryVariables>({ query: GetResourcesDocument, ...options });
};
export const GetRoadmapDocument = gql`
    query GetRoadmap($getRoadmapInput: GetRoadmapInput!) {
  getRoadmap(getRoadmapInput: $getRoadmapInput) {
    roadmap_uuid
    tenant_uuid
    draft_next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
    published_next_steps {
      source
      next_step_type
      category
      goals
      is_completable
      link {
        target
        uri
        type
      }
      next_step_description
      next_step_title
      next_step_uuid
      requireUpload
      type
    }
  }
}
    `;

export function useGetRoadmapQuery(options: Omit<Urql.UseQueryArgs<GetRoadmapQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRoadmapQuery, GetRoadmapQueryVariables>({ query: GetRoadmapDocument, ...options });
};
export const GetSavedCareersDocument = gql`
    query GetSavedCareers($userUuid: String!) {
  getSavedCareersStatus(userUuid: $userUuid) {
    careerUuid
    isRemoved
  }
}
    `;

export function useGetSavedCareersQuery(options: Omit<Urql.UseQueryArgs<GetSavedCareersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSavedCareersQuery, GetSavedCareersQueryVariables>({ query: GetSavedCareersDocument, ...options });
};
export const GetSavedJobsByUserUuidDocument = gql`
    query GetSavedJobsByUserUUID($userUuid: String!) {
  savedJobsByUserUUID(user_uuid: $userUuid) {
    company
    created_at
    description
    location
    notes
    stage_history {
      created_at
      stage
    }
    job {
      uuid
      source
      source_type
      ingested_at
    }
    title
    uuid
    url
    __typename
  }
}
    `;

export function useGetSavedJobsByUserUuidQuery(options: Omit<Urql.UseQueryArgs<GetSavedJobsByUserUuidQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSavedJobsByUserUuidQuery, GetSavedJobsByUserUuidQueryVariables>({ query: GetSavedJobsByUserUuidDocument, ...options });
};
export const GetSavedLearningDocument = gql`
    query GetSavedLearning($userUuid: String!) {
  getSavedLearningsStatus(userUuid: $userUuid) {
    learningUuid
    isRemoved
  }
}
    `;

export function useGetSavedLearningQuery(options: Omit<Urql.UseQueryArgs<GetSavedLearningQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSavedLearningQuery, GetSavedLearningQueryVariables>({ query: GetSavedLearningDocument, ...options });
};
export const GetSavedResourceDocument = gql`
    query GetSavedResource($userUuid: String!) {
  getSavedResourcesStatus(userUuid: $userUuid) {
    resourceUuid
    isRemoved
  }
}
    `;

export function useGetSavedResourceQuery(options: Omit<Urql.UseQueryArgs<GetSavedResourceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSavedResourceQuery, GetSavedResourceQueryVariables>({ query: GetSavedResourceDocument, ...options });
};
export const GetTagsDocument = gql`
    query GetTags($getTagsInput: GetTagsInput!) {
  getTags(getTagsInput: $getTagsInput) {
    count
    items {
      category
      created_at
      created_by
      created_by_full_name
      is_visible_to_recruiters
      user_count
      label
      status
      tag_uuid
      tenant_uuid
      updated_at
      updated_by
    }
    next
  }
}
    `;

export function useGetTagsQuery(options: Omit<Urql.UseQueryArgs<GetTagsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTagsQuery, GetTagsQueryVariables>({ query: GetTagsDocument, ...options });
};
export const GetTargetCareerDocument = gql`
    query GetTargetCareer($locale: String, $user_uuid: String!) {
  getUserProfile(getProfileInput: {locale: $locale, user_uuid: $user_uuid}) {
    user_uuid
    target_career
    target_career_label
    target_skills
  }
}
    `;

export function useGetTargetCareerQuery(options: Omit<Urql.UseQueryArgs<GetTargetCareerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTargetCareerQuery, GetTargetCareerQueryVariables>({ query: GetTargetCareerDocument, ...options });
};
export const GetTenantDocument = gql`
    query GetTenant($tenantCode: String!) {
  getTenant(tenant_id: $tenantCode) {
    id
    name
    logo_url
    favicon
    page_title
    code
    configuration_id
    sign_up_description
    sign_up_link
    sign_up_image_url
    mobile_sign_up_image_url
  }
}
    `;

export function useGetTenantQuery(options: Omit<Urql.UseQueryArgs<GetTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantQuery, GetTenantQueryVariables>({ query: GetTenantDocument, ...options });
};
export const GetTenantAssetsDocument = gql`
    query getTenantAssets($getTenantAssetsInput: GetTenantAssetsInput!) {
  getTenantAssets(getTenantAssetsInput: $getTenantAssetsInput) {
    cookie_policy
    privacy_policy
    terms_of_service
  }
}
    `;

export function useGetTenantAssetsQuery(options: Omit<Urql.UseQueryArgs<GetTenantAssetsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantAssetsQuery, GetTenantAssetsQueryVariables>({ query: GetTenantAssetsDocument, ...options });
};
export const GetTenantConfigurationDocument = gql`
    query GetTenantConfiguration($configuration_id: String!, $locale: String) {
  getTenantConfiguration(configuration_id: $configuration_id) {
    configuration_id
    tenant_id
    job_sources {
      include
      exclude
    }
    featured_job_providers
    overlayTitle {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
    overlayDescription {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
    banner {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
    marketingConsent {
      en_US
      fr_CA
      es_ES
      pt_PT
    }
    link
    location_restrictions {
      id
      country_code
      country_label(locale: $locale)
      division_code
      division_label(locale: $locale)
    }
    show_all_candidates
    job_language_restriction
    job_employer_restriction
    insight_configuration {
      label
      dashboard_id
    }
    date_posted_range
    default_policy_language
    default_coach_visibility
    show_candidate_tag_rule {
      operator
      groups {
        operator
        items
        is_negated
      }
    }
    supported_career_area_ids
    show_course_recs_for_jobs
  }
}
    `;

export function useGetTenantConfigurationQuery(options: Omit<Urql.UseQueryArgs<GetTenantConfigurationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantConfigurationQuery, GetTenantConfigurationQueryVariables>({ query: GetTenantConfigurationDocument, ...options });
};
export const GetTenantConfigurationByTenantIdDocument = gql`
    query GetTenantConfigurationByTenantId($tenant_id: String!) {
  getTenantConfigurationByTenantId(tenant_id: $tenant_id) {
    configuration_id
    date_posted_range
    default_policy_language
    default_coach_visibility
    featured_job_providers
    job_employer_restriction
    job_language_restriction
    label
    link
    rest_client_id
    rest_client_ids
    show_all_candidates
    supported_career_area_ids
    tenant_id
    assets {
      cookie_policy {
        en_US
        es_ES
        fr_CA
        pt_PT
      }
      privacy_policy {
        en_US
        es_ES
        fr_CA
        pt_PT
      }
      terms_of_service {
        en_US
        es_ES
        fr_CA
        pt_PT
      }
    }
    banner {
      en_US
      es_ES
      fr_CA
      pt_PT
    }
    careers {
      exclude
      include
    }
    course_providers {
      exclude
      include
    }
    deep_case_management {
      gov_client_configuration {
        api_key
        base_url
        config_type
        env
        organization_id
      }
    }
    insight_configuration {
      dashboard_id
      label
    }
    job_sources {
      exclude
      include
    }
    location_restrictions {
      country_code
      country_label
      division_code
      division_label
      id
    }
    marketingConsent {
      en_US
      es_ES
      fr_CA
      pt_PT
    }
    overlayDescription {
      en_US
      es_ES
      fr_CA
      pt_PT
    }
    overlayTitle {
      en_US
      es_ES
      fr_CA
      pt_PT
    }
    resources {
      exclude
      include
    }
    show_candidate_tag_rule {
      operator
      groups {
        is_negated
        items
        operator
      }
    }
    show_course_recs_for_jobs
  }
}
    `;

export function useGetTenantConfigurationByTenantIdQuery(options: Omit<Urql.UseQueryArgs<GetTenantConfigurationByTenantIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantConfigurationByTenantIdQuery, GetTenantConfigurationByTenantIdQueryVariables>({ query: GetTenantConfigurationByTenantIdDocument, ...options });
};
export const GetTenantFeaturesDocument = gql`
    query getTenantFeatures($tenant_uuid: String!) {
  getTenantFeatures(tenant_uuid: $tenant_uuid) {
    feature_uuid
    slug
    label
  }
}
    `;

export function useGetTenantFeaturesQuery(options: Omit<Urql.UseQueryArgs<GetTenantFeaturesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantFeaturesQuery, GetTenantFeaturesQueryVariables>({ query: GetTenantFeaturesDocument, ...options });
};
export const GetTenantLeavesDocument = gql`
    query GetTenantLeaves($id: String!) {
  getTenantLeaves(id: $id) {
    code
    configuration_id
    depth
    id
    legacy
    logo_url
    name
    parent_id
    description_label
  }
}
    `;

export function useGetTenantLeavesQuery(options: Omit<Urql.UseQueryArgs<GetTenantLeavesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantLeavesQuery, GetTenantLeavesQueryVariables>({ query: GetTenantLeavesDocument, ...options });
};
export const GetTenantPolicyAssetsDocument = gql`
    query getTenantPolicyAssets($getTenantPolicyAssetsInput: GetTenantPolicyAssetsInput!) {
  getTenantPolicyAssets(getTenantPolicyAssetsInput: $getTenantPolicyAssetsInput) {
    asset_id
    asset_type
    language
    tenant_id
    updated_at
    url
    uploaded_by
  }
}
    `;

export function useGetTenantPolicyAssetsQuery(options: Omit<Urql.UseQueryArgs<GetTenantPolicyAssetsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantPolicyAssetsQuery, GetTenantPolicyAssetsQueryVariables>({ query: GetTenantPolicyAssetsDocument, ...options });
};
export const GetUserCaseNotesDocument = gql`
    query GetUserCaseNotes($getUserCaseNotesInput: GetUserCaseNotesInput!) {
  getUserCaseNotes(getUserCaseNotesInput: $getUserCaseNotesInput) {
    count
    items {
      id
      note
      created_at
      created_by {
        user_uuid
        user_name
        __typename
      }
      modified_history {
        modified_at
        modified_by {
          user_uuid
          user_name
          __typename
        }
      }
      __typename
    }
    next
    __typename
  }
}
    `;

export function useGetUserCaseNotesQuery(options: Omit<Urql.UseQueryArgs<GetUserCaseNotesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserCaseNotesQuery, GetUserCaseNotesQueryVariables>({ query: GetUserCaseNotesDocument, ...options });
};
export const GetUserFeaturesDocument = gql`
    query GetUserFeatures {
  getUserFeatures {
    feature_uuid
    label
    slug
  }
}
    `;

export function useGetUserFeaturesQuery(options?: Omit<Urql.UseQueryArgs<GetUserFeaturesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserFeaturesQuery, GetUserFeaturesQueryVariables>({ query: GetUserFeaturesDocument, ...options });
};
export const GetUserNextStepsDocument = gql`
    query GetUserNextSteps($user_uuid: String!, $searchUserNextStepInput: SearchUserNextStepInput!) {
  getUserNextSteps(user_uuid: $user_uuid) {
    next_steps(searchUserNextStepInput: $searchUserNextStepInput) {
      category
      requireUpload
      created_at
      description
      goals
      is_completable
      next_step_uuid
      coach_uuid
      order
      assigned
      status
      title
      source
      type
      updated_at
      user_uuid
      link {
        target
        type
        uri
      }
      requireUpload
      integrationContext {
        integration_type
        external_id
        external_friendly_name
        ffai_resource_id
      }
    }
    number_of_completed_steps
    total_number_of_steps
  }
}
    `;

export function useGetUserNextStepsQuery(options: Omit<Urql.UseQueryArgs<GetUserNextStepsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserNextStepsQuery, GetUserNextStepsQueryVariables>({ query: GetUserNextStepsDocument, ...options });
};
export const GetUserProfileDocument = gql`
    query GetUserProfile($locale: String, $user_uuid: String!) {
  getUserProfile(getProfileInput: {locale: $locale, user_uuid: $user_uuid}) {
    user_uuid
    anonymized_user_uuid
    communities
    first_name
    gender
    goal
    date_of_birth
    previously_contacted
    employer_id
    genderObject {
      label
      dataSource
      additionalText
    }
    ethnicities {
      label
      dataSource
      additionalText
    }
    education {
      label
      dataSource
      additionalText
    }
    communitiesObject {
      label
      dataSource
      additionalText
    }
    employment_status {
      label
      dataSource
      additionalText
    }
    industries_of_interest
    postal_code
    interest_profile {
      artistic
      enterprising
      conventional
      social
      investigative
      realistic
    }
    is_onboarded
    last_name
    skills {
      is_inferred
      id
      label
      proficiency
      skilltype
      skill_type
      skill_label
      skill_uuid
      is_custom
    }
    support_needs
    support_needs_custom
    tags {
      category
      label
      tag_uuid
    }
    target_career
    target_career_label
    target_skills
    work_experiences {
      career_uuid
      confidence_score
      company
      completion_date
      starting_date
      experience_level
      experience_type
      is_current
      job_title
      work_exp_uuid
      irrelevant_skill_uuids
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
      career(locale: $locale) {
        id
        baseline_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
        specialized_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
      }
    }
    education_experiences {
      major
      institution_name
      degree_type
      status
      starting_year
      completion_year
      is_current
      education_exp_uuid
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
    }
    certifications {
      certification_uuid
      name
      provider
      issue_date
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
    }
    career_area_interests {
      id
      label
    }
    services {
      service_uuid
      title
      provider
      stage
      start_date
      end_date
    }
    year_of_birth
    location
    country
    email
    phone_number
    postal_code
    pref_lang
    profileStep
    outcomes {
      disengaged
      other
      educated {
        degree_type
        institution_name
        program_name
      }
      employed {
        company
        job_title
        salary
        salary_type
        salary_currency_code
        employment_type
      }
      referred {
        provider
        service_name
      }
    }
    job_applications {
      job_id
      applied_at
    }
    tenants
    email_consent
    created_at
    coach_assigned {
      coach_uuid
      updated_at
      coach_data {
        first_name
        last_name
      }
    }
    recruiter_outreach
    account_preferences {
      notifications {
        topic
        frequency
        type
      }
      marketing_consent_history {
        is_consented
        created_at
      }
    }
  }
}
    `;

export function useGetUserProfileQuery(options: Omit<Urql.UseQueryArgs<GetUserProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>({ query: GetUserProfileDocument, ...options });
};
export const GetUserProfilesDocument = gql`
    query GetUserProfiles($searchInput: FindProfilesSearchInput!) {
  getUserProfiles(searchInput: $searchInput) {
    total_results
    total_pages
    profiles {
      user_uuid
      postal_code
      employer_id
      coach_assigned {
        coach_uuid
        updated_at
        coach_data {
          first_name
          last_name
        }
      }
      instance_name
      first_name
      last_name
      email
      user_tenant_role
      location
      created_at
      tenants
      tags {
        tag_uuid
        label
        category
      }
      coach_assigned {
        coach_uuid
        updated_at
        coach_data {
          first_name
          last_name
        }
      }
    }
  }
}
    `;

export function useGetUserProfilesQuery(options: Omit<Urql.UseQueryArgs<GetUserProfilesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserProfilesQuery, GetUserProfilesQueryVariables>({ query: GetUserProfilesDocument, ...options });
};
export const GetUserRoadmapDocument = gql`
    query GetUserRoadmap($locale: String, $user_uuid: String!, $searchUserNextStepInput: SearchUserNextStepInput!) {
  getUserProfile(getProfileInput: {locale: $locale, user_uuid: $user_uuid}) {
    user_uuid
    first_name
  }
  getUserNextSteps(user_uuid: $user_uuid) {
    next_steps(searchUserNextStepInput: $searchUserNextStepInput) {
      category
      created_at
      requireUpload
      description
      goals
      is_completable
      next_step_uuid
      coach_uuid
      order
      status
      title
      type
      source
      assigned
      updated_at
      user_uuid
      link {
        target
        type
        uri
      }
    }
    number_of_completed_steps
    total_number_of_steps
  }
}
    `;

export function useGetUserRoadmapQuery(options: Omit<Urql.UseQueryArgs<GetUserRoadmapQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserRoadmapQuery, GetUserRoadmapQueryVariables>({ query: GetUserRoadmapDocument, ...options });
};
export const GetUserRoleDocument = gql`
    query GetUserRole {
  getUserRole {
    label
  }
}
    `;

export function useGetUserRoleQuery(options?: Omit<Urql.UseQueryArgs<GetUserRoleQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserRoleQuery, GetUserRoleQueryVariables>({ query: GetUserRoleDocument, ...options });
};
export const GetUsersSubmissionPdfDocument = gql`
    query GetUsersSubmissionPDF($formUuid: String!, $userUuid: String!) {
  getUsersSubmissionPDF(formUuid: $formUuid, userUuid: $userUuid)
}
    `;

export function useGetUsersSubmissionPdfQuery(options: Omit<Urql.UseQueryArgs<GetUsersSubmissionPdfQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersSubmissionPdfQuery, GetUsersSubmissionPdfQueryVariables>({ query: GetUsersSubmissionPdfDocument, ...options });
};
export const GetVervoeAssessmentsDocument = gql`
    query GetVervoeAssessments($tenant_id: String!) {
  getVervoeAssessments(tenant_id: $tenant_id) {
    externalId
    name
    integrationType
  }
}
    `;

export function useGetVervoeAssessmentsQuery(options: Omit<Urql.UseQueryArgs<GetVervoeAssessmentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetVervoeAssessmentsQuery, GetVervoeAssessmentsQueryVariables>({ query: GetVervoeAssessmentsDocument, ...options });
};
export const GetWorkExperienceDocument = gql`
    query GetWorkExperience($locale: String, $user_uuid: String!) {
  getUserProfile(getProfileInput: {locale: $locale, user_uuid: $user_uuid}) {
    user_uuid
    work_experiences {
      career_uuid
    }
  }
}
    `;

export function useGetWorkExperienceQuery(options: Omit<Urql.UseQueryArgs<GetWorkExperienceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWorkExperienceQuery, GetWorkExperienceQueryVariables>({ query: GetWorkExperienceDocument, ...options });
};
export const GetWorkExperienceAndSkillsDocument = gql`
    query GetWorkExperienceAndSkills($locale: String, $user_uuid: String!) {
  getUserProfile(getProfileInput: {locale: $locale, user_uuid: $user_uuid}) {
    user_uuid
    skills {
      is_inferred
      id
      label
      proficiency
      skilltype
      skill_type
      skill_label
      skill_uuid
      is_custom
    }
    work_experiences {
      career_uuid
      confidence_score
      company
      starting_date
      completion_date
      experience_level
      experience_type
      is_current
      from_resume
      job_title
      work_exp_uuid
      irrelevant_skill_uuids
      llm_generated_skills {
        classified_skill_uuid
        llm_generated_label
      }
      career {
        id
        baseline_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
        specialized_skills {
          id
          label
          skilltype
          skill_label
          skill_type
          skill_uuid
        }
      }
    }
  }
}
    `;

export function useGetWorkExperienceAndSkillsQuery(options: Omit<Urql.UseQueryArgs<GetWorkExperienceAndSkillsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWorkExperienceAndSkillsQuery, GetWorkExperienceAndSkillsQueryVariables>({ query: GetWorkExperienceAndSkillsDocument, ...options });
};
export const HasIntegrationOfTypeDocument = gql`
    query HasIntegrationOfType($hasIntegrationOfTypeInput: FindIntegrationByTypeInput!) {
  hasIntegrationOfType(hasIntegrationOfTypeInput: $hasIntegrationOfTypeInput)
}
    `;

export function useHasIntegrationOfTypeQuery(options: Omit<Urql.UseQueryArgs<HasIntegrationOfTypeQueryVariables>, 'query'>) {
  return Urql.useQuery<HasIntegrationOfTypeQuery, HasIntegrationOfTypeQueryVariables>({ query: HasIntegrationOfTypeDocument, ...options });
};
export const SearchJobsV2Document = gql`
    query searchJobsV2($searchInput: JobService_JobSearchInput!) {
  searchJobsV2(searchInput: $searchInput) {
    total_results
    total_pages
    jobs {
      job_id
      job_title
      location
      country
      company
      created_at
      active
      archived
      description
      application_url
      application_email
      job_type
      posting_source
      department
      seniority
      expiry_date
      education_level
      work_experience_level
      classified_job_type
      classified_seniority_type
      remote_type
      __typename
    }
  }
}
    `;

export function useSearchJobsV2Query(options: Omit<Urql.UseQueryArgs<SearchJobsV2QueryVariables>, 'query'>) {
  return Urql.useQuery<SearchJobsV2Query, SearchJobsV2QueryVariables>({ query: SearchJobsV2Document, ...options });
};
export const TriggerReportingEtlDocument = gql`
    query TriggerReportingEtl($triggerReportingEtlInput: TriggerReportingEtlInput!) {
  triggerReportingEtl(triggerReportingEtlInput: $triggerReportingEtlInput) {
    statusCode
    body
  }
}
    `;

export function useTriggerReportingEtlQuery(options: Omit<Urql.UseQueryArgs<TriggerReportingEtlQueryVariables>, 'query'>) {
  return Urql.useQuery<TriggerReportingEtlQuery, TriggerReportingEtlQueryVariables>({ query: TriggerReportingEtlDocument, ...options });
};
export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  AccountPreferences?: (data: WithTypename<AccountPreferences>) => null | string,
  AccountPreferencesProfile?: (data: WithTypename<AccountPreferencesProfile>) => null | string,
  AddressEmploymentOntario?: (data: WithTypename<AddressEmploymentOntario>) => null | string,
  AgeRange?: (data: WithTypename<AgeRange>) => null | string,
  Assessment?: (data: WithTypename<Assessment>) => null | string,
  AssetMetadata?: (data: WithTypename<AssetMetadata>) => null | string,
  AssetsDto?: (data: WithTypename<AssetsDto>) => null | string,
  AsyncUpdateStatus?: (data: WithTypename<AsyncUpdateStatus>) => null | string,
  BasicProfileData?: (data: WithTypename<BasicProfileData>) => null | string,
  BatchUpdateResults?: (data: WithTypename<BatchUpdateResults>) => null | string,
  BuildUserResumeResponse?: (data: WithTypename<BuildUserResumeResponse>) => null | string,
  BulkDeleteUserData_200_response?: (data: WithTypename<BulkDeleteUserData_200_Response>) => null | string,
  BulkProfileUpdateResult?: (data: WithTypename<BulkProfileUpdateResult>) => null | string,
  BulkUpdateFailure?: (data: WithTypename<BulkUpdateFailure>) => null | string,
  CandidateRecommender_Candidate?: (data: WithTypename<CandidateRecommender_Candidate>) => null | string,
  CandidateRecommender_CandidateRecommenderOutput?: (data: WithTypename<CandidateRecommender_CandidateRecommenderOutput>) => null | string,
  CandidateRecommender_CollectionLinks?: (data: WithTypename<CandidateRecommender_CollectionLinks>) => null | string,
  CandidateRecommender_EmbeddedCandidates?: (data: WithTypename<CandidateRecommender_EmbeddedCandidates>) => null | string,
  CandidateRecommender_HealthResponse?: (data: WithTypename<CandidateRecommender_HealthResponse>) => null | string,
  CandidateRecommender_Link?: (data: WithTypename<CandidateRecommender_Link>) => null | string,
  Career?: (data: WithTypename<Career>) => null | string,
  CareerArea?: (data: WithTypename<CareerArea>) => null | string,
  CareerGraph?: (data: WithTypename<CareerGraph>) => null | string,
  CareerRecommender_Career?: (data: WithTypename<CareerRecommender_Career>) => null | string,
  CareerRecommender_CareerArea?: (data: WithTypename<CareerRecommender_CareerArea>) => null | string,
  CareerRecommender_CareerRecommenderOutput?: (data: WithTypename<CareerRecommender_CareerRecommenderOutput>) => null | string,
  CareerRecommender_CollectionLinks?: (data: WithTypename<CareerRecommender_CollectionLinks>) => null | string,
  CareerRecommender_EmbeddedCareers?: (data: WithTypename<CareerRecommender_EmbeddedCareers>) => null | string,
  CareerRecommender_HealthResponse?: (data: WithTypename<CareerRecommender_HealthResponse>) => null | string,
  CareerRecommender_Link?: (data: WithTypename<CareerRecommender_Link>) => null | string,
  CareerRecommender_MonetaryValue?: (data: WithTypename<CareerRecommender_MonetaryValue>) => null | string,
  CareerRecommender_Skill?: (data: WithTypename<CareerRecommender_Skill>) => null | string,
  CareerSkillGraph?: (data: WithTypename<CareerSkillGraph>) => null | string,
  Careers?: (data: WithTypename<Careers>) => null | string,
  CaseNote?: (data: WithTypename<CaseNote>) => null | string,
  CaseNoteEditor?: (data: WithTypename<CaseNoteEditor>) => null | string,
  CaseNoteModify?: (data: WithTypename<CaseNoteModify>) => null | string,
  Certification?: (data: WithTypename<Certification>) => null | string,
  CertificationForBuildResume?: (data: WithTypename<CertificationForBuildResume>) => null | string,
  ClientIdentificationEmploymentOntario?: (data: WithTypename<ClientIdentificationEmploymentOntario>) => null | string,
  ClientTenant?: (data: WithTypename<ClientTenant>) => null | string,
  Coach?: (data: WithTypename<Coach>) => null | string,
  CoachAssigned?: (data: WithTypename<CoachAssigned>) => null | string,
  CoachData?: (data: WithTypename<CoachData>) => null | string,
  CognitoUpdateError?: (data: WithTypename<CognitoUpdateError>) => null | string,
  CognitoUpdateResult?: (data: WithTypename<CognitoUpdateResult>) => null | string,
  CommonBatchUpdateResponse?: (data: WithTypename<CommonBatchUpdateResponse>) => null | string,
  Configuration?: (data: WithTypename<Configuration>) => null | string,
  ConfirmNextStepDocResponse?: (data: WithTypename<ConfirmNextStepDocResponse>) => null | string,
  ConfirmRegistrationResponse?: (data: WithTypename<ConfirmRegistrationResponse>) => null | string,
  Contact?: (data: WithTypename<Contact>) => null | string,
  ContactInfoEmploymentOntario?: (data: WithTypename<ContactInfoEmploymentOntario>) => null | string,
  Cost?: (data: WithTypename<Cost>) => null | string,
  CountryDivisions?: (data: WithTypename<CountryDivisions>) => null | string,
  Course?: (data: WithTypename<Course>) => null | string,
  CourseCost?: (data: WithTypename<CourseCost>) => null | string,
  CourseProvider?: (data: WithTypename<CourseProvider>) => null | string,
  CourseRecommender_Career?: (data: WithTypename<CourseRecommender_Career>) => null | string,
  CourseRecommender_CollectionLinks?: (data: WithTypename<CourseRecommender_CollectionLinks>) => null | string,
  CourseRecommender_Course?: (data: WithTypename<CourseRecommender_Course>) => null | string,
  CourseRecommender_CourseRecommenderOutput?: (data: WithTypename<CourseRecommender_CourseRecommenderOutput>) => null | string,
  CourseRecommender_Course_cost?: (data: WithTypename<CourseRecommender_Course_Cost>) => null | string,
  CourseRecommender_EmbeddedCourses?: (data: WithTypename<CourseRecommender_EmbeddedCourses>) => null | string,
  CourseRecommender_HealthResponse?: (data: WithTypename<CourseRecommender_HealthResponse>) => null | string,
  CourseRecommender_Link?: (data: WithTypename<CourseRecommender_Link>) => null | string,
  CourseRecommender_Provider?: (data: WithTypename<CourseRecommender_Provider>) => null | string,
  CourseRecommender_Skill?: (data: WithTypename<CourseRecommender_Skill>) => null | string,
  CourseResult?: (data: WithTypename<CourseResult>) => null | string,
  CourseSkillGraph?: (data: WithTypename<CourseSkillGraph>) => null | string,
  CourseSubject?: (data: WithTypename<CourseSubject>) => null | string,
  CoursesResponse?: (data: WithTypename<CoursesResponse>) => null | string,
  CreateFormResponse?: (data: WithTypename<CreateFormResponse>) => null | string,
  CurrencyInputField?: (data: WithTypename<CurrencyInputField>) => null | string,
  CurrencyInputFieldUserResponse?: (data: WithTypename<CurrencyInputFieldUserResponse>) => null | string,
  CurrentFormVersionResponse?: (data: WithTypename<CurrentFormVersionResponse>) => null | string,
  CurrentUserTenantResponse?: (data: WithTypename<CurrentUserTenantResponse>) => null | string,
  DataSourceType?: (data: WithTypename<DataSourceType>) => null | string,
  DateInputField?: (data: WithTypename<DateInputField>) => null | string,
  DateInputFieldUserResponse?: (data: WithTypename<DateInputFieldUserResponse>) => null | string,
  DeepCaseManagement?: (data: WithTypename<DeepCaseManagement>) => null | string,
  DefaultQuestionField?: (data: WithTypename<DefaultQuestionField>) => null | string,
  DefaultQuestionGraphInputField?: (data: WithTypename<DefaultQuestionGraphInputField>) => null | string,
  DefaultQuestionOptionWithTags?: (data: WithTypename<DefaultQuestionOptionWithTags>) => null | string,
  DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags?: (data: WithTypename<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags>) => null | string,
  DefaultQuestionsResponse?: (data: WithTypename<DefaultQuestionsResponse>) => null | string,
  DelNextStepDocResponse?: (data: WithTypename<DelNextStepDocResponse>) => null | string,
  DeleteConfigurationOutput?: (data: WithTypename<DeleteConfigurationOutput>) => null | string,
  DeleteEmployersByTenantResponse?: (data: WithTypename<DeleteEmployersByTenantResponse>) => null | string,
  DeleteExportReportsOutput?: (data: WithTypename<DeleteExportReportsOutput>) => null | string,
  DeleteProfilesOutput?: (data: WithTypename<DeleteProfilesOutput>) => null | string,
  DeleteTenantNextStepsOutput?: (data: WithTypename<DeleteTenantNextStepsOutput>) => null | string,
  DeleteUserNextStepsOutput?: (data: WithTypename<DeleteUserNextStepsOutput>) => null | string,
  DeleteUsersOutput?: (data: WithTypename<DeleteUsersOutput>) => null | string,
  DownloadNextStepDocsResponse?: (data: WithTypename<DownloadNextStepDocsResponse>) => null | string,
  DropdownInputField?: (data: WithTypename<DropdownInputField>) => null | string,
  DropdownInputFieldOption?: (data: WithTypename<DropdownInputFieldOption>) => null | string,
  DropdownInputFieldOptionWithTags?: (data: WithTypename<DropdownInputFieldOptionWithTags>) => null | string,
  DropdownInputFieldUserResponse?: (data: WithTypename<DropdownInputFieldUserResponse>) => null | string,
  DropdownInputFieldWithTags?: (data: WithTypename<DropdownInputFieldWithTags>) => null | string,
  EduExperienceForBuildResume?: (data: WithTypename<EduExperienceForBuildResume>) => null | string,
  EducationExperience?: (data: WithTypename<EducationExperience>) => null | string,
  EducationOutcome?: (data: WithTypename<EducationOutcome>) => null | string,
  EmailEmploymentOntario?: (data: WithTypename<EmailEmploymentOntario>) => null | string,
  EmployedOutcome?: (data: WithTypename<EmployedOutcome>) => null | string,
  Employer?: (data: WithTypename<Employer>) => null | string,
  EmployerCaseNote?: (data: WithTypename<EmployerCaseNote>) => null | string,
  EmployerSearchResult?: (data: WithTypename<EmployerSearchResult>) => null | string,
  EmploymentOntarioIdentity?: (data: WithTypename<EmploymentOntarioIdentity>) => null | string,
  EmpyraIdentity?: (data: WithTypename<EmpyraIdentity>) => null | string,
  ExistenceCheckResponse?: (data: WithTypename<ExistenceCheckResponse>) => null | string,
  ExportProfileAuthorizeResponseData?: (data: WithTypename<ExportProfileAuthorizeResponseData>) => null | string,
  ExportProfileData?: (data: WithTypename<ExportProfileData>) => null | string,
  ExportProfileDownloadCsvFileResponseData?: (data: WithTypename<ExportProfileDownloadCsvFileResponseData>) => null | string,
  ExportReport?: (data: WithTypename<ExportReport>) => null | string,
  ExternalAssessment?: (data: WithTypename<ExternalAssessment>) => null | string,
  ExternalIdentities?: (data: WithTypename<ExternalIdentities>) => null | string,
  Feature?: (data: WithTypename<Feature>) => null | string,
  FilterOptions?: (data: WithTypename<FilterOptions>) => null | string,
  Filters?: (data: WithTypename<Filters>) => null | string,
  FixedText?: (data: WithTypename<FixedText>) => null | string,
  Form?: (data: WithTypename<Form>) => null | string,
  FormAndLastSubmissionResponse?: (data: WithTypename<FormAndLastSubmissionResponse>) => null | string,
  FormBuilderTag?: (data: WithTypename<FormBuilderTag>) => null | string,
  FormResponse?: (data: WithTypename<FormResponse>) => null | string,
  FormVersionToFormBuilderResponse?: (data: WithTypename<FormVersionToFormBuilderResponse>) => null | string,
  FormsListItem?: (data: WithTypename<FormsListItem>) => null | string,
  GenerateURLResponseNextStepDoc?: (data: WithTypename<GenerateUrlResponseNextStepDoc>) => null | string,
  GenereateURLReponse?: (data: WithTypename<GenereateUrlReponse>) => null | string,
  GetAllUserUuidsWrapper?: (data: WithTypename<GetAllUserUuidsWrapper>) => null | string,
  GetCareersOutput?: (data: WithTypename<GetCareersOutput>) => null | string,
  GetLearningProvidersOutput?: (data: WithTypename<GetLearningProvidersOutput>) => null | string,
  GetLookerInsightOutput?: (data: WithTypename<GetLookerInsightOutput>) => null | string,
  GetResourcesOutput?: (data: WithTypename<GetResourcesOutput>) => null | string,
  GetTagsResponse?: (data: WithTypename<GetTagsResponse>) => null | string,
  GetUserCaseNotesResponse?: (data: WithTypename<GetUserCaseNotesResponse>) => null | string,
  GovClientConfiguration?: (data: WithTypename<GovClientConfiguration>) => null | string,
  Group?: (data: WithTypename<Group>) => null | string,
  GroupCoachFailure?: (data: WithTypename<GroupCoachFailure>) => null | string,
  GroupCoachSuccess?: (data: WithTypename<GroupCoachSuccess>) => null | string,
  GroupCoachesResult?: (data: WithTypename<GroupCoachesResult>) => null | string,
  GroupsResponse?: (data: WithTypename<GroupsResponse>) => null | string,
  HealthResponse?: (data: WithTypename<HealthResponse>) => null | string,
  InputField?: (data: WithTypename<InputField>) => null | string,
  InputFieldUserCurrencyResponse?: (data: WithTypename<InputFieldUserCurrencyResponse>) => null | string,
  InputFieldUserDateResponse?: (data: WithTypename<InputFieldUserDateResponse>) => null | string,
  InputFieldUserMultiChoiceResponse?: (data: WithTypename<InputFieldUserMultiChoiceResponse>) => null | string,
  InputFieldUserMultiChoiceResponseWithTags?: (data: WithTypename<InputFieldUserMultiChoiceResponseWithTags>) => null | string,
  InputFieldUserNumberResponse?: (data: WithTypename<InputFieldUserNumberResponse>) => null | string,
  InputFieldUserResponseWithTags?: (data: WithTypename<InputFieldUserResponseWithTags>) => null | string,
  InputFieldUserStringResponse?: (data: WithTypename<InputFieldUserStringResponse>) => null | string,
  InsightConfiguration?: (data: WithTypename<InsightConfiguration>) => null | string,
  Integration?: (data: WithTypename<Integration>) => null | string,
  IntercomIdentity?: (data: WithTypename<IntercomIdentity>) => null | string,
  InterestProfile?: (data: WithTypename<InterestProfile>) => null | string,
  Invitation?: (data: WithTypename<Invitation>) => null | string,
  InviteLink?: (data: WithTypename<InviteLink>) => null | string,
  JobApplication?: (data: WithTypename<JobApplication>) => null | string,
  JobAttributes?: (data: WithTypename<JobAttributes>) => null | string,
  JobConfigurationOutput?: (data: WithTypename<JobConfigurationOutput>) => null | string,
  JobRecommender_EmbeddedJobs?: (data: WithTypename<JobRecommender_EmbeddedJobs>) => null | string,
  JobRecommender_HealthResponse?: (data: WithTypename<JobRecommender_HealthResponse>) => null | string,
  JobRecommender_Job?: (data: WithTypename<JobRecommender_Job>) => null | string,
  JobRecommender_JobRecommenderOutput?: (data: WithTypename<JobRecommender_JobRecommenderOutput>) => null | string,
  JobService_AdditionalSkill?: (data: WithTypename<JobService_AdditionalSkill>) => null | string,
  JobService_ArchiveAllManualJobsResponse?: (data: WithTypename<JobService_ArchiveAllManualJobsResponse>) => null | string,
  JobService_EasyApplyToATSJobResponse?: (data: WithTypename<JobService_EasyApplyToAtsJobResponse>) => null | string,
  JobService_GetJobsResponseWrapper?: (data: WithTypename<JobService_GetJobsResponseWrapper>) => null | string,
  JobService_GetJobsV2ResponseWrapper?: (data: WithTypename<JobService_GetJobsV2ResponseWrapper>) => null | string,
  JobService_Job?: (data: WithTypename<JobService_Job>) => null | string,
  JobService_JobDto?: (data: WithTypename<JobService_JobDto>) => null | string,
  JobService_JobEducationNeeded?: (data: WithTypename<JobService_JobEducationNeeded>) => null | string,
  JobService_JobExperienceNeeded?: (data: WithTypename<JobService_JobExperienceNeeded>) => null | string,
  JobService_JobSalaryRange?: (data: WithTypename<JobService_JobSalaryRange>) => null | string,
  JobService_JobSearchCareer?: (data: WithTypename<JobService_JobSearchCareer>) => null | string,
  JobService_JobSearchCareerArea?: (data: WithTypename<JobService_JobSearchCareerArea>) => null | string,
  JobService_JobSearchInferredJobAttribute?: (data: WithTypename<JobService_JobSearchInferredJobAttribute>) => null | string,
  JobService_JobSearchJob?: (data: WithTypename<JobService_JobSearchJob>) => null | string,
  JobService_JobSearchMonetaryValue?: (data: WithTypename<JobService_JobSearchMonetaryValue>) => null | string,
  JobService_JobSearchResponseWrapper?: (data: WithTypename<JobService_JobSearchResponseWrapper>) => null | string,
  JobService_JobSearchRole?: (data: WithTypename<JobService_JobSearchRole>) => null | string,
  JobService_JobSearchSkill?: (data: WithTypename<JobService_JobSearchSkill>) => null | string,
  JobService_JobSearchV2ResponseWrapper?: (data: WithTypename<JobService_JobSearchV2ResponseWrapper>) => null | string,
  JobService_JobSearchV3ResponseWrapper?: (data: WithTypename<JobService_JobSearchV3ResponseWrapper>) => null | string,
  JobService_ManualJobResponse?: (data: WithTypename<JobService_ManualJobResponse>) => null | string,
  JobService_SavedJob?: (data: WithTypename<JobService_SavedJob>) => null | string,
  JobService_SkillsResponse?: (data: WithTypename<JobService_SkillsResponse>) => null | string,
  JobService_Stage?: (data: WithTypename<JobService_Stage>) => null | string,
  JobSourceConfiguration?: (data: WithTypename<JobSourceConfiguration>) => null | string,
  JobsClassifierResponse?: (data: WithTypename<JobsClassifierResponse>) => null | string,
  Label?: (data: WithTypename<Label>) => null | string,
  LearningProviders?: (data: WithTypename<LearningProviders>) => null | string,
  LibraryQL?: (data: WithTypename<LibraryQl>) => null | string,
  Link?: (data: WithTypename<Link>) => null | string,
  LlmGeneratedSkill?: (data: WithTypename<LlmGeneratedSkill>) => null | string,
  LlmGeneratedWorkExpSkills?: (data: WithTypename<LlmGeneratedWorkExpSkills>) => null | string,
  MarketingConsentHistory?: (data: WithTypename<MarketingConsentHistory>) => null | string,
  Member?: (data: WithTypename<Member>) => null | string,
  MergeIdentity?: (data: WithTypename<MergeIdentity>) => null | string,
  MonetaryValue?: (data: WithTypename<MonetaryValue>) => null | string,
  MonetaryValueGraph?: (data: WithTypename<MonetaryValueGraph>) => null | string,
  MultiChoiceCheckboxInputField?: (data: WithTypename<MultiChoiceCheckboxInputField>) => null | string,
  MultiChoiceCheckboxInputFieldOption?: (data: WithTypename<MultiChoiceCheckboxInputFieldOption>) => null | string,
  MultiChoiceCheckboxInputFieldOptionWithTags?: (data: WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags>) => null | string,
  MultiChoiceCheckboxInputFieldUserResponse?: (data: WithTypename<MultiChoiceCheckboxInputFieldUserResponse>) => null | string,
  MultiChoiceCheckboxInputFieldUserResponseWithTags?: (data: WithTypename<MultiChoiceCheckboxInputFieldUserResponseWithTags>) => null | string,
  MultiChoiceCheckboxInputFieldWithTags?: (data: WithTypename<MultiChoiceCheckboxInputFieldWithTags>) => null | string,
  NextStepDoc?: (data: WithTypename<NextStepDoc>) => null | string,
  NextStepDocDownload?: (data: WithTypename<NextStepDocDownload>) => null | string,
  NextStepsConfigurations?: (data: WithTypename<NextStepsConfigurations>) => null | string,
  NextStepsMetadata?: (data: WithTypename<NextStepsMetadata>) => null | string,
  Notification?: (data: WithTypename<Notification>) => null | string,
  OnboardingFormResponse?: (data: WithTypename<OnboardingFormResponse>) => null | string,
  Outcomes?: (data: WithTypename<Outcomes>) => null | string,
  Pagination?: (data: WithTypename<Pagination>) => null | string,
  PhoneEmploymentOntario?: (data: WithTypename<PhoneEmploymentOntario>) => null | string,
  PipelineMetadataOutput?: (data: WithTypename<PipelineMetadataOutput>) => null | string,
  PreSignedURLForCustomerType?: (data: WithTypename<PreSignedUrlForCustomerType>) => null | string,
  PreSignedURLType?: (data: WithTypename<PreSignedUrlType>) => null | string,
  PresignedPost?: (data: WithTypename<PresignedPost>) => null | string,
  ProcessQueue_200_response?: (data: WithTypename<ProcessQueue_200_Response>) => null | string,
  ProcessUserResumeQueue_200_response?: (data: WithTypename<ProcessUserResumeQueue_200_Response>) => null | string,
  Profile?: (data: WithTypename<Profile>) => null | string,
  ProfilesQueryWrapper?: (data: WithTypename<ProfilesQueryWrapper>) => null | string,
  Recruiter?: (data: WithTypename<Recruiter>) => null | string,
  RecruiterEmployerReference?: (data: WithTypename<RecruiterEmployerReference>) => null | string,
  RecruiterResponse?: (data: WithTypename<RecruiterResponse>) => null | string,
  RecruiterSearchResult?: (data: WithTypename<RecruiterSearchResult>) => null | string,
  ReferralOutcome?: (data: WithTypename<ReferralOutcome>) => null | string,
  RegistrationCustomQuestionsExportResponse?: (data: WithTypename<RegistrationCustomQuestionsExportResponse>) => null | string,
  RegistrationForm?: (data: WithTypename<RegistrationForm>) => null | string,
  RegistrationInputField?: (data: WithTypename<RegistrationInputField>) => null | string,
  RegistrationResponse?: (data: WithTypename<RegistrationResponse>) => null | string,
  RegistrationSubmissionResponse?: (data: WithTypename<RegistrationSubmissionResponse>) => null | string,
  RegistrationTextInputField?: (data: WithTypename<RegistrationTextInputField>) => null | string,
  RemoveUsersFromFormBuilderResponse?: (data: WithTypename<RemoveUsersFromFormBuilderResponse>) => null | string,
  RemoveUsersOutput?: (data: WithTypename<RemoveUsersOutput>) => null | string,
  ReportsAssessmentsData?: (data: WithTypename<ReportsAssessmentsData>) => null | string,
  Resource?: (data: WithTypename<Resource>) => null | string,
  ResourceProvider?: (data: WithTypename<ResourceProvider>) => null | string,
  ResourceRecommender_CollectionLinks?: (data: WithTypename<ResourceRecommender_CollectionLinks>) => null | string,
  ResourceRecommender_EmbeddedResources?: (data: WithTypename<ResourceRecommender_EmbeddedResources>) => null | string,
  ResourceRecommender_HealthResponse?: (data: WithTypename<ResourceRecommender_HealthResponse>) => null | string,
  ResourceRecommender_Link?: (data: WithTypename<ResourceRecommender_Link>) => null | string,
  ResourceRecommender_Resource?: (data: WithTypename<ResourceRecommender_Resource>) => null | string,
  ResourceRecommender_ResourceRecommenderOutput?: (data: WithTypename<ResourceRecommender_ResourceRecommenderOutput>) => null | string,
  ResourceRecommender_Resource_cost?: (data: WithTypename<ResourceRecommender_Resource_Cost>) => null | string,
  ResourceRecommender_Resource_provider?: (data: WithTypename<ResourceRecommender_Resource_Provider>) => null | string,
  Resources?: (data: WithTypename<Resources>) => null | string,
  RestApiClient?: (data: WithTypename<RestApiClient>) => null | string,
  ResumeBuilderResponse?: (data: WithTypename<ResumeBuilderResponse>) => null | string,
  ResumeParserResponse?: (data: WithTypename<ResumeParserResponse>) => null | string,
  Roadmap?: (data: WithTypename<Roadmap>) => null | string,
  RoadmapStats?: (data: WithTypename<RoadmapStats>) => null | string,
  Role?: (data: WithTypename<Role>) => null | string,
  SaveCareerOutput?: (data: WithTypename<SaveCareerOutput>) => null | string,
  SaveCareerStatusOutput?: (data: WithTypename<SaveCareerStatusOutput>) => null | string,
  SaveLearningOutput?: (data: WithTypename<SaveLearningOutput>) => null | string,
  SaveLearningStatusOutput?: (data: WithTypename<SaveLearningStatusOutput>) => null | string,
  SaveResourceOutput?: (data: WithTypename<SaveResourceOutput>) => null | string,
  SaveResourceStatusOutput?: (data: WithTypename<SaveResourceStatusOutput>) => null | string,
  SavedTalent?: (data: WithTypename<SavedTalent>) => null | string,
  SearchQueryProcessingResponse?: (data: WithTypename<SearchQueryProcessingResponse>) => null | string,
  SelectedMultiChoiceCheckboxInputFieldOption?: (data: WithTypename<SelectedMultiChoiceCheckboxInputFieldOption>) => null | string,
  SelectedMultiChoiceCheckboxInputFieldOptionWithTags?: (data: WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>) => null | string,
  ServerError?: (data: WithTypename<ServerError>) => null | string,
  ServerError_response?: (data: WithTypename<ServerError_Response>) => null | string,
  Service?: (data: WithTypename<Service>) => null | string,
  Skill?: (data: WithTypename<Skill>) => null | string,
  SkillClassifierResponse?: (data: WithTypename<SkillClassifierResponse>) => null | string,
  SkillModification?: (data: WithTypename<SkillModification>) => null | string,
  SubmissionResponse?: (data: WithTypename<SubmissionResponse>) => null | string,
  SubscribeUserCustomerLogo_200_response?: (data: WithTypename<SubscribeUserCustomerLogo_200_Response>) => null | string,
  SubscribeUserProfilePic_200_response?: (data: WithTypename<SubscribeUserProfilePic_200_Response>) => null | string,
  SubscribeUserResume_200_response?: (data: WithTypename<SubscribeUserResume_200_Response>) => null | string,
  Tag?: (data: WithTypename<Tag>) => null | string,
  TagRule?: (data: WithTypename<TagRule>) => null | string,
  TagRuleGroup?: (data: WithTypename<TagRuleGroup>) => null | string,
  TaggingUserTag?: (data: WithTypename<TaggingUserTag>) => null | string,
  TargetCareer?: (data: WithTypename<TargetCareer>) => null | string,
  Tenant?: (data: WithTypename<Tenant>) => null | string,
  TenantAssetOutput?: (data: WithTypename<TenantAssetOutput>) => null | string,
  TenantFeatureBulkResult?: (data: WithTypename<TenantFeatureBulkResult>) => null | string,
  TenantRole?: (data: WithTypename<TenantRole>) => null | string,
  TestSchema?: (data: WithTypename<TestSchema>) => null | string,
  TextInputField?: (data: WithTypename<TextInputField>) => null | string,
  TextInputFieldUserResponse?: (data: WithTypename<TextInputFieldUserResponse>) => null | string,
  TranslatedField?: (data: WithTypename<TranslatedField>) => null | string,
  Translation?: (data: WithTypename<Translation>) => null | string,
  TranslationField?: (data: WithTypename<TranslationField>) => null | string,
  TranslationGraph?: (data: WithTypename<TranslationGraph>) => null | string,
  TriggerReportingEtlResponse?: (data: WithTypename<TriggerReportingEtlResponse>) => null | string,
  UnsaveCareerOutput?: (data: WithTypename<UnsaveCareerOutput>) => null | string,
  UnsaveLearningOutput?: (data: WithTypename<UnsaveLearningOutput>) => null | string,
  UnsaveResourceOutput?: (data: WithTypename<UnsaveResourceOutput>) => null | string,
  UpdateUserTenantOutput?: (data: WithTypename<UpdateUserTenantOutput>) => null | string,
  UserNextStepIntegration?: (data: WithTypename<UserNextStepIntegration>) => null | string,
  UserNextSteps?: (data: WithTypename<UserNextSteps>) => null | string,
  UserNextStepsWrapper?: (data: WithTypename<UserNextStepsWrapper>) => null | string,
  UserProfileRole?: (data: WithTypename<UserProfileRole>) => null | string,
  UserReference?: (data: WithTypename<UserReference>) => null | string,
  UserSkill?: (data: WithTypename<UserSkill>) => null | string,
  UserSkillsGenerationResponse?: (data: WithTypename<UserSkillsGenerationResponse>) => null | string,
  UserTag?: (data: WithTypename<UserTag>) => null | string,
  UserType?: (data: WithTypename<UserType>) => null | string,
  ValidationError?: (data: WithTypename<ValidationError>) => null | string,
  VervoeInviteToAssessmentResponse?: (data: WithTypename<VervoeInviteToAssessmentResponse>) => null | string,
  WorkExperience?: (data: WithTypename<WorkExperience>) => null | string,
  WorkExperienceForBuildResume?: (data: WithTypename<WorkExperienceForBuildResume>) => null | string,
  comprehensiveDeleteTenant_200_response?: (data: WithTypename<ComprehensiveDeleteTenant_200_Response>) => null | string,
  deleteCachedResumeData_200_response?: (data: WithTypename<DeleteCachedResumeData_200_Response>) => null | string,
  deleteFormBuilderData_200_response?: (data: WithTypename<DeleteFormBuilderData_200_Response>) => null | string,
  deleteResume_200_response?: (data: WithTypename<DeleteResume_200_Response>) => null | string,
  getInvitations_200_response?: (data: WithTypename<GetInvitations_200_Response>) => null | string,
  getInviteLinks_200_response?: (data: WithTypename<GetInviteLinks_200_Response>) => null | string,
  health_200_response?: (data: WithTypename<Health_200_Response>) => null | string,
  listMembers_200_response?: (data: WithTypename<ListMembers_200_Response>) => null | string,
  listSubTenants_200_response?: (data: WithTypename<ListSubTenants_200_Response>) => null | string,
  listTenants_200_response?: (data: WithTypename<ListTenants_200_Response>) => null | string,
  mutation_BulkDeleteUserData_oneOf_0_msg?: (data: WithTypename<Mutation_BulkDeleteUserData_OneOf_0_Msg>) => null | string,
  mutation_BulkDeleteUserData_oneOf_0_nextStepDocs?: (data: WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs>) => null | string,
  mutation_BulkDeleteUserData_oneOf_0_nextStepDocs_filesDeleted_items?: (data: WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs_FilesDeleted_Items>) => null | string,
  mutation_BulkDeleteUserData_oneOf_0_userResumes?: (data: WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes>) => null | string,
  mutation_BulkDeleteUserData_oneOf_0_userResumes_filesDeleted_items?: (data: WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes_FilesDeleted_Items>) => null | string,
  mutation_LLMGenerateSkills_oneOf_1_detail_items_loc_items?: (data: WithTypename<Mutation_LlmGenerateSkills_OneOf_1_Detail_Items_Loc_Items>) => null | string,
  mutation_deleteRecruiters_items?: (data: WithTypename<Mutation_DeleteRecruiters_Items>) => null | string,
  query_SubscribeUserCustomerLogo_oneOf_0_ResponseMetadata?: (data: WithTypename<Query_SubscribeUserCustomerLogo_OneOf_0_ResponseMetadata>) => null | string,
  query_SubscribeUserProfilePic_oneOf_0_ResponseMetadata?: (data: WithTypename<Query_SubscribeUserProfilePic_OneOf_0_ResponseMetadata>) => null | string,
  query_SubscribeUserResume_oneOf_0_ResponseMetadata?: (data: WithTypename<Query_SubscribeUserResume_OneOf_0_ResponseMetadata>) => null | string,
  query_classifyJobTitle_oneOf_1_detail_items_loc_items?: (data: WithTypename<Query_ClassifyJobTitle_OneOf_1_Detail_Items_Loc_Items>) => null | string,
  query_extractJobAttributes_oneOf_1_detail_items_loc_items?: (data: WithTypename<Query_ExtractJobAttributes_OneOf_1_Detail_Items_Loc_Items>) => null | string,
  query_listUserFormSubmissionsByFormId_items?: (data: WithTypename<Query_ListUserFormSubmissionsByFormId_Items>) => null | string,
  query_listUserFormSubmissionsByFormId_items_data_items?: (data: WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    adminGetUserFeatures?: GraphCacheResolver<WithTypename<Query>, QueryAdminGetUserFeaturesArgs, Array<WithTypename<Feature> | string>>,
    careerAreaByUUIDS?: GraphCacheResolver<WithTypename<Query>, QueryCareerAreaByUuidsArgs, Array<WithTypename<CareerArea> | string>>,
    careerAreas?: GraphCacheResolver<WithTypename<Query>, QueryCareerAreasArgs, Array<WithTypename<CareerArea> | string>>,
    checkExistenceByEmail?: GraphCacheResolver<WithTypename<Query>, QueryCheckExistenceByEmailArgs, WithTypename<ExistenceCheckResponse> | string>,
    checkProfileExistence?: GraphCacheResolver<WithTypename<Query>, QueryCheckProfileExistenceArgs, Scalars['Boolean'] | string>,
    classifyJobTitle?: GraphCacheResolver<WithTypename<Query>, QueryClassifyJobTitleArgs, WithTypename<ClassifyJobTitle_Response> | string>,
    classifyJobTitles?: GraphCacheResolver<WithTypename<Query>, QueryClassifyJobTitlesArgs, Array<WithTypename<ClassifyJobTitles_Response> | string>>,
    classifySkillTitle?: GraphCacheResolver<WithTypename<Query>, QueryClassifySkillTitleArgs, WithTypename<ClassifySkillTitle_Response> | string>,
    classifySkillTitles?: GraphCacheResolver<WithTypename<Query>, QueryClassifySkillTitlesArgs, Array<WithTypename<ClassifySkillTitles_Response> | string>>,
    downloadJsonFileContent?: GraphCacheResolver<WithTypename<Query>, QueryDownloadJsonFileContentArgs, Scalars['String'] | string>,
    downloadNextStepDoc?: GraphCacheResolver<WithTypename<Query>, QueryDownloadNextStepDocArgs, WithTypename<DownloadNextStepDocsResponse> | string>,
    extractJobAttributes?: GraphCacheResolver<WithTypename<Query>, QueryExtractJobAttributesArgs, Array<WithTypename<ExtractJobAttributes_Response> | string>>,
    findEmployer?: GraphCacheResolver<WithTypename<Query>, QueryFindEmployerArgs, WithTypename<Employer> | string>,
    findGroup?: GraphCacheResolver<WithTypename<Query>, QueryFindGroupArgs, WithTypename<Group> | string>,
    findIntegrationById?: GraphCacheResolver<WithTypename<Query>, QueryFindIntegrationByIdArgs, WithTypename<Integration> | string>,
    findIntegrationByType?: GraphCacheResolver<WithTypename<Query>, QueryFindIntegrationByTypeArgs, WithTypename<Integration> | string>,
    getActiveDefaultQuestions?: GraphCacheResolver<WithTypename<Query>, QueryGetActiveDefaultQuestionsArgs, Array<WithTypename<DefaultQuestionsResponse> | string>>,
    getAllAssessments?: GraphCacheResolver<WithTypename<Query>, QueryGetAllAssessmentsArgs, Array<WithTypename<Assessment> | string>>,
    getAllCoaches?: GraphCacheResolver<WithTypename<Query>, QueryGetAllCoachesArgs, Array<WithTypename<Profile> | string>>,
    getAllFeatures?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Feature> | string>>,
    getAllNextStepsMetadata?: GraphCacheResolver<WithTypename<Query>, QueryGetAllNextStepsMetadataArgs, Array<WithTypename<NextStepsMetadata> | string>>,
    getAllPolicyAssets?: GraphCacheResolver<WithTypename<Query>, QueryGetAllPolicyAssetsArgs, Array<WithTypename<AssetsDto> | string>>,
    getAllTags?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Tag> | string>>,
    getAllUserUuids?: GraphCacheResolver<WithTypename<Query>, QueryGetAllUserUuidsArgs, WithTypename<GetAllUserUuidsWrapper> | string>,
    getAssessmentsForReport?: GraphCacheResolver<WithTypename<Query>, QueryGetAssessmentsForReportArgs, Array<WithTypename<ReportsAssessmentsData> | string>>,
    getAsyncUpdateStatuses?: GraphCacheResolver<WithTypename<Query>, QueryGetAsyncUpdateStatusesArgs, Array<WithTypename<AsyncUpdateStatus> | string>>,
    getBasicExportedReportProfiles?: GraphCacheResolver<WithTypename<Query>, QueryGetBasicExportedReportProfilesArgs, Array<WithTypename<BasicProfileData> | string>>,
    getCandidateRecommendations?: GraphCacheResolver<WithTypename<Query>, QueryGetCandidateRecommendationsArgs, WithTypename<CandidateRecommender_CandidateRecommenderOutput> | string>,
    getCareer?: GraphCacheResolver<WithTypename<Query>, QueryGetCareerArgs, WithTypename<CareerGraph> | string>,
    getCareerMatchByID?: GraphCacheResolver<WithTypename<Query>, QueryGetCareerMatchByIdArgs, WithTypename<CareerRecommender_Career> | string>,
    getCareerRecommendations?: GraphCacheResolver<WithTypename<Query>, QueryGetCareerRecommendationsArgs, WithTypename<CareerRecommender_CareerRecommenderOutput> | string>,
    getCareers?: GraphCacheResolver<WithTypename<Query>, QueryGetCareersArgs, WithTypename<GetCareersOutput> | string>,
    getChildTenants?: GraphCacheResolver<WithTypename<Query>, QueryGetChildTenantsArgs, Array<WithTypename<Tenant> | string>>,
    getCombinedExportReports?: GraphCacheResolver<WithTypename<Query>, QueryGetCombinedExportReportsArgs, Array<WithTypename<ExportReport> | string>>,
    getConfiguration?: GraphCacheResolver<WithTypename<Query>, QueryGetConfigurationArgs, WithTypename<FilterOptions> | string>,
    getCourse?: GraphCacheResolver<WithTypename<Query>, QueryGetCourseArgs, WithTypename<Course> | string>,
    getCourseRecommendations?: GraphCacheResolver<WithTypename<Query>, QueryGetCourseRecommendationsArgs, WithTypename<CourseRecommender_CourseRecommenderOutput> | string>,
    getCourses?: GraphCacheResolver<WithTypename<Query>, QueryGetCoursesArgs, WithTypename<CoursesResponse> | string>,
    getCurrentFormVersion?: GraphCacheResolver<WithTypename<Query>, QueryGetCurrentFormVersionArgs, WithTypename<CurrentFormVersionResponse> | string>,
    getCurrentUserTenant?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<Tenant> | string>,
    getCurrentUserTenantNestedV1?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<CurrentUserTenantResponse> | string>,
    getDefaultQuestions?: GraphCacheResolver<WithTypename<Query>, QueryGetDefaultQuestionsArgs, Array<WithTypename<DefaultQuestionsResponse> | string>>,
    getEmployers?: GraphCacheResolver<WithTypename<Query>, QueryGetEmployersArgs, WithTypename<EmployerSearchResult> | string>,
    getExportReport?: GraphCacheResolver<WithTypename<Query>, QueryGetExportReportArgs, WithTypename<ExportReport> | string>,
    getExportedProfilesCSV?: GraphCacheResolver<WithTypename<Query>, QueryGetExportedProfilesCsvArgs, Scalars['String'] | string>,
    getExportedReportProfiles?: GraphCacheResolver<WithTypename<Query>, QueryGetExportedReportProfilesArgs, Array<WithTypename<ExportProfileData> | string>>,
    getFormAndLastSubmission?: GraphCacheResolver<WithTypename<Query>, QueryGetFormAndLastSubmissionArgs, WithTypename<FormAndLastSubmissionResponse> | string>,
    getFormDetails?: GraphCacheResolver<WithTypename<Query>, QueryGetFormDetailsArgs, WithTypename<Form> | string>,
    getFormVersionToFormBuilder?: GraphCacheResolver<WithTypename<Query>, QueryGetFormVersionToFormBuilderArgs, WithTypename<FormVersionToFormBuilderResponse> | string>,
    getForms?: GraphCacheResolver<WithTypename<Query>, QueryGetFormsArgs, Array<WithTypename<Form> | string>>,
    getFormsLibrary?: GraphCacheResolver<WithTypename<Query>, QueryGetFormsLibraryArgs, Array<WithTypename<FormResponse> | string>>,
    getFormsList?: GraphCacheResolver<WithTypename<Query>, QueryGetFormsListArgs, Array<WithTypename<FormsListItem> | string>>,
    getGroups?: GraphCacheResolver<WithTypename<Query>, QueryGetGroupsArgs, WithTypename<GroupsResponse> | string>,
    getInvitation?: GraphCacheResolver<WithTypename<Query>, QueryGetInvitationArgs, WithTypename<Invitation> | string>,
    getInvitations?: GraphCacheResolver<WithTypename<Query>, QueryGetInvitationsArgs, WithTypename<GetInvitations_200_Response> | string>,
    getInviteLink?: GraphCacheResolver<WithTypename<Query>, QueryGetInviteLinkArgs, WithTypename<InviteLink> | string>,
    getInviteLinkByTenant?: GraphCacheResolver<WithTypename<Query>, QueryGetInviteLinkByTenantArgs, WithTypename<InviteLink> | string>,
    getInviteLinks?: GraphCacheResolver<WithTypename<Query>, QueryGetInviteLinksArgs, WithTypename<GetInviteLinks_200_Response> | string>,
    getJob?: GraphCacheResolver<WithTypename<Query>, QueryGetJobArgs, WithTypename<JobService_JobSearchJob> | string>,
    getJobConfiguration?: GraphCacheResolver<WithTypename<Query>, QueryGetJobConfigurationArgs, WithTypename<JobConfigurationOutput> | string>,
    getJobRecommendations?: GraphCacheResolver<WithTypename<Query>, QueryGetJobRecommendationsArgs, WithTypename<JobRecommender_JobRecommenderOutput> | string>,
    getJobRecommendationsByIds?: GraphCacheResolver<WithTypename<Query>, QueryGetJobRecommendationsByIdsArgs, WithTypename<JobRecommender_JobRecommenderOutput> | string>,
    getJobs?: GraphCacheResolver<WithTypename<Query>, QueryGetJobsArgs, WithTypename<JobService_GetJobsResponseWrapper> | string>,
    getJobsV2?: GraphCacheResolver<WithTypename<Query>, QueryGetJobsV2Args, WithTypename<JobService_GetJobsV2ResponseWrapper> | string>,
    getLearningProviders?: GraphCacheResolver<WithTypename<Query>, QueryGetLearningProvidersArgs, WithTypename<GetLearningProvidersOutput> | string>,
    getLocations?: GraphCacheResolver<WithTypename<Query>, QueryGetLocationsArgs, Array<WithTypename<CountryDivisions> | string>>,
    getLookerSSOEmbedURL?: GraphCacheResolver<WithTypename<Query>, QueryGetLookerSsoEmbedUrlArgs, WithTypename<GetLookerInsightOutput> | string>,
    getNextStepsConfigurations?: GraphCacheResolver<WithTypename<Query>, QueryGetNextStepsConfigurationsArgs, WithTypename<NextStepsConfigurations> | string>,
    getNextStepsLibrary?: GraphCacheResolver<WithTypename<Query>, QueryGetNextStepsLibraryArgs, WithTypename<LibraryQl> | string>,
    getNextStepsMetadata?: GraphCacheResolver<WithTypename<Query>, QueryGetNextStepsMetadataArgs, WithTypename<NextStepsMetadata> | string>,
    getOnboardingForm?: GraphCacheResolver<WithTypename<Query>, QueryGetOnboardingFormArgs, WithTypename<OnboardingFormResponse> | string>,
    getPipelineMetadata?: GraphCacheResolver<WithTypename<Query>, QueryGetPipelineMetadataArgs, WithTypename<PipelineMetadataOutput> | string>,
    getPostObjectURL?: GraphCacheResolver<WithTypename<Query>, QueryGetPostObjectUrlArgs, WithTypename<GenereateUrlReponse> | string>,
    getPostObjectURLNextStepDoc?: GraphCacheResolver<WithTypename<Query>, QueryGetPostObjectUrlNextStepDocArgs, WithTypename<GenerateUrlResponseNextStepDoc> | string>,
    getProfileFilters?: GraphCacheResolver<WithTypename<Query>, QueryGetProfileFiltersArgs, WithTypename<Filters> | string>,
    getProfilesFromEmails?: GraphCacheResolver<WithTypename<Query>, QueryGetProfilesFromEmailsArgs, Array<WithTypename<Profile> | string>>,
    getProfilesFromUuids?: GraphCacheResolver<WithTypename<Query>, QueryGetProfilesFromUuidsArgs, Array<WithTypename<Profile> | string>>,
    getRecruiterByEmail?: GraphCacheResolver<WithTypename<Query>, QueryGetRecruiterByEmailArgs, WithTypename<Recruiter> | string>,
    getRecruiterByUserUuid?: GraphCacheResolver<WithTypename<Query>, QueryGetRecruiterByUserUuidArgs, WithTypename<Recruiter> | string>,
    getRecruiterSavedTalents?: GraphCacheResolver<WithTypename<Query>, QueryGetRecruiterSavedTalentsArgs, Array<WithTypename<SavedTalent> | string>>,
    getRecruiterSavedTalentsByJob?: GraphCacheResolver<WithTypename<Query>, QueryGetRecruiterSavedTalentsByJobArgs, Array<WithTypename<SavedTalent> | string>>,
    getRecruiters?: GraphCacheResolver<WithTypename<Query>, QueryGetRecruitersArgs, WithTypename<RecruiterSearchResult> | string>,
    getRegistrationCustomQuestionsResponses?: GraphCacheResolver<WithTypename<Query>, QueryGetRegistrationCustomQuestionsResponsesArgs, Array<WithTypename<RegistrationCustomQuestionsExportResponse> | string>>,
    getRegistrationForm?: GraphCacheResolver<WithTypename<Query>, QueryGetRegistrationFormArgs, WithTypename<RegistrationForm> | string>,
    getRegistrationSubmission?: GraphCacheResolver<WithTypename<Query>, QueryGetRegistrationSubmissionArgs, WithTypename<RegistrationSubmissionResponse> | string>,
    getRegistrationSubmissionsForTenantUsers?: GraphCacheResolver<WithTypename<Query>, QueryGetRegistrationSubmissionsForTenantUsersArgs, Array<WithTypename<RegistrationSubmissionResponse> | string>>,
    getRelatedTenants?: GraphCacheResolver<WithTypename<Query>, QueryGetRelatedTenantsArgs, Array<WithTypename<Tenant> | string>>,
    getResource?: GraphCacheResolver<WithTypename<Query>, QueryGetResourceArgs, WithTypename<Resource> | string>,
    getResourceRecommendations?: GraphCacheResolver<WithTypename<Query>, QueryGetResourceRecommendationsArgs, WithTypename<ResourceRecommender_ResourceRecommenderOutput> | string>,
    getResources?: GraphCacheResolver<WithTypename<Query>, QueryGetResourcesArgs, WithTypename<GetResourcesOutput> | string>,
    getRestApiClient?: GraphCacheResolver<WithTypename<Query>, QueryGetRestApiClientArgs, WithTypename<RestApiClient> | string>,
    getRestClientConfigurations?: GraphCacheResolver<WithTypename<Query>, QueryGetRestClientConfigurationsArgs, Array<WithTypename<Configuration> | string>>,
    getRoadmap?: GraphCacheResolver<WithTypename<Query>, QueryGetRoadmapArgs, WithTypename<Roadmap> | string>,
    getRoadmapStats?: GraphCacheResolver<WithTypename<Query>, QueryGetRoadmapStatsArgs, WithTypename<RoadmapStats> | string>,
    getSavedCareers?: GraphCacheResolver<WithTypename<Query>, QueryGetSavedCareersArgs, Array<Scalars['String'] | string>>,
    getSavedCareersStatus?: GraphCacheResolver<WithTypename<Query>, QueryGetSavedCareersStatusArgs, Array<WithTypename<SaveCareerStatusOutput> | string>>,
    getSavedLearnings?: GraphCacheResolver<WithTypename<Query>, QueryGetSavedLearningsArgs, Array<Scalars['String'] | string>>,
    getSavedLearningsStatus?: GraphCacheResolver<WithTypename<Query>, QueryGetSavedLearningsStatusArgs, Array<WithTypename<SaveLearningStatusOutput> | string>>,
    getSavedResources?: GraphCacheResolver<WithTypename<Query>, QueryGetSavedResourcesArgs, Array<Scalars['String'] | string>>,
    getSavedResourcesStatus?: GraphCacheResolver<WithTypename<Query>, QueryGetSavedResourcesStatusArgs, Array<WithTypename<SaveResourceStatusOutput> | string>>,
    getTags?: GraphCacheResolver<WithTypename<Query>, QueryGetTagsArgs, WithTypename<GetTagsResponse> | string>,
    getTenant?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantArgs, WithTypename<Tenant> | string>,
    getTenantAssets?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantAssetsArgs, WithTypename<TenantAssetOutput> | string>,
    getTenantByClientId?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantByClientIdArgs, WithTypename<ClientTenant> | string>,
    getTenantConfiguration?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantConfigurationArgs, WithTypename<Configuration> | string>,
    getTenantConfigurationByTenantId?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantConfigurationByTenantIdArgs, WithTypename<Configuration> | string>,
    getTenantFeatures?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantFeaturesArgs, Array<WithTypename<Feature> | string>>,
    getTenantLeaves?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantLeavesArgs, Array<WithTypename<Tenant> | string>>,
    getTenantPolicyAssets?: GraphCacheResolver<WithTypename<Query>, QueryGetTenantPolicyAssetsArgs, Array<WithTypename<AssetsDto> | string>>,
    getTotalProfilesCount?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Scalars['Int'] | string>,
    getTranslation?: GraphCacheResolver<WithTypename<Query>, QueryGetTranslationArgs, WithTypename<TranslationGraph> | string>,
    getTranslations?: GraphCacheResolver<WithTypename<Query>, QueryGetTranslationsArgs, Array<WithTypename<TranslationGraph> | string>>,
    getUserCaseNotes?: GraphCacheResolver<WithTypename<Query>, QueryGetUserCaseNotesArgs, WithTypename<GetUserCaseNotesResponse> | string>,
    getUserFeatures?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Feature> | string>>,
    getUserNextSteps?: GraphCacheResolver<WithTypename<Query>, QueryGetUserNextStepsArgs, WithTypename<UserNextStepsWrapper> | string>,
    getUserProfile?: GraphCacheResolver<WithTypename<Query>, QueryGetUserProfileArgs, WithTypename<Profile> | string>,
    getUserProfiles?: GraphCacheResolver<WithTypename<Query>, QueryGetUserProfilesArgs, WithTypename<ProfilesQueryWrapper> | string>,
    getUserRole?: GraphCacheResolver<WithTypename<Query>, QueryGetUserRoleArgs, WithTypename<Role> | string>,
    getUserTags?: GraphCacheResolver<WithTypename<Query>, QueryGetUserTagsArgs, Array<WithTypename<TaggingUserTag> | string>>,
    getUsersSubmissionPDF?: GraphCacheResolver<WithTypename<Query>, QueryGetUsersSubmissionPdfArgs, Scalars['JSON'] | string>,
    getVervoeAssessments?: GraphCacheResolver<WithTypename<Query>, QueryGetVervoeAssessmentsArgs, Array<WithTypename<ExternalAssessment> | string>>,
    hasIntegrationOfType?: GraphCacheResolver<WithTypename<Query>, QueryHasIntegrationOfTypeArgs, Scalars['Boolean'] | string>,
    hello?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<TestSchema> | string>,
    isTokenRevoked?: GraphCacheResolver<WithTypename<Query>, QueryIsTokenRevokedArgs, Scalars['Boolean'] | string>,
    listMembers?: GraphCacheResolver<WithTypename<Query>, QueryListMembersArgs, WithTypename<ListMembers_200_Response> | string>,
    listSubTenants?: GraphCacheResolver<WithTypename<Query>, QueryListSubTenantsArgs, WithTypename<ListSubTenants_200_Response> | string>,
    listTenants?: GraphCacheResolver<WithTypename<Query>, QueryListTenantsArgs, WithTypename<ListTenants_200_Response> | string>,
    listUserFormSubmissionsByFormId?: GraphCacheResolver<WithTypename<Query>, QueryListUserFormSubmissionsByFormIdArgs, Array<WithTypename<Query_ListUserFormSubmissionsByFormId_Items> | string>>,
    savedJobsByUserUUID?: GraphCacheResolver<WithTypename<Query>, QuerySavedJobsByUserUuidArgs, Array<WithTypename<JobService_SavedJob> | string>>,
    searchJobs?: GraphCacheResolver<WithTypename<Query>, QuerySearchJobsArgs, WithTypename<JobService_JobSearchResponseWrapper> | string>,
    searchJobsV2?: GraphCacheResolver<WithTypename<Query>, QuerySearchJobsV2Args, WithTypename<JobService_JobSearchV2ResponseWrapper> | string>,
    searchJobsV3?: GraphCacheResolver<WithTypename<Query>, QuerySearchJobsV3Args, WithTypename<JobService_JobSearchV3ResponseWrapper> | string>,
    triggerReportingEtl?: GraphCacheResolver<WithTypename<Query>, QueryTriggerReportingEtlArgs, WithTypename<TriggerReportingEtlResponse> | string>
  },
  AccountPreferences?: {
    marketing_consent_history?: GraphCacheResolver<WithTypename<AccountPreferences>, Record<string, never>, Array<WithTypename<MarketingConsentHistory> | string>>,
    notifications?: GraphCacheResolver<WithTypename<AccountPreferences>, Record<string, never>, Array<WithTypename<Notification> | string>>
  },
  AccountPreferencesProfile?: {
    account_preferences?: GraphCacheResolver<WithTypename<AccountPreferencesProfile>, Record<string, never>, WithTypename<AccountPreferences> | string>,
    user_uuid?: GraphCacheResolver<WithTypename<AccountPreferencesProfile>, Record<string, never>, Scalars['String'] | string>
  },
  AddressEmploymentOntario?: {
    address_type?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    city?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    country?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    postal_code?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    province?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    state?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    street_1?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    street_2?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    suite?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    zip?: GraphCacheResolver<WithTypename<AddressEmploymentOntario>, Record<string, never>, Scalars['String'] | string>
  },
  AgeRange?: {
    max_age?: GraphCacheResolver<WithTypename<AgeRange>, Record<string, never>, Scalars['Float'] | string>,
    min_age?: GraphCacheResolver<WithTypename<AgeRange>, Record<string, never>, Scalars['Float'] | string>
  },
  Assessment?: {
    assessment_uuid?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['DateTime'] | string>,
    created_by?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    link?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    result?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['DateTime'] | string>,
    updated_by?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<Assessment>, Record<string, never>, Scalars['String'] | string>
  },
  AssetMetadata?: {
    cookie_policy?: GraphCacheResolver<WithTypename<AssetMetadata>, Record<string, never>, WithTypename<TranslationField> | string>,
    privacy_policy?: GraphCacheResolver<WithTypename<AssetMetadata>, Record<string, never>, WithTypename<TranslationField> | string>,
    terms_of_service?: GraphCacheResolver<WithTypename<AssetMetadata>, Record<string, never>, WithTypename<TranslationField> | string>
  },
  AssetsDto?: {
    asset_id?: GraphCacheResolver<WithTypename<AssetsDto>, Record<string, never>, Scalars['String'] | string>,
    asset_type?: GraphCacheResolver<WithTypename<AssetsDto>, Record<string, never>, SupportedAssetType | string>,
    language?: GraphCacheResolver<WithTypename<AssetsDto>, Record<string, never>, SupportedLanguages | string>,
    tenant_id?: GraphCacheResolver<WithTypename<AssetsDto>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<AssetsDto>, Record<string, never>, Scalars['DateTime'] | string>,
    uploaded_by?: GraphCacheResolver<WithTypename<AssetsDto>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<AssetsDto>, Record<string, never>, Scalars['String'] | string>
  },
  AsyncUpdateStatus?: {
    async_update_status_uuid?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, Scalars['String'] | string>,
    callback_url?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, Scalars['DateTime'] | string>,
    record_id?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, Scalars['String'] | string>,
    record_type?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, EoAsyncUpdateStatusType | string>,
    response?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, Scalars['JSON'] | string>,
    status?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, EoAsyncUpdateStatus | string>,
    updated_at?: GraphCacheResolver<WithTypename<AsyncUpdateStatus>, Record<string, never>, Scalars['DateTime'] | string>
  },
  BasicProfileData?: {
    email?: GraphCacheResolver<WithTypename<BasicProfileData>, Record<string, never>, Scalars['String'] | string>,
    first_name?: GraphCacheResolver<WithTypename<BasicProfileData>, Record<string, never>, Scalars['String'] | string>,
    last_name?: GraphCacheResolver<WithTypename<BasicProfileData>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<BasicProfileData>, Record<string, never>, Scalars['String'] | string>
  },
  BatchUpdateResults?: {
    description?: GraphCacheResolver<WithTypename<BatchUpdateResults>, Record<string, never>, Scalars['String'] | string>,
    externalRecordID?: GraphCacheResolver<WithTypename<BatchUpdateResults>, Record<string, never>, Scalars['String'] | string>,
    referenceNumber?: GraphCacheResolver<WithTypename<BatchUpdateResults>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<BatchUpdateResults>, Record<string, never>, Scalars['Boolean'] | string>
  },
  BuildUserResumeResponse?: {
    base64File?: GraphCacheResolver<WithTypename<BuildUserResumeResponse>, Record<string, never>, Scalars['String'] | string>
  },
  BulkDeleteUserData_200_response?: {
    deletedCount?: GraphCacheResolver<WithTypename<BulkDeleteUserData_200_Response>, Record<string, never>, Scalars['Float'] | string>,
    msg?: GraphCacheResolver<WithTypename<BulkDeleteUserData_200_Response>, Record<string, never>, WithTypename<Mutation_BulkDeleteUserData_OneOf_0_Msg> | string>,
    nextStepDocs?: GraphCacheResolver<WithTypename<BulkDeleteUserData_200_Response>, Record<string, never>, WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs> | string>,
    statusCode?: GraphCacheResolver<WithTypename<BulkDeleteUserData_200_Response>, Record<string, never>, Scalars['Float'] | string>,
    userResumes?: GraphCacheResolver<WithTypename<BulkDeleteUserData_200_Response>, Record<string, never>, WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes> | string>
  },
  BulkProfileUpdateResult?: {
    failed?: GraphCacheResolver<WithTypename<BulkProfileUpdateResult>, Record<string, never>, Array<WithTypename<BulkUpdateFailure> | string>>,
    successful?: GraphCacheResolver<WithTypename<BulkProfileUpdateResult>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  BulkUpdateFailure?: {
    error?: GraphCacheResolver<WithTypename<BulkUpdateFailure>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<BulkUpdateFailure>, Record<string, never>, Scalars['String'] | string>
  },
  CandidateRecommender_Candidate?: {
    education_match?: GraphCacheResolver<WithTypename<CandidateRecommender_Candidate>, Record<string, never>, Scalars['Float'] | string>,
    experience_match?: GraphCacheResolver<WithTypename<CandidateRecommender_Candidate>, Record<string, never>, Scalars['Float'] | string>,
    id?: GraphCacheResolver<WithTypename<CandidateRecommender_Candidate>, Record<string, never>, Scalars['String'] | string>,
    match?: GraphCacheResolver<WithTypename<CandidateRecommender_Candidate>, Record<string, never>, Scalars['Float'] | string>,
    overqualification?: GraphCacheResolver<WithTypename<CandidateRecommender_Candidate>, Record<string, never>, Scalars['Boolean'] | string>,
    skill_match?: GraphCacheResolver<WithTypename<CandidateRecommender_Candidate>, Record<string, never>, Scalars['Float'] | string>,
    trainable?: GraphCacheResolver<WithTypename<CandidateRecommender_Candidate>, Record<string, never>, Scalars['Boolean'] | string>
  },
  CandidateRecommender_CandidateRecommenderOutput?: {
    _embedded?: GraphCacheResolver<WithTypename<CandidateRecommender_CandidateRecommenderOutput>, Record<string, never>, WithTypename<CandidateRecommender_EmbeddedCandidates> | string>,
    _links?: GraphCacheResolver<WithTypename<CandidateRecommender_CandidateRecommenderOutput>, Record<string, never>, WithTypename<CandidateRecommender_CollectionLinks> | string>,
    count?: GraphCacheResolver<WithTypename<CandidateRecommender_CandidateRecommenderOutput>, Record<string, never>, Scalars['Int'] | string>,
    total?: GraphCacheResolver<WithTypename<CandidateRecommender_CandidateRecommenderOutput>, Record<string, never>, Scalars['Int'] | string>
  },
  CandidateRecommender_CollectionLinks?: {
    first?: GraphCacheResolver<WithTypename<CandidateRecommender_CollectionLinks>, Record<string, never>, WithTypename<CandidateRecommender_Link> | string>,
    last?: GraphCacheResolver<WithTypename<CandidateRecommender_CollectionLinks>, Record<string, never>, WithTypename<CandidateRecommender_Link> | string>,
    next?: GraphCacheResolver<WithTypename<CandidateRecommender_CollectionLinks>, Record<string, never>, WithTypename<CandidateRecommender_Link> | string>,
    prev?: GraphCacheResolver<WithTypename<CandidateRecommender_CollectionLinks>, Record<string, never>, WithTypename<CandidateRecommender_Link> | string>,
    self?: GraphCacheResolver<WithTypename<CandidateRecommender_CollectionLinks>, Record<string, never>, WithTypename<CandidateRecommender_Link> | string>
  },
  CandidateRecommender_EmbeddedCandidates?: {
    candidates?: GraphCacheResolver<WithTypename<CandidateRecommender_EmbeddedCandidates>, Record<string, never>, Array<WithTypename<CandidateRecommender_Candidate> | string>>
  },
  CandidateRecommender_HealthResponse?: {
    status?: GraphCacheResolver<WithTypename<CandidateRecommender_HealthResponse>, Record<string, never>, CandidateRecommender_Query_Healthcheck_Status | string>
  },
  CandidateRecommender_Link?: {
    href?: GraphCacheResolver<WithTypename<CandidateRecommender_Link>, Record<string, never>, Scalars['URL'] | string>
  },
  Career?: {
    baseline_skills?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, Array<WithTypename<Skill> | string>>,
    career_area?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, WithTypename<CareerArea> | string>,
    description?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, Scalars['String'] | string>,
    interest_profile_code?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, Scalars['String'] | string>,
    salary?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, WithTypename<MonetaryValue> | string>,
    specialized_skills?: GraphCacheResolver<WithTypename<Career>, Record<string, never>, Array<WithTypename<Skill> | string>>
  },
  CareerArea?: {
    career_area_id?: GraphCacheResolver<WithTypename<CareerArea>, Record<string, never>, Scalars['String'] | string>,
    career_area_name?: GraphCacheResolver<WithTypename<CareerArea>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<CareerArea>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<CareerArea>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<CareerArea>, Record<string, never>, Scalars['String'] | string>,
    machine_key?: GraphCacheResolver<WithTypename<CareerArea>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<CareerArea>, Record<string, never>, Scalars['String'] | string>
  },
  CareerGraph?: {
    _id?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, Scalars['String'] | string>,
    baselineSkills?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, Array<WithTypename<CareerSkillGraph> | string>>,
    careerArea?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, WithTypename<CareerArea> | string>,
    description?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, Scalars['String'] | string>,
    interestProfileCode?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, Scalars['String'] | string>,
    salary?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, WithTypename<MonetaryValueGraph> | string>,
    specializedSkills?: GraphCacheResolver<WithTypename<CareerGraph>, Record<string, never>, Array<WithTypename<CareerSkillGraph> | string>>
  },
  CareerRecommender_Career?: {
    baseline_skills?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Array<WithTypename<CareerRecommender_Skill> | string>>,
    career_area?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, WithTypename<CareerRecommender_CareerArea> | string>,
    demand?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Scalars['Float'] | string>,
    description?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Scalars['String'] | string>,
    interest_profile_code?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Scalars['CareerRecommender_query_get_career_recommendations__embedded_careers_items_interest_profile_code'] | string>,
    label?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Scalars['String'] | string>,
    match?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Scalars['Float'] | string>,
    salary?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, WithTypename<CareerRecommender_MonetaryValue> | string>,
    specialized_skills?: GraphCacheResolver<WithTypename<CareerRecommender_Career>, Record<string, never>, Array<WithTypename<CareerRecommender_Skill> | string>>
  },
  CareerRecommender_CareerArea?: {
    id?: GraphCacheResolver<WithTypename<CareerRecommender_CareerArea>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<CareerRecommender_CareerArea>, Record<string, never>, Scalars['String'] | string>
  },
  CareerRecommender_CareerRecommenderOutput?: {
    _embedded?: GraphCacheResolver<WithTypename<CareerRecommender_CareerRecommenderOutput>, Record<string, never>, WithTypename<CareerRecommender_EmbeddedCareers> | string>,
    _links?: GraphCacheResolver<WithTypename<CareerRecommender_CareerRecommenderOutput>, Record<string, never>, WithTypename<CareerRecommender_CollectionLinks> | string>,
    count?: GraphCacheResolver<WithTypename<CareerRecommender_CareerRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>,
    total?: GraphCacheResolver<WithTypename<CareerRecommender_CareerRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>
  },
  CareerRecommender_CollectionLinks?: {
    first?: GraphCacheResolver<WithTypename<CareerRecommender_CollectionLinks>, Record<string, never>, WithTypename<CareerRecommender_Link> | string>,
    last?: GraphCacheResolver<WithTypename<CareerRecommender_CollectionLinks>, Record<string, never>, WithTypename<CareerRecommender_Link> | string>,
    next?: GraphCacheResolver<WithTypename<CareerRecommender_CollectionLinks>, Record<string, never>, WithTypename<CareerRecommender_Link> | string>,
    prev?: GraphCacheResolver<WithTypename<CareerRecommender_CollectionLinks>, Record<string, never>, WithTypename<CareerRecommender_Link> | string>,
    self?: GraphCacheResolver<WithTypename<CareerRecommender_CollectionLinks>, Record<string, never>, WithTypename<CareerRecommender_Link> | string>
  },
  CareerRecommender_EmbeddedCareers?: {
    careers?: GraphCacheResolver<WithTypename<CareerRecommender_EmbeddedCareers>, Record<string, never>, Array<WithTypename<CareerRecommender_Career> | string>>
  },
  CareerRecommender_HealthResponse?: {
    status?: GraphCacheResolver<WithTypename<CareerRecommender_HealthResponse>, Record<string, never>, CareerRecommender_Query_Healthcheck_Status | string>
  },
  CareerRecommender_Link?: {
    href?: GraphCacheResolver<WithTypename<CareerRecommender_Link>, Record<string, never>, Scalars['String'] | string>
  },
  CareerRecommender_MonetaryValue?: {
    currency?: GraphCacheResolver<WithTypename<CareerRecommender_MonetaryValue>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<CareerRecommender_MonetaryValue>, Record<string, never>, Scalars['Float'] | string>
  },
  CareerRecommender_Skill?: {
    id?: GraphCacheResolver<WithTypename<CareerRecommender_Skill>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<CareerRecommender_Skill>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<CareerRecommender_Skill>, Record<string, never>, CareerRecommender_Query_Get_Career_Recommendations__Embedded_Careers_Items_Baseline_Skills_Items_Skilltype | string>
  },
  CareerSkillGraph?: {
    id?: GraphCacheResolver<WithTypename<CareerSkillGraph>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<CareerSkillGraph>, Record<string, never>, Scalars['String'] | string>,
    proficiency?: GraphCacheResolver<WithTypename<CareerSkillGraph>, Record<string, never>, Scalars['Float'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<CareerSkillGraph>, Record<string, never>, Scalars['String'] | string>
  },
  Careers?: {
    career_area?: GraphCacheResolver<WithTypename<Careers>, Record<string, never>, WithTypename<CareerArea> | string>,
    career_id?: GraphCacheResolver<WithTypename<Careers>, Record<string, never>, Scalars['String'] | string>,
    career_name?: GraphCacheResolver<WithTypename<Careers>, Record<string, never>, Scalars['String'] | string>
  },
  CaseNote?: {
    created_at?: GraphCacheResolver<WithTypename<CaseNote>, Record<string, never>, Scalars['DateTime'] | string>,
    created_by?: GraphCacheResolver<WithTypename<CaseNote>, Record<string, never>, WithTypename<CaseNoteEditor> | string>,
    id?: GraphCacheResolver<WithTypename<CaseNote>, Record<string, never>, Scalars['String'] | string>,
    modified_history?: GraphCacheResolver<WithTypename<CaseNote>, Record<string, never>, Array<WithTypename<CaseNoteModify> | string>>,
    note?: GraphCacheResolver<WithTypename<CaseNote>, Record<string, never>, Scalars['String'] | string>
  },
  CaseNoteEditor?: {
    user_name?: GraphCacheResolver<WithTypename<CaseNoteEditor>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<CaseNoteEditor>, Record<string, never>, Scalars['String'] | string>
  },
  CaseNoteModify?: {
    modified_at?: GraphCacheResolver<WithTypename<CaseNoteModify>, Record<string, never>, Scalars['DateTime'] | string>,
    modified_by?: GraphCacheResolver<WithTypename<CaseNoteModify>, Record<string, never>, WithTypename<CaseNoteEditor> | string>
  },
  Certification?: {
    certification_uuid?: GraphCacheResolver<WithTypename<Certification>, Record<string, never>, Scalars['String'] | string>,
    issue_date?: GraphCacheResolver<WithTypename<Certification>, Record<string, never>, Scalars['DateTime'] | string>,
    llm_generated_skills?: GraphCacheResolver<WithTypename<Certification>, Record<string, never>, Array<WithTypename<LlmGeneratedSkill> | string>>,
    name?: GraphCacheResolver<WithTypename<Certification>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<Certification>, Record<string, never>, Scalars['String'] | string>
  },
  CertificationForBuildResume?: {
    issue_year?: GraphCacheResolver<WithTypename<CertificationForBuildResume>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<CertificationForBuildResume>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<CertificationForBuildResume>, Record<string, never>, Scalars['String'] | string>
  },
  ClientIdentificationEmploymentOntario?: {
    deaf?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    deaf_blind?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    first_nations?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    francophone?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    inuit?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    metis?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    new_comer?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    odsp_ind?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    person_with_disability?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    racialized_person?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    youth_ind?: GraphCacheResolver<WithTypename<ClientIdentificationEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>
  },
  ClientTenant?: {
    cognito_client_id?: GraphCacheResolver<WithTypename<ClientTenant>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<ClientTenant>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ClientTenant>, Record<string, never>, Scalars['String'] | string>
  },
  Coach?: {
    coach_uuid?: GraphCacheResolver<WithTypename<Coach>, Record<string, never>, Scalars['String'] | string>,
    group_uuid?: GraphCacheResolver<WithTypename<Coach>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<Coach>, Record<string, never>, WithTypename<UserReference> | string>
  },
  CoachAssigned?: {
    coach_data?: GraphCacheResolver<WithTypename<CoachAssigned>, Record<string, never>, Array<WithTypename<CoachData> | string>>,
    coach_uuid?: GraphCacheResolver<WithTypename<CoachAssigned>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<CoachAssigned>, Record<string, never>, Scalars['DateTime'] | string>
  },
  CoachData?: {
    first_name?: GraphCacheResolver<WithTypename<CoachData>, Record<string, never>, Scalars['String'] | string>,
    last_name?: GraphCacheResolver<WithTypename<CoachData>, Record<string, never>, Scalars['String'] | string>
  },
  CognitoUpdateError?: {
    error?: GraphCacheResolver<WithTypename<CognitoUpdateError>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<CognitoUpdateError>, Record<string, never>, Scalars['String'] | string>
  },
  CognitoUpdateResult?: {
    failed?: GraphCacheResolver<WithTypename<CognitoUpdateResult>, Record<string, never>, Array<WithTypename<CognitoUpdateError> | string>>,
    successful?: GraphCacheResolver<WithTypename<CognitoUpdateResult>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  CommonBatchUpdateResponse?: {
    callBackURL?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['String'] | string>,
    entityType?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['String'] | string>,
    message?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['String'] | string>,
    numberOfRecordReceived?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['Float'] | string>,
    receivedDateTime?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['String'] | string>,
    requestID?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['String'] | string>,
    requestStatus?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['String'] | string>,
    requestType?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['String'] | string>,
    resultInd?: GraphCacheResolver<WithTypename<CommonBatchUpdateResponse>, Record<string, never>, Scalars['Boolean'] | string>
  },
  Configuration?: {
    assets?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<AssetMetadata> | string>,
    banner?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<TranslationField> | string>,
    careers?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<FilterOptions> | string>,
    configuration_id?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['String'] | string>,
    course_providers?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<FilterOptions> | string>,
    date_posted_range?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['Float'] | string>,
    deep_case_management?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<DeepCaseManagement> | string>,
    default_coach_visibility?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['String'] | string>,
    default_policy_language?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, SupportedLanguages | string>,
    featured_job_providers?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Array<JobSource | string>>,
    insight_configuration?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Array<WithTypename<InsightConfiguration> | string>>,
    job_employer_restriction?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['Boolean'] | string>,
    job_language_restriction?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['Boolean'] | string>,
    job_sources?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<JobSourceConfiguration> | string>,
    label?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['String'] | string>,
    link?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['String'] | string>,
    location_restrictions?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Array<WithTypename<CountryDivisions> | string>>,
    marketingConsent?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<TranslationField> | string>,
    overlayDescription?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<TranslationField> | string>,
    overlayTitle?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<TranslationField> | string>,
    resources?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<FilterOptions> | string>,
    rest_client_id?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['String'] | string>,
    rest_client_ids?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Array<Scalars['String'] | string>>,
    show_all_candidates?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['Boolean'] | string>,
    show_candidate_tag_rule?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, WithTypename<TagRule> | string>,
    show_course_recs_for_jobs?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['Boolean'] | string>,
    supported_career_area_ids?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Array<Scalars['String'] | string>>,
    tenant_id?: GraphCacheResolver<WithTypename<Configuration>, Record<string, never>, Scalars['String'] | string>
  },
  ConfirmNextStepDocResponse?: {
    finalDocs?: GraphCacheResolver<WithTypename<ConfirmNextStepDocResponse>, Record<string, never>, Array<WithTypename<NextStepDoc> | string>>,
    statusCode?: GraphCacheResolver<WithTypename<ConfirmNextStepDocResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  ConfirmRegistrationResponse?: {
    verified?: GraphCacheResolver<WithTypename<ConfirmRegistrationResponse>, Record<string, never>, Scalars['Boolean'] | string>
  },
  Contact?: {
    contact_uuid?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['String'] | string>,
    contacted_by_name?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['String'] | string>,
    contacted_by_uuid?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['DateTime'] | string>,
    employer_name?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['String'] | string>,
    employer_uuid?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['String'] | string>,
    job_id?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['String'] | string>,
    job_title?: GraphCacheResolver<WithTypename<Contact>, Record<string, never>, Scalars['String'] | string>
  },
  ContactInfoEmploymentOntario?: {
    addresses?: GraphCacheResolver<WithTypename<ContactInfoEmploymentOntario>, Record<string, never>, Array<WithTypename<AddressEmploymentOntario> | string>>,
    emails?: GraphCacheResolver<WithTypename<ContactInfoEmploymentOntario>, Record<string, never>, Array<WithTypename<EmailEmploymentOntario> | string>>,
    phones?: GraphCacheResolver<WithTypename<ContactInfoEmploymentOntario>, Record<string, never>, Array<WithTypename<PhoneEmploymentOntario> | string>>
  },
  Cost?: {
    currency?: GraphCacheResolver<WithTypename<Cost>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<Cost>, Record<string, never>, Scalars['Float'] | string>
  },
  CountryDivisions?: {
    country_code?: GraphCacheResolver<WithTypename<CountryDivisions>, Record<string, never>, Scalars['String'] | string>,
    country_label?: GraphCacheResolver<WithTypename<CountryDivisions>, CountryDivisionsCountry_LabelArgs, Scalars['String'] | string>,
    division_code?: GraphCacheResolver<WithTypename<CountryDivisions>, Record<string, never>, Scalars['String'] | string>,
    division_label?: GraphCacheResolver<WithTypename<CountryDivisions>, CountryDivisionsDivision_LabelArgs, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<CountryDivisions>, Record<string, never>, Scalars['String'] | string>
  },
  Course?: {
    careers?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Array<WithTypename<CareerGraph> | string>>,
    cost?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, WithTypename<CourseCost> | string>,
    country?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    course_results?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Array<WithTypename<CourseResult> | string>>,
    course_type?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Array<Scalars['String'] | string>>,
    credits_accepted?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['Boolean'] | string>,
    delivery?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    diplomas?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Array<Scalars['String'] | string>>,
    duration_hours?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['Float'] | string>,
    duration_unit?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    duration_value?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['Float'] | string>,
    lang?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    lang_label?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    level?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    location?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, WithTypename<CourseProvider> | string>,
    skills?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Array<WithTypename<CourseSkillGraph> | string>>,
    source_id?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    subjects?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Array<WithTypename<CourseSubject> | string>>,
    subscription_required?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['Boolean'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<Course>, Record<string, never>, Scalars['String'] | string>
  },
  CourseCost?: {
    currency?: GraphCacheResolver<WithTypename<CourseCost>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<CourseCost>, Record<string, never>, Scalars['Float'] | string>
  },
  CourseProvider?: {
    _id?: GraphCacheResolver<WithTypename<CourseProvider>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<CourseProvider>, Record<string, never>, Scalars['String'] | string>
  },
  CourseRecommender_Career?: {
    id?: GraphCacheResolver<WithTypename<CourseRecommender_Career>, Record<string, never>, Scalars['UUID'] | string>,
    label?: GraphCacheResolver<WithTypename<CourseRecommender_Career>, Record<string, never>, Scalars['String'] | string>
  },
  CourseRecommender_CollectionLinks?: {
    first?: GraphCacheResolver<WithTypename<CourseRecommender_CollectionLinks>, Record<string, never>, WithTypename<CourseRecommender_Link> | string>,
    last?: GraphCacheResolver<WithTypename<CourseRecommender_CollectionLinks>, Record<string, never>, WithTypename<CourseRecommender_Link> | string>,
    next?: GraphCacheResolver<WithTypename<CourseRecommender_CollectionLinks>, Record<string, never>, WithTypename<CourseRecommender_Link> | string>,
    prev?: GraphCacheResolver<WithTypename<CourseRecommender_CollectionLinks>, Record<string, never>, WithTypename<CourseRecommender_Link> | string>,
    self?: GraphCacheResolver<WithTypename<CourseRecommender_CollectionLinks>, Record<string, never>, WithTypename<CourseRecommender_Link> | string>
  },
  CourseRecommender_Course?: {
    careers?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Array<WithTypename<CourseRecommender_Career> | string>>,
    cost?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, WithTypename<CourseRecommender_Course_Cost> | string>,
    credits_accepted?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['Boolean'] | string>,
    diplomas?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Array<Scalars['String'] | string>>,
    duration_hours?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['Float'] | string>,
    duration_unit?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['String'] | string>,
    duration_value?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['Float'] | string>,
    id?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['UUID'] | string>,
    label?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['String'] | string>,
    owner?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, WithTypename<CourseRecommender_Provider> | string>,
    provider?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, WithTypename<CourseRecommender_Provider> | string>,
    skills?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Array<WithTypename<CourseRecommender_Skill> | string>>,
    subscription_required?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['Boolean'] | string>,
    url?: GraphCacheResolver<WithTypename<CourseRecommender_Course>, Record<string, never>, Scalars['URL'] | string>
  },
  CourseRecommender_CourseRecommenderOutput?: {
    _embedded?: GraphCacheResolver<WithTypename<CourseRecommender_CourseRecommenderOutput>, Record<string, never>, WithTypename<CourseRecommender_EmbeddedCourses> | string>,
    _links?: GraphCacheResolver<WithTypename<CourseRecommender_CourseRecommenderOutput>, Record<string, never>, WithTypename<CourseRecommender_CollectionLinks> | string>,
    count?: GraphCacheResolver<WithTypename<CourseRecommender_CourseRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>,
    total?: GraphCacheResolver<WithTypename<CourseRecommender_CourseRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>
  },
  CourseRecommender_Course_cost?: {
    currency?: GraphCacheResolver<WithTypename<CourseRecommender_Course_Cost>, Record<string, never>, Scalars['CourseRecommender_query_get_course_recommendations__embedded_courses_items_cost_currency'] | string>,
    value?: GraphCacheResolver<WithTypename<CourseRecommender_Course_Cost>, Record<string, never>, Scalars['Float'] | string>
  },
  CourseRecommender_EmbeddedCourses?: {
    courses?: GraphCacheResolver<WithTypename<CourseRecommender_EmbeddedCourses>, Record<string, never>, Array<WithTypename<CourseRecommender_Course> | string>>
  },
  CourseRecommender_HealthResponse?: {
    status?: GraphCacheResolver<WithTypename<CourseRecommender_HealthResponse>, Record<string, never>, CourseRecommender_Query_Healthcheck_Status | string>
  },
  CourseRecommender_Link?: {
    href?: GraphCacheResolver<WithTypename<CourseRecommender_Link>, Record<string, never>, Scalars['URL'] | string>
  },
  CourseRecommender_Provider?: {
    id?: GraphCacheResolver<WithTypename<CourseRecommender_Provider>, Record<string, never>, Scalars['UUID'] | string>,
    label?: GraphCacheResolver<WithTypename<CourseRecommender_Provider>, Record<string, never>, Scalars['String'] | string>,
    logo_url?: GraphCacheResolver<WithTypename<CourseRecommender_Provider>, Record<string, never>, Scalars['URL'] | string>
  },
  CourseRecommender_Skill?: {
    id?: GraphCacheResolver<WithTypename<CourseRecommender_Skill>, Record<string, never>, Scalars['UUID'] | string>,
    label?: GraphCacheResolver<WithTypename<CourseRecommender_Skill>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<CourseRecommender_Skill>, Record<string, never>, CourseRecommender_Query_Get_Course_Recommendations__Embedded_Courses_Items_Skills_Items_Skilltype | string>
  },
  CourseResult?: {
    result?: GraphCacheResolver<WithTypename<CourseResult>, Record<string, never>, Scalars['String'] | string>,
    result_type?: GraphCacheResolver<WithTypename<CourseResult>, Record<string, never>, Scalars['String'] | string>
  },
  CourseSkillGraph?: {
    pref_label?: GraphCacheResolver<WithTypename<CourseSkillGraph>, Record<string, never>, Scalars['String'] | string>,
    skill_uuid?: GraphCacheResolver<WithTypename<CourseSkillGraph>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<CourseSkillGraph>, Record<string, never>, Scalars['String'] | string>
  },
  CourseSubject?: {
    _id?: GraphCacheResolver<WithTypename<CourseSubject>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<CourseSubject>, Record<string, never>, Scalars['String'] | string>
  },
  CoursesResponse?: {
    count?: GraphCacheResolver<WithTypename<CoursesResponse>, Record<string, never>, Scalars['Int'] | string>,
    courses?: GraphCacheResolver<WithTypename<CoursesResponse>, Record<string, never>, Array<WithTypename<Course> | string>>
  },
  CreateFormResponse?: {
    form_uuid?: GraphCacheResolver<WithTypename<CreateFormResponse>, Record<string, never>, Scalars['String'] | string>,
    form_version_uuid?: GraphCacheResolver<WithTypename<CreateFormResponse>, Record<string, never>, Scalars['String'] | string>
  },
  CurrencyInputField?: {
    description?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    hasRange?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    order?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, WithTypename<InputFieldUserCurrencyResponse> | string>,
    uuid?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<CurrencyInputField>, Record<string, never>, Scalars['Int'] | string>
  },
  CurrencyInputFieldUserResponse?: {
    max?: GraphCacheResolver<WithTypename<CurrencyInputFieldUserResponse>, Record<string, never>, Scalars['Float'] | string>,
    min?: GraphCacheResolver<WithTypename<CurrencyInputFieldUserResponse>, Record<string, never>, Scalars['Float'] | string>
  },
  CurrentFormVersionResponse?: {
    components?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Array<WithTypename<InputField> | string>>,
    data?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['JSON'] | string>,
    description?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['String'] | string>,
    description_i18n?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, WithTypename<TranslatedField> | string>,
    formUuid?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['String'] | string>,
    formVersionUuid?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['String'] | string>,
    source_language?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['String'] | string>,
    title_i18n?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, WithTypename<TranslatedField> | string>,
    version?: GraphCacheResolver<WithTypename<CurrentFormVersionResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  CurrentUserTenantResponse?: {
    tenant?: GraphCacheResolver<WithTypename<CurrentUserTenantResponse>, Record<string, never>, WithTypename<Tenant> | string>
  },
  DataSourceType?: {
    additionalText?: GraphCacheResolver<WithTypename<DataSourceType>, Record<string, never>, Scalars['String'] | string>,
    dataSource?: GraphCacheResolver<WithTypename<DataSourceType>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<DataSourceType>, Record<string, never>, Scalars['String'] | string>
  },
  DateInputField?: {
    description?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    hasRange?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    order?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, WithTypename<InputFieldUserDateResponse> | string>,
    uuid?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<DateInputField>, Record<string, never>, Scalars['Int'] | string>
  },
  DateInputFieldUserResponse?: {
    endDate?: GraphCacheResolver<WithTypename<DateInputFieldUserResponse>, Record<string, never>, Scalars['String'] | string>,
    startDate?: GraphCacheResolver<WithTypename<DateInputFieldUserResponse>, Record<string, never>, Scalars['String'] | string>
  },
  DeepCaseManagement?: {
    gov_client_configuration?: GraphCacheResolver<WithTypename<DeepCaseManagement>, Record<string, never>, WithTypename<GovClientConfiguration> | string>
  },
  DefaultQuestionField?: {
    description?: GraphCacheResolver<WithTypename<DefaultQuestionField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<DefaultQuestionField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<DefaultQuestionField>, Record<string, never>, Scalars['Boolean'] | string>,
    options?: GraphCacheResolver<WithTypename<DefaultQuestionField>, Record<string, never>, Array<WithTypename<DefaultQuestionOptionWithTags> | string>>,
    questionText?: GraphCacheResolver<WithTypename<DefaultQuestionField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<DefaultQuestionField>, Record<string, never>, WithTypename<TranslatedField> | string>
  },
  DefaultQuestionGraphInputField?: {
    description?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    options?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Array<WithTypename<DefaultQuestionOptionWithTags> | string>>,
    order?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, WithTypename<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags> | string>,
    uuid?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<DefaultQuestionGraphInputField>, Record<string, never>, Scalars['Int'] | string>
  },
  DefaultQuestionOptionWithTags?: {
    additionalText?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, Scalars['String'] | string>,
    additionalTextI18n?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    dataSource?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, Scalars['String'] | string>,
    isChecked?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    requiredAditionalTextBox?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    tags?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, Array<WithTypename<FormBuilderTag> | string>>,
    text?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, Scalars['String'] | string>,
    textI18n?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    value?: GraphCacheResolver<WithTypename<DefaultQuestionOptionWithTags>, Record<string, never>, Scalars['Int'] | string>
  },
  DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags>, Record<string, never>, Scalars['String'] | string>,
    textValue?: GraphCacheResolver<WithTypename<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<DefaultQuestionsMultiChoiceInputFieldUserResponseWithTags>, Record<string, never>, Array<WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags> | string>>
  },
  DefaultQuestionsResponse?: {
    assigned_form_title?: GraphCacheResolver<WithTypename<DefaultQuestionsResponse>, Record<string, never>, Scalars['String'] | string>,
    is_active?: GraphCacheResolver<WithTypename<DefaultQuestionsResponse>, Record<string, never>, Scalars['Boolean'] | string>,
    is_assigned?: GraphCacheResolver<WithTypename<DefaultQuestionsResponse>, Record<string, never>, Scalars['Boolean'] | string>,
    question?: GraphCacheResolver<WithTypename<DefaultQuestionsResponse>, Record<string, never>, WithTypename<DefaultQuestionField> | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<DefaultQuestionsResponse>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<DefaultQuestionsResponse>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<DefaultQuestionsResponse>, Record<string, never>, Scalars['String'] | string>
  },
  DelNextStepDocResponse?: {
    docDeleted?: GraphCacheResolver<WithTypename<DelNextStepDocResponse>, Record<string, never>, Scalars['String'] | string>,
    msg?: GraphCacheResolver<WithTypename<DelNextStepDocResponse>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<DelNextStepDocResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  DeleteConfigurationOutput?: {
    deletedStatus?: GraphCacheResolver<WithTypename<DeleteConfigurationOutput>, Record<string, never>, Scalars['String'] | string>
  },
  DeleteEmployersByTenantResponse?: {
    deletedCount?: GraphCacheResolver<WithTypename<DeleteEmployersByTenantResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  DeleteExportReportsOutput?: {
    deleteStatus?: GraphCacheResolver<WithTypename<DeleteExportReportsOutput>, Record<string, never>, Scalars['String'] | string>
  },
  DeleteProfilesOutput?: {
    deletedCount?: GraphCacheResolver<WithTypename<DeleteProfilesOutput>, Record<string, never>, Scalars['Float'] | string>
  },
  DeleteTenantNextStepsOutput?: {
    deleteStatus?: GraphCacheResolver<WithTypename<DeleteTenantNextStepsOutput>, Record<string, never>, Scalars['String'] | string>
  },
  DeleteUserNextStepsOutput?: {
    deletedCount?: GraphCacheResolver<WithTypename<DeleteUserNextStepsOutput>, Record<string, never>, Scalars['Float'] | string>
  },
  DeleteUsersOutput?: {
    deletedCount?: GraphCacheResolver<WithTypename<DeleteUsersOutput>, Record<string, never>, Scalars['Int'] | string>
  },
  DownloadNextStepDocsResponse?: {
    docUrls?: GraphCacheResolver<WithTypename<DownloadNextStepDocsResponse>, Record<string, never>, Array<WithTypename<NextStepDocDownload> | string>>,
    msg?: GraphCacheResolver<WithTypename<DownloadNextStepDocsResponse>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<DownloadNextStepDocsResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  DropdownInputField?: {
    defaultOption?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, WithTypename<DropdownInputFieldOption> | string>,
    description?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    options?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Array<WithTypename<DropdownInputFieldOption> | string>>,
    order?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, WithTypename<InputFieldUserNumberResponse> | string>,
    uuid?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<DropdownInputField>, Record<string, never>, Scalars['Int'] | string>
  },
  DropdownInputFieldOption?: {
    text?: GraphCacheResolver<WithTypename<DropdownInputFieldOption>, Record<string, never>, Scalars['String'] | string>,
    textI18n?: GraphCacheResolver<WithTypename<DropdownInputFieldOption>, Record<string, never>, WithTypename<TranslatedField> | string>,
    value?: GraphCacheResolver<WithTypename<DropdownInputFieldOption>, Record<string, never>, Scalars['Int'] | string>
  },
  DropdownInputFieldOptionWithTags?: {
    tags?: GraphCacheResolver<WithTypename<DropdownInputFieldOptionWithTags>, Record<string, never>, Array<WithTypename<FormBuilderTag> | string>>,
    text?: GraphCacheResolver<WithTypename<DropdownInputFieldOptionWithTags>, Record<string, never>, Scalars['String'] | string>,
    textI18n?: GraphCacheResolver<WithTypename<DropdownInputFieldOptionWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    value?: GraphCacheResolver<WithTypename<DropdownInputFieldOptionWithTags>, Record<string, never>, Scalars['Int'] | string>
  },
  DropdownInputFieldUserResponse?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<DropdownInputFieldUserResponse>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<DropdownInputFieldUserResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  DropdownInputFieldWithTags?: {
    defaultOption?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, WithTypename<DropdownInputFieldOptionWithTags> | string>,
    description?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    options?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Array<WithTypename<DropdownInputFieldOptionWithTags> | string>>,
    order?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, WithTypename<InputFieldUserResponseWithTags> | string>,
    uuid?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<DropdownInputFieldWithTags>, Record<string, never>, Scalars['Int'] | string>
  },
  EduExperienceForBuildResume?: {
    date_range?: GraphCacheResolver<WithTypename<EduExperienceForBuildResume>, Record<string, never>, Scalars['String'] | string>,
    degree_type?: GraphCacheResolver<WithTypename<EduExperienceForBuildResume>, Record<string, never>, Scalars['String'] | string>,
    institution_name?: GraphCacheResolver<WithTypename<EduExperienceForBuildResume>, Record<string, never>, Scalars['String'] | string>,
    major?: GraphCacheResolver<WithTypename<EduExperienceForBuildResume>, Record<string, never>, Scalars['String'] | string>
  },
  EducationExperience?: {
    completion_year?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['Float'] | string>,
    degree_type?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['String'] | string>,
    education_exp_uuid?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['String'] | string>,
    from_resume?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['Boolean'] | string>,
    institution_name?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['String'] | string>,
    is_current?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['Boolean'] | string>,
    llm_generated_skills?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Array<WithTypename<LlmGeneratedSkill> | string>>,
    major?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['String'] | string>,
    starting_year?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['Float'] | string>,
    status?: GraphCacheResolver<WithTypename<EducationExperience>, Record<string, never>, Scalars['String'] | string>
  },
  EducationOutcome?: {
    degree_type?: GraphCacheResolver<WithTypename<EducationOutcome>, Record<string, never>, Scalars['String'] | string>,
    institution_name?: GraphCacheResolver<WithTypename<EducationOutcome>, Record<string, never>, Scalars['String'] | string>,
    program_name?: GraphCacheResolver<WithTypename<EducationOutcome>, Record<string, never>, Scalars['String'] | string>
  },
  EmailEmploymentOntario?: {
    email_address?: GraphCacheResolver<WithTypename<EmailEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    primary_ind?: GraphCacheResolver<WithTypename<EmailEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    type?: GraphCacheResolver<WithTypename<EmailEmploymentOntario>, Record<string, never>, Scalars['String'] | string>
  },
  EmployedOutcome?: {
    company?: GraphCacheResolver<WithTypename<EmployedOutcome>, Record<string, never>, Scalars['String'] | string>,
    employment_type?: GraphCacheResolver<WithTypename<EmployedOutcome>, Record<string, never>, EmploymentTypeEnum | string>,
    job_title?: GraphCacheResolver<WithTypename<EmployedOutcome>, Record<string, never>, Scalars['String'] | string>,
    salary?: GraphCacheResolver<WithTypename<EmployedOutcome>, Record<string, never>, Scalars['Float'] | string>,
    salary_currency_code?: GraphCacheResolver<WithTypename<EmployedOutcome>, Record<string, never>, Scalars['String'] | string>,
    salary_type?: GraphCacheResolver<WithTypename<EmployedOutcome>, Record<string, never>, SalaryTypeEnum | string>
  },
  Employer?: {
    CaseNote?: GraphCacheResolver<WithTypename<Employer>, Record<string, never>, Array<WithTypename<EmployerCaseNote> | string>>,
    created_at?: GraphCacheResolver<WithTypename<Employer>, Record<string, never>, Scalars['DateTime'] | string>,
    employer_uuid?: GraphCacheResolver<WithTypename<Employer>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Employer>, Record<string, never>, Scalars['String'] | string>,
    recruiters?: GraphCacheResolver<WithTypename<Employer>, Record<string, never>, Array<WithTypename<Recruiter> | string>>,
    type?: GraphCacheResolver<WithTypename<Employer>, Record<string, never>, Scalars['String'] | string>
  },
  EmployerCaseNote?: {
    created_at?: GraphCacheResolver<WithTypename<EmployerCaseNote>, Record<string, never>, Scalars['DateTime'] | string>,
    created_by?: GraphCacheResolver<WithTypename<EmployerCaseNote>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<EmployerCaseNote>, Record<string, never>, Scalars['String'] | string>,
    employer_uuid?: GraphCacheResolver<WithTypename<EmployerCaseNote>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<EmployerCaseNote>, Record<string, never>, Scalars['String'] | string>,
    modified_by?: GraphCacheResolver<WithTypename<EmployerCaseNote>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<EmployerCaseNote>, Record<string, never>, Scalars['DateTime'] | string>
  },
  EmployerSearchResult?: {
    employers?: GraphCacheResolver<WithTypename<EmployerSearchResult>, Record<string, never>, Array<WithTypename<Employer> | string>>,
    tenantCode?: GraphCacheResolver<WithTypename<EmployerSearchResult>, Record<string, never>, Scalars['String'] | string>,
    totalPages?: GraphCacheResolver<WithTypename<EmployerSearchResult>, Record<string, never>, Scalars['Int'] | string>,
    totalResults?: GraphCacheResolver<WithTypename<EmployerSearchResult>, Record<string, never>, Scalars['Int'] | string>
  },
  EmploymentOntarioIdentity?: {
    employment_ontario_user_id?: GraphCacheResolver<WithTypename<EmploymentOntarioIdentity>, Record<string, never>, Scalars['String'] | string>
  },
  EmpyraIdentity?: {
    empyra_user_id?: GraphCacheResolver<WithTypename<EmpyraIdentity>, Record<string, never>, Scalars['String'] | string>
  },
  ExistenceCheckResponse?: {
    exists?: GraphCacheResolver<WithTypename<ExistenceCheckResponse>, Record<string, never>, Scalars['Boolean'] | string>
  },
  ExportProfileAuthorizeResponseData?: {
    body?: GraphCacheResolver<WithTypename<ExportProfileAuthorizeResponseData>, Record<string, never>, Scalars['String'] | string>,
    export_report_uuid?: GraphCacheResolver<WithTypename<ExportProfileAuthorizeResponseData>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<ExportProfileAuthorizeResponseData>, Record<string, never>, Scalars['Float'] | string>
  },
  ExportProfileData?: {
    account_preferences?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<AccountPreferences> | string>,
    anonymized_user_uuid?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    career_area_interests?: GraphCacheResolver<WithTypename<ExportProfileData>, ExportProfileDataCareer_Area_InterestsArgs, Array<WithTypename<CareerArea> | string>>,
    caseNotes?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<CaseNote> | string>>,
    certifications?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<Certification> | string>>,
    coach_assigned?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<CoachAssigned> | string>,
    communities?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<Scalars['String'] | string>>,
    communitiesObject?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<DataSourceType> | string>>,
    contact_history?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<Contact> | string>>,
    country?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['DateTime'] | string>,
    date_of_birth?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    education?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<DataSourceType> | string>,
    education_experiences?: GraphCacheResolver<WithTypename<ExportProfileData>, ExportProfileDataEducation_ExperiencesArgs, Array<WithTypename<EducationExperience> | string>>,
    email?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    email_consent?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['Boolean'] | string>,
    employer_id?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    employment_status?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<DataSourceType> | string>>,
    ethnicities?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<DataSourceType> | string>>,
    external_identities?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<ExternalIdentities> | string>,
    first_name?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    gender?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    genderObject?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<DataSourceType> | string>,
    goal?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    help_response?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    industries_of_interest?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<Scalars['String'] | string>>,
    instance_name?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    interest_profile?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<InterestProfile> | string>,
    is_onboarded?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['Boolean'] | string>,
    job_applications?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<JobApplication> | string>>,
    last_career?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<WorkExperience> | string>,
    last_education?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<EducationExperience> | string>,
    last_name?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    link_response?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    location?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    no_of_step_completed?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['Float'] | string>,
    no_of_step_outstanding?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['Float'] | string>,
    outcomes?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, WithTypename<Outcomes> | string>,
    phone_number?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    postal_code?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    pref_lang?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    previously_contacted?: GraphCacheResolver<WithTypename<ExportProfileData>, ExportProfileDataPreviously_ContactedArgs, Scalars['Boolean'] | string>,
    profileStep?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['Int'] | string>,
    recruiter_outreach?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['Boolean'] | string>,
    services?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<Service> | string>>,
    signup_type?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, ExternalIdentityTypes | string>,
    skills?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<UserSkill> | string>>,
    support_needs?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<Scalars['String'] | string>>,
    support_needs_custom?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<Scalars['String'] | string>>,
    tags?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<UserTag> | string>>,
    target_career?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    target_career_label?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    target_skills?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<Scalars['String'] | string>>,
    tenantRoles?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<WithTypename<TenantRole> | string>>,
    tenants?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Array<Scalars['String'] | string>>,
    user_tenant_role?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['String'] | string>,
    work_experiences?: GraphCacheResolver<WithTypename<ExportProfileData>, ExportProfileDataWork_ExperiencesArgs, Array<WithTypename<WorkExperience> | string>>,
    year_of_birth?: GraphCacheResolver<WithTypename<ExportProfileData>, Record<string, never>, Scalars['Int'] | string>
  },
  ExportProfileDownloadCsvFileResponseData?: {
    statusCode?: GraphCacheResolver<WithTypename<ExportProfileDownloadCsvFileResponseData>, Record<string, never>, Scalars['Float'] | string>,
    url?: GraphCacheResolver<WithTypename<ExportProfileDownloadCsvFileResponseData>, Record<string, never>, Scalars['String'] | string>
  },
  ExportReport?: {
    created_at?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['DateTime'] | string>,
    export_report_uuid?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    failed_message?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    failed_retry?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['Float'] | string>,
    logGroupName?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    logStreamName?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    num_users?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['Float'] | string>,
    s3key?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    tenant_ids?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Array<Scalars['String'] | string>>,
    type?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    user_specified_name?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<ExportReport>, Record<string, never>, Scalars['String'] | string>
  },
  ExternalAssessment?: {
    externalId?: GraphCacheResolver<WithTypename<ExternalAssessment>, Record<string, never>, Scalars['String'] | string>,
    integrationType?: GraphCacheResolver<WithTypename<ExternalAssessment>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ExternalAssessment>, Record<string, never>, Scalars['String'] | string>
  },
  ExternalIdentities?: {
    employment_ontario?: GraphCacheResolver<WithTypename<ExternalIdentities>, Record<string, never>, WithTypename<EmploymentOntarioIdentity> | string>,
    empyra?: GraphCacheResolver<WithTypename<ExternalIdentities>, Record<string, never>, WithTypename<EmpyraIdentity> | string>,
    intercom?: GraphCacheResolver<WithTypename<ExternalIdentities>, Record<string, never>, WithTypename<IntercomIdentity> | string>,
    merge?: GraphCacheResolver<WithTypename<ExternalIdentities>, Record<string, never>, Array<WithTypename<MergeIdentity> | string>>
  },
  Feature?: {
    default_enabled?: GraphCacheResolver<WithTypename<Feature>, Record<string, never>, Scalars['Boolean'] | string>,
    feature_uuid?: GraphCacheResolver<WithTypename<Feature>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<Feature>, Record<string, never>, Scalars['String'] | string>,
    slug?: GraphCacheResolver<WithTypename<Feature>, Record<string, never>, Scalars['String'] | string>
  },
  FilterOptions?: {
    exclude?: GraphCacheResolver<WithTypename<FilterOptions>, Record<string, never>, Array<Scalars['String'] | string>>,
    include?: GraphCacheResolver<WithTypename<FilterOptions>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  Filters?: {
    location?: GraphCacheResolver<WithTypename<Filters>, Record<string, never>, Array<Scalars['String'] | string>>,
    target_careers?: GraphCacheResolver<WithTypename<Filters>, Record<string, never>, Array<WithTypename<TargetCareer> | string>>
  },
  FixedText?: {
    description?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['Boolean'] | string>,
    order?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['Int'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<FixedText>, Record<string, never>, Scalars['Int'] | string>
  },
  Form?: {
    components?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Array<WithTypename<InputField> | string>>,
    created_at?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    description_i18n?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, WithTypename<TranslatedField> | string>,
    email_active?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Boolean'] | string>,
    first_name_active?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Boolean'] | string>,
    form_id?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    form_version_id?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    is_active?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Boolean'] | string>,
    last_name_active?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Boolean'] | string>,
    location_active?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Boolean'] | string>,
    phone_number_active?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Boolean'] | string>,
    sourceLanguage?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, FormStatus | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    title_i18n?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, FormTypes | string>,
    updated_at?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Int'] | string>,
    zip_code_active?: GraphCacheResolver<WithTypename<Form>, Record<string, never>, Scalars['Boolean'] | string>
  },
  FormAndLastSubmissionResponse?: {
    data?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Array<WithTypename<InputField> | string>>,
    description?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, WithTypename<TranslatedField> | string>,
    formUuid?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    formVersionUuid?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    sourceLanguage?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    submissionUuid?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    titleI18n?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, WithTypename<TranslatedField> | string>,
    userUuid?: GraphCacheResolver<WithTypename<FormAndLastSubmissionResponse>, Record<string, never>, Scalars['String'] | string>
  },
  FormBuilderTag?: {
    label?: GraphCacheResolver<WithTypename<FormBuilderTag>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<FormBuilderTag>, Record<string, never>, Scalars['String'] | string>
  },
  FormResponse?: {
    created_at?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['String'] | string>,
    form_id?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['String'] | string>,
    form_version_id?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['String'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    version?: GraphCacheResolver<WithTypename<FormResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  FormVersionToFormBuilderResponse?: {
    components?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Array<WithTypename<InputField> | string>>,
    description?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['String'] | string>,
    description_i18n?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, WithTypename<TranslatedField> | string>,
    form_id?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['String'] | string>,
    form_version_id?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['String'] | string>,
    source_language?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['String'] | string>,
    title_i18n?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<FormVersionToFormBuilderResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  FormsListItem?: {
    created_at?: GraphCacheResolver<WithTypename<FormsListItem>, Record<string, never>, Scalars['DateTime'] | string>,
    form_uuid?: GraphCacheResolver<WithTypename<FormsListItem>, Record<string, never>, Scalars['String'] | string>,
    form_version_id?: GraphCacheResolver<WithTypename<FormsListItem>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<FormsListItem>, Record<string, never>, Scalars['String'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<FormsListItem>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<FormsListItem>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<FormsListItem>, Record<string, never>, Scalars['DateTime'] | string>
  },
  GenerateURLResponseNextStepDoc?: {
    presignedPosts?: GraphCacheResolver<WithTypename<GenerateUrlResponseNextStepDoc>, Record<string, never>, Array<WithTypename<PresignedPost> | string>>,
    statusCode?: GraphCacheResolver<WithTypename<GenerateUrlResponseNextStepDoc>, Record<string, never>, Scalars['Int'] | string>
  },
  GenereateURLReponse?: {
    body?: GraphCacheResolver<WithTypename<GenereateUrlReponse>, Record<string, never>, Scalars['String'] | string>,
    msg?: GraphCacheResolver<WithTypename<GenereateUrlReponse>, Record<string, never>, Scalars['String'] | string>,
    s3Key?: GraphCacheResolver<WithTypename<GenereateUrlReponse>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<GenereateUrlReponse>, Record<string, never>, Scalars['Int'] | string>,
    url?: GraphCacheResolver<WithTypename<GenereateUrlReponse>, Record<string, never>, Scalars['String'] | string>
  },
  GetAllUserUuidsWrapper?: {
    user_uuids?: GraphCacheResolver<WithTypename<GetAllUserUuidsWrapper>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  GetCareersOutput?: {
    allCareerIds?: GraphCacheResolver<WithTypename<GetCareersOutput>, Record<string, never>, Array<Scalars['String'] | string>>,
    careers?: GraphCacheResolver<WithTypename<GetCareersOutput>, Record<string, never>, Array<WithTypename<Careers> | string>>,
    count?: GraphCacheResolver<WithTypename<GetCareersOutput>, Record<string, never>, Scalars['Int'] | string>
  },
  GetLearningProvidersOutput?: {
    allLearningProvidersIds?: GraphCacheResolver<WithTypename<GetLearningProvidersOutput>, Record<string, never>, Array<Scalars['String'] | string>>,
    count?: GraphCacheResolver<WithTypename<GetLearningProvidersOutput>, Record<string, never>, Scalars['Int'] | string>,
    providers?: GraphCacheResolver<WithTypename<GetLearningProvidersOutput>, Record<string, never>, Array<WithTypename<LearningProviders> | string>>
  },
  GetLookerInsightOutput?: {
    url?: GraphCacheResolver<WithTypename<GetLookerInsightOutput>, Record<string, never>, Scalars['String'] | string>
  },
  GetResourcesOutput?: {
    allResourcesIds?: GraphCacheResolver<WithTypename<GetResourcesOutput>, Record<string, never>, Array<Scalars['String'] | string>>,
    count?: GraphCacheResolver<WithTypename<GetResourcesOutput>, Record<string, never>, Scalars['Int'] | string>,
    resources?: GraphCacheResolver<WithTypename<GetResourcesOutput>, Record<string, never>, Array<WithTypename<Resources> | string>>
  },
  GetTagsResponse?: {
    count?: GraphCacheResolver<WithTypename<GetTagsResponse>, Record<string, never>, Scalars['Float'] | string>,
    items?: GraphCacheResolver<WithTypename<GetTagsResponse>, Record<string, never>, Array<WithTypename<Tag> | string>>,
    next?: GraphCacheResolver<WithTypename<GetTagsResponse>, Record<string, never>, Scalars['Float'] | string>
  },
  GetUserCaseNotesResponse?: {
    count?: GraphCacheResolver<WithTypename<GetUserCaseNotesResponse>, Record<string, never>, Scalars['Int'] | string>,
    items?: GraphCacheResolver<WithTypename<GetUserCaseNotesResponse>, Record<string, never>, Array<WithTypename<CaseNote> | string>>,
    next?: GraphCacheResolver<WithTypename<GetUserCaseNotesResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  GovClientConfiguration?: {
    api_key?: GraphCacheResolver<WithTypename<GovClientConfiguration>, Record<string, never>, Scalars['String'] | string>,
    base_url?: GraphCacheResolver<WithTypename<GovClientConfiguration>, Record<string, never>, Scalars['String'] | string>,
    config_type?: GraphCacheResolver<WithTypename<GovClientConfiguration>, Record<string, never>, Scalars['String'] | string>,
    env?: GraphCacheResolver<WithTypename<GovClientConfiguration>, Record<string, never>, Scalars['String'] | string>,
    organization_id?: GraphCacheResolver<WithTypename<GovClientConfiguration>, Record<string, never>, Scalars['String'] | string>
  },
  Group?: {
    coaches?: GraphCacheResolver<WithTypename<Group>, Record<string, never>, Array<WithTypename<Coach> | string>>,
    created_at?: GraphCacheResolver<WithTypename<Group>, Record<string, never>, Scalars['DateTime'] | string>,
    employer_uuid?: GraphCacheResolver<WithTypename<Group>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Group>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<Group>, Record<string, never>, Scalars['DateTime'] | string>
  },
  GroupCoachFailure?: {
    coach_uuid?: GraphCacheResolver<WithTypename<GroupCoachFailure>, Record<string, never>, Scalars['String'] | string>,
    error?: GraphCacheResolver<WithTypename<GroupCoachFailure>, Record<string, never>, Scalars['String'] | string>
  },
  GroupCoachSuccess?: {
    coach_uuid?: GraphCacheResolver<WithTypename<GroupCoachSuccess>, Record<string, never>, Scalars['String'] | string>
  },
  GroupCoachesResult?: {
    failed?: GraphCacheResolver<WithTypename<GroupCoachesResult>, Record<string, never>, Array<WithTypename<GroupCoachFailure> | string>>,
    successful?: GraphCacheResolver<WithTypename<GroupCoachesResult>, Record<string, never>, Array<WithTypename<GroupCoachSuccess> | string>>
  },
  GroupsResponse?: {
    groups?: GraphCacheResolver<WithTypename<GroupsResponse>, Record<string, never>, Array<WithTypename<Group> | string>>,
    limit?: GraphCacheResolver<WithTypename<GroupsResponse>, Record<string, never>, Scalars['Int'] | string>,
    totalPages?: GraphCacheResolver<WithTypename<GroupsResponse>, Record<string, never>, Scalars['Int'] | string>,
    totalResults?: GraphCacheResolver<WithTypename<GroupsResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  HealthResponse?: {
    status?: GraphCacheResolver<WithTypename<HealthResponse>, Record<string, never>, Scalars['String'] | string>
  },
  InputField?: {
    currencyInputField?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<CurrencyInputField> | string>,
    dateInputField?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<DateInputField> | string>,
    defaultQuestionField?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<DefaultQuestionGraphInputField> | string>,
    dropdownInputField?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<DropdownInputField> | string>,
    dropdownInputFieldWithTags?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<DropdownInputFieldWithTags> | string>,
    fixedText?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<FixedText> | string>,
    multiChoiceCheckboxInputField?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<MultiChoiceCheckboxInputField> | string>,
    multiChoiceCheckboxInputFieldWithTags?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<MultiChoiceCheckboxInputFieldWithTags> | string>,
    textInputField?: GraphCacheResolver<WithTypename<InputField>, Record<string, never>, WithTypename<TextInputField> | string>
  },
  InputFieldUserCurrencyResponse?: {
    max?: GraphCacheResolver<WithTypename<InputFieldUserCurrencyResponse>, Record<string, never>, Scalars['Int'] | string>,
    min?: GraphCacheResolver<WithTypename<InputFieldUserCurrencyResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  InputFieldUserDateResponse?: {
    endDate?: GraphCacheResolver<WithTypename<InputFieldUserDateResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    startDate?: GraphCacheResolver<WithTypename<InputFieldUserDateResponse>, Record<string, never>, Scalars['DateTime'] | string>
  },
  InputFieldUserMultiChoiceResponse?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<InputFieldUserMultiChoiceResponse>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<InputFieldUserMultiChoiceResponse>, Record<string, never>, Array<WithTypename<SelectedMultiChoiceCheckboxInputFieldOption> | string>>
  },
  InputFieldUserMultiChoiceResponseWithTags?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<InputFieldUserMultiChoiceResponseWithTags>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<InputFieldUserMultiChoiceResponseWithTags>, Record<string, never>, Array<WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags> | string>>
  },
  InputFieldUserNumberResponse?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<InputFieldUserNumberResponse>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<InputFieldUserNumberResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  InputFieldUserResponseWithTags?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<InputFieldUserResponseWithTags>, Record<string, never>, Scalars['String'] | string>,
    tags?: GraphCacheResolver<WithTypename<InputFieldUserResponseWithTags>, Record<string, never>, Array<WithTypename<FormBuilderTag> | string>>,
    value?: GraphCacheResolver<WithTypename<InputFieldUserResponseWithTags>, Record<string, never>, Scalars['Int'] | string>
  },
  InputFieldUserStringResponse?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<InputFieldUserStringResponse>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<InputFieldUserStringResponse>, Record<string, never>, Scalars['String'] | string>
  },
  InsightConfiguration?: {
    dashboard_id?: GraphCacheResolver<WithTypename<InsightConfiguration>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<InsightConfiguration>, Record<string, never>, Scalars['String'] | string>
  },
  Integration?: {
    api_key?: GraphCacheResolver<WithTypename<Integration>, Record<string, never>, Scalars['String'] | string>,
    base_api_url?: GraphCacheResolver<WithTypename<Integration>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<Integration>, Record<string, never>, Scalars['DateTime'] | string>,
    integration_id?: GraphCacheResolver<WithTypename<Integration>, Record<string, never>, Scalars['ID'] | string>,
    integration_type?: GraphCacheResolver<WithTypename<Integration>, Record<string, never>, IntegrationType | string>,
    is_active?: GraphCacheResolver<WithTypename<Integration>, Record<string, never>, Scalars['Boolean'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<Integration>, Record<string, never>, Scalars['ID'] | string>
  },
  IntercomIdentity?: {
    intercom_user_id?: GraphCacheResolver<WithTypename<IntercomIdentity>, Record<string, never>, Scalars['String'] | string>
  },
  InterestProfile?: {
    artistic?: GraphCacheResolver<WithTypename<InterestProfile>, Record<string, never>, Scalars['Float'] | string>,
    conventional?: GraphCacheResolver<WithTypename<InterestProfile>, Record<string, never>, Scalars['Float'] | string>,
    enterprising?: GraphCacheResolver<WithTypename<InterestProfile>, Record<string, never>, Scalars['Float'] | string>,
    investigative?: GraphCacheResolver<WithTypename<InterestProfile>, Record<string, never>, Scalars['Float'] | string>,
    realistic?: GraphCacheResolver<WithTypename<InterestProfile>, Record<string, never>, Scalars['Float'] | string>,
    social?: GraphCacheResolver<WithTypename<InterestProfile>, Record<string, never>, Scalars['Float'] | string>
  },
  Invitation?: {
    created_at?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['DateTime'] | string>,
    created_by_uuid?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['String'] | string>,
    email?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['String'] | string>,
    employer_uuid?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['String'] | string>,
    invitation_link?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['String'] | string>,
    invitation_uuid?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['String'] | string>,
    invite_link_uuid?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['UUID'] | string>,
    is_valid?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['Boolean'] | string>,
    role?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['String'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['UUID'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['DateTime'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<Invitation>, Record<string, never>, Scalars['String'] | string>
  },
  InviteLink?: {
    code?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['DateTime'] | string>,
    email_domains?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Array<Scalars['String'] | string>>,
    employer_id?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    is_active?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    role?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    tag_id?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['DateTime'] | string>,
    user_count?: GraphCacheResolver<WithTypename<InviteLink>, Record<string, never>, Scalars['Int'] | string>
  },
  JobApplication?: {
    application_id?: GraphCacheResolver<WithTypename<JobApplication>, Record<string, never>, Scalars['String'] | string>,
    applied_at?: GraphCacheResolver<WithTypename<JobApplication>, Record<string, never>, Scalars['DateTime'] | string>,
    job_id?: GraphCacheResolver<WithTypename<JobApplication>, Record<string, never>, Scalars['String'] | string>
  },
  JobAttributes?: {
    attributes?: GraphCacheResolver<WithTypename<JobAttributes>, Record<string, never>, Array<Scalars['JSON'] | string>>,
    job_id?: GraphCacheResolver<WithTypename<JobAttributes>, Record<string, never>, Scalars['String'] | string>
  },
  JobConfigurationOutput?: {
    featured_job_providers?: GraphCacheResolver<WithTypename<JobConfigurationOutput>, Record<string, never>, Array<JobSource | string>>,
    job_sources?: GraphCacheResolver<WithTypename<JobConfigurationOutput>, Record<string, never>, WithTypename<JobSourceConfiguration> | string>
  },
  JobRecommender_EmbeddedJobs?: {
    jobs?: GraphCacheResolver<WithTypename<JobRecommender_EmbeddedJobs>, Record<string, never>, Array<WithTypename<JobRecommender_Job> | string>>
  },
  JobRecommender_HealthResponse?: {
    status?: GraphCacheResolver<WithTypename<JobRecommender_HealthResponse>, Record<string, never>, JobRecommender_Query_Healthcheck_Status | string>
  },
  JobRecommender_Job?: {
    education_match?: GraphCacheResolver<WithTypename<JobRecommender_Job>, Record<string, never>, Scalars['Float'] | string>,
    experience_match?: GraphCacheResolver<WithTypename<JobRecommender_Job>, Record<string, never>, Scalars['Float'] | string>,
    job_id?: GraphCacheResolver<WithTypename<JobRecommender_Job>, Record<string, never>, Scalars['UUID'] | string>,
    match?: GraphCacheResolver<WithTypename<JobRecommender_Job>, Record<string, never>, Scalars['Float'] | string>,
    skill_match?: GraphCacheResolver<WithTypename<JobRecommender_Job>, Record<string, never>, Scalars['Float'] | string>
  },
  JobRecommender_JobRecommenderOutput?: {
    _embedded?: GraphCacheResolver<WithTypename<JobRecommender_JobRecommenderOutput>, Record<string, never>, WithTypename<JobRecommender_EmbeddedJobs> | string>,
    count?: GraphCacheResolver<WithTypename<JobRecommender_JobRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>,
    total?: GraphCacheResolver<WithTypename<JobRecommender_JobRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>
  },
  JobService_AdditionalSkill?: {
    classified_skill_existed?: GraphCacheResolver<WithTypename<JobService_AdditionalSkill>, Record<string, never>, Scalars['Boolean'] | string>,
    custom_label?: GraphCacheResolver<WithTypename<JobService_AdditionalSkill>, Record<string, never>, Scalars['String'] | string>,
    skill_uuid?: GraphCacheResolver<WithTypename<JobService_AdditionalSkill>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<JobService_AdditionalSkill>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_ArchiveAllManualJobsResponse?: {
    numArchived?: GraphCacheResolver<WithTypename<JobService_ArchiveAllManualJobsResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  JobService_EasyApplyToATSJobResponse?: {
    application_id?: GraphCacheResolver<WithTypename<JobService_EasyApplyToAtsJobResponse>, Record<string, never>, Scalars['String'] | string>,
    applied_at?: GraphCacheResolver<WithTypename<JobService_EasyApplyToAtsJobResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    job_id?: GraphCacheResolver<WithTypename<JobService_EasyApplyToAtsJobResponse>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_GetJobsResponseWrapper?: {
    jobs?: GraphCacheResolver<WithTypename<JobService_GetJobsResponseWrapper>, Record<string, never>, Array<WithTypename<JobService_JobSearchJob> | string>>,
    total_results?: GraphCacheResolver<WithTypename<JobService_GetJobsResponseWrapper>, Record<string, never>, Scalars['Float'] | string>
  },
  JobService_GetJobsV2ResponseWrapper?: {
    jobs?: GraphCacheResolver<WithTypename<JobService_GetJobsV2ResponseWrapper>, Record<string, never>, Array<WithTypename<JobService_JobDto> | string>>,
    total_results?: GraphCacheResolver<WithTypename<JobService_GetJobsV2ResponseWrapper>, Record<string, never>, Scalars['Float'] | string>
  },
  JobService_Job?: {
    ingested_at?: GraphCacheResolver<WithTypename<JobService_Job>, Record<string, never>, Scalars['DateTime'] | string>,
    source?: GraphCacheResolver<WithTypename<JobService_Job>, Record<string, never>, Scalars['String'] | string>,
    source_type?: GraphCacheResolver<WithTypename<JobService_Job>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<JobService_Job>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_JobDto?: {
    active?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Boolean'] | string>,
    additional_skills?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<WithTypename<JobService_AdditionalSkill> | string>>,
    allows_easy_apply?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Boolean'] | string>,
    application_count?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    application_email?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    application_url?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    archived?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Boolean'] | string>,
    career?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    career_object?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, WithTypename<JobService_JobSearchCareer> | string>,
    classified_job_type?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<Scalars['String'] | string>>,
    classified_seniority_type?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Float'] | string>,
    company?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    country?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['DateTime'] | string>,
    department?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    education_level?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Int'] | string>,
    edus_needed?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<WithTypename<JobService_JobEducationNeeded> | string>>,
    employer_id?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    expiry_date?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['DateTime'] | string>,
    exps_needed?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<WithTypename<JobService_JobExperienceNeeded> | string>>,
    inferred_attributes?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<WithTypename<JobService_JobSearchInferredJobAttribute> | string>>,
    job_id?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    job_title?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    job_type?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    lang?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    last_updated_at?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['DateTime'] | string>,
    location?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    location_lower?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    posted_at?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    posting_source?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    provider_id?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    remote?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Boolean'] | string>,
    remote_type?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<Scalars['String'] | string>>,
    salary_range?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, WithTypename<JobService_JobSalaryRange> | string>,
    score?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Float'] | string>,
    seniority?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    skills?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<Scalars['String'] | string>>,
    skills_needed?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<Scalars['String'] | string>>,
    skills_needed_objects?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<WithTypename<JobService_JobSearchSkill> | string>>,
    skills_objects?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Array<WithTypename<JobService_JobSearchSkill> | string>>,
    state?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['String'] | string>,
    work_experience_level?: GraphCacheResolver<WithTypename<JobService_JobDto>, Record<string, never>, Scalars['Int'] | string>
  },
  JobService_JobEducationNeeded?: {
    degree_type?: GraphCacheResolver<WithTypename<JobService_JobEducationNeeded>, Record<string, never>, Scalars['String'] | string>,
    level?: GraphCacheResolver<WithTypename<JobService_JobEducationNeeded>, Record<string, never>, Scalars['Int'] | string>
  },
  JobService_JobExperienceNeeded?: {
    level?: GraphCacheResolver<WithTypename<JobService_JobExperienceNeeded>, Record<string, never>, Scalars['Int'] | string>,
    max_year?: GraphCacheResolver<WithTypename<JobService_JobExperienceNeeded>, Record<string, never>, Scalars['Int'] | string>,
    min_year?: GraphCacheResolver<WithTypename<JobService_JobExperienceNeeded>, Record<string, never>, Scalars['Int'] | string>
  },
  JobService_JobSalaryRange?: {
    currency?: GraphCacheResolver<WithTypename<JobService_JobSalaryRange>, Record<string, never>, Scalars['String'] | string>,
    max?: GraphCacheResolver<WithTypename<JobService_JobSalaryRange>, Record<string, never>, Scalars['Float'] | string>,
    min?: GraphCacheResolver<WithTypename<JobService_JobSalaryRange>, Record<string, never>, Scalars['Float'] | string>,
    pay_period?: GraphCacheResolver<WithTypename<JobService_JobSalaryRange>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_JobSearchCareer?: {
    baseline_skills?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, Array<WithTypename<JobService_JobSearchSkill> | string>>,
    career_area?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, WithTypename<JobService_JobSearchCareerArea> | string>,
    career_uuid?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, Scalars['String'] | string>,
    interest_profile_code?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, Scalars['String'] | string>,
    salary_mean?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, WithTypename<JobService_JobSearchMonetaryValue> | string>,
    specialized_skills?: GraphCacheResolver<WithTypename<JobService_JobSearchCareer>, Record<string, never>, Array<WithTypename<JobService_JobSearchSkill> | string>>
  },
  JobService_JobSearchCareerArea?: {
    career_area_uuid?: GraphCacheResolver<WithTypename<JobService_JobSearchCareerArea>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<JobService_JobSearchCareerArea>, Record<string, never>, Scalars['String'] | string>,
    machine_key?: GraphCacheResolver<WithTypename<JobService_JobSearchCareerArea>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<JobService_JobSearchCareerArea>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_JobSearchInferredJobAttribute?: {
    label?: GraphCacheResolver<WithTypename<JobService_JobSearchInferredJobAttribute>, Record<string, never>, Scalars['String'] | string>,
    level?: GraphCacheResolver<WithTypename<JobService_JobSearchInferredJobAttribute>, Record<string, never>, Scalars['Int'] | string>,
    phrase?: GraphCacheResolver<WithTypename<JobService_JobSearchInferredJobAttribute>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<JobService_JobSearchInferredJobAttribute>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<JobService_JobSearchInferredJobAttribute>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<JobService_JobSearchInferredJobAttribute>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_JobSearchJob?: {
    active?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Boolean'] | string>,
    additional_skills?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Array<WithTypename<JobService_AdditionalSkill> | string>>,
    allows_easy_apply?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Boolean'] | string>,
    application_count?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    application_email?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    application_url?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    archived?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Boolean'] | string>,
    career?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, WithTypename<JobService_JobSearchCareer> | string>,
    classified_job_type?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Array<Scalars['String'] | string>>,
    classified_seniority_type?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Float'] | string>,
    company?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    country?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['DateTime'] | string>,
    department?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    education_level?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Int'] | string>,
    employer_id?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    expiry_date?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['DateTime'] | string>,
    inferred_attributes?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Array<WithTypename<JobService_JobSearchInferredJobAttribute> | string>>,
    job_id?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    job_title?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    job_type?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    lang?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    last_updated_at?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['DateTime'] | string>,
    location?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    location_lower?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    posted_at?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    posted_by_uuid?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    posting_source?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    provider_id?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    remote?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Boolean'] | string>,
    remote_type?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Array<Scalars['String'] | string>>,
    role?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, WithTypename<JobService_JobSearchRole> | string>,
    salary_range?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, WithTypename<JobService_JobSalaryRange> | string>,
    score?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Float'] | string>,
    seniority?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    skills?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Array<WithTypename<JobService_JobSearchSkill> | string>>,
    skills_needed?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Array<WithTypename<JobService_JobSearchSkill> | string>>,
    state?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['String'] | string>,
    work_experience_level?: GraphCacheResolver<WithTypename<JobService_JobSearchJob>, Record<string, never>, Scalars['Int'] | string>
  },
  JobService_JobSearchMonetaryValue?: {
    currency?: GraphCacheResolver<WithTypename<JobService_JobSearchMonetaryValue>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<JobService_JobSearchMonetaryValue>, Record<string, never>, Scalars['Float'] | string>
  },
  JobService_JobSearchResponseWrapper?: {
    jobs?: GraphCacheResolver<WithTypename<JobService_JobSearchResponseWrapper>, Record<string, never>, Array<WithTypename<JobService_JobSearchJob> | string>>,
    total_pages?: GraphCacheResolver<WithTypename<JobService_JobSearchResponseWrapper>, Record<string, never>, Scalars['Float'] | string>,
    total_results?: GraphCacheResolver<WithTypename<JobService_JobSearchResponseWrapper>, Record<string, never>, Scalars['Float'] | string>
  },
  JobService_JobSearchRole?: {
    description?: GraphCacheResolver<WithTypename<JobService_JobSearchRole>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<JobService_JobSearchRole>, Record<string, never>, Scalars['String'] | string>,
    role_uuid?: GraphCacheResolver<WithTypename<JobService_JobSearchRole>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_JobSearchSkill?: {
    description?: GraphCacheResolver<WithTypename<JobService_JobSearchSkill>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<JobService_JobSearchSkill>, Record<string, never>, Scalars['String'] | string>,
    skill_uuid?: GraphCacheResolver<WithTypename<JobService_JobSearchSkill>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<JobService_JobSearchSkill>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_JobSearchV2ResponseWrapper?: {
    jobs?: GraphCacheResolver<WithTypename<JobService_JobSearchV2ResponseWrapper>, Record<string, never>, Array<WithTypename<JobService_JobDto> | string>>,
    total_pages?: GraphCacheResolver<WithTypename<JobService_JobSearchV2ResponseWrapper>, Record<string, never>, Scalars['Float'] | string>,
    total_results?: GraphCacheResolver<WithTypename<JobService_JobSearchV2ResponseWrapper>, Record<string, never>, Scalars['Float'] | string>
  },
  JobService_JobSearchV3ResponseWrapper?: {
    jobs?: GraphCacheResolver<WithTypename<JobService_JobSearchV3ResponseWrapper>, Record<string, never>, Array<WithTypename<JobService_JobDto> | string>>
  },
  JobService_ManualJobResponse?: {
    job?: GraphCacheResolver<WithTypename<JobService_ManualJobResponse>, Record<string, never>, WithTypename<JobService_JobSearchJob> | string>,
    jobDto?: GraphCacheResolver<WithTypename<JobService_ManualJobResponse>, Record<string, never>, WithTypename<JobService_JobDto> | string>,
    message?: GraphCacheResolver<WithTypename<JobService_ManualJobResponse>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_SavedJob?: {
    company?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['String'] | string>,
    job?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, WithTypename<JobService_Job> | string>,
    location?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['String'] | string>,
    notes?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['String'] | string>,
    stage_history?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Array<WithTypename<JobService_Stage> | string>>,
    title?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<JobService_SavedJob>, Record<string, never>, Scalars['String'] | string>
  },
  JobService_SkillsResponse?: {
    message?: GraphCacheResolver<WithTypename<JobService_SkillsResponse>, Record<string, never>, Scalars['String'] | string>,
    skills?: GraphCacheResolver<WithTypename<JobService_SkillsResponse>, Record<string, never>, Array<WithTypename<JobService_JobSearchSkill> | string>>
  },
  JobService_Stage?: {
    created_at?: GraphCacheResolver<WithTypename<JobService_Stage>, Record<string, never>, Scalars['DateTime'] | string>,
    stage?: GraphCacheResolver<WithTypename<JobService_Stage>, Record<string, never>, Scalars['String'] | string>
  },
  JobSourceConfiguration?: {
    exclude?: GraphCacheResolver<WithTypename<JobSourceConfiguration>, Record<string, never>, Array<JobSource | string>>,
    include?: GraphCacheResolver<WithTypename<JobSourceConfiguration>, Record<string, never>, Array<JobSource | string>>
  },
  JobsClassifierResponse?: {
    confidence_score?: GraphCacheResolver<WithTypename<JobsClassifierResponse>, Record<string, never>, Scalars['Float'] | string>,
    job_title_requested?: GraphCacheResolver<WithTypename<JobsClassifierResponse>, Record<string, never>, Scalars['String'] | string>,
    role?: GraphCacheResolver<WithTypename<JobsClassifierResponse>, Record<string, never>, Scalars['String'] | string>,
    role_uuid?: GraphCacheResolver<WithTypename<JobsClassifierResponse>, Record<string, never>, Scalars['String'] | string>,
    top_candidate?: GraphCacheResolver<WithTypename<JobsClassifierResponse>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<JobsClassifierResponse>, Record<string, never>, Scalars['String'] | string>
  },
  Label?: {
    en_GB?: GraphCacheResolver<WithTypename<Label>, Record<string, never>, Scalars['String'] | string>,
    en_US?: GraphCacheResolver<WithTypename<Label>, Record<string, never>, Scalars['String'] | string>,
    es_ES?: GraphCacheResolver<WithTypename<Label>, Record<string, never>, Scalars['String'] | string>,
    fr_CA?: GraphCacheResolver<WithTypename<Label>, Record<string, never>, Scalars['String'] | string>,
    pt_PT?: GraphCacheResolver<WithTypename<Label>, Record<string, never>, Scalars['String'] | string>
  },
  LearningProviders?: {
    description?: GraphCacheResolver<WithTypename<LearningProviders>, Record<string, never>, Scalars['String'] | string>,
    logo_url?: GraphCacheResolver<WithTypename<LearningProviders>, Record<string, never>, Scalars['String'] | string>,
    provider_id?: GraphCacheResolver<WithTypename<LearningProviders>, Record<string, never>, Scalars['String'] | string>,
    provider_name?: GraphCacheResolver<WithTypename<LearningProviders>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<LearningProviders>, Record<string, never>, Scalars['String'] | string>
  },
  LibraryQL?: {
    library_uuid?: GraphCacheResolver<WithTypename<LibraryQl>, Record<string, never>, Scalars['String'] | string>,
    next_steps?: GraphCacheResolver<WithTypename<LibraryQl>, Record<string, never>, Array<WithTypename<NextStepsMetadata> | string>>,
    tenant_uuid?: GraphCacheResolver<WithTypename<LibraryQl>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<LibraryQl>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<LibraryQl>, Record<string, never>, Scalars['String'] | string>,
    updated_by?: GraphCacheResolver<WithTypename<LibraryQl>, Record<string, never>, Scalars['String'] | string>
  },
  Link?: {
    target?: GraphCacheResolver<WithTypename<Link>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<Link>, Record<string, never>, Scalars['String'] | string>,
    uri?: GraphCacheResolver<WithTypename<Link>, Record<string, never>, Scalars['String'] | string>
  },
  LlmGeneratedSkill?: {
    classified_skill_uuid?: GraphCacheResolver<WithTypename<LlmGeneratedSkill>, Record<string, never>, Scalars['String'] | string>,
    llm_generated_label?: GraphCacheResolver<WithTypename<LlmGeneratedSkill>, Record<string, never>, Scalars['String'] | string>
  },
  LlmGeneratedWorkExpSkills?: {
    classified_skill_uuid?: GraphCacheResolver<WithTypename<LlmGeneratedWorkExpSkills>, Record<string, never>, Scalars['String'] | string>,
    llm_generated_label?: GraphCacheResolver<WithTypename<LlmGeneratedWorkExpSkills>, Record<string, never>, Scalars['String'] | string>
  },
  MarketingConsentHistory?: {
    created_at?: GraphCacheResolver<WithTypename<MarketingConsentHistory>, Record<string, never>, Scalars['DateTime'] | string>,
    is_consented?: GraphCacheResolver<WithTypename<MarketingConsentHistory>, Record<string, never>, Scalars['Boolean'] | string>
  },
  Member?: {
    tenant_id?: GraphCacheResolver<WithTypename<Member>, Record<string, never>, Scalars['String'] | string>,
    user_id?: GraphCacheResolver<WithTypename<Member>, Record<string, never>, Scalars['String'] | string>
  },
  MergeIdentity?: {
    candidate_id?: GraphCacheResolver<WithTypename<MergeIdentity>, Record<string, never>, Scalars['String'] | string>,
    employer_uuid?: GraphCacheResolver<WithTypename<MergeIdentity>, Record<string, never>, Scalars['String'] | string>
  },
  MonetaryValue?: {
    currency?: GraphCacheResolver<WithTypename<MonetaryValue>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<MonetaryValue>, Record<string, never>, Scalars['Float'] | string>
  },
  MonetaryValueGraph?: {
    currency?: GraphCacheResolver<WithTypename<MonetaryValueGraph>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<MonetaryValueGraph>, Record<string, never>, Scalars['Int'] | string>
  },
  MultiChoiceCheckboxInputField?: {
    description?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    options?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Array<WithTypename<MultiChoiceCheckboxInputFieldOption> | string>>,
    order?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, WithTypename<InputFieldUserMultiChoiceResponse> | string>,
    uuid?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputField>, Record<string, never>, Scalars['Int'] | string>
  },
  MultiChoiceCheckboxInputFieldOption?: {
    id?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOption>, Record<string, never>, Scalars['Int'] | string>,
    isChecked?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOption>, Record<string, never>, Scalars['Boolean'] | string>,
    label?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOption>, Record<string, never>, Scalars['String'] | string>,
    labelI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOption>, Record<string, never>, WithTypename<TranslatedField> | string>,
    requiredAditionalTextBox?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOption>, Record<string, never>, Scalars['Boolean'] | string>
  },
  MultiChoiceCheckboxInputFieldOptionWithTags?: {
    id?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Scalars['Int'] | string>,
    isChecked?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    label?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Scalars['String'] | string>,
    labelI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    requiredAditionalTextBox?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    tags?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Array<WithTypename<FormBuilderTag> | string>>
  },
  MultiChoiceCheckboxInputFieldUserResponse?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldUserResponse>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldUserResponse>, Record<string, never>, Array<WithTypename<SelectedMultiChoiceCheckboxInputFieldOption> | string>>
  },
  MultiChoiceCheckboxInputFieldUserResponseWithTags?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldUserResponseWithTags>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldUserResponseWithTags>, Record<string, never>, Array<WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags> | string>>
  },
  MultiChoiceCheckboxInputFieldWithTags?: {
    description?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['Boolean'] | string>,
    options?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Array<WithTypename<MultiChoiceCheckboxInputFieldOptionWithTags> | string>>,
    order?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, WithTypename<InputFieldUserMultiChoiceResponseWithTags> | string>,
    uuid?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<MultiChoiceCheckboxInputFieldWithTags>, Record<string, never>, Scalars['Int'] | string>
  },
  NextStepDoc?: {
    name?: GraphCacheResolver<WithTypename<NextStepDoc>, Record<string, never>, Scalars['String'] | string>,
    s3Key?: GraphCacheResolver<WithTypename<NextStepDoc>, Record<string, never>, Scalars['String'] | string>
  },
  NextStepDocDownload?: {
    fileName?: GraphCacheResolver<WithTypename<NextStepDocDownload>, Record<string, never>, Scalars['String'] | string>,
    s3Key?: GraphCacheResolver<WithTypename<NextStepDocDownload>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<NextStepDocDownload>, Record<string, never>, Scalars['String'] | string>
  },
  NextStepsConfigurations?: {
    config_id?: GraphCacheResolver<WithTypename<NextStepsConfigurations>, Record<string, never>, Scalars['String'] | string>,
    draft_next_step_ids?: GraphCacheResolver<WithTypename<NextStepsConfigurations>, Record<string, never>, Array<Scalars['String'] | string>>,
    name?: GraphCacheResolver<WithTypename<NextStepsConfigurations>, Record<string, never>, Scalars['String'] | string>,
    next_step_ids?: GraphCacheResolver<WithTypename<NextStepsConfigurations>, Record<string, never>, Array<Scalars['String'] | string>>,
    tenant_id?: GraphCacheResolver<WithTypename<NextStepsConfigurations>, Record<string, never>, Scalars['String'] | string>
  },
  NextStepsMetadata?: {
    category?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, WithTypename<Label> | string>,
    goals?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Array<Scalars['String'] | string>>,
    is_completable?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['Boolean'] | string>,
    link?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, WithTypename<Link> | string>,
    next_step_description?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>,
    next_step_title?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>,
    next_step_type?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>,
    next_step_uuid?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>,
    requireUpload?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['Boolean'] | string>,
    source?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, WithTypename<Label> | string>,
    type?: GraphCacheResolver<WithTypename<NextStepsMetadata>, Record<string, never>, Scalars['String'] | string>
  },
  Notification?: {
    frequency?: GraphCacheResolver<WithTypename<Notification>, Record<string, never>, NotificationFrequencyEnum | string>,
    topic?: GraphCacheResolver<WithTypename<Notification>, Record<string, never>, NotificationTopicEnum | string>,
    type?: GraphCacheResolver<WithTypename<Notification>, Record<string, never>, NotificationTypeEnum | string>
  },
  OnboardingFormResponse?: {
    components?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Array<WithTypename<InputField> | string>>,
    created_at?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    created_by?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    form_id?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    form_version_id?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<OnboardingFormResponse>, Record<string, never>, Scalars['String'] | string>
  },
  Outcomes?: {
    disengaged?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, Scalars['Boolean'] | string>,
    educated?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, WithTypename<EducationOutcome> | string>,
    education_outcomes?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, Array<WithTypename<EducationOutcome> | string>>,
    employed?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, WithTypename<EmployedOutcome> | string>,
    employed_outcomes?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, Array<WithTypename<EmployedOutcome> | string>>,
    other?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, Scalars['Boolean'] | string>,
    referral_outcomes?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, Array<WithTypename<ReferralOutcome> | string>>,
    referred?: GraphCacheResolver<WithTypename<Outcomes>, Record<string, never>, WithTypename<ReferralOutcome> | string>
  },
  Pagination?: {
    next?: GraphCacheResolver<WithTypename<Pagination>, Record<string, never>, Scalars['String'] | string>,
    prev?: GraphCacheResolver<WithTypename<Pagination>, Record<string, never>, Scalars['String'] | string>
  },
  PhoneEmploymentOntario?: {
    phone_number?: GraphCacheResolver<WithTypename<PhoneEmploymentOntario>, Record<string, never>, Scalars['String'] | string>,
    tty?: GraphCacheResolver<WithTypename<PhoneEmploymentOntario>, Record<string, never>, Scalars['Boolean'] | string>,
    type?: GraphCacheResolver<WithTypename<PhoneEmploymentOntario>, Record<string, never>, Scalars['String'] | string>
  },
  PipelineMetadataOutput?: {
    dag_run_id?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['String'] | string>,
    data_type?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, ResourceDataType | string>,
    errors_url?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['String'] | string>,
    filename?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['String'] | string>,
    finished_at?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    input_url?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['String'] | string>,
    num_archived_records?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['Float'] | string>,
    num_error_records?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['Float'] | string>,
    num_new_records?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['Float'] | string>,
    num_total_records?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['Float'] | string>,
    num_updated_records?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['Float'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<PipelineMetadataOutput>, Record<string, never>, Scalars['String'] | string>
  },
  PreSignedURLForCustomerType?: {
    body?: GraphCacheResolver<WithTypename<PreSignedUrlForCustomerType>, Record<string, never>, Scalars['String'] | string>,
    fields?: GraphCacheResolver<WithTypename<PreSignedUrlForCustomerType>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<PreSignedUrlForCustomerType>, Record<string, never>, Scalars['String'] | string>
  },
  PreSignedURLType?: {
    body?: GraphCacheResolver<WithTypename<PreSignedUrlType>, Record<string, never>, Scalars['String'] | string>,
    fields?: GraphCacheResolver<WithTypename<PreSignedUrlType>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<PreSignedUrlType>, Record<string, never>, Scalars['String'] | string>
  },
  PresignedPost?: {
    body?: GraphCacheResolver<WithTypename<PresignedPost>, Record<string, never>, Scalars['String'] | string>,
    fileName?: GraphCacheResolver<WithTypename<PresignedPost>, Record<string, never>, Scalars['String'] | string>,
    msg?: GraphCacheResolver<WithTypename<PresignedPost>, Record<string, never>, Scalars['String'] | string>,
    s3Key?: GraphCacheResolver<WithTypename<PresignedPost>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<PresignedPost>, Record<string, never>, Scalars['String'] | string>
  },
  ProcessQueue_200_response?: {
    message?: GraphCacheResolver<WithTypename<ProcessQueue_200_Response>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<ProcessQueue_200_Response>, Record<string, never>, Scalars['Float'] | string>
  },
  ProcessUserResumeQueue_200_response?: {
    message?: GraphCacheResolver<WithTypename<ProcessUserResumeQueue_200_Response>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<ProcessUserResumeQueue_200_Response>, Record<string, never>, Scalars['Float'] | string>
  },
  Profile?: {
    account_preferences?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<AccountPreferences> | string>,
    anonymized_user_uuid?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    career_area_interests?: GraphCacheResolver<WithTypename<Profile>, ProfileCareer_Area_InterestsArgs, Array<WithTypename<CareerArea> | string>>,
    caseNotes?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<CaseNote> | string>>,
    certifications?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<Certification> | string>>,
    coach_assigned?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<CoachAssigned> | string>,
    communities?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<Scalars['String'] | string>>,
    communitiesObject?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<DataSourceType> | string>>,
    contact_history?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<Contact> | string>>,
    country?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['DateTime'] | string>,
    date_of_birth?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    education?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<DataSourceType> | string>,
    education_experiences?: GraphCacheResolver<WithTypename<Profile>, ProfileEducation_ExperiencesArgs, Array<WithTypename<EducationExperience> | string>>,
    email?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    email_consent?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['Boolean'] | string>,
    employer_id?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    employment_status?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<DataSourceType> | string>>,
    ethnicities?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<DataSourceType> | string>>,
    external_identities?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<ExternalIdentities> | string>,
    first_name?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    gender?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    genderObject?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<DataSourceType> | string>,
    goal?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    help_response?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    industries_of_interest?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<Scalars['String'] | string>>,
    instance_name?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    interest_profile?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<InterestProfile> | string>,
    is_onboarded?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['Boolean'] | string>,
    job_applications?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<JobApplication> | string>>,
    last_career?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<WorkExperience> | string>,
    last_education?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<EducationExperience> | string>,
    last_name?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    link_response?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    location?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    outcomes?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, WithTypename<Outcomes> | string>,
    phone_number?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    postal_code?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    pref_lang?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    previously_contacted?: GraphCacheResolver<WithTypename<Profile>, ProfilePreviously_ContactedArgs, Scalars['Boolean'] | string>,
    profileStep?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['Int'] | string>,
    recruiter_outreach?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['Boolean'] | string>,
    services?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<Service> | string>>,
    signup_type?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, ExternalIdentityTypes | string>,
    skills?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<UserSkill> | string>>,
    support_needs?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<Scalars['String'] | string>>,
    support_needs_custom?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<Scalars['String'] | string>>,
    tags?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<UserTag> | string>>,
    target_career?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    target_career_label?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    target_skills?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<Scalars['String'] | string>>,
    tenantRoles?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<WithTypename<TenantRole> | string>>,
    tenants?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Array<Scalars['String'] | string>>,
    user_tenant_role?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['String'] | string>,
    work_experiences?: GraphCacheResolver<WithTypename<Profile>, ProfileWork_ExperiencesArgs, Array<WithTypename<WorkExperience> | string>>,
    year_of_birth?: GraphCacheResolver<WithTypename<Profile>, Record<string, never>, Scalars['Int'] | string>
  },
  ProfilesQueryWrapper?: {
    profiles?: GraphCacheResolver<WithTypename<ProfilesQueryWrapper>, Record<string, never>, Array<WithTypename<Profile> | string>>,
    total_pages?: GraphCacheResolver<WithTypename<ProfilesQueryWrapper>, Record<string, never>, Scalars['Float'] | string>,
    total_results?: GraphCacheResolver<WithTypename<ProfilesQueryWrapper>, Record<string, never>, Scalars['Float'] | string>
  },
  Recruiter?: {
    employer?: GraphCacheResolver<WithTypename<Recruiter>, Record<string, never>, WithTypename<RecruiterEmployerReference> | string>,
    employer_uuid?: GraphCacheResolver<WithTypename<Recruiter>, Record<string, never>, Scalars['String'] | string>,
    recruiter_uuid?: GraphCacheResolver<WithTypename<Recruiter>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<Recruiter>, Record<string, never>, WithTypename<UserReference> | string>
  },
  RecruiterEmployerReference?: {
    employer_uuid?: GraphCacheResolver<WithTypename<RecruiterEmployerReference>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<RecruiterEmployerReference>, Record<string, never>, Scalars['String'] | string>
  },
  RecruiterResponse?: {
    employer?: GraphCacheResolver<WithTypename<RecruiterResponse>, Record<string, never>, WithTypename<RecruiterEmployerReference> | string>,
    recruiter_uuid?: GraphCacheResolver<WithTypename<RecruiterResponse>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<RecruiterResponse>, Record<string, never>, WithTypename<UserReference> | string>
  },
  RecruiterSearchResult?: {
    recruiters?: GraphCacheResolver<WithTypename<RecruiterSearchResult>, Record<string, never>, Array<WithTypename<RecruiterResponse> | string>>,
    totalPages?: GraphCacheResolver<WithTypename<RecruiterSearchResult>, Record<string, never>, Scalars['Int'] | string>,
    totalResults?: GraphCacheResolver<WithTypename<RecruiterSearchResult>, Record<string, never>, Scalars['Int'] | string>
  },
  ReferralOutcome?: {
    provider?: GraphCacheResolver<WithTypename<ReferralOutcome>, Record<string, never>, Scalars['String'] | string>,
    service_name?: GraphCacheResolver<WithTypename<ReferralOutcome>, Record<string, never>, Scalars['String'] | string>
  },
  RegistrationCustomQuestionsExportResponse?: {
    question?: GraphCacheResolver<WithTypename<RegistrationCustomQuestionsExportResponse>, Record<string, never>, Scalars['String'] | string>,
    registrationresponses?: GraphCacheResolver<WithTypename<RegistrationCustomQuestionsExportResponse>, Record<string, never>, Array<WithTypename<RegistrationResponse> | string>>
  },
  RegistrationForm?: {
    components?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Array<WithTypename<RegistrationInputField> | string>>,
    created_at?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['DateTime'] | string>,
    email_active?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['Boolean'] | string>,
    first_name_active?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['Boolean'] | string>,
    form_id?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['String'] | string>,
    form_version_id?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['String'] | string>,
    is_active?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['Boolean'] | string>,
    last_name_active?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['Boolean'] | string>,
    location_active?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['Boolean'] | string>,
    phone_number_active?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['Boolean'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['DateTime'] | string>,
    zip_code_active?: GraphCacheResolver<WithTypename<RegistrationForm>, Record<string, never>, Scalars['Boolean'] | string>
  },
  RegistrationInputField?: {
    defaultQuestionField?: GraphCacheResolver<WithTypename<RegistrationInputField>, Record<string, never>, WithTypename<DefaultQuestionGraphInputField> | string>,
    dropdownInputFieldWithTags?: GraphCacheResolver<WithTypename<RegistrationInputField>, Record<string, never>, WithTypename<DropdownInputFieldWithTags> | string>,
    multiChoiceCheckboxInputFieldWithTags?: GraphCacheResolver<WithTypename<RegistrationInputField>, Record<string, never>, WithTypename<MultiChoiceCheckboxInputFieldWithTags> | string>,
    textInputField?: GraphCacheResolver<WithTypename<RegistrationInputField>, Record<string, never>, WithTypename<RegistrationTextInputField> | string>
  },
  RegistrationResponse?: {
    response?: GraphCacheResolver<WithTypename<RegistrationResponse>, Record<string, never>, Scalars['String'] | string>,
    userUuid?: GraphCacheResolver<WithTypename<RegistrationResponse>, Record<string, never>, Scalars['String'] | string>
  },
  RegistrationSubmissionResponse?: {
    created_by?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    data?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Array<WithTypename<RegistrationInputField> | string>>,
    description?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    form_created_at?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    form_id?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    form_updated_at?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    form_version_id?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    is_assigned?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['Boolean'] | string>,
    is_deleted?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['Boolean'] | string>,
    status?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    submission_created_at?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    submission_id?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    submission_updated_at?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<RegistrationSubmissionResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  RegistrationTextInputField?: {
    description?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    order?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, WithTypename<TextInputFieldUserResponse> | string>,
    uuid?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<RegistrationTextInputField>, Record<string, never>, Scalars['Int'] | string>
  },
  RemoveUsersFromFormBuilderResponse?: {
    deletedCount?: GraphCacheResolver<WithTypename<RemoveUsersFromFormBuilderResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  RemoveUsersOutput?: {
    deleteCount?: GraphCacheResolver<WithTypename<RemoveUsersOutput>, Record<string, never>, Scalars['Int'] | string>
  },
  ReportsAssessmentsData?: {
    assessment_uuid?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['DateTime'] | string>,
    link?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['String'] | string>,
    score?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<ReportsAssessmentsData>, Record<string, never>, Scalars['String'] | string>
  },
  Resource?: {
    age_range?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, WithTypename<AgeRange> | string>,
    archived?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['Boolean'] | string>,
    career_interest?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Array<Scalars['String'] | string>>,
    community?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Array<Scalars['String'] | string>>,
    cost?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, WithTypename<Cost> | string>,
    country?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['DateTime'] | string>,
    credits_accepted?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['Boolean'] | string>,
    dag_run_id?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    input_url?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    lang?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    lang_label?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    last_updated_at?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['DateTime'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, WithTypename<ResourceProvider> | string>,
    source_id?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    subscription_required?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['Boolean'] | string>,
    support_needs?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Array<Scalars['String'] | string>>,
    tenant_id?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    url?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<Resource>, Record<string, never>, Scalars['String'] | string>
  },
  ResourceProvider?: {
    description?: GraphCacheResolver<WithTypename<ResourceProvider>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<ResourceProvider>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<ResourceProvider>, Record<string, never>, Scalars['String'] | string>,
    uuid?: GraphCacheResolver<WithTypename<ResourceProvider>, Record<string, never>, Scalars['String'] | string>
  },
  ResourceRecommender_CollectionLinks?: {
    first?: GraphCacheResolver<WithTypename<ResourceRecommender_CollectionLinks>, Record<string, never>, WithTypename<ResourceRecommender_Link> | string>,
    last?: GraphCacheResolver<WithTypename<ResourceRecommender_CollectionLinks>, Record<string, never>, WithTypename<ResourceRecommender_Link> | string>,
    next?: GraphCacheResolver<WithTypename<ResourceRecommender_CollectionLinks>, Record<string, never>, WithTypename<ResourceRecommender_Link> | string>,
    prev?: GraphCacheResolver<WithTypename<ResourceRecommender_CollectionLinks>, Record<string, never>, WithTypename<ResourceRecommender_Link> | string>,
    self?: GraphCacheResolver<WithTypename<ResourceRecommender_CollectionLinks>, Record<string, never>, WithTypename<ResourceRecommender_Link> | string>
  },
  ResourceRecommender_EmbeddedResources?: {
    resources?: GraphCacheResolver<WithTypename<ResourceRecommender_EmbeddedResources>, Record<string, never>, Array<WithTypename<ResourceRecommender_Resource> | string>>
  },
  ResourceRecommender_HealthResponse?: {
    status?: GraphCacheResolver<WithTypename<ResourceRecommender_HealthResponse>, Record<string, never>, ResourceRecommender_Query_Healthcheck_Status | string>
  },
  ResourceRecommender_Link?: {
    href?: GraphCacheResolver<WithTypename<ResourceRecommender_Link>, Record<string, never>, Scalars['URL'] | string>
  },
  ResourceRecommender_Resource?: {
    career_interest?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Array<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_career_interest_items'] | string>>,
    community?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Array<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_community_items'] | string>>,
    cost?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, WithTypename<ResourceRecommender_Resource_Cost> | string>,
    country?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_country'] | string>,
    credits_accepted?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Scalars['Boolean'] | string>,
    description?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Scalars['UUID'] | string>,
    label?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Scalars['String'] | string>,
    provider?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, WithTypename<ResourceRecommender_Resource_Provider> | string>,
    subscription_required?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Scalars['Boolean'] | string>,
    support_needs?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Array<Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_support_needs_items'] | string>>,
    url?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource>, Record<string, never>, Scalars['URL'] | string>
  },
  ResourceRecommender_ResourceRecommenderOutput?: {
    _embedded?: GraphCacheResolver<WithTypename<ResourceRecommender_ResourceRecommenderOutput>, Record<string, never>, WithTypename<ResourceRecommender_EmbeddedResources> | string>,
    _links?: GraphCacheResolver<WithTypename<ResourceRecommender_ResourceRecommenderOutput>, Record<string, never>, WithTypename<ResourceRecommender_CollectionLinks> | string>,
    count?: GraphCacheResolver<WithTypename<ResourceRecommender_ResourceRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>,
    total?: GraphCacheResolver<WithTypename<ResourceRecommender_ResourceRecommenderOutput>, Record<string, never>, Scalars['NonNegativeInt'] | string>
  },
  ResourceRecommender_Resource_cost?: {
    currency?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource_Cost>, Record<string, never>, Scalars['ResourceRecommender_query_get_resource_recommendations__embedded_resources_items_cost_currency'] | string>,
    value?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource_Cost>, Record<string, never>, Scalars['Float'] | string>
  },
  ResourceRecommender_Resource_provider?: {
    id?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource_Provider>, Record<string, never>, Scalars['UUID'] | string>,
    label?: GraphCacheResolver<WithTypename<ResourceRecommender_Resource_Provider>, Record<string, never>, Scalars['String'] | string>
  },
  Resources?: {
    description?: GraphCacheResolver<WithTypename<Resources>, Record<string, never>, Scalars['String'] | string>,
    resource_id?: GraphCacheResolver<WithTypename<Resources>, Record<string, never>, Scalars['String'] | string>,
    resource_name?: GraphCacheResolver<WithTypename<Resources>, Record<string, never>, Scalars['String'] | string>,
    source?: GraphCacheResolver<WithTypename<Resources>, Record<string, never>, Scalars['String'] | string>,
    source_id?: GraphCacheResolver<WithTypename<Resources>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<Resources>, Record<string, never>, Scalars['String'] | string>
  },
  RestApiClient?: {
    client_id?: GraphCacheResolver<WithTypename<RestApiClient>, Record<string, never>, Scalars['String'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<RestApiClient>, Record<string, never>, Scalars['String'] | string>
  },
  ResumeBuilderResponse?: {
    results?: GraphCacheResolver<WithTypename<ResumeBuilderResponse>, Record<string, never>, Scalars['JSON'] | string>
  },
  ResumeParserResponse?: {
    user_attributes?: GraphCacheResolver<WithTypename<ResumeParserResponse>, Record<string, never>, Scalars['JSON'] | string>
  },
  Roadmap?: {
    draft_next_steps?: GraphCacheResolver<WithTypename<Roadmap>, Record<string, never>, Array<WithTypename<NextStepsMetadata> | string>>,
    name?: GraphCacheResolver<WithTypename<Roadmap>, Record<string, never>, Scalars['String'] | string>,
    published_next_steps?: GraphCacheResolver<WithTypename<Roadmap>, Record<string, never>, Array<WithTypename<NextStepsMetadata> | string>>,
    roadmap_uuid?: GraphCacheResolver<WithTypename<Roadmap>, Record<string, never>, Scalars['String'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<Roadmap>, Record<string, never>, Scalars['String'] | string>
  },
  RoadmapStats?: {
    completedNextSteps?: GraphCacheResolver<WithTypename<RoadmapStats>, Record<string, never>, Scalars['Float'] | string>,
    completedRatio?: GraphCacheResolver<WithTypename<RoadmapStats>, Record<string, never>, Scalars['Float'] | string>
  },
  Role?: {
    label?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['String'] | string>
  },
  SaveCareerOutput?: {
    careerUuid?: GraphCacheResolver<WithTypename<SaveCareerOutput>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<SaveCareerOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<SaveCareerOutput>, Record<string, never>, Scalars['Int'] | string>,
    isRemoved?: GraphCacheResolver<WithTypename<SaveCareerOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<SaveCareerOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    userUuid?: GraphCacheResolver<WithTypename<SaveCareerOutput>, Record<string, never>, Scalars['String'] | string>
  },
  SaveCareerStatusOutput?: {
    careerUuid?: GraphCacheResolver<WithTypename<SaveCareerStatusOutput>, Record<string, never>, Scalars['String'] | string>,
    isRemoved?: GraphCacheResolver<WithTypename<SaveCareerStatusOutput>, Record<string, never>, Scalars['Boolean'] | string>
  },
  SaveLearningOutput?: {
    createdAt?: GraphCacheResolver<WithTypename<SaveLearningOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<SaveLearningOutput>, Record<string, never>, Scalars['Int'] | string>,
    isRemoved?: GraphCacheResolver<WithTypename<SaveLearningOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    learningUuid?: GraphCacheResolver<WithTypename<SaveLearningOutput>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<SaveLearningOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    userUuid?: GraphCacheResolver<WithTypename<SaveLearningOutput>, Record<string, never>, Scalars['String'] | string>
  },
  SaveLearningStatusOutput?: {
    isRemoved?: GraphCacheResolver<WithTypename<SaveLearningStatusOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    learningUuid?: GraphCacheResolver<WithTypename<SaveLearningStatusOutput>, Record<string, never>, Scalars['String'] | string>
  },
  SaveResourceOutput?: {
    createdAt?: GraphCacheResolver<WithTypename<SaveResourceOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<SaveResourceOutput>, Record<string, never>, Scalars['Int'] | string>,
    isRemoved?: GraphCacheResolver<WithTypename<SaveResourceOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    resourceUuid?: GraphCacheResolver<WithTypename<SaveResourceOutput>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<SaveResourceOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    userUuid?: GraphCacheResolver<WithTypename<SaveResourceOutput>, Record<string, never>, Scalars['String'] | string>
  },
  SaveResourceStatusOutput?: {
    isRemoved?: GraphCacheResolver<WithTypename<SaveResourceStatusOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    resourceUuid?: GraphCacheResolver<WithTypename<SaveResourceStatusOutput>, Record<string, never>, Scalars['String'] | string>
  },
  SavedTalent?: {
    created_at?: GraphCacheResolver<WithTypename<SavedTalent>, Record<string, never>, Scalars['String'] | string>,
    job_uuid?: GraphCacheResolver<WithTypename<SavedTalent>, Record<string, never>, Scalars['String'] | string>,
    talent_uuid?: GraphCacheResolver<WithTypename<SavedTalent>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<SavedTalent>, Record<string, never>, Scalars['String'] | string>
  },
  SearchQueryProcessingResponse?: {
    alpha?: GraphCacheResolver<WithTypename<SearchQueryProcessingResponse>, Record<string, never>, Scalars['Float'] | string>,
    query_embedding?: GraphCacheResolver<WithTypename<SearchQueryProcessingResponse>, Record<string, never>, Array<Scalars['Float'] | string>>
  },
  SelectedMultiChoiceCheckboxInputFieldOption?: {
    aditionalText?: GraphCacheResolver<WithTypename<SelectedMultiChoiceCheckboxInputFieldOption>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<SelectedMultiChoiceCheckboxInputFieldOption>, Record<string, never>, Scalars['Int'] | string>,
    textValue?: GraphCacheResolver<WithTypename<SelectedMultiChoiceCheckboxInputFieldOption>, Record<string, never>, Scalars['String'] | string>
  },
  SelectedMultiChoiceCheckboxInputFieldOptionWithTags?: {
    aditionalText?: GraphCacheResolver<WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Scalars['Int'] | string>,
    tags?: GraphCacheResolver<WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Array<WithTypename<FormBuilderTag> | string>>,
    textValue?: GraphCacheResolver<WithTypename<SelectedMultiChoiceCheckboxInputFieldOptionWithTags>, Record<string, never>, Scalars['String'] | string>
  },
  ServerError?: {
    error?: GraphCacheResolver<WithTypename<ServerError>, Record<string, never>, Scalars['String'] | string>,
    message?: GraphCacheResolver<WithTypename<ServerError>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<ServerError>, Record<string, never>, Scalars['Float'] | string>
  },
  ServerError_response?: {
    error?: GraphCacheResolver<WithTypename<ServerError_Response>, Record<string, never>, Scalars['String'] | string>
  },
  Service?: {
    end_date?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['DateTime'] | string>,
    provider?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['String'] | string>,
    service_uuid?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['String'] | string>,
    stage?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['String'] | string>,
    start_date?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['DateTime'] | string>,
    title?: GraphCacheResolver<WithTypename<Service>, Record<string, never>, Scalars['String'] | string>
  },
  Skill?: {
    id?: GraphCacheResolver<WithTypename<Skill>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<Skill>, Record<string, never>, Scalars['String'] | string>,
    skill_label?: GraphCacheResolver<WithTypename<Skill>, Record<string, never>, Scalars['String'] | string>,
    skill_type?: GraphCacheResolver<WithTypename<Skill>, Record<string, never>, Scalars['String'] | string>,
    skill_uuid?: GraphCacheResolver<WithTypename<Skill>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<Skill>, Record<string, never>, Scalars['String'] | string>
  },
  SkillClassifierResponse?: {
    is_custom?: GraphCacheResolver<WithTypename<SkillClassifierResponse>, Record<string, never>, Scalars['Boolean'] | string>,
    label?: GraphCacheResolver<WithTypename<SkillClassifierResponse>, Record<string, never>, Scalars['String'] | string>,
    skill_requested?: GraphCacheResolver<WithTypename<SkillClassifierResponse>, Record<string, never>, Scalars['String'] | string>,
    skill_type?: GraphCacheResolver<WithTypename<SkillClassifierResponse>, Record<string, never>, SkillType | string>,
    uuid?: GraphCacheResolver<WithTypename<SkillClassifierResponse>, Record<string, never>, Scalars['String'] | string>
  },
  SkillModification?: {
    modifiedSkills?: GraphCacheResolver<WithTypename<SkillModification>, Record<string, never>, Array<WithTypename<UserSkill> | string>>
  },
  SubmissionResponse?: {
    created_at?: GraphCacheResolver<WithTypename<SubmissionResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    data?: GraphCacheResolver<WithTypename<SubmissionResponse>, Record<string, never>, Array<WithTypename<InputField> | string>>,
    form_version_id?: GraphCacheResolver<WithTypename<SubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    is_deleted?: GraphCacheResolver<WithTypename<SubmissionResponse>, Record<string, never>, Scalars['Boolean'] | string>,
    submission_id?: GraphCacheResolver<WithTypename<SubmissionResponse>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<SubmissionResponse>, Record<string, never>, Scalars['DateTime'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<SubmissionResponse>, Record<string, never>, Scalars['String'] | string>
  },
  SubscribeUserCustomerLogo_200_response?: {
    ResponseMetadata?: GraphCacheResolver<WithTypename<SubscribeUserCustomerLogo_200_Response>, Record<string, never>, WithTypename<Query_SubscribeUserCustomerLogo_OneOf_0_ResponseMetadata> | string>,
    SubscriptionArn?: GraphCacheResolver<WithTypename<SubscribeUserCustomerLogo_200_Response>, Record<string, never>, Scalars['String'] | string>
  },
  SubscribeUserProfilePic_200_response?: {
    ResponseMetadata?: GraphCacheResolver<WithTypename<SubscribeUserProfilePic_200_Response>, Record<string, never>, WithTypename<Query_SubscribeUserProfilePic_OneOf_0_ResponseMetadata> | string>,
    SubscriptionArn?: GraphCacheResolver<WithTypename<SubscribeUserProfilePic_200_Response>, Record<string, never>, Scalars['String'] | string>
  },
  SubscribeUserResume_200_response?: {
    ResponseMetadata?: GraphCacheResolver<WithTypename<SubscribeUserResume_200_Response>, Record<string, never>, WithTypename<Query_SubscribeUserResume_OneOf_0_ResponseMetadata> | string>,
    SubscriptionArn?: GraphCacheResolver<WithTypename<SubscribeUserResume_200_Response>, Record<string, never>, Scalars['String'] | string>
  },
  Tag?: {
    category?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['DateTime'] | string>,
    created_by?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    created_by_full_name?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    intercom_tag_id?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    is_visible_to_recruiters?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['Boolean'] | string>,
    label?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, TagStatusEnum | string>,
    tag_uuid?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    tenant_uuid?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['DateTime'] | string>,
    updated_by?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['String'] | string>,
    user_count?: GraphCacheResolver<WithTypename<Tag>, Record<string, never>, Scalars['Float'] | string>
  },
  TagRule?: {
    groups?: GraphCacheResolver<WithTypename<TagRule>, Record<string, never>, Array<WithTypename<TagRuleGroup> | string>>,
    operator?: GraphCacheResolver<WithTypename<TagRule>, Record<string, never>, RelationOperator | string>
  },
  TagRuleGroup?: {
    is_negated?: GraphCacheResolver<WithTypename<TagRuleGroup>, Record<string, never>, Scalars['Boolean'] | string>,
    items?: GraphCacheResolver<WithTypename<TagRuleGroup>, Record<string, never>, Array<Scalars['String'] | string>>,
    operator?: GraphCacheResolver<WithTypename<TagRuleGroup>, Record<string, never>, RelationOperator | string>
  },
  TaggingUserTag?: {
    category?: GraphCacheResolver<WithTypename<TaggingUserTag>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<TaggingUserTag>, Record<string, never>, Scalars['DateTime'] | string>,
    label?: GraphCacheResolver<WithTypename<TaggingUserTag>, Record<string, never>, Scalars['String'] | string>,
    tag_uuid?: GraphCacheResolver<WithTypename<TaggingUserTag>, Record<string, never>, Scalars['String'] | string>,
    user_tag_uuid?: GraphCacheResolver<WithTypename<TaggingUserTag>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<TaggingUserTag>, Record<string, never>, Scalars['String'] | string>
  },
  TargetCareer?: {
    career_uuid?: GraphCacheResolver<WithTypename<TargetCareer>, Record<string, never>, Scalars['String'] | string>,
    pref_label?: GraphCacheResolver<WithTypename<TargetCareer>, Record<string, never>, Scalars['String'] | string>
  },
  Tenant?: {
    code?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    configuration_id?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    custom_domain?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    depth?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['Int'] | string>,
    description_label?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    favicon?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    legacy?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['Boolean'] | string>,
    lil_sso_link_format?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    logo_url?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    mobile_sign_up_image_url?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    onboarding_form_link?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    page_title?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    parent_id?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    registration_email_subject?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    sign_up_description?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    sign_up_image_url?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    sign_up_link?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, Scalars['String'] | string>,
    text_overlay_description?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, WithTypename<Translation> | string>,
    text_overlay_title?: GraphCacheResolver<WithTypename<Tenant>, Record<string, never>, WithTypename<Translation> | string>
  },
  TenantAssetOutput?: {
    cookie_policy?: GraphCacheResolver<WithTypename<TenantAssetOutput>, Record<string, never>, Scalars['String'] | string>,
    privacy_policy?: GraphCacheResolver<WithTypename<TenantAssetOutput>, Record<string, never>, Scalars['String'] | string>,
    terms_of_service?: GraphCacheResolver<WithTypename<TenantAssetOutput>, Record<string, never>, Scalars['String'] | string>
  },
  TenantFeatureBulkResult?: {
    updated?: GraphCacheResolver<WithTypename<TenantFeatureBulkResult>, Record<string, never>, Scalars['Float'] | string>
  },
  TenantRole?: {
    role?: GraphCacheResolver<WithTypename<TenantRole>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<TenantRole>, Record<string, never>, Scalars['String'] | string>
  },
  TestSchema?: {
    name?: GraphCacheResolver<WithTypename<TestSchema>, Record<string, never>, Scalars['String'] | string>
  },
  TextInputField?: {
    description?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['String'] | string>,
    descriptionI18n?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    hasDescription?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['Boolean'] | string>,
    order?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['String'] | string>,
    placeholderI18n?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    questionText?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['String'] | string>,
    questionTextI18n?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, WithTypename<TranslatedField> | string>,
    type?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, WithTypename<InputFieldUserStringResponse> | string>,
    uuid?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<TextInputField>, Record<string, never>, Scalars['Int'] | string>
  },
  TextInputFieldUserResponse?: {
    aditionalResponse?: GraphCacheResolver<WithTypename<TextInputFieldUserResponse>, Record<string, never>, Scalars['String'] | string>,
    value?: GraphCacheResolver<WithTypename<TextInputFieldUserResponse>, Record<string, never>, Scalars['String'] | string>
  },
  TranslatedField?: {
    en_US?: GraphCacheResolver<WithTypename<TranslatedField>, Record<string, never>, Scalars['String'] | string>,
    es_ES?: GraphCacheResolver<WithTypename<TranslatedField>, Record<string, never>, Scalars['String'] | string>,
    fr_CA?: GraphCacheResolver<WithTypename<TranslatedField>, Record<string, never>, Scalars['String'] | string>,
    pt_PT?: GraphCacheResolver<WithTypename<TranslatedField>, Record<string, never>, Scalars['String'] | string>
  },
  Translation?: {
    en_US?: GraphCacheResolver<WithTypename<Translation>, Record<string, never>, Scalars['String'] | string>,
    fr_CA?: GraphCacheResolver<WithTypename<Translation>, Record<string, never>, Scalars['String'] | string>
  },
  TranslationField?: {
    en_US?: GraphCacheResolver<WithTypename<TranslationField>, Record<string, never>, Scalars['String'] | string>,
    es_ES?: GraphCacheResolver<WithTypename<TranslationField>, Record<string, never>, Scalars['String'] | string>,
    fr_CA?: GraphCacheResolver<WithTypename<TranslationField>, Record<string, never>, Scalars['String'] | string>,
    pt_PT?: GraphCacheResolver<WithTypename<TranslationField>, Record<string, never>, Scalars['String'] | string>
  },
  TranslationGraph?: {
    sourceLanguage?: GraphCacheResolver<WithTypename<TranslationGraph>, Record<string, never>, Scalars['String'] | string>,
    text?: GraphCacheResolver<WithTypename<TranslationGraph>, Record<string, never>, Scalars['String'] | string>
  },
  TriggerReportingEtlResponse?: {
    body?: GraphCacheResolver<WithTypename<TriggerReportingEtlResponse>, Record<string, never>, Scalars['String'] | string>,
    statusCode?: GraphCacheResolver<WithTypename<TriggerReportingEtlResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  UnsaveCareerOutput?: {
    careerUuid?: GraphCacheResolver<WithTypename<UnsaveCareerOutput>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<UnsaveCareerOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UnsaveCareerOutput>, Record<string, never>, Scalars['Int'] | string>,
    isRemoved?: GraphCacheResolver<WithTypename<UnsaveCareerOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UnsaveCareerOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    userUuid?: GraphCacheResolver<WithTypename<UnsaveCareerOutput>, Record<string, never>, Scalars['String'] | string>
  },
  UnsaveLearningOutput?: {
    createdAt?: GraphCacheResolver<WithTypename<UnsaveLearningOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UnsaveLearningOutput>, Record<string, never>, Scalars['Int'] | string>,
    isRemoved?: GraphCacheResolver<WithTypename<UnsaveLearningOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    learningUuid?: GraphCacheResolver<WithTypename<UnsaveLearningOutput>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UnsaveLearningOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    userUuid?: GraphCacheResolver<WithTypename<UnsaveLearningOutput>, Record<string, never>, Scalars['String'] | string>
  },
  UnsaveResourceOutput?: {
    createdAt?: GraphCacheResolver<WithTypename<UnsaveResourceOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UnsaveResourceOutput>, Record<string, never>, Scalars['Int'] | string>,
    isRemoved?: GraphCacheResolver<WithTypename<UnsaveResourceOutput>, Record<string, never>, Scalars['Boolean'] | string>,
    resourceUuid?: GraphCacheResolver<WithTypename<UnsaveResourceOutput>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UnsaveResourceOutput>, Record<string, never>, Scalars['DateTime'] | string>,
    userUuid?: GraphCacheResolver<WithTypename<UnsaveResourceOutput>, Record<string, never>, Scalars['String'] | string>
  },
  UpdateUserTenantOutput?: {
    id?: GraphCacheResolver<WithTypename<UpdateUserTenantOutput>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<UpdateUserTenantOutput>, Record<string, never>, Scalars['String'] | string>
  },
  UserNextStepIntegration?: {
    external_friendly_name?: GraphCacheResolver<WithTypename<UserNextStepIntegration>, Record<string, never>, Scalars['String'] | string>,
    external_id?: GraphCacheResolver<WithTypename<UserNextStepIntegration>, Record<string, never>, Scalars['String'] | string>,
    ffai_resource_id?: GraphCacheResolver<WithTypename<UserNextStepIntegration>, Record<string, never>, Scalars['String'] | string>,
    integration_type?: GraphCacheResolver<WithTypename<UserNextStepIntegration>, Record<string, never>, Scalars['String'] | string>
  },
  UserNextSteps?: {
    assigned?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['Boolean'] | string>,
    category?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    coach_uuid?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    completed_at?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['DateTime'] | string>,
    created_at?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    goals?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Array<Scalars['String'] | string>>,
    integrationContext?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Array<WithTypename<UserNextStepIntegration> | string>>,
    is_completable?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['Boolean'] | string>,
    link?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, WithTypename<Link> | string>,
    next_step_uuid?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    order?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['Int'] | string>,
    requireUpload?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['Boolean'] | string>,
    source?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    title?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['DateTime'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<UserNextSteps>, Record<string, never>, Scalars['String'] | string>
  },
  UserNextStepsWrapper?: {
    next_steps?: GraphCacheResolver<WithTypename<UserNextStepsWrapper>, UserNextStepsWrapperNext_StepsArgs, Array<WithTypename<UserNextSteps> | string>>,
    number_of_completed_steps?: GraphCacheResolver<WithTypename<UserNextStepsWrapper>, Record<string, never>, Scalars['Int'] | string>,
    total_number_of_steps?: GraphCacheResolver<WithTypename<UserNextStepsWrapper>, Record<string, never>, Scalars['Int'] | string>
  },
  UserProfileRole?: {
    role?: GraphCacheResolver<WithTypename<UserProfileRole>, Record<string, never>, Scalars['String'] | string>,
    tenant_id?: GraphCacheResolver<WithTypename<UserProfileRole>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<UserProfileRole>, Record<string, never>, Scalars['String'] | string>
  },
  UserReference?: {
    created_at?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Scalars['DateTime'] | string>,
    email?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Scalars['String'] | string>,
    first_name?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Scalars['String'] | string>,
    invitation?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Array<WithTypename<Invitation> | string>>,
    is_registered?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Scalars['Boolean'] | string>,
    last_name?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Mutation_CreateEmployer_Recruiters_Items_User_Type | string>,
    user_uuid?: GraphCacheResolver<WithTypename<UserReference>, Record<string, never>, Scalars['String'] | string>
  },
  UserSkill?: {
    id?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['String'] | string>,
    is_custom?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['Boolean'] | string>,
    is_inferred?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['Boolean'] | string>,
    label?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['String'] | string>,
    proficiency?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['Int'] | string>,
    skill_label?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['String'] | string>,
    skill_type?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['String'] | string>,
    skill_uuid?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['String'] | string>,
    skilltype?: GraphCacheResolver<WithTypename<UserSkill>, Record<string, never>, Scalars['String'] | string>
  },
  UserSkillsGenerationResponse?: {
    skills?: GraphCacheResolver<WithTypename<UserSkillsGenerationResponse>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  UserTag?: {
    category?: GraphCacheResolver<WithTypename<UserTag>, Record<string, never>, Scalars['String'] | string>,
    created_at?: GraphCacheResolver<WithTypename<UserTag>, Record<string, never>, Scalars['DateTime'] | string>,
    label?: GraphCacheResolver<WithTypename<UserTag>, Record<string, never>, Scalars['String'] | string>,
    tag_uuid?: GraphCacheResolver<WithTypename<UserTag>, Record<string, never>, Scalars['String'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<UserTag>, Record<string, never>, Scalars['String'] | string>
  },
  UserType?: {
    S3Key?: GraphCacheResolver<WithTypename<UserType>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<UserType>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<UserType>, Record<string, never>, Scalars['Int'] | string>,
    isDuplicate?: GraphCacheResolver<WithTypename<UserType>, Record<string, never>, Scalars['Boolean'] | string>,
    resumeUploadStatus?: GraphCacheResolver<WithTypename<UserType>, Record<string, never>, ResumeUploadStatusEnum | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UserType>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<UserType>, Record<string, never>, Scalars['String'] | string>
  },
  ValidationError?: {
    loc?: GraphCacheResolver<WithTypename<ValidationError>, Record<string, never>, Array<WithTypename<Query_ExtractJobAttributes_OneOf_1_Detail_Items_Loc_Items> | string>>,
    msg?: GraphCacheResolver<WithTypename<ValidationError>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<ValidationError>, Record<string, never>, Scalars['String'] | string>
  },
  VervoeInviteToAssessmentResponse?: {
    candidateAssessmentUuid?: GraphCacheResolver<WithTypename<VervoeInviteToAssessmentResponse>, Record<string, never>, Scalars['String'] | string>,
    candidateUrl?: GraphCacheResolver<WithTypename<VervoeInviteToAssessmentResponse>, Record<string, never>, Scalars['String'] | string>
  },
  WorkExperience?: {
    career?: GraphCacheResolver<WithTypename<WorkExperience>, WorkExperienceCareerArgs, WithTypename<Career> | string>,
    career_uuid?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['String'] | string>,
    company?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['String'] | string>,
    completion_date?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['DateTime'] | string>,
    confidence_score?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['Float'] | string>,
    experience_level?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['String'] | string>,
    experience_type?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['String'] | string>,
    from_resume?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['Boolean'] | string>,
    irrelevant_skill_uuids?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Array<Scalars['String'] | string>>,
    is_current?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['Boolean'] | string>,
    job_title?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['String'] | string>,
    llm_generated_skills?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Array<WithTypename<LlmGeneratedWorkExpSkills> | string>>,
    starting_date?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['DateTime'] | string>,
    work_exp_uuid?: GraphCacheResolver<WithTypename<WorkExperience>, Record<string, never>, Scalars['String'] | string>
  },
  WorkExperienceForBuildResume?: {
    achievements?: GraphCacheResolver<WithTypename<WorkExperienceForBuildResume>, Record<string, never>, Array<Scalars['String'] | string>>,
    company?: GraphCacheResolver<WithTypename<WorkExperienceForBuildResume>, Record<string, never>, Scalars['String'] | string>,
    date_range?: GraphCacheResolver<WithTypename<WorkExperienceForBuildResume>, Record<string, never>, Scalars['String'] | string>,
    job_title?: GraphCacheResolver<WithTypename<WorkExperienceForBuildResume>, Record<string, never>, Scalars['String'] | string>
  },
  comprehensiveDeleteTenant_200_response?: {
    deletionSuccess?: GraphCacheResolver<WithTypename<ComprehensiveDeleteTenant_200_Response>, Record<string, never>, Scalars['Boolean'] | string>
  },
  deleteCachedResumeData_200_response?: {
    S3Key?: GraphCacheResolver<WithTypename<DeleteCachedResumeData_200_Response>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<DeleteCachedResumeData_200_Response>, Record<string, never>, Scalars['String'] | string>
  },
  deleteFormBuilderData_200_response?: {
    deleteStatus?: GraphCacheResolver<WithTypename<DeleteFormBuilderData_200_Response>, Record<string, never>, Scalars['String'] | string>
  },
  deleteResume_200_response?: {
    S3Key?: GraphCacheResolver<WithTypename<DeleteResume_200_Response>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<DeleteResume_200_Response>, Record<string, never>, Scalars['String'] | string>
  },
  getInvitations_200_response?: {
    count?: GraphCacheResolver<WithTypename<GetInvitations_200_Response>, Record<string, never>, Scalars['Int'] | string>,
    items?: GraphCacheResolver<WithTypename<GetInvitations_200_Response>, Record<string, never>, Array<WithTypename<Invitation> | string>>,
    next?: GraphCacheResolver<WithTypename<GetInvitations_200_Response>, Record<string, never>, Scalars['Int'] | string>
  },
  getInviteLinks_200_response?: {
    inviteLinks?: GraphCacheResolver<WithTypename<GetInviteLinks_200_Response>, Record<string, never>, Array<WithTypename<InviteLink> | string>>
  },
  health_200_response?: {
    status?: GraphCacheResolver<WithTypename<Health_200_Response>, Record<string, never>, Scalars['String'] | string>,
    timestamp?: GraphCacheResolver<WithTypename<Health_200_Response>, Record<string, never>, Scalars['Int'] | string>
  },
  listMembers_200_response?: {
    items?: GraphCacheResolver<WithTypename<ListMembers_200_Response>, Record<string, never>, Array<WithTypename<Member> | string>>,
    links?: GraphCacheResolver<WithTypename<ListMembers_200_Response>, Record<string, never>, WithTypename<Pagination> | string>
  },
  listSubTenants_200_response?: {
    items?: GraphCacheResolver<WithTypename<ListSubTenants_200_Response>, Record<string, never>, Array<WithTypename<Tenant> | string>>
  },
  listTenants_200_response?: {
    items?: GraphCacheResolver<WithTypename<ListTenants_200_Response>, Record<string, never>, Array<WithTypename<Tenant> | string>>,
    links?: GraphCacheResolver<WithTypename<ListTenants_200_Response>, Record<string, never>, WithTypename<Pagination> | string>
  },
  mutation_BulkDeleteUserData_oneOf_0_msg?: {
    deletedCount?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_Msg>, Record<string, never>, Scalars['Float'] | string>
  },
  mutation_BulkDeleteUserData_oneOf_0_nextStepDocs?: {
    deletedCount?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs>, Record<string, never>, Scalars['Float'] | string>,
    filesDeleted?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs>, Record<string, never>, Array<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs_FilesDeleted_Items> | string>>,
    statusCode?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs>, Record<string, never>, Scalars['Float'] | string>
  },
  mutation_BulkDeleteUserData_oneOf_0_nextStepDocs_filesDeleted_items?: {
    S3Key?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_NextStepDocs_FilesDeleted_Items>, Record<string, never>, Scalars['String'] | string>
  },
  mutation_BulkDeleteUserData_oneOf_0_userResumes?: {
    deletedCount?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes>, Record<string, never>, Scalars['Float'] | string>,
    filesDeleted?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes>, Record<string, never>, Array<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes_FilesDeleted_Items> | string>>,
    statusCode?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes>, Record<string, never>, Scalars['Float'] | string>
  },
  mutation_BulkDeleteUserData_oneOf_0_userResumes_filesDeleted_items?: {
    S3Key?: GraphCacheResolver<WithTypename<Mutation_BulkDeleteUserData_OneOf_0_UserResumes_FilesDeleted_Items>, Record<string, never>, Scalars['String'] | string>
  },
  mutation_LLMGenerateSkills_oneOf_1_detail_items_loc_items?: {
    Int?: GraphCacheResolver<WithTypename<Mutation_LlmGenerateSkills_OneOf_1_Detail_Items_Loc_Items>, Record<string, never>, Scalars['Int'] | string>,
    String?: GraphCacheResolver<WithTypename<Mutation_LlmGenerateSkills_OneOf_1_Detail_Items_Loc_Items>, Record<string, never>, Scalars['String'] | string>
  },
  mutation_deleteRecruiters_items?: {
    employer_uuid?: GraphCacheResolver<WithTypename<Mutation_DeleteRecruiters_Items>, Record<string, never>, Scalars['String'] | string>,
    recruiter_uuid?: GraphCacheResolver<WithTypename<Mutation_DeleteRecruiters_Items>, Record<string, never>, Scalars['String'] | string>
  },
  query_SubscribeUserCustomerLogo_oneOf_0_ResponseMetadata?: {
    RequestId?: GraphCacheResolver<WithTypename<Query_SubscribeUserCustomerLogo_OneOf_0_ResponseMetadata>, Record<string, never>, Scalars['String'] | string>
  },
  query_SubscribeUserProfilePic_oneOf_0_ResponseMetadata?: {
    RequestId?: GraphCacheResolver<WithTypename<Query_SubscribeUserProfilePic_OneOf_0_ResponseMetadata>, Record<string, never>, Scalars['String'] | string>
  },
  query_SubscribeUserResume_oneOf_0_ResponseMetadata?: {
    RequestId?: GraphCacheResolver<WithTypename<Query_SubscribeUserResume_OneOf_0_ResponseMetadata>, Record<string, never>, Scalars['String'] | string>
  },
  query_classifyJobTitle_oneOf_1_detail_items_loc_items?: {
    Int?: GraphCacheResolver<WithTypename<Query_ClassifyJobTitle_OneOf_1_Detail_Items_Loc_Items>, Record<string, never>, Scalars['Int'] | string>,
    String?: GraphCacheResolver<WithTypename<Query_ClassifyJobTitle_OneOf_1_Detail_Items_Loc_Items>, Record<string, never>, Scalars['String'] | string>
  },
  query_extractJobAttributes_oneOf_1_detail_items_loc_items?: {
    Int?: GraphCacheResolver<WithTypename<Query_ExtractJobAttributes_OneOf_1_Detail_Items_Loc_Items>, Record<string, never>, Scalars['Int'] | string>,
    String?: GraphCacheResolver<WithTypename<Query_ExtractJobAttributes_OneOf_1_Detail_Items_Loc_Items>, Record<string, never>, Scalars['String'] | string>
  },
  query_listUserFormSubmissionsByFormId_items?: {
    created_at?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items>, Record<string, never>, Scalars['DateTime'] | string>,
    data?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items>, Record<string, never>, Array<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items> | string>>,
    form_version_id?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items>, Record<string, never>, Scalars['String'] | string>,
    is_deleted?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items>, Record<string, never>, Scalars['Boolean'] | string>,
    submission_id?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items>, Record<string, never>, Scalars['String'] | string>,
    updated_at?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items>, Record<string, never>, Scalars['DateTime'] | string>,
    user_uuid?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items>, Record<string, never>, Scalars['String'] | string>
  },
  query_listUserFormSubmissionsByFormId_items_data_items?: {
    defaultOption?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, WithTypename<DropdownInputFieldOption> | string>,
    description?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['String'] | string>,
    hasDescription?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['Boolean'] | string>,
    isDeprecated?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['Boolean'] | string>,
    isOptional?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['Boolean'] | string>,
    isStaff?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['Boolean'] | string>,
    options?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Array<WithTypename<DropdownInputFieldOption> | string>>,
    order?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['Int'] | string>,
    placeholder?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['String'] | string>,
    questionText?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['String'] | string>,
    userResponse?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, WithTypename<DropdownInputFieldUserResponse> | string>,
    uuid?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['String'] | string>,
    version?: GraphCacheResolver<WithTypename<Query_ListUserFormSubmissionsByFormId_Items_Data_Items>, Record<string, never>, Scalars['Int'] | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  BulkDeleteUserData?: GraphCacheOptimisticMutationResolver<MutationBulkDeleteUserDataArgs, Maybe<WithTypename<BulkDeleteUserData_200_Response>>>,
  LLMBuildResume?: GraphCacheOptimisticMutationResolver<MutationLlmBuildResumeArgs, Maybe<WithTypename<LlmBuildResume_Response>>>,
  LLMGenerateSkills?: GraphCacheOptimisticMutationResolver<MutationLlmGenerateSkillsArgs, Maybe<WithTypename<LlmGenerateSkills_Response>>>,
  LLMParseResume?: GraphCacheOptimisticMutationResolver<MutationLlmParseResumeArgs, Maybe<WithTypename<LlmParseResume_Response>>>,
  UserResumeController_processResume?: GraphCacheOptimisticMutationResolver<MutationUserResumeController_ProcessResumeArgs, Maybe<Scalars['JSON']>>,
  addContact?: GraphCacheOptimisticMutationResolver<MutationAddContactArgs, WithTypename<Contact>>,
  addMember?: GraphCacheOptimisticMutationResolver<MutationAddMemberArgs, Maybe<WithTypename<Member>>>,
  addNextStepToLibrary?: GraphCacheOptimisticMutationResolver<MutationAddNextStepToLibraryArgs, WithTypename<LibraryQl>>,
  addTenantAsset?: GraphCacheOptimisticMutationResolver<MutationAddTenantAssetArgs, WithTypename<AssetsDto>>,
  addUserCaseNote?: GraphCacheOptimisticMutationResolver<MutationAddUserCaseNoteArgs, WithTypename<CaseNote>>,
  addUserSkills?: GraphCacheOptimisticMutationResolver<MutationAddUserSkillsArgs, WithTypename<SkillModification>>,
  archiveAllJobs?: GraphCacheOptimisticMutationResolver<Record<string, never>, WithTypename<JobService_ArchiveAllManualJobsResponse>>,
  archiveForm?: GraphCacheOptimisticMutationResolver<MutationArchiveFormArgs, Scalars['Boolean']>,
  archiveManualJob?: GraphCacheOptimisticMutationResolver<MutationArchiveManualJobArgs, WithTypename<JobService_ManualJobResponse>>,
  assignCoach?: GraphCacheOptimisticMutationResolver<MutationAssignCoachArgs, Array<WithTypename<Profile>>>,
  assignGroupCoaches?: GraphCacheOptimisticMutationResolver<MutationAssignGroupCoachesArgs, Maybe<WithTypename<GroupCoachesResult>>>,
  assignUserNextStep?: GraphCacheOptimisticMutationResolver<MutationAssignUserNextStepArgs, WithTypename<UserNextStepsWrapper>>,
  buildUserResume?: GraphCacheOptimisticMutationResolver<Record<string, never>, WithTypename<BuildUserResumeResponse>>,
  bulkUpdateProfiles?: GraphCacheOptimisticMutationResolver<MutationBulkUpdateProfilesArgs, WithTypename<BulkProfileUpdateResult>>,
  clearCandidateCache?: GraphCacheOptimisticMutationResolver<Record<string, never>, Maybe<Scalars['Boolean']>>,
  comprehensiveDeleteTenant?: GraphCacheOptimisticMutationResolver<MutationComprehensiveDeleteTenantArgs, Maybe<WithTypename<ComprehensiveDeleteTenant_200_Response>>>,
  configureTenant?: GraphCacheOptimisticMutationResolver<MutationConfigureTenantArgs, Scalars['String']>,
  confirmNextStepDoc?: GraphCacheOptimisticMutationResolver<MutationConfirmNextStepDocArgs, WithTypename<ConfirmNextStepDocResponse>>,
  confirmRecruiterRegistration?: GraphCacheOptimisticMutationResolver<MutationConfirmRecruiterRegistrationArgs, Maybe<WithTypename<ConfirmRegistrationResponse>>>,
  createAssessmentFromExternalData?: GraphCacheOptimisticMutationResolver<MutationCreateAssessmentFromExternalDataArgs, WithTypename<Assessment>>,
  createAsyncUpdateStatus?: GraphCacheOptimisticMutationResolver<MutationCreateAsyncUpdateStatusArgs, WithTypename<AsyncUpdateStatus>>,
  createConfigurationV2?: GraphCacheOptimisticMutationResolver<MutationCreateConfigurationV2Args, WithTypename<Configuration>>,
  createCustomerObjectUrl?: GraphCacheOptimisticMutationResolver<MutationCreateCustomerObjectUrlArgs, WithTypename<PreSignedUrlForCustomerType>>,
  createDefaultQuestions?: GraphCacheOptimisticMutationResolver<MutationCreateDefaultQuestionsArgs, Maybe<Scalars['String']>>,
  createEmployer?: GraphCacheOptimisticMutationResolver<MutationCreateEmployerArgs, Maybe<WithTypename<Employer>>>,
  createEmployerCaseNote?: GraphCacheOptimisticMutationResolver<MutationCreateEmployerCaseNoteArgs, Maybe<WithTypename<EmployerCaseNote>>>,
  createForm?: GraphCacheOptimisticMutationResolver<MutationCreateFormArgs, WithTypename<Form>>,
  createFormVersion?: GraphCacheOptimisticMutationResolver<MutationCreateFormVersionArgs, WithTypename<Form>>,
  createGroup?: GraphCacheOptimisticMutationResolver<MutationCreateGroupArgs, Maybe<WithTypename<Group>>>,
  createIntegration?: GraphCacheOptimisticMutationResolver<MutationCreateIntegrationArgs, Maybe<WithTypename<Integration>>>,
  createInvitation?: GraphCacheOptimisticMutationResolver<MutationCreateInvitationArgs, Maybe<WithTypename<Invitation>>>,
  createInviteLink?: GraphCacheOptimisticMutationResolver<MutationCreateInviteLinkArgs, Maybe<WithTypename<InviteLink>>>,
  createManualPostJob?: GraphCacheOptimisticMutationResolver<MutationCreateManualPostJobArgs, WithTypename<JobService_ManualJobResponse>>,
  createMergeLinkToken?: GraphCacheOptimisticMutationResolver<MutationCreateMergeLinkTokenArgs, Scalars['String']>,
  createNewDraft?: GraphCacheOptimisticMutationResolver<MutationCreateNewDraftArgs, Maybe<WithTypename<CreateFormResponse>>>,
  createNewForm?: GraphCacheOptimisticMutationResolver<MutationCreateNewFormArgs, Maybe<WithTypename<CreateFormResponse>>>,
  createNextStepsConfigurations?: GraphCacheOptimisticMutationResolver<MutationCreateNextStepsConfigurationsArgs, WithTypename<NextStepsConfigurations>>,
  createNextStepsMetadata?: GraphCacheOptimisticMutationResolver<MutationCreateNextStepsMetadataArgs, WithTypename<NextStepsMetadata>>,
  createPostObjectURL?: GraphCacheOptimisticMutationResolver<MutationCreatePostObjectUrlArgs, WithTypename<PreSignedUrlType>>,
  createRecruiter?: GraphCacheOptimisticMutationResolver<MutationCreateRecruiterArgs, Maybe<WithTypename<Recruiter>>>,
  createRegistrationForm?: GraphCacheOptimisticMutationResolver<MutationCreateRegistrationFormArgs, Maybe<WithTypename<RegistrationForm>>>,
  createRegistrationSubmission?: GraphCacheOptimisticMutationResolver<MutationCreateRegistrationSubmissionArgs, Maybe<Scalars['Boolean']>>,
  createReportExport?: GraphCacheOptimisticMutationResolver<MutationCreateReportExportArgs, Scalars['Boolean']>,
  createRestApiClient?: GraphCacheOptimisticMutationResolver<MutationCreateRestApiClientArgs, Maybe<WithTypename<RestApiClient>>>,
  createSubTenant?: GraphCacheOptimisticMutationResolver<MutationCreateSubTenantArgs, Maybe<WithTypename<Tenant>>>,
  createTenant?: GraphCacheOptimisticMutationResolver<MutationCreateTenantArgs, Maybe<WithTypename<Tenant>>>,
  createUserProfile?: GraphCacheOptimisticMutationResolver<MutationCreateUserProfileArgs, WithTypename<Profile>>,
  createUserRole?: GraphCacheOptimisticMutationResolver<MutationCreateUserRoleArgs, WithTypename<Role>>,
  createUserTags?: GraphCacheOptimisticMutationResolver<MutationCreateUserTagsArgs, Array<WithTypename<TaggingUserTag>>>,
  createUserTagsForTenant?: GraphCacheOptimisticMutationResolver<MutationCreateUserTagsForTenantArgs, Array<WithTypename<TaggingUserTag>>>,
  delNextStepDoc?: GraphCacheOptimisticMutationResolver<MutationDelNextStepDocArgs, WithTypename<DelNextStepDocResponse>>,
  deleteCachedResumeData?: GraphCacheOptimisticMutationResolver<MutationDeleteCachedResumeDataArgs, Maybe<WithTypename<DeleteCachedResumeData_200_Response>>>,
  deleteContactsFromUser?: GraphCacheOptimisticMutationResolver<MutationDeleteContactsFromUserArgs, Array<WithTypename<Contact>>>,
  deleteDataByTenant?: GraphCacheOptimisticMutationResolver<MutationDeleteDataByTenantArgs, WithTypename<DeleteConfigurationOutput>>,
  deleteEmployer?: GraphCacheOptimisticMutationResolver<MutationDeleteEmployerArgs, Maybe<Scalars['Int']>>,
  deleteEmployerCaseNote?: GraphCacheOptimisticMutationResolver<MutationDeleteEmployerCaseNoteArgs, Maybe<WithTypename<EmployerCaseNote>>>,
  deleteEmployerDataByTenant?: GraphCacheOptimisticMutationResolver<MutationDeleteEmployerDataByTenantArgs, Maybe<WithTypename<DeleteEmployersByTenantResponse>>>,
  deleteExportReportsByTenant?: GraphCacheOptimisticMutationResolver<MutationDeleteExportReportsByTenantArgs, WithTypename<DeleteExportReportsOutput>>,
  deleteFeature?: GraphCacheOptimisticMutationResolver<MutationDeleteFeatureArgs, Scalars['String']>,
  deleteForm?: GraphCacheOptimisticMutationResolver<MutationDeleteFormArgs, Maybe<Scalars['Boolean']>>,
  deleteFormBuilderData?: GraphCacheOptimisticMutationResolver<MutationDeleteFormBuilderDataArgs, Maybe<WithTypename<DeleteFormBuilderData_200_Response>>>,
  deleteInvitation?: GraphCacheOptimisticMutationResolver<MutationDeleteInvitationArgs, Maybe<Scalars['Void']>>,
  deletePolicyAssets?: GraphCacheOptimisticMutationResolver<MutationDeletePolicyAssetsArgs, WithTypename<Configuration>>,
  deleteProfiles?: GraphCacheOptimisticMutationResolver<MutationDeleteProfilesArgs, WithTypename<DeleteProfilesOutput>>,
  deleteRecruiter?: GraphCacheOptimisticMutationResolver<MutationDeleteRecruiterArgs, Maybe<WithTypename<Recruiter>>>,
  deleteRecruiters?: GraphCacheOptimisticMutationResolver<MutationDeleteRecruitersArgs, Maybe<Array<WithTypename<Mutation_DeleteRecruiters_Items>>>>,
  deleteRecruitersByUserUuids?: GraphCacheOptimisticMutationResolver<MutationDeleteRecruitersByUserUuidsArgs, Maybe<Array<WithTypename<Mutation_DeleteRecruiters_Items>>>>,
  deleteResume?: GraphCacheOptimisticMutationResolver<MutationDeleteResumeArgs, Maybe<WithTypename<DeleteResume_200_Response>>>,
  deleteSavedJob?: GraphCacheOptimisticMutationResolver<MutationDeleteSavedJobArgs, WithTypename<JobService_SavedJob>>,
  deleteTag?: GraphCacheOptimisticMutationResolver<MutationDeleteTagArgs, Scalars['String']>,
  deleteTagsByTenant?: GraphCacheOptimisticMutationResolver<MutationDeleteTagsByTenantArgs, Scalars['String']>,
  deleteTenant?: GraphCacheOptimisticMutationResolver<MutationDeleteTenantArgs, Maybe<Scalars['Void']>>,
  deleteTenantFeatures?: GraphCacheOptimisticMutationResolver<MutationDeleteTenantFeaturesArgs, Scalars['String']>,
  deleteTenantFromAccessControl?: GraphCacheOptimisticMutationResolver<MutationDeleteTenantFromAccessControlArgs, Maybe<Scalars['String']>>,
  deleteTenantNextSteps?: GraphCacheOptimisticMutationResolver<MutationDeleteTenantNextStepsArgs, WithTypename<DeleteTenantNextStepsOutput>>,
  deleteUserCaseNote?: GraphCacheOptimisticMutationResolver<MutationDeleteUserCaseNoteArgs, Scalars['String']>,
  deleteUserNextStep?: GraphCacheOptimisticMutationResolver<MutationDeleteUserNextStepArgs, WithTypename<DeleteUserNextStepsOutput>>,
  deleteUserNextSteps?: GraphCacheOptimisticMutationResolver<MutationDeleteUserNextStepsArgs, WithTypename<DeleteUserNextStepsOutput>>,
  deleteUserTags?: GraphCacheOptimisticMutationResolver<MutationDeleteUserTagsArgs, Array<WithTypename<TaggingUserTag>>>,
  deleteUsers?: GraphCacheOptimisticMutationResolver<MutationDeleteUsersArgs, Scalars['Boolean']>,
  deleteUsersFromAccessControl?: GraphCacheOptimisticMutationResolver<MutationDeleteUsersFromAccessControlArgs, WithTypename<DeleteUsersOutput>>,
  deleteUsersFromAssessments?: GraphCacheOptimisticMutationResolver<MutationDeleteUsersFromAssessmentsArgs, WithTypename<DeleteUsersOutput>>,
  disableTenantFeatures?: GraphCacheOptimisticMutationResolver<MutationDisableTenantFeaturesArgs, Array<Scalars['String']>>,
  disableUserFeatures?: GraphCacheOptimisticMutationResolver<MutationDisableUserFeaturesArgs, Scalars['String']>,
  easyApplyToATSJob?: GraphCacheOptimisticMutationResolver<MutationEasyApplyToAtsJobArgs, WithTypename<JobService_EasyApplyToAtsJobResponse>>,
  enableTenantFeatures?: GraphCacheOptimisticMutationResolver<MutationEnableTenantFeaturesArgs, Array<WithTypename<Feature>>>,
  enableTenantFeaturesBulk?: GraphCacheOptimisticMutationResolver<MutationEnableTenantFeaturesBulkArgs, WithTypename<TenantFeatureBulkResult>>,
  enableUserFeatures?: GraphCacheOptimisticMutationResolver<MutationEnableUserFeaturesArgs, Array<WithTypename<Feature>>>,
  exchangeMergeAccountToken?: GraphCacheOptimisticMutationResolver<MutationExchangeMergeAccountTokenArgs, Scalars['String']>,
  exportProfileAuthorize?: GraphCacheOptimisticMutationResolver<MutationExportProfileAuthorizeArgs, WithTypename<ExportProfileAuthorizeResponseData>>,
  exportProfileDownloadCsvFile?: GraphCacheOptimisticMutationResolver<MutationExportProfileDownloadCsvFileArgs, WithTypename<ExportProfileDownloadCsvFileResponseData>>,
  getCandidateRecommendationsByID?: GraphCacheOptimisticMutationResolver<MutationGetCandidateRecommendationsByIdArgs, Maybe<WithTypename<CandidateRecommender_Candidate>>>,
  getSkillsFromCareer?: GraphCacheOptimisticMutationResolver<MutationGetSkillsFromCareerArgs, WithTypename<JobService_SkillsResponse>>,
  inviteToVervoeAssessment?: GraphCacheOptimisticMutationResolver<MutationInviteToVervoeAssessmentArgs, WithTypename<VervoeInviteToAssessmentResponse>>,
  leadSignUp?: GraphCacheOptimisticMutationResolver<MutationLeadSignUpArgs, Scalars['Boolean']>,
  manageDeleteNextStep?: GraphCacheOptimisticMutationResolver<MutationManageDeleteNextStepArgs, Scalars['Boolean']>,
  moveUser?: GraphCacheOptimisticMutationResolver<MutationMoveUserArgs, Scalars['String']>,
  moveUsers?: GraphCacheOptimisticMutationResolver<MutationMoveUsersArgs, Scalars['String']>,
  processSearchQuery?: GraphCacheOptimisticMutationResolver<MutationProcessSearchQueryArgs, Maybe<WithTypename<ProcessSearchQuery_Response>>>,
  provisionTenant?: GraphCacheOptimisticMutationResolver<MutationProvisionTenantArgs, Maybe<WithTypename<Tenant>>>,
  publishForm?: GraphCacheOptimisticMutationResolver<MutationPublishFormArgs, Maybe<Scalars['Boolean']>>,
  publishRoadmap?: GraphCacheOptimisticMutationResolver<MutationPublishRoadmapArgs, WithTypename<Roadmap>>,
  removeAsyncUpdateStatus?: GraphCacheOptimisticMutationResolver<MutationRemoveAsyncUpdateStatusArgs, Scalars['Boolean']>,
  removeMember?: GraphCacheOptimisticMutationResolver<MutationRemoveMemberArgs, Maybe<Scalars['Void']>>,
  removeUserSkills?: GraphCacheOptimisticMutationResolver<MutationRemoveUserSkillsArgs, WithTypename<SkillModification>>,
  removeUsersFromFormBuilder?: GraphCacheOptimisticMutationResolver<MutationRemoveUsersFromFormBuilderArgs, Maybe<WithTypename<RemoveUsersFromFormBuilderResponse>>>,
  removeUsersFromSaveCareer?: GraphCacheOptimisticMutationResolver<MutationRemoveUsersFromSaveCareerArgs, Maybe<WithTypename<RemoveUsersOutput>>>,
  removeUsersFromSaveLearning?: GraphCacheOptimisticMutationResolver<MutationRemoveUsersFromSaveLearningArgs, Maybe<WithTypename<RemoveUsersOutput>>>,
  removeUsersFromSaveResource?: GraphCacheOptimisticMutationResolver<MutationRemoveUsersFromSaveResourceArgs, Maybe<WithTypename<RemoveUsersOutput>>>,
  requestSubmissionsExportation?: GraphCacheOptimisticMutationResolver<MutationRequestSubmissionsExportationArgs, Maybe<Scalars['Boolean']>>,
  resetProfile?: GraphCacheOptimisticMutationResolver<MutationResetProfileArgs, WithTypename<Profile>>,
  resetUserNextSteps?: GraphCacheOptimisticMutationResolver<MutationResetUserNextStepsArgs, WithTypename<UserNextStepsWrapper>>,
  revokeToken?: GraphCacheOptimisticMutationResolver<MutationRevokeTokenArgs, Scalars['String']>,
  saveCareer?: GraphCacheOptimisticMutationResolver<MutationSaveCareerArgs, Maybe<WithTypename<SaveCareerOutput>>>,
  saveFormVersionDraft?: GraphCacheOptimisticMutationResolver<MutationSaveFormVersionDraftArgs, Maybe<Scalars['Boolean']>>,
  saveJob?: GraphCacheOptimisticMutationResolver<MutationSaveJobArgs, WithTypename<JobService_SavedJob>>,
  saveLearning?: GraphCacheOptimisticMutationResolver<MutationSaveLearningArgs, Maybe<WithTypename<SaveLearningOutput>>>,
  saveResource?: GraphCacheOptimisticMutationResolver<MutationSaveResourceArgs, Maybe<WithTypename<SaveResourceOutput>>>,
  saveSubmission?: GraphCacheOptimisticMutationResolver<MutationSaveSubmissionArgs, Maybe<WithTypename<SubmissionResponse>>>,
  saveTalents?: GraphCacheOptimisticMutationResolver<MutationSaveTalentsArgs, Maybe<Array<WithTypename<SavedTalent>>>>,
  scriptUpdateLanguageRestriction?: GraphCacheOptimisticMutationResolver<Record<string, never>, Scalars['String']>,
  setDeepCaseManagementConfiguration?: GraphCacheOptimisticMutationResolver<MutationSetDeepCaseManagementConfigurationArgs, WithTypename<DeepCaseManagement>>,
  syncEap?: GraphCacheOptimisticMutationResolver<MutationSyncEapArgs, Scalars['Boolean']>,
  tagUsers?: GraphCacheOptimisticMutationResolver<MutationTagUsersArgs, Array<WithTypename<Profile>>>,
  toggleTagVisibility?: GraphCacheOptimisticMutationResolver<MutationToggleTagVisibilityArgs, WithTypename<Tag>>,
  unassignCoach?: GraphCacheOptimisticMutationResolver<MutationUnassignCoachArgs, Array<WithTypename<Profile>>>,
  unassignGroupCoaches?: GraphCacheOptimisticMutationResolver<MutationUnassignGroupCoachesArgs, Maybe<WithTypename<GroupCoachesResult>>>,
  unsaveCareer?: GraphCacheOptimisticMutationResolver<MutationUnsaveCareerArgs, Maybe<WithTypename<UnsaveCareerOutput>>>,
  unsaveLearning?: GraphCacheOptimisticMutationResolver<MutationUnsaveLearningArgs, Maybe<WithTypename<UnsaveLearningOutput>>>,
  unsaveResource?: GraphCacheOptimisticMutationResolver<MutationUnsaveResourceArgs, Maybe<WithTypename<UnsaveResourceOutput>>>,
  unsaveTalents?: GraphCacheOptimisticMutationResolver<MutationUnsaveTalentsArgs, Maybe<Array<WithTypename<SavedTalent>>>>,
  updateAccountPreferences?: GraphCacheOptimisticMutationResolver<MutationUpdateAccountPreferencesArgs, WithTypename<AccountPreferencesProfile>>,
  updateAsyncUpdateStatus?: GraphCacheOptimisticMutationResolver<MutationUpdateAsyncUpdateStatusArgs, WithTypename<AsyncUpdateStatus>>,
  updateCognitoEmployerIds?: GraphCacheOptimisticMutationResolver<MutationUpdateCognitoEmployerIdsArgs, WithTypename<CognitoUpdateResult>>,
  updateConfiguration?: GraphCacheOptimisticMutationResolver<MutationUpdateConfigurationArgs, Scalars['String']>,
  updateConfigurationV2?: GraphCacheOptimisticMutationResolver<MutationUpdateConfigurationV2Args, WithTypename<Configuration>>,
  updateDefaultQuestions?: GraphCacheOptimisticMutationResolver<MutationUpdateDefaultQuestionsArgs, Maybe<Scalars['String']>>,
  updateDraftRoadmap?: GraphCacheOptimisticMutationResolver<MutationUpdateDraftRoadmapArgs, WithTypename<Roadmap>>,
  updateEmployer?: GraphCacheOptimisticMutationResolver<MutationUpdateEmployerArgs, Maybe<WithTypename<Employer>>>,
  updateEmployerCaseNote?: GraphCacheOptimisticMutationResolver<MutationUpdateEmployerCaseNoteArgs, Maybe<WithTypename<EmployerCaseNote>>>,
  updateExportReport?: GraphCacheOptimisticMutationResolver<MutationUpdateExportReportArgs, WithTypename<ExportReport>>,
  updateFormVersion?: GraphCacheOptimisticMutationResolver<MutationUpdateFormVersionArgs, WithTypename<Form>>,
  updateGroup?: GraphCacheOptimisticMutationResolver<MutationUpdateGroupArgs, Maybe<WithTypename<Group>>>,
  updateIntegration?: GraphCacheOptimisticMutationResolver<MutationUpdateIntegrationArgs, Maybe<WithTypename<Integration>>>,
  updateInvitation?: GraphCacheOptimisticMutationResolver<MutationUpdateInvitationArgs, Maybe<WithTypename<Invitation>>>,
  updateInviteLink?: GraphCacheOptimisticMutationResolver<MutationUpdateInviteLinkArgs, Maybe<WithTypename<InviteLink>>>,
  updateJobConfiguration?: GraphCacheOptimisticMutationResolver<MutationUpdateJobConfigurationArgs, WithTypename<JobConfigurationOutput>>,
  updateJobSearchHistory?: GraphCacheOptimisticMutationResolver<MutationUpdateJobSearchHistoryArgs, Scalars['String']>,
  updateLearningProviderLogo?: GraphCacheOptimisticMutationResolver<MutationUpdateLearningProviderLogoArgs, WithTypename<LearningProviders>>,
  updateLibrary?: GraphCacheOptimisticMutationResolver<MutationUpdateLibraryArgs, WithTypename<LibraryQl>>,
  updateManualJob?: GraphCacheOptimisticMutationResolver<MutationUpdateManualJobArgs, WithTypename<JobService_ManualJobResponse>>,
  updateNextStepsConfigurations?: GraphCacheOptimisticMutationResolver<MutationUpdateNextStepsConfigurationsArgs, WithTypename<NextStepsConfigurations>>,
  updateNextStepsMetadata?: GraphCacheOptimisticMutationResolver<MutationUpdateNextStepsMetadataArgs, WithTypename<NextStepsMetadata>>,
  updateOnboardingFormActivationStatus?: GraphCacheOptimisticMutationResolver<MutationUpdateOnboardingFormActivationStatusArgs, Maybe<Scalars['Boolean']>>,
  updateProfileWithTagChecking?: GraphCacheOptimisticMutationResolver<MutationUpdateProfileWithTagCheckingArgs, WithTypename<Profile>>,
  updateRecruiter?: GraphCacheOptimisticMutationResolver<MutationUpdateRecruiterArgs, Maybe<WithTypename<Recruiter>>>,
  updateRegistrationConfiguration?: GraphCacheOptimisticMutationResolver<MutationUpdateRegistrationConfigurationArgs, WithTypename<Configuration>>,
  updateRegistrationForm?: GraphCacheOptimisticMutationResolver<MutationUpdateRegistrationFormArgs, Maybe<WithTypename<RegistrationForm>>>,
  updateSubmission?: GraphCacheOptimisticMutationResolver<MutationUpdateSubmissionArgs, Maybe<WithTypename<SubmissionResponse>>>,
  updateTenant?: GraphCacheOptimisticMutationResolver<MutationUpdateTenantArgs, Maybe<WithTypename<Tenant>>>,
  updateTenantWorkConfiguration?: GraphCacheOptimisticMutationResolver<MutationUpdateTenantWorkConfigurationArgs, Scalars['String']>,
  updateUserCaseNote?: GraphCacheOptimisticMutationResolver<MutationUpdateUserCaseNoteArgs, WithTypename<CaseNote>>,
  updateUserProfile?: GraphCacheOptimisticMutationResolver<MutationUpdateUserProfileArgs, WithTypename<Profile>>,
  updateUserProfileRole?: GraphCacheOptimisticMutationResolver<MutationUpdateUserProfileRoleArgs, WithTypename<UserProfileRole>>,
  updateUserRole?: GraphCacheOptimisticMutationResolver<MutationUpdateUserRoleArgs, WithTypename<Role>>,
  updateUserSkills?: GraphCacheOptimisticMutationResolver<MutationUpdateUserSkillsArgs, WithTypename<SkillModification>>,
  updateUserTenant?: GraphCacheOptimisticMutationResolver<MutationUpdateUserTenantArgs, Maybe<Array<WithTypename<UpdateUserTenantOutput>>>>,
  updateUserTenantForAccessControl?: GraphCacheOptimisticMutationResolver<MutationUpdateUserTenantForAccessControlArgs, Scalars['String']>,
  upsertAssessment?: GraphCacheOptimisticMutationResolver<MutationUpsertAssessmentArgs, WithTypename<Assessment>>,
  upsertFeature?: GraphCacheOptimisticMutationResolver<MutationUpsertFeatureArgs, WithTypename<Feature>>,
  upsertIrrelevantWorkExperienceSkills?: GraphCacheOptimisticMutationResolver<MutationUpsertIrrelevantWorkExperienceSkillsArgs, WithTypename<WorkExperience>>,
  upsertTag?: GraphCacheOptimisticMutationResolver<MutationUpsertTagArgs, WithTypename<Tag>>,
  upsertTags?: GraphCacheOptimisticMutationResolver<MutationUpsertTagsArgs, Array<WithTypename<Tag>>>,
  upsertTenant?: GraphCacheOptimisticMutationResolver<MutationUpsertTenantArgs, WithTypename<Tenant>>,
  upsertUserNextStep?: GraphCacheOptimisticMutationResolver<MutationUpsertUserNextStepArgs, WithTypename<UserNextStepsWrapper>>,
  upsertUserRole?: GraphCacheOptimisticMutationResolver<MutationUpsertUserRoleArgs, WithTypename<Role>>,
  userResumeUpload?: GraphCacheOptimisticMutationResolver<MutationUserResumeUploadArgs, WithTypename<UserType>>
};

export type GraphCacheUpdaters = {
  Mutation?: {
    BulkDeleteUserData?: GraphCacheUpdateResolver<{ BulkDeleteUserData: Maybe<WithTypename<BulkDeleteUserData_200_Response>> }, MutationBulkDeleteUserDataArgs>,
    LLMBuildResume?: GraphCacheUpdateResolver<{ LLMBuildResume: Maybe<WithTypename<LlmBuildResume_Response>> }, MutationLlmBuildResumeArgs>,
    LLMGenerateSkills?: GraphCacheUpdateResolver<{ LLMGenerateSkills: Maybe<WithTypename<LlmGenerateSkills_Response>> }, MutationLlmGenerateSkillsArgs>,
    LLMParseResume?: GraphCacheUpdateResolver<{ LLMParseResume: Maybe<WithTypename<LlmParseResume_Response>> }, MutationLlmParseResumeArgs>,
    UserResumeController_processResume?: GraphCacheUpdateResolver<{ UserResumeController_processResume: Maybe<Scalars['JSON']> }, MutationUserResumeController_ProcessResumeArgs>,
    addContact?: GraphCacheUpdateResolver<{ addContact: WithTypename<Contact> }, MutationAddContactArgs>,
    addMember?: GraphCacheUpdateResolver<{ addMember: Maybe<WithTypename<Member>> }, MutationAddMemberArgs>,
    addNextStepToLibrary?: GraphCacheUpdateResolver<{ addNextStepToLibrary: WithTypename<LibraryQl> }, MutationAddNextStepToLibraryArgs>,
    addTenantAsset?: GraphCacheUpdateResolver<{ addTenantAsset: WithTypename<AssetsDto> }, MutationAddTenantAssetArgs>,
    addUserCaseNote?: GraphCacheUpdateResolver<{ addUserCaseNote: WithTypename<CaseNote> }, MutationAddUserCaseNoteArgs>,
    addUserSkills?: GraphCacheUpdateResolver<{ addUserSkills: WithTypename<SkillModification> }, MutationAddUserSkillsArgs>,
    archiveAllJobs?: GraphCacheUpdateResolver<{ archiveAllJobs: WithTypename<JobService_ArchiveAllManualJobsResponse> }, Record<string, never>>,
    archiveForm?: GraphCacheUpdateResolver<{ archiveForm: Scalars['Boolean'] }, MutationArchiveFormArgs>,
    archiveManualJob?: GraphCacheUpdateResolver<{ archiveManualJob: WithTypename<JobService_ManualJobResponse> }, MutationArchiveManualJobArgs>,
    assignCoach?: GraphCacheUpdateResolver<{ assignCoach: Array<WithTypename<Profile>> }, MutationAssignCoachArgs>,
    assignGroupCoaches?: GraphCacheUpdateResolver<{ assignGroupCoaches: Maybe<WithTypename<GroupCoachesResult>> }, MutationAssignGroupCoachesArgs>,
    assignUserNextStep?: GraphCacheUpdateResolver<{ assignUserNextStep: WithTypename<UserNextStepsWrapper> }, MutationAssignUserNextStepArgs>,
    buildUserResume?: GraphCacheUpdateResolver<{ buildUserResume: WithTypename<BuildUserResumeResponse> }, Record<string, never>>,
    bulkUpdateProfiles?: GraphCacheUpdateResolver<{ bulkUpdateProfiles: WithTypename<BulkProfileUpdateResult> }, MutationBulkUpdateProfilesArgs>,
    clearCandidateCache?: GraphCacheUpdateResolver<{ clearCandidateCache: Maybe<Scalars['Boolean']> }, Record<string, never>>,
    comprehensiveDeleteTenant?: GraphCacheUpdateResolver<{ comprehensiveDeleteTenant: Maybe<WithTypename<ComprehensiveDeleteTenant_200_Response>> }, MutationComprehensiveDeleteTenantArgs>,
    configureTenant?: GraphCacheUpdateResolver<{ configureTenant: Scalars['String'] }, MutationConfigureTenantArgs>,
    confirmNextStepDoc?: GraphCacheUpdateResolver<{ confirmNextStepDoc: WithTypename<ConfirmNextStepDocResponse> }, MutationConfirmNextStepDocArgs>,
    confirmRecruiterRegistration?: GraphCacheUpdateResolver<{ confirmRecruiterRegistration: Maybe<WithTypename<ConfirmRegistrationResponse>> }, MutationConfirmRecruiterRegistrationArgs>,
    createAssessmentFromExternalData?: GraphCacheUpdateResolver<{ createAssessmentFromExternalData: WithTypename<Assessment> }, MutationCreateAssessmentFromExternalDataArgs>,
    createAsyncUpdateStatus?: GraphCacheUpdateResolver<{ createAsyncUpdateStatus: WithTypename<AsyncUpdateStatus> }, MutationCreateAsyncUpdateStatusArgs>,
    createConfigurationV2?: GraphCacheUpdateResolver<{ createConfigurationV2: WithTypename<Configuration> }, MutationCreateConfigurationV2Args>,
    createCustomerObjectUrl?: GraphCacheUpdateResolver<{ createCustomerObjectUrl: WithTypename<PreSignedUrlForCustomerType> }, MutationCreateCustomerObjectUrlArgs>,
    createDefaultQuestions?: GraphCacheUpdateResolver<{ createDefaultQuestions: Maybe<Scalars['String']> }, MutationCreateDefaultQuestionsArgs>,
    createEmployer?: GraphCacheUpdateResolver<{ createEmployer: Maybe<WithTypename<Employer>> }, MutationCreateEmployerArgs>,
    createEmployerCaseNote?: GraphCacheUpdateResolver<{ createEmployerCaseNote: Maybe<WithTypename<EmployerCaseNote>> }, MutationCreateEmployerCaseNoteArgs>,
    createForm?: GraphCacheUpdateResolver<{ createForm: WithTypename<Form> }, MutationCreateFormArgs>,
    createFormVersion?: GraphCacheUpdateResolver<{ createFormVersion: WithTypename<Form> }, MutationCreateFormVersionArgs>,
    createGroup?: GraphCacheUpdateResolver<{ createGroup: Maybe<WithTypename<Group>> }, MutationCreateGroupArgs>,
    createIntegration?: GraphCacheUpdateResolver<{ createIntegration: Maybe<WithTypename<Integration>> }, MutationCreateIntegrationArgs>,
    createInvitation?: GraphCacheUpdateResolver<{ createInvitation: Maybe<WithTypename<Invitation>> }, MutationCreateInvitationArgs>,
    createInviteLink?: GraphCacheUpdateResolver<{ createInviteLink: Maybe<WithTypename<InviteLink>> }, MutationCreateInviteLinkArgs>,
    createManualPostJob?: GraphCacheUpdateResolver<{ createManualPostJob: WithTypename<JobService_ManualJobResponse> }, MutationCreateManualPostJobArgs>,
    createMergeLinkToken?: GraphCacheUpdateResolver<{ createMergeLinkToken: Scalars['String'] }, MutationCreateMergeLinkTokenArgs>,
    createNewDraft?: GraphCacheUpdateResolver<{ createNewDraft: Maybe<WithTypename<CreateFormResponse>> }, MutationCreateNewDraftArgs>,
    createNewForm?: GraphCacheUpdateResolver<{ createNewForm: Maybe<WithTypename<CreateFormResponse>> }, MutationCreateNewFormArgs>,
    createNextStepsConfigurations?: GraphCacheUpdateResolver<{ createNextStepsConfigurations: WithTypename<NextStepsConfigurations> }, MutationCreateNextStepsConfigurationsArgs>,
    createNextStepsMetadata?: GraphCacheUpdateResolver<{ createNextStepsMetadata: WithTypename<NextStepsMetadata> }, MutationCreateNextStepsMetadataArgs>,
    createPostObjectURL?: GraphCacheUpdateResolver<{ createPostObjectURL: WithTypename<PreSignedUrlType> }, MutationCreatePostObjectUrlArgs>,
    createRecruiter?: GraphCacheUpdateResolver<{ createRecruiter: Maybe<WithTypename<Recruiter>> }, MutationCreateRecruiterArgs>,
    createRegistrationForm?: GraphCacheUpdateResolver<{ createRegistrationForm: Maybe<WithTypename<RegistrationForm>> }, MutationCreateRegistrationFormArgs>,
    createRegistrationSubmission?: GraphCacheUpdateResolver<{ createRegistrationSubmission: Maybe<Scalars['Boolean']> }, MutationCreateRegistrationSubmissionArgs>,
    createReportExport?: GraphCacheUpdateResolver<{ createReportExport: Scalars['Boolean'] }, MutationCreateReportExportArgs>,
    createRestApiClient?: GraphCacheUpdateResolver<{ createRestApiClient: Maybe<WithTypename<RestApiClient>> }, MutationCreateRestApiClientArgs>,
    createSubTenant?: GraphCacheUpdateResolver<{ createSubTenant: Maybe<WithTypename<Tenant>> }, MutationCreateSubTenantArgs>,
    createTenant?: GraphCacheUpdateResolver<{ createTenant: Maybe<WithTypename<Tenant>> }, MutationCreateTenantArgs>,
    createUserProfile?: GraphCacheUpdateResolver<{ createUserProfile: WithTypename<Profile> }, MutationCreateUserProfileArgs>,
    createUserRole?: GraphCacheUpdateResolver<{ createUserRole: WithTypename<Role> }, MutationCreateUserRoleArgs>,
    createUserTags?: GraphCacheUpdateResolver<{ createUserTags: Array<WithTypename<TaggingUserTag>> }, MutationCreateUserTagsArgs>,
    createUserTagsForTenant?: GraphCacheUpdateResolver<{ createUserTagsForTenant: Array<WithTypename<TaggingUserTag>> }, MutationCreateUserTagsForTenantArgs>,
    delNextStepDoc?: GraphCacheUpdateResolver<{ delNextStepDoc: WithTypename<DelNextStepDocResponse> }, MutationDelNextStepDocArgs>,
    deleteCachedResumeData?: GraphCacheUpdateResolver<{ deleteCachedResumeData: Maybe<WithTypename<DeleteCachedResumeData_200_Response>> }, MutationDeleteCachedResumeDataArgs>,
    deleteContactsFromUser?: GraphCacheUpdateResolver<{ deleteContactsFromUser: Array<WithTypename<Contact>> }, MutationDeleteContactsFromUserArgs>,
    deleteDataByTenant?: GraphCacheUpdateResolver<{ deleteDataByTenant: WithTypename<DeleteConfigurationOutput> }, MutationDeleteDataByTenantArgs>,
    deleteEmployer?: GraphCacheUpdateResolver<{ deleteEmployer: Maybe<Scalars['Int']> }, MutationDeleteEmployerArgs>,
    deleteEmployerCaseNote?: GraphCacheUpdateResolver<{ deleteEmployerCaseNote: Maybe<WithTypename<EmployerCaseNote>> }, MutationDeleteEmployerCaseNoteArgs>,
    deleteEmployerDataByTenant?: GraphCacheUpdateResolver<{ deleteEmployerDataByTenant: Maybe<WithTypename<DeleteEmployersByTenantResponse>> }, MutationDeleteEmployerDataByTenantArgs>,
    deleteExportReportsByTenant?: GraphCacheUpdateResolver<{ deleteExportReportsByTenant: WithTypename<DeleteExportReportsOutput> }, MutationDeleteExportReportsByTenantArgs>,
    deleteFeature?: GraphCacheUpdateResolver<{ deleteFeature: Scalars['String'] }, MutationDeleteFeatureArgs>,
    deleteForm?: GraphCacheUpdateResolver<{ deleteForm: Maybe<Scalars['Boolean']> }, MutationDeleteFormArgs>,
    deleteFormBuilderData?: GraphCacheUpdateResolver<{ deleteFormBuilderData: Maybe<WithTypename<DeleteFormBuilderData_200_Response>> }, MutationDeleteFormBuilderDataArgs>,
    deleteInvitation?: GraphCacheUpdateResolver<{ deleteInvitation: Maybe<Scalars['Void']> }, MutationDeleteInvitationArgs>,
    deletePolicyAssets?: GraphCacheUpdateResolver<{ deletePolicyAssets: WithTypename<Configuration> }, MutationDeletePolicyAssetsArgs>,
    deleteProfiles?: GraphCacheUpdateResolver<{ deleteProfiles: WithTypename<DeleteProfilesOutput> }, MutationDeleteProfilesArgs>,
    deleteRecruiter?: GraphCacheUpdateResolver<{ deleteRecruiter: Maybe<WithTypename<Recruiter>> }, MutationDeleteRecruiterArgs>,
    deleteRecruiters?: GraphCacheUpdateResolver<{ deleteRecruiters: Maybe<Array<WithTypename<Mutation_DeleteRecruiters_Items>>> }, MutationDeleteRecruitersArgs>,
    deleteRecruitersByUserUuids?: GraphCacheUpdateResolver<{ deleteRecruitersByUserUuids: Maybe<Array<WithTypename<Mutation_DeleteRecruiters_Items>>> }, MutationDeleteRecruitersByUserUuidsArgs>,
    deleteResume?: GraphCacheUpdateResolver<{ deleteResume: Maybe<WithTypename<DeleteResume_200_Response>> }, MutationDeleteResumeArgs>,
    deleteSavedJob?: GraphCacheUpdateResolver<{ deleteSavedJob: WithTypename<JobService_SavedJob> }, MutationDeleteSavedJobArgs>,
    deleteTag?: GraphCacheUpdateResolver<{ deleteTag: Scalars['String'] }, MutationDeleteTagArgs>,
    deleteTagsByTenant?: GraphCacheUpdateResolver<{ deleteTagsByTenant: Scalars['String'] }, MutationDeleteTagsByTenantArgs>,
    deleteTenant?: GraphCacheUpdateResolver<{ deleteTenant: Maybe<Scalars['Void']> }, MutationDeleteTenantArgs>,
    deleteTenantFeatures?: GraphCacheUpdateResolver<{ deleteTenantFeatures: Scalars['String'] }, MutationDeleteTenantFeaturesArgs>,
    deleteTenantFromAccessControl?: GraphCacheUpdateResolver<{ deleteTenantFromAccessControl: Maybe<Scalars['String']> }, MutationDeleteTenantFromAccessControlArgs>,
    deleteTenantNextSteps?: GraphCacheUpdateResolver<{ deleteTenantNextSteps: WithTypename<DeleteTenantNextStepsOutput> }, MutationDeleteTenantNextStepsArgs>,
    deleteUserCaseNote?: GraphCacheUpdateResolver<{ deleteUserCaseNote: Scalars['String'] }, MutationDeleteUserCaseNoteArgs>,
    deleteUserNextStep?: GraphCacheUpdateResolver<{ deleteUserNextStep: WithTypename<DeleteUserNextStepsOutput> }, MutationDeleteUserNextStepArgs>,
    deleteUserNextSteps?: GraphCacheUpdateResolver<{ deleteUserNextSteps: WithTypename<DeleteUserNextStepsOutput> }, MutationDeleteUserNextStepsArgs>,
    deleteUserTags?: GraphCacheUpdateResolver<{ deleteUserTags: Array<WithTypename<TaggingUserTag>> }, MutationDeleteUserTagsArgs>,
    deleteUsers?: GraphCacheUpdateResolver<{ deleteUsers: Scalars['Boolean'] }, MutationDeleteUsersArgs>,
    deleteUsersFromAccessControl?: GraphCacheUpdateResolver<{ deleteUsersFromAccessControl: WithTypename<DeleteUsersOutput> }, MutationDeleteUsersFromAccessControlArgs>,
    deleteUsersFromAssessments?: GraphCacheUpdateResolver<{ deleteUsersFromAssessments: WithTypename<DeleteUsersOutput> }, MutationDeleteUsersFromAssessmentsArgs>,
    disableTenantFeatures?: GraphCacheUpdateResolver<{ disableTenantFeatures: Array<Scalars['String']> }, MutationDisableTenantFeaturesArgs>,
    disableUserFeatures?: GraphCacheUpdateResolver<{ disableUserFeatures: Scalars['String'] }, MutationDisableUserFeaturesArgs>,
    easyApplyToATSJob?: GraphCacheUpdateResolver<{ easyApplyToATSJob: WithTypename<JobService_EasyApplyToAtsJobResponse> }, MutationEasyApplyToAtsJobArgs>,
    enableTenantFeatures?: GraphCacheUpdateResolver<{ enableTenantFeatures: Array<WithTypename<Feature>> }, MutationEnableTenantFeaturesArgs>,
    enableTenantFeaturesBulk?: GraphCacheUpdateResolver<{ enableTenantFeaturesBulk: WithTypename<TenantFeatureBulkResult> }, MutationEnableTenantFeaturesBulkArgs>,
    enableUserFeatures?: GraphCacheUpdateResolver<{ enableUserFeatures: Array<WithTypename<Feature>> }, MutationEnableUserFeaturesArgs>,
    exchangeMergeAccountToken?: GraphCacheUpdateResolver<{ exchangeMergeAccountToken: Scalars['String'] }, MutationExchangeMergeAccountTokenArgs>,
    exportProfileAuthorize?: GraphCacheUpdateResolver<{ exportProfileAuthorize: WithTypename<ExportProfileAuthorizeResponseData> }, MutationExportProfileAuthorizeArgs>,
    exportProfileDownloadCsvFile?: GraphCacheUpdateResolver<{ exportProfileDownloadCsvFile: WithTypename<ExportProfileDownloadCsvFileResponseData> }, MutationExportProfileDownloadCsvFileArgs>,
    getCandidateRecommendationsByID?: GraphCacheUpdateResolver<{ getCandidateRecommendationsByID: Maybe<WithTypename<CandidateRecommender_Candidate>> }, MutationGetCandidateRecommendationsByIdArgs>,
    getSkillsFromCareer?: GraphCacheUpdateResolver<{ getSkillsFromCareer: WithTypename<JobService_SkillsResponse> }, MutationGetSkillsFromCareerArgs>,
    inviteToVervoeAssessment?: GraphCacheUpdateResolver<{ inviteToVervoeAssessment: WithTypename<VervoeInviteToAssessmentResponse> }, MutationInviteToVervoeAssessmentArgs>,
    leadSignUp?: GraphCacheUpdateResolver<{ leadSignUp: Scalars['Boolean'] }, MutationLeadSignUpArgs>,
    manageDeleteNextStep?: GraphCacheUpdateResolver<{ manageDeleteNextStep: Scalars['Boolean'] }, MutationManageDeleteNextStepArgs>,
    moveUser?: GraphCacheUpdateResolver<{ moveUser: Scalars['String'] }, MutationMoveUserArgs>,
    moveUsers?: GraphCacheUpdateResolver<{ moveUsers: Scalars['String'] }, MutationMoveUsersArgs>,
    processSearchQuery?: GraphCacheUpdateResolver<{ processSearchQuery: Maybe<WithTypename<ProcessSearchQuery_Response>> }, MutationProcessSearchQueryArgs>,
    provisionTenant?: GraphCacheUpdateResolver<{ provisionTenant: Maybe<WithTypename<Tenant>> }, MutationProvisionTenantArgs>,
    publishForm?: GraphCacheUpdateResolver<{ publishForm: Maybe<Scalars['Boolean']> }, MutationPublishFormArgs>,
    publishRoadmap?: GraphCacheUpdateResolver<{ publishRoadmap: WithTypename<Roadmap> }, MutationPublishRoadmapArgs>,
    removeAsyncUpdateStatus?: GraphCacheUpdateResolver<{ removeAsyncUpdateStatus: Scalars['Boolean'] }, MutationRemoveAsyncUpdateStatusArgs>,
    removeMember?: GraphCacheUpdateResolver<{ removeMember: Maybe<Scalars['Void']> }, MutationRemoveMemberArgs>,
    removeUserSkills?: GraphCacheUpdateResolver<{ removeUserSkills: WithTypename<SkillModification> }, MutationRemoveUserSkillsArgs>,
    removeUsersFromFormBuilder?: GraphCacheUpdateResolver<{ removeUsersFromFormBuilder: Maybe<WithTypename<RemoveUsersFromFormBuilderResponse>> }, MutationRemoveUsersFromFormBuilderArgs>,
    removeUsersFromSaveCareer?: GraphCacheUpdateResolver<{ removeUsersFromSaveCareer: Maybe<WithTypename<RemoveUsersOutput>> }, MutationRemoveUsersFromSaveCareerArgs>,
    removeUsersFromSaveLearning?: GraphCacheUpdateResolver<{ removeUsersFromSaveLearning: Maybe<WithTypename<RemoveUsersOutput>> }, MutationRemoveUsersFromSaveLearningArgs>,
    removeUsersFromSaveResource?: GraphCacheUpdateResolver<{ removeUsersFromSaveResource: Maybe<WithTypename<RemoveUsersOutput>> }, MutationRemoveUsersFromSaveResourceArgs>,
    requestSubmissionsExportation?: GraphCacheUpdateResolver<{ requestSubmissionsExportation: Maybe<Scalars['Boolean']> }, MutationRequestSubmissionsExportationArgs>,
    resetProfile?: GraphCacheUpdateResolver<{ resetProfile: WithTypename<Profile> }, MutationResetProfileArgs>,
    resetUserNextSteps?: GraphCacheUpdateResolver<{ resetUserNextSteps: WithTypename<UserNextStepsWrapper> }, MutationResetUserNextStepsArgs>,
    revokeToken?: GraphCacheUpdateResolver<{ revokeToken: Scalars['String'] }, MutationRevokeTokenArgs>,
    saveCareer?: GraphCacheUpdateResolver<{ saveCareer: Maybe<WithTypename<SaveCareerOutput>> }, MutationSaveCareerArgs>,
    saveFormVersionDraft?: GraphCacheUpdateResolver<{ saveFormVersionDraft: Maybe<Scalars['Boolean']> }, MutationSaveFormVersionDraftArgs>,
    saveJob?: GraphCacheUpdateResolver<{ saveJob: WithTypename<JobService_SavedJob> }, MutationSaveJobArgs>,
    saveLearning?: GraphCacheUpdateResolver<{ saveLearning: Maybe<WithTypename<SaveLearningOutput>> }, MutationSaveLearningArgs>,
    saveResource?: GraphCacheUpdateResolver<{ saveResource: Maybe<WithTypename<SaveResourceOutput>> }, MutationSaveResourceArgs>,
    saveSubmission?: GraphCacheUpdateResolver<{ saveSubmission: Maybe<WithTypename<SubmissionResponse>> }, MutationSaveSubmissionArgs>,
    saveTalents?: GraphCacheUpdateResolver<{ saveTalents: Maybe<Array<WithTypename<SavedTalent>>> }, MutationSaveTalentsArgs>,
    scriptUpdateLanguageRestriction?: GraphCacheUpdateResolver<{ scriptUpdateLanguageRestriction: Scalars['String'] }, Record<string, never>>,
    setDeepCaseManagementConfiguration?: GraphCacheUpdateResolver<{ setDeepCaseManagementConfiguration: WithTypename<DeepCaseManagement> }, MutationSetDeepCaseManagementConfigurationArgs>,
    syncEap?: GraphCacheUpdateResolver<{ syncEap: Scalars['Boolean'] }, MutationSyncEapArgs>,
    tagUsers?: GraphCacheUpdateResolver<{ tagUsers: Array<WithTypename<Profile>> }, MutationTagUsersArgs>,
    toggleTagVisibility?: GraphCacheUpdateResolver<{ toggleTagVisibility: WithTypename<Tag> }, MutationToggleTagVisibilityArgs>,
    unassignCoach?: GraphCacheUpdateResolver<{ unassignCoach: Array<WithTypename<Profile>> }, MutationUnassignCoachArgs>,
    unassignGroupCoaches?: GraphCacheUpdateResolver<{ unassignGroupCoaches: Maybe<WithTypename<GroupCoachesResult>> }, MutationUnassignGroupCoachesArgs>,
    unsaveCareer?: GraphCacheUpdateResolver<{ unsaveCareer: Maybe<WithTypename<UnsaveCareerOutput>> }, MutationUnsaveCareerArgs>,
    unsaveLearning?: GraphCacheUpdateResolver<{ unsaveLearning: Maybe<WithTypename<UnsaveLearningOutput>> }, MutationUnsaveLearningArgs>,
    unsaveResource?: GraphCacheUpdateResolver<{ unsaveResource: Maybe<WithTypename<UnsaveResourceOutput>> }, MutationUnsaveResourceArgs>,
    unsaveTalents?: GraphCacheUpdateResolver<{ unsaveTalents: Maybe<Array<WithTypename<SavedTalent>>> }, MutationUnsaveTalentsArgs>,
    updateAccountPreferences?: GraphCacheUpdateResolver<{ updateAccountPreferences: WithTypename<AccountPreferencesProfile> }, MutationUpdateAccountPreferencesArgs>,
    updateAsyncUpdateStatus?: GraphCacheUpdateResolver<{ updateAsyncUpdateStatus: WithTypename<AsyncUpdateStatus> }, MutationUpdateAsyncUpdateStatusArgs>,
    updateCognitoEmployerIds?: GraphCacheUpdateResolver<{ updateCognitoEmployerIds: WithTypename<CognitoUpdateResult> }, MutationUpdateCognitoEmployerIdsArgs>,
    updateConfiguration?: GraphCacheUpdateResolver<{ updateConfiguration: Scalars['String'] }, MutationUpdateConfigurationArgs>,
    updateConfigurationV2?: GraphCacheUpdateResolver<{ updateConfigurationV2: WithTypename<Configuration> }, MutationUpdateConfigurationV2Args>,
    updateDefaultQuestions?: GraphCacheUpdateResolver<{ updateDefaultQuestions: Maybe<Scalars['String']> }, MutationUpdateDefaultQuestionsArgs>,
    updateDraftRoadmap?: GraphCacheUpdateResolver<{ updateDraftRoadmap: WithTypename<Roadmap> }, MutationUpdateDraftRoadmapArgs>,
    updateEmployer?: GraphCacheUpdateResolver<{ updateEmployer: Maybe<WithTypename<Employer>> }, MutationUpdateEmployerArgs>,
    updateEmployerCaseNote?: GraphCacheUpdateResolver<{ updateEmployerCaseNote: Maybe<WithTypename<EmployerCaseNote>> }, MutationUpdateEmployerCaseNoteArgs>,
    updateExportReport?: GraphCacheUpdateResolver<{ updateExportReport: WithTypename<ExportReport> }, MutationUpdateExportReportArgs>,
    updateFormVersion?: GraphCacheUpdateResolver<{ updateFormVersion: WithTypename<Form> }, MutationUpdateFormVersionArgs>,
    updateGroup?: GraphCacheUpdateResolver<{ updateGroup: Maybe<WithTypename<Group>> }, MutationUpdateGroupArgs>,
    updateIntegration?: GraphCacheUpdateResolver<{ updateIntegration: Maybe<WithTypename<Integration>> }, MutationUpdateIntegrationArgs>,
    updateInvitation?: GraphCacheUpdateResolver<{ updateInvitation: Maybe<WithTypename<Invitation>> }, MutationUpdateInvitationArgs>,
    updateInviteLink?: GraphCacheUpdateResolver<{ updateInviteLink: Maybe<WithTypename<InviteLink>> }, MutationUpdateInviteLinkArgs>,
    updateJobConfiguration?: GraphCacheUpdateResolver<{ updateJobConfiguration: WithTypename<JobConfigurationOutput> }, MutationUpdateJobConfigurationArgs>,
    updateJobSearchHistory?: GraphCacheUpdateResolver<{ updateJobSearchHistory: Scalars['String'] }, MutationUpdateJobSearchHistoryArgs>,
    updateLearningProviderLogo?: GraphCacheUpdateResolver<{ updateLearningProviderLogo: WithTypename<LearningProviders> }, MutationUpdateLearningProviderLogoArgs>,
    updateLibrary?: GraphCacheUpdateResolver<{ updateLibrary: WithTypename<LibraryQl> }, MutationUpdateLibraryArgs>,
    updateManualJob?: GraphCacheUpdateResolver<{ updateManualJob: WithTypename<JobService_ManualJobResponse> }, MutationUpdateManualJobArgs>,
    updateNextStepsConfigurations?: GraphCacheUpdateResolver<{ updateNextStepsConfigurations: WithTypename<NextStepsConfigurations> }, MutationUpdateNextStepsConfigurationsArgs>,
    updateNextStepsMetadata?: GraphCacheUpdateResolver<{ updateNextStepsMetadata: WithTypename<NextStepsMetadata> }, MutationUpdateNextStepsMetadataArgs>,
    updateOnboardingFormActivationStatus?: GraphCacheUpdateResolver<{ updateOnboardingFormActivationStatus: Maybe<Scalars['Boolean']> }, MutationUpdateOnboardingFormActivationStatusArgs>,
    updateProfileWithTagChecking?: GraphCacheUpdateResolver<{ updateProfileWithTagChecking: WithTypename<Profile> }, MutationUpdateProfileWithTagCheckingArgs>,
    updateRecruiter?: GraphCacheUpdateResolver<{ updateRecruiter: Maybe<WithTypename<Recruiter>> }, MutationUpdateRecruiterArgs>,
    updateRegistrationConfiguration?: GraphCacheUpdateResolver<{ updateRegistrationConfiguration: WithTypename<Configuration> }, MutationUpdateRegistrationConfigurationArgs>,
    updateRegistrationForm?: GraphCacheUpdateResolver<{ updateRegistrationForm: Maybe<WithTypename<RegistrationForm>> }, MutationUpdateRegistrationFormArgs>,
    updateSubmission?: GraphCacheUpdateResolver<{ updateSubmission: Maybe<WithTypename<SubmissionResponse>> }, MutationUpdateSubmissionArgs>,
    updateTenant?: GraphCacheUpdateResolver<{ updateTenant: Maybe<WithTypename<Tenant>> }, MutationUpdateTenantArgs>,
    updateTenantWorkConfiguration?: GraphCacheUpdateResolver<{ updateTenantWorkConfiguration: Scalars['String'] }, MutationUpdateTenantWorkConfigurationArgs>,
    updateUserCaseNote?: GraphCacheUpdateResolver<{ updateUserCaseNote: WithTypename<CaseNote> }, MutationUpdateUserCaseNoteArgs>,
    updateUserProfile?: GraphCacheUpdateResolver<{ updateUserProfile: WithTypename<Profile> }, MutationUpdateUserProfileArgs>,
    updateUserProfileRole?: GraphCacheUpdateResolver<{ updateUserProfileRole: WithTypename<UserProfileRole> }, MutationUpdateUserProfileRoleArgs>,
    updateUserRole?: GraphCacheUpdateResolver<{ updateUserRole: WithTypename<Role> }, MutationUpdateUserRoleArgs>,
    updateUserSkills?: GraphCacheUpdateResolver<{ updateUserSkills: WithTypename<SkillModification> }, MutationUpdateUserSkillsArgs>,
    updateUserTenant?: GraphCacheUpdateResolver<{ updateUserTenant: Maybe<Array<WithTypename<UpdateUserTenantOutput>>> }, MutationUpdateUserTenantArgs>,
    updateUserTenantForAccessControl?: GraphCacheUpdateResolver<{ updateUserTenantForAccessControl: Scalars['String'] }, MutationUpdateUserTenantForAccessControlArgs>,
    upsertAssessment?: GraphCacheUpdateResolver<{ upsertAssessment: WithTypename<Assessment> }, MutationUpsertAssessmentArgs>,
    upsertFeature?: GraphCacheUpdateResolver<{ upsertFeature: WithTypename<Feature> }, MutationUpsertFeatureArgs>,
    upsertIrrelevantWorkExperienceSkills?: GraphCacheUpdateResolver<{ upsertIrrelevantWorkExperienceSkills: WithTypename<WorkExperience> }, MutationUpsertIrrelevantWorkExperienceSkillsArgs>,
    upsertTag?: GraphCacheUpdateResolver<{ upsertTag: WithTypename<Tag> }, MutationUpsertTagArgs>,
    upsertTags?: GraphCacheUpdateResolver<{ upsertTags: Array<WithTypename<Tag>> }, MutationUpsertTagsArgs>,
    upsertTenant?: GraphCacheUpdateResolver<{ upsertTenant: WithTypename<Tenant> }, MutationUpsertTenantArgs>,
    upsertUserNextStep?: GraphCacheUpdateResolver<{ upsertUserNextStep: WithTypename<UserNextStepsWrapper> }, MutationUpsertUserNextStepArgs>,
    upsertUserRole?: GraphCacheUpdateResolver<{ upsertUserRole: WithTypename<Role> }, MutationUpsertUserRoleArgs>,
    userResumeUpload?: GraphCacheUpdateResolver<{ userResumeUpload: WithTypename<UserType> }, MutationUserResumeUploadArgs>
  },
  Subscription?: {},
};

export type GraphCacheConfig = {
  schema?: IntrospectionData,
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
  storage?: GraphCacheStorageAdapter
};